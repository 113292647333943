import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { cThemeColorType } from "../../../app/constants";
import Button, { EButtonVariant } from "../../Button/Button";
import { IClearFn } from "../FormField";
import styles from "./ClearField.module.scss";

interface IClearField {
  onClear: IClearFn;
  fieldRef: any;
  className?: string;
}

/**
 * A button to clear a form field
 * @param onClear   Function to run when clicking clear button
 * @param fieldRef  The ref of the field to be cleared
 * @param className Custom classes
 * @returns JSX.Element
 */
function ClearField({ onClear, fieldRef, className }: IClearField): JSX.Element {
  /**
   * Handle click of the clear button
   */
  function handleClick(): void {
    onClear(fieldRef);
  }

  const clx = classNames(styles.className, className);

  return (
    <Button
      testId="clear-button"
      variant={EButtonVariant.Round}
      onClick={handleClick}
      className={clx}
      color={cThemeColorType.Secondary}
    >
      <FontAwesomeIcon icon={faTimes} />
    </Button>
  );
}

export default ClearField;
