import Div from "../../Div/Div";
import Column from "../../Row/Column/Column";
import Row from "../../Row/Row";
import { IFormField } from "../FormField";

type TAffixWrapper = {
  children: React.ReactNode;
} & Pick<IFormField, "prepend" | "append" | "fullWidth">;

/**
 * Form field affix wrapper - handles prepend and append components
 * @param children  Component children - form field
 * @param prepend   The component to prepend to the field
 * @param append    The component to append to the field
 * @param fullWidth Is the field full width?
 * @returns JSX.Element
 */
function AffixWrapper({ children, prepend, append, fullWidth }: TAffixWrapper): JSX.Element {
  const spacingValue = 4;

  return (
    <>
      {prepend || append ? (
        <Row alignItems={{ base: "center" }}>
          <>
            {prepend && (
              <Column grow={false}>
                <Div spacing={{ pr: spacingValue }}>{prepend}</Div>
              </Column>
            )}
            <Column grow={fullWidth || false}>{children}</Column>
            {append && (
              <Column grow={false}>
                <Div spacing={{ pl: spacingValue }}>{append}</Div>
              </Column>
            )}
          </>
        </Row>
      ) : (
        <>{children}</>
      )}
    </>
  );
}

export default AffixWrapper;
