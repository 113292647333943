import { ReactNode } from "react";
import { FallbackProps } from "react-error-boundary";
import { IXDirection } from "../../app/types";
import Div from "../Div/Div";
import ExternalPageWrapper from "../ExternalPageWrapper/ExternalPageWrapper";
import Typography from "../Typography/Typography";
import styles from "./ErrorFallback.module.scss";

/**
 * This is rendered from ErrorBoundary in the event of a component error
 * @param error The error message that was thrown
 * @returns JSX.Element
 */

function ErrorFallback({ error }: Partial<FallbackProps>): JSX.Element {
  const errorCode = parseInt(String(error) || "", 10);
  let errorMessage: ReactNode = "";

  // If customer code is invalid, or if local storage is not set
  // check if the error is a number other than 0
  if (!isNaN(errorCode) && errorCode !== 0) {
    errorMessage = (
      <>
        <Typography variant="h3" className={styles.title} align={IXDirection.Center}>
          There appears to be a problem
        </Typography>
        <Typography align={IXDirection.Center} className={styles.text}>
          <p>Please contact your system administrator and provide code {errorCode}</p>
        </Typography>
      </>
    );
  } else {
    // Generic error fallback
    errorMessage = "Something went wrong, please refresh the page.";
  }

  return (
    <ExternalPageWrapper>
      <Div testId="error-fallback">
        <Div className={styles.textWrapper}>{errorMessage}</Div>
      </Div>
    </ExternalPageWrapper>
  );
}

export default ErrorFallback;
