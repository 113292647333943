import { useEffect, useState } from "react";
import {
  Control,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import { cStatusType, cThemeColorType } from "../../../../../app/constants";
import { useAppSelector } from "../../../../../app/hooks";
import Button, { cButtonType } from "../../../../../components/Button/Button";
import DisplayError from "../../../../../components/DisplayError/DisplayError";
import Div from "../../../../../components/Div/Div";
import FormField, { cFormFieldType, IFieldOption } from "../../../../../components/FormField/FormField";
import InfoBox, { EInfoBoxVariant } from "../../../../../components/InfoBox/InfoBox";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../../components/Modal/Modal";
import { selectStreamsEnabled } from "../../../../../modules/customerSlice";
import { selectAddUserError, selectUsersStatus } from "../../../../../modules/usersSlice";
import { isValidEmail } from "../../../../../utils/isValidEmail/isValidEmail";
import { Inputs } from "../Users";
import styles from "./AddUserModal.module.scss";

interface IAddUserModal {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: UseFormHandleSubmit<Inputs>;
  onSubmit: SubmitHandler<Inputs>;
  register: UseFormRegister<Inputs>;
  errors: FieldErrors<Inputs>;
  watch: UseFormWatch<any>;
  control: Control<Inputs, any>;
  notifyGroupOptions: IFieldOption[];
  securityGroupOptions: IFieldOption[];
  allowSecurityGroups: boolean;
  isFormReset: () => boolean;
  isEdit?: boolean;
  reset: () => void;
  dispatchResetEditUser: () => void;
}

/**
 * The add user user modal
 * @param isOpen                Is the modal open?
 * @param handleClose           Function to close the modal
 * @param handleSubmit          React hook form handleSubmit function
 * @param register              React hook form register function
 * @param errors                React hook form errors
 * @param watch                 React hook form watch function
 * @param onSubmit              React hook form onSubmit function
 * @param control               React hook form control
 * @param notifyGroupOptions    The notify group options
 * @param securityGroupOptions  The security group options
 * @param allowSecurityGroups   Are security groups allowed?
 * @param reset                 RHF reset function
 * @param dispatchResetEditUser Dispatch edit user reset to state
 * @returns JSX.Element
 */
function AddUserModal({
  isOpen,
  handleClose,
  handleSubmit,
  register,
  errors,
  watch,
  onSubmit,
  control,
  notifyGroupOptions,
  securityGroupOptions,
  allowSecurityGroups,
  isFormReset,
  isEdit,
  reset,
  dispatchResetEditUser,
}: IAddUserModal) {
  const spacing = { mb: 5 }; // Field spacing
  const error = useAppSelector(selectAddUserError);
  const status = useAppSelector(selectUsersStatus);
  const streamsEnabled = useAppSelector(selectStreamsEnabled);
  const isSubmitting = status === cStatusType.Loading;
  const [title, setTitle] = useState("Add a New User");
  const isTeamLead = watch("isTeamLead");

  // Track the initial value of isTeamLead switch
  const [initialIsTeamLead, setInitialIsTeamLead] = useState<boolean>(false);

  // Set the initial value of isTeamLead when the modal opens
  useEffect(() => {
    if (isOpen) {
      setInitialIsTeamLead(isTeamLead);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isEdit) {
      setTitle("Edit User");
    } else {
      setTitle("Add a New User");
    }
  }, [isEdit]);

  useEffect(() => {
    if (isOpen === false) {
      reset(); // Reset form on close
      dispatchResetEditUser();
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} testId="add-user-modal" scrollable>
      <ModalHeader hasBoxShadow>
        <ModalTitle testId="add-user-modal-header">{title}</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <form id="add-user-form" onSubmit={handleSubmit(onSubmit)}>
          <FormField label="User id" name="id" register={register} error={errors.id} fullWidth hidden />
          <FormField
            label="First name"
            name="firstName"
            register={register}
            error={errors.firstName}
            spacing={spacing}
            required
            fullWidth
            data-lpignore={true}
            pattern={{ value: /^[A-Za-z ]+$/, message: "A-Z,a-z and space only" }}
          />
          <FormField
            label="Last name"
            name="lastName"
            register={register}
            error={errors.lastName}
            spacing={spacing}
            required
            fullWidth
            data-lpignore={true}
            pattern={{ value: /^[A-Za-z ]+$/, message: "A-Z,a-z and space only" }}
          />
          <FormField
            label="Preferred name"
            name="preferredName"
            register={register}
            error={errors.preferredName}
            spacing={spacing}
            required
            fullWidth
          />
          <FormField
            label="Email"
            name="emailAddress"
            register={register}
            error={errors.emailAddress}
            spacing={spacing}
            required
            fullWidth
            validate={(value: string) => {
              if (!isValidEmail(value)) {
                return "Email is invalid";
              }
            }}
          />
          <FormField
            label="Confirm email"
            name="confirmEmailAddress"
            register={register}
            error={errors.confirmEmailAddress}
            spacing={spacing}
            required
            fullWidth
            validate={(value: string) => {
              if (!isValidEmail(value)) {
                return "Email is invalid";
              }
              if (watch("emailAddress") !== value) {
                return "Emails do no match";
              }
            }}
          />
          <FormField
            label="Phone number"
            name="phoneNumber"
            type={cFormFieldType.Telephone}
            register={register}
            error={errors.phoneNumber}
            spacing={spacing}
            fullWidth
            control={control as Control<any, any>}
            validate={(value: string) => {
              if (value) {
                if (value !== null && !isValidPhoneNumber(value)) {
                  return "Phone number is invalid";
                }
              }
            }}
          />
          <FormField
            label="Notification group"
            name="notifyGroup"
            type={cFormFieldType.Select}
            options={notifyGroupOptions}
            register={register}
            error={errors.notifyGroup as any}
            spacing={spacing}
            control={control as Control<any, any>}
            fullWidth
            required
          />
          <FormField
            label="Security groups"
            type={cFormFieldType.Select}
            name="securityGroups"
            options={securityGroupOptions}
            register={register}
            error={errors.securityGroups as any}
            spacing={spacing}
            control={control as Control<any, any>}
            isMulti
            fullWidth
            hidden={!allowSecurityGroups}
          />
          <FormField
            name="isAdmin"
            type={cFormFieldType.Switch}
            register={register}
            error={errors.isAdmin}
            spacing={spacing}
            fullWidth
            options={[
              {
                label: "Administrator",
                value: "true",
              },
            ]}
          />
          {streamsEnabled && (
            <FormField
              name="isTeamLead"
              type={cFormFieldType.Switch}
              register={register}
              error={errors.isTeamLead}
              spacing={spacing}
              fullWidth
              options={[
                {
                  label: "Team lead",
                  value: "true",
                },
              ]}
              testId="team-lead-switch"
            />
          )}
          {initialIsTeamLead && !isTeamLead && (
            <InfoBox variant={EInfoBoxVariant.Warning}>
              <Div className={styles.warning}>Any tasks assigned to this user will be reassigned</Div>
            </InfoBox>
          )}
          {error && <DisplayError>{error}</DisplayError>}
        </form>
      </ModalContent>
      <ModalActions hasBoxShadow>
        <Button color={cThemeColorType.Secondary} onClick={handleClose} testId="add-user-modal-close-button">
          Cancel
        </Button>
        <Button
          formId="add-user-form"
          type={cButtonType.SubmitType}
          isLoading={isSubmitting}
          disabled={isEdit && isFormReset()}
          testId="add-user-modal-submit-button"
        >
          Save
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default AddUserModal;
