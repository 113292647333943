import { cMonoColorType } from "../../../../../../app/constants";
import { IXDirection } from "../../../../../../app/types";
import Button, { EButtonVariant } from "../../../../../../components/Button/Button";
import Div from "../../../../../../components/Div/Div";
import Icon, { EIcon } from "../../../../../../components/Icon/Icon";
import Typography from "../../../../../../components/Typography/Typography";
import { ICollaborator } from "../ManageCollaborationModal";
import styles from "./CollaboratorsList.module.scss";

interface ICollaboratorsList {
  collaborators: ICollaborator[];
}

/**
 * Collaborators list
 * @param collaborators The collaborators
 * @returns JSX.Element
 */
function CollaboratorsList({ collaborators }: ICollaboratorsList): JSX.Element {
  /**
   * Render the action icon
   * @param collaborator The collaborator
   * @returns JSX.Element
   */
  const renderActionIcon = (collaborator: ICollaborator) => {
    if (collaborator.isOwner)
      return (
        <Typography color={cMonoColorType.Light} align={IXDirection.Center}>
          Owner
        </Typography>
      );

    let icon, testId;

    if (collaborator.action === "collaborator") {
      icon = EIcon.Edit;
      testId = "edit-collaborator";
    } else {
      icon = EIcon.VisibilityOn;
      testId = "view-collaborator";
    }

    return (
      <span data-testid={testId}>
        <Icon icon={icon} className={styles.actionIcon} />
      </span>
    );
  };

  return (
    <Div bgColor={cMonoColorType.Light} p={{ base: 6 }}>
      <Typography variant="h5" spacing={{ mb: 4 }}>
        Collaborators
      </Typography>
      <Typography variant="preHeading" color={cMonoColorType.Light} spacing={{ mb: 6 }}>
        Collaborators will be notified via email when you start the collaboration process.
      </Typography>
      <Div>
        {collaborators.map((collaborator) => (
          <Div
            key={collaborator.id}
            display={{ base: "flex" }}
            alignItems={{ base: "center" }}
            className={styles.collaborator}
            testId="collaborator-item"
          >
            <Div
              display={{ base: "flex" }}
              alignItems={{ base: "center" }}
              justifyContent={{ base: "space-between" }}
              className={styles.collaboratorLayout}
            >
              <Div display={{ md: "flex" }} alignItems={{ base: "center" }} className={styles.info}>
                <Div className={styles.name}>
                  <Typography weight="bold">
                    {collaborator.firstName} {collaborator.lastName}{" "}
                    {collaborator.isOwner && <span className={styles.ownerIndicator}>(you)</span>}
                    <span className={styles.mobileAction}>{renderActionIcon(collaborator)}</span>
                  </Typography>
                </Div>
                <Div className={styles.email}>
                  <Typography>{collaborator.emailAddress}</Typography>
                </Div>
                <Div className={styles.phoneNumber}>
                  <Typography>{collaborator.phoneNumber}</Typography>
                </Div>
                <Div className={styles.desktopAction}>{renderActionIcon(collaborator)}</Div>
                <Div className={styles.notify}>
                  <Button color={cMonoColorType.Dark} icon={EIcon.Email} testId="notify-btn" />
                </Div>
              </Div>
              <Div>
                <Button
                  color={cMonoColorType.Dark}
                  variant={EButtonVariant.Link}
                  icon={EIcon.Close}
                  testId="remove-collaborator-btn"
                />
              </Div>
            </Div>
          </Div>
        ))}
      </Div>
    </Div>
  );
}

export default CollaboratorsList;
