import Typography from "../../../../components/Typography/Typography";

interface IEntriesHeader {
  children: string | React.ReactNode;
}

/**
 * Render a header for a grouped list section on the notifications page
 * @param children Header contents
 * @returns JSX.Element
 */
function EntriesHeader({ children }: IEntriesHeader): JSX.Element {
  return <Typography variant="h5">{children}</Typography>;
}

export default EntriesHeader;
