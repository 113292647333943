import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { each, map } from "lodash";
import { getRefreshToken, updateIsLoggedIn } from "../modules/userSlice";
import { handleErrorMessage } from "../utils/handleErrorMessage/handleErrorMessage";
import handleErrorStatus from "../utils/handleErrorStatus/handleErrorStatus";
import { hasBearerTokenExpired } from "../utils/hasBearerTokenExpired/hasBearerTokenExpired";
import isJSON from "../utils/isJSON/isJSON";
import { APIKEY, APIKEY_EXPIRY } from "./constants";

interface IApiParms {
  endpoint: string;
  method?: "GET" | "POST";
  body?: any;
  params?: object;
  headers?: object;
  includeCredentials?: boolean;
  dispatch?: any;
  progressAction?: ActionCreatorWithPayload<number, string>;
}

/**
 * API wrapper
 * @param endpoint           The API endpoint to call (append to the base URL)
 * @param method             The method or verb of the call eg. GET / POST / PUT...
 * @param body               The body of data to send to the API
 * @param params             Query string params object (key / value pairs)
 * @param headers            Any additional headers to send to the API
 * @param includeCredentials Whether to include BearerToken as part of the request headers
 * @param dispatch           Redux dispatcher
 * @param progressAction     Dispatch the progress percentage action to redux
 * @returns Array
 */
export default async function api({
  endpoint,
  method = "POST",
  body,
  params,
  headers,
  includeCredentials = true,
  dispatch,
  progressAction,
}: IApiParms): Promise<any> {
  try {
    const queryParams = params
      ? "/?" +
        map(params, (value, key) => {
          return `${key}=${value}`;
        }).join("&")
      : "";

    const domain =
      process.env.REACT_APP_SYSTEM_MODE === "dev" // If system mode is "dev"
        ? process.env.REACT_APP_API_BASE // Set the domain to API BASE env var
        : `${window.location.protocol}//${window.location.host}`; // Otherwise set to same location (same server)

    const url = `${domain}/ui${endpoint}${queryParams}`; // Build the URL to call (Node env var for base)

    let reqHeaders: any = {
      Accept: "application/json", // Only accept JSON
      "Content-Type": "application/json",
      ...headers, // Spread custom headers
    };

    const apiKey = localStorage.getItem(APIKEY);

    // If including credentials, send API key as BearerToken
    if (includeCredentials === true && apiKey) {
      reqHeaders = { ...reqHeaders, Authorization: "Bearer " + apiKey };
    }

    // If we want to send progress to a reducer, we have to use XMLHttpRequest
    if (progressAction) {
      const bearerTokenExpiry = localStorage.getItem(APIKEY_EXPIRY);
      const tokenExpired = bearerTokenExpiry && hasBearerTokenExpired(bearerTokenExpiry);

      // Handle expired bearer token
      if (tokenExpired) {
        try {
          const refreshedToken = await dispatch(getRefreshToken()).unwrap();
          reqHeaders = { ...reqHeaders, Authorization: "Bearer " + refreshedToken.value };
        } catch (error) {
          dispatch(updateIsLoggedIn(false));
          localStorage.removeItem(APIKEY_EXPIRY);
          window.location.reload();
        }
      }

      return new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();

        // Add listener for progress
        xhr.upload.addEventListener("progress", function (e) {
          if (e.lengthComputable) {
            const progress = (e.loaded / e.total) * 100;
            dispatch(progressAction(progress)); // Dispatch progress
          }
        });

        xhr.addEventListener("load", async function () {
          const { response } = xhr;

          // If Unauthorized response, log the user out
          handleErrorStatus({ dispatch, statusCode: response.status });

          if (xhr.status !== 200) {
            // If response is an error
            console.error(xhr.status, xhr.statusText); // Display the error in the console
            const json = await JSON.parse(xhr.responseText); // Return the JSON decoded response
            const { Data } = json;

            reject(new Error(`${Data.Code}: ${Data.Message}`)); // Reject the error
          }

          const contentType = xhr.getResponseHeader("content-type"); // Get the content-type to check

          // If response content type is JSON
          if (contentType && contentType.indexOf("application/json") !== -1) {
            const json = await JSON.parse(xhr.responseText); // Return the JSON decoded response
            resolve({ data: json.Data, ok: true });
          }

          resolve(xhr.responseText); // Otherwise return the text response
        });

        xhr.open(method, url, true);

        // Set request headers
        each(reqHeaders, (header, key) => {
          xhr.setRequestHeader(key, header);
        });

        xhr.send(JSON.stringify(body));
      });
    } else {
      // No progress action specified...
      // Call API
      const response = await fetch(url, {
        body: JSON.stringify(body),
        method,
        headers: reqHeaders,
        credentials: process.env.NODE_ENV === "development" ? "include" : "same-origin",
      });

      if (!response.ok) {
        // If response is an error
        console.error(response.status, response.statusText); // Display the error in the console
        const json = await response.json(); // Return the JSON decoded response

        throw new Error(JSON.stringify({ data: json.Data, ok: false })); // Throw the error
      }

      const contentType = response.headers.get("content-type"); // Get the content-type to check

      // If response content type is JSON
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const json = await response.json(); // Return the JSON decoded response
        return { data: json.Data, ok: true };
      }

      return await response.text(); // Otherwise return the text response
    }
  } catch (error: any) {
    if (isJSON(error.message)) {
      // If error message is JSON
      const {
        data: { Code, Message },
      } = JSON.parse(error.message); // Parse the JSON

      const errorMessage = handleErrorMessage(Code, Message);
      throw new Error(errorMessage); // Throw in format "Code: Message"
    }

    throw new Error(error.message); // Throw any other errors
  }
}
