import { cStatusType, cThemeColorType } from "../../../../../app/constants";
import Button from "../../../../../components/Button/Button";
import DisplayError from "../../../../../components/DisplayError/DisplayError";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../../components/Modal/Modal";
import Typography from "../../../../../components/Typography/Typography";
import { IUser } from "../../../../../modules/usersSlice";

interface IReassignAllTasksModal {
  isOpen: boolean;
  handleClose: (canReassign: boolean) => void;
  user?: IUser;
  error?: string;
  status: cStatusType;
}

/**
 * A modal to confirm if the admin wants to reassign all this user's tasks
 * @param isOpen                 Is the modal open?
 * @param handleClose            Function to close the modal
 * @param user                   The user to reassign tasks from
 * @param error                  Any error message
 * @param status                 The API loading status of the reassign call
 * @returns JSX.Element
 */
function ReassignAllTasksModal({ isOpen, handleClose, user, error, status }: IReassignAllTasksModal) {
  return (
    <Modal isOpen={isOpen} handleClose={() => handleClose(false)} testId="reassign-all-tasks-modal">
      <ModalHeader>
        <ModalTitle>Reassign all tasks?</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <Typography>
          This will reassign all tasks across all Matters that are currently assigned to{" "}
          {`${user?.firstName} ${user?.lastName}`}
        </Typography>
        {error && <DisplayError>{error}</DisplayError>}
      </ModalContent>
      <ModalActions>
        <Button
          color={cThemeColorType.Secondary}
          onClick={() => handleClose(false)}
          disabled={status === cStatusType.Loading}
        >
          Cancel
        </Button>
        <Button onClick={() => handleClose(true)} isLoading={status === cStatusType.Loading}>
          Reassign
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default ReassignAllTasksModal;
