import Typography from "../Typography/Typography";
import styles from "./DisplayError.module.scss";

interface IDisplayError {
  children: string;
}

/**
 * Display error content
 * @param children Error content
 * @returns JSX.Element
 */
function DisplayError({ children }: IDisplayError): JSX.Element {
  return (
    <Typography className={styles.className} spacing={{ mt: 4 }}>
      {children}
    </Typography>
  );
}

export default DisplayError;
