import classNames from "classnames";
import { cMonoColorType, cSizeType, cThemeColorType } from "../../app/constants";
import Div from "../Div/Div";
import styles from "./Badge.module.scss";

interface IBadge {
  children: React.ReactNode | JSX.Element | string | number;
  color?: cThemeColorType | cMonoColorType;
  className?: string;
  size?: cSizeType;
}

/**
 * A simple badge element
 * @param children  The contents of the badge
 * @param color     The background color of the badge
 * @param size      The size of the badge
 * @param className Custom class
 * @returns JSX.Element
 */
function Badge({ children, color, size, className }: IBadge): JSX.Element {
  const clx = classNames(styles.className, className, color && styles[color], size && styles[size]);

  return (
    <Div testId="badge" className={clx}>
      {children}
    </Div>
  );
}

export default Badge;
