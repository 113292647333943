import classNames from "classnames";
import { cMonoColorType, cSemanticColorType, cThemeColorType } from "../../app/constants";
import { useSpacing } from "../../app/hooks";
import { TWordBreakOpts } from "../../app/styleTypes";
import { ISpacing, IXDirection } from "../../app/types";
import styles from "./Typography.module.scss";

export type Variant = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "small" | "preHeading" | "div";
export type Weight = "lighter" | "light" | "normal" | "medium" | "bold";
export type Casing = "lower" | "upper";
export type Style = "normal" | "italic" | "oblique";

interface Props {
  variant?: Variant;
  children: string | JSX.Element | React.ReactNode;
  spacing?: ISpacing;
  align?: IXDirection;
  weight?: Weight;
  casing?: Casing;
  fontStyle?: Style;
  color?: cMonoColorType | cThemeColorType | cSemanticColorType;
  className?: string;
  testId?: string;
  wordBreak?: TWordBreakOpts;
}

/**
 * Typography element
 * @param variant   The optional type variant, one of h1, h2, h3, h4, h5, h6, p or div (defaults to "div")
 * @param children  The contents of the type element
 * @param spacing   Spacing object
 * @param align     Text alignment
 * @param weight    Text weight
 * @param casing    Text case - upper | lower
 * @param fontStyle CSS font-style property
 * @param color     Text color
 * @param className Custom classes
 * @param testId    The testId used to query in tests
 * @param wordBreak CSS word-break style options
 * @returns JSX.Element
 */
function Typography({
  variant,
  children,
  spacing,
  align = IXDirection.Left,
  weight,
  casing,
  fontStyle,
  color,
  className,
  testId,
  wordBreak,
}: Props): JSX.Element {
  const spacingClx = useSpacing(spacing);
  const clx = classNames(
    spacingClx,
    styles[align],
    casing && styles[casing],
    fontStyle && styles[fontStyle],
    weight && styles[weight],
    color && styles[color],
    variant && styles[variant],
    wordBreak && styles[wordBreak],
    className,
  );

  // Switch variant based on prop
  switch (variant) {
    case "h1":
      return (
        <h1 data-testid={testId} className={clx}>
          {children}
        </h1>
      );

    case "h2":
      return (
        <h2 data-testid={testId} className={clx}>
          {children}
        </h2>
      );

    case "h3":
      return (
        <h3 data-testid={testId} className={clx}>
          {children}
        </h3>
      );

    case "h4":
      return (
        <h4 data-testid={testId} className={clx}>
          {children}
        </h4>
      );

    case "h5":
      return (
        <h5 data-testid={testId} className={clx}>
          {children}
        </h5>
      );

    case "h6":
    case "preHeading":
      return (
        <h6 data-testid={testId} className={clx}>
          {children}
        </h6>
      );

    case "small":
      return (
        <small data-testid={testId} className={clx}>
          {children}
        </small>
      );

    case "p":
      return (
        <p data-testid={testId} className={clx}>
          {children}
        </p>
      );

    default:
      return (
        <div data-testid={testId} className={clx}>
          {children}
        </div>
      );
  }
}

export default Typography;
