/**
 * Test if a string is JSON
 * @param str The string to test
 * @returns boolean
 */
export default function isJSON(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
