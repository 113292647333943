import { lazy, Suspense } from "react";
import Div from "../../Div/Div";
import { IDatepickerProps } from "./DatepickerField";

const LazyDatepickerField = lazy(() => import("./DatepickerField"));

const DatepickerField = (props: IDatepickerProps) => (
  <Suspense fallback={<Div />}>
    <LazyDatepickerField {...props} />
  </Suspense>
);

export default DatepickerField;
