import classNames from "classnames";
import { cThemeColorType } from "../../../app/constants";
import { useAppSelector } from "../../../app/hooks";
import { selectIsLoggedIn } from "../../../modules/userSlice";
import Div from "../../Div/Div";
import Icon, { EIcon } from "../../Icon/Icon";
import styles from "./SupportButton.module.scss";

interface ISupportButton {
  handleToggle: () => void;
}

/**
 * The customer support button
 * @param handleToggle Toggles the support form open/closed
 * @returns JSX.Element
 */
function SupportButton({ handleToggle }: ISupportButton): JSX.Element {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const clx = classNames(styles.className, isLoggedIn && styles.animate);

  return (
    <Div className={clx} onClick={handleToggle} testId="customer-support-button">
      <div className={styles.supportIcon}>
        <Icon icon={EIcon.Support} color={cThemeColorType.Primary} />
      </div>
      <Div className={styles.supportText} spacing={{ ml: 4 }}>
        Support
      </Div>
    </Div>
  );
}

export default SupportButton;
