import { useState } from "react";
import { cThemeColorType } from "../../../../../app/constants";
import { IDirection } from "../../../../../app/types";
import { EButtonVariant } from "../../../../../components/Button/Button";
import Icon, { EIcon } from "../../../../../components/Icon/Icon";
import Popover from "../../../../../components/Popover/Popover";
import { EUserStatus, IUser } from "../../../../../modules/usersSlice";
import Contents from "./Contents/Contents";

interface IUsersActionPopoverProps {
  userID: number;
  loggedInUser: IUser;
  editUserInit: (userID: number) => void;
  disableUserInit: (userID: number) => void;
  status: EUserStatus;
  enableUserInit: (userID: number) => void;
  deleteUserInit: (userID: number) => void;
  resetPasswordInit: (userID: number) => void;
  reassignAllTasksInit: (userID: number) => void;
  streamsEnabled?: boolean;
}

/**
 * Users list item action list popover
 * @param userID                      The user id we need to amend
 * @param loggedInUser                The logged in user
 * @param editUserInit                Function to start the edit user process
 * @param disableUserInit             Function to start the disable user process
 * @param status                      The user status
 * @param enableUserInit              Function to start the enable a user process
 * @param handleResetPasswordInit     Function to start reset password process
 * @param deleteUserInit              Function to start the delete a user process
 * @param reassignAllTasksInit        Function to start the reassign all tasks process
 * @param streamsEnabled              Are streams enabled?
 * @returns JSX.Element
 */
function UsersActionPopover({
  userID,
  loggedInUser,
  editUserInit,
  disableUserInit,
  status,
  enableUserInit,
  deleteUserInit,
  resetPasswordInit,
  reassignAllTasksInit,
  streamsEnabled,
}: IUsersActionPopoverProps): JSX.Element {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false); // Popover open / closed state

  /**
   * Closes the popover then calls editDetails on the parent
   */
  function handleEditUserInit(): void {
    setIsPopoverOpen(false);
    editUserInit(userID);
  }

  /**
   * Initialise reset password
   */
  function handleResetPasswordInit(): void {
    setIsPopoverOpen(false);
    resetPasswordInit(userID);
  }

  /**
   * Initialise disable user
   */
  function handleDisableUserInit(): void {
    setIsPopoverOpen(false);
    disableUserInit(userID);
  }

  /**
   * Initialise enable user
   */
  function handleEnableUserInit(): void {
    setIsPopoverOpen(false);
    enableUserInit(userID);
  }

  /**
   * Initialise delete user
   */
  function handleDeleteUserInit(): void {
    setIsPopoverOpen(false);
    deleteUserInit(userID);
  }

  /**
   * Initialise reassign all tasks
   */
  function handleReassignAllTasksInit(): void {
    setIsPopoverOpen(false);
    reassignAllTasksInit(userID);
  }

  return (
    <Popover
      direction={IDirection.Bottom}
      popoverContents={
        <Contents
          userID={userID}
          loggedInUser={loggedInUser}
          editUserInit={handleEditUserInit}
          resetPasswordInit={handleResetPasswordInit}
          disableUserInit={handleDisableUserInit}
          status={status}
          enableUserInit={handleEnableUserInit}
          deleteUserInit={handleDeleteUserInit}
          reassignAllTasksInit={handleReassignAllTasksInit}
          streamsEnabled={streamsEnabled}
        />
      }
      buttonContents={<Icon icon={EIcon.ActionsMenu} color={cThemeColorType.Primary} />}
      buttonProps={{
        variant: EButtonVariant.Round,
        color: cThemeColorType.Secondary,
      }}
      width="280px"
      isOpen={isPopoverOpen}
      setIsOpen={setIsPopoverOpen}
    />
  );
}

export default UsersActionPopover;
