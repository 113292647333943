import { cThemeColorType } from "../../../../../app/constants";
import Button from "../../../../../components/Button/Button";
import DisplayError from "../../../../../components/DisplayError/DisplayError";
import Div from "../../../../../components/Div/Div";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../../components/Modal/Modal";
import Typography from "../../../../../components/Typography/Typography";
import { IUser } from "../../../../../modules/usersSlice";

interface IResetPasswordModal {
  isOpen: boolean;
  handleClose: () => void;
  selectedUser: IUser | undefined;
  handleResetPassword: () => void;
  isLoading: boolean;
  error?: string;
}

/**
 * Reset password modal on users' screen
 * @param isOpen              Is the modal open?
 * @param handleClose         Handle modal close
 * @param selectedUser        The currently selected user
 * @param handleResetPassword Function to reset the user's password
 * @param isLoading           Is the user state sending an API call?
 * @param error               API error
 * @returns JSX.Element
 */
function ResetPasswordModal({
  isOpen,
  handleClose,
  selectedUser,
  handleResetPassword,
  isLoading,
  error,
}: IResetPasswordModal): JSX.Element {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <ModalHeader>
        <ModalTitle>Reset Password</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <Typography>
          You have requested to reset the password of{" "}
          <b>
            {selectedUser?.firstName} {selectedUser?.lastName}
          </b>{" "}
          (<b>{selectedUser?.loginName}</b>). Selecting confirm will send an email notification to the user to reset
          their password.
        </Typography>
        {error && (
          <Div py={{ base: 4 }}>
            <DisplayError>{error}</DisplayError>
          </Div>
        )}
      </ModalContent>
      <ModalActions>
        <Button color={cThemeColorType.Secondary} onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleResetPassword} isLoading={isLoading}>
          Confirm
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default ResetPasswordModal;
