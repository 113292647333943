import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";
import api from "../app/api";
import { userActivityReportUrl } from "../app/apiUrls";
import { cStatusType } from "../app/constants";
import { RootState } from "../app/store";
import { downloadBase64File } from "../utils/download/download";

/**
 * Report state interface
 */
interface IReportState {
  status: cStatusType;
  error?: string;
}

/**
 * Initial state
 */
const initialState: IReportState = {
  status: cStatusType.Idle,
};

/**
 * Thunk for downloading user activity report
 */
export const postUserActivityReport = createAsyncThunk(
  "report/postUserActivityReport",
  async ({ startDate, endDate }: { startDate: string; endDate: string }, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = userActivityReportUrl;
      const response = await api({
        endpoint,
        dispatch,
        body: {
          startDate: format(new Date(startDate), "yyyy-MM-dd"),
          endDate: format(new Date(endDate), "yyyy-MM-dd"),
        },
      });
      return response.data;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Create report slice
 */
export const reportSlice = createSlice({
  name: "report", // The name of the slice
  initialState, // Set the initialState
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Set status to failed if the promise is rejected
      .addCase(postUserActivityReport.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      // Set status to failed if the promise is rejected
      .addCase(postUserActivityReport.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Set status back to idle once the promise has been fulfilled
      .addCase(postUserActivityReport.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        downloadBase64File(action.payload);
      });
  },
});

export const selectReportStatus = (state: RootState) => state.report.status; // Select the status

export default reportSlice.reducer;
