import { chain, map, sortBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { cSizeType, cStatusType, CUSTOMER_CODE } from "../../app/constants";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { IFieldOption } from "../../components/FormField/FormField";
import Spinner from "../../components/Spinner/Spinner";
import SuccessModal from "../../components/SuccessModal/SuccessModal";
import { selectAllowSecurityGroups } from "../../modules/customerSlice";
import { postErrorCodes, selectAllErrorCodes } from "../../modules/errorCodesSlice";
import { postNotifyGroups, selectNotifyGroups, selectNotifyGroupsStatus } from "../../modules/notifyGroupsSlice";
import { postSecurityGroups, selectSecurityGroups } from "../../modules/securityGroupsSlice";
import { postPasswordResetRequest, selectUser, selectUserError, selectUserStatus } from "../../modules/userSlice";
import {
  IUser,
  postDeleteUser,
  postDisableUser,
  postEnableUser,
  postReplacementUsers,
  postSecurityGroupIDs,
  postUserReassignTask,
  postUsers,
  postUserToEdit,
  resetUsersError,
  selectDeleteUserStatus,
  selectEditUser,
  selectReplacementUserIDs,
  selectSecurityGroupIDs,
  selectUsers,
  selectUsersError,
  selectUsersStatus,
  updateResetUserSearch,
} from "../../modules/usersSlice";
import sortUsersByName from "../../utils/sortUsersByName/sortUsersByName";
import Admin from "./Admin/Admin";
import DeleteUserModal from "./Admin/Users/DeleteUserModal/DeleteUserModal";
import DisableUserModal from "./Admin/Users/DisableUserModal/DisableUserModal";
import EnableUserModal from "./Admin/Users/EnableUserModal/EnableUserModal";
import ReassignAllTasksModal from "./Admin/Users/ReassignAllTasksModal/ReassignAllTasksModal";
import ResetPasswordModal from "./Admin/Users/ResetPasswordModal/ResetPasswordModal";

/**
 * The admin container
 * @returns JSX.Element
 */
export function AdminContainer() {
  const users = useAppSelector(selectUsers);
  const notifyGroups = useAppSelector(selectNotifyGroups);
  const securityGroups = useAppSelector(selectSecurityGroups);
  const allowSecurityGroups = useAppSelector(selectAllowSecurityGroups) || false;
  const editUser = useAppSelector(selectEditUser);
  const securityGroupIDs = useAppSelector(selectSecurityGroupIDs);
  const replacementUserIDs = useAppSelector(selectReplacementUserIDs);
  const usersError = useAppSelector(selectUsersError);
  const usersStatus = useAppSelector(selectUsersStatus);
  const dispatch = useAppDispatch();
  const [sortedUsers, setSortedUsers] = useState<IUser[]>([]);
  const [isDisableUserModalOpen, setIsDisableUserModalOpen] = useState(false);
  const [isEnableUserModalOpen, setIsEnableUserModalOpen] = useState(false);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);
  const [isReassignAllTasksModalOpen, setIsReassignAllTasksModalOpen] = useState(false);
  const [showResetPasswordSuccessModal, setShowResetPasswordSuccessModal] = useState<boolean>(false); // Show the reset password success modal
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const userStatus = useAppSelector(selectUserStatus); // User status for posting password reset data
  const notifyGroupsStatus = useAppSelector(selectNotifyGroupsStatus);
  const userError = useAppSelector(selectUserError);
  const loggedInUser = useAppSelector(selectUser);
  const errorCodes = useAppSelector(selectAllErrorCodes);
  const deleteUserStatus = useAppSelector(selectDeleteUserStatus);

  useEffect(() => {
    dispatch(postUsers({})); // Get all users
    dispatch(postErrorCodes({ data: "all" })); // Get all error codes
    dispatch(postNotifyGroups("all")); // Get all notify groups
    dispatch(postSecurityGroups("all")); // Get all security groups
  }, []);

  /**
   * Starts the edit user process
   * @param userID the user id to edit
   */
  function editUserInit(userID: number) {
    dispatch(postUserToEdit(userID));
    dispatch(postSecurityGroupIDs(userID));
  }

  /**
   * Starts the disable user process
   * @param userID the user id to disable
   */
  function disableUserInit(userID: number) {
    setSelectedUser(users[userID]);
    setIsDisableUserModalOpen(true);
  }

  /**
   * Disables the user if the confirmation modal returns true
   * @param canDisable can the user be disabled?
   */
  async function disableUser(canDisable: boolean) {
    try {
      if (canDisable && selectedUser?.id) {
        await dispatch(postDisableUser(selectedUser.id)).unwrap();
        setIsDisableUserModalOpen(false);
        dispatch(updateResetUserSearch(true));
        setSelectedUser(undefined);
      } else {
        setIsDisableUserModalOpen(false);
        setSelectedUser(undefined);
      }
    } catch (error: any) {
      console.log(error.message);
    }
  }

  /**
   * Starts the enable user process
   * @param userID the user id to enable
   */
  function enableUserInit(userID: number) {
    setSelectedUser(users[userID]);
    setIsEnableUserModalOpen(true);
  }

  /**
   * Enables the user if the confirmation modal returns true
   * @param canEnable can the user be enabled?
   */
  async function enableUser(canEnable: boolean) {
    setIsEnableUserModalOpen(false);
    if (canEnable && selectedUser?.id) {
      await dispatch(postEnableUser(selectedUser.id)).unwrap();
      dispatch(updateResetUserSearch(true));
      setSelectedUser(undefined);
    }
  }

  /**
   * Starts the delete user process
   * @param userID the user id to enable
   */
  function deleteUserInit(userID: number) {
    setSelectedUser(users[userID]);
    dispatch(postReplacementUsers(userID));
    setIsDeleteUserModalOpen(true);
  }

  /**
   * Deletes the user if the delete user modal returns true
   * @param canDelete     Can the user be deleted?
   * @param replacementID The user id used to replace ownership of documents, matters and embed links
   */
  async function deleteUser(canDelete: boolean, replacementID?: number) {
    try {
      if (canDelete && selectedUser?.id && replacementID) {
        await dispatch(postDeleteUser({ id: selectedUser.id, replacementID })).unwrap();
        await dispatch(postUsers({ refresh: true }));
        setSelectedUser(undefined);
        setIsDeleteUserModalOpen(false);
        dispatch(updateResetUserSearch(true));
      } else {
        setSelectedUser(undefined);
        setIsDeleteUserModalOpen(false);
        dispatch(updateResetUserSearch(true));
      }
    } catch (error: any) {
      console.log(error.message);
    }
  }

  /**
   * Handle reset password success modal close
   */
  function handleShowResetPasswordModalClose() {
    setShowResetPasswordSuccessModal(false);
  }

  /**
   * Handle reset password modal close
   */
  function handleResetPasswordModalClose() {
    setIsResetPasswordModalOpen(false);
  }

  /**
   * Starts the reset password process
   * @param userID the user id to reset
   */
  function resetPasswordInit(userID: number) {
    setSelectedUser(users[userID]);
    setIsResetPasswordModalOpen(true);
  }

  /**
   * Handle reset password request
   */
  async function resetPassword() {
    try {
      await dispatch(
        postPasswordResetRequest({
          username: selectedUser?.loginName as string,
          ccof: localStorage.getItem(CUSTOMER_CODE) as string,
        }),
      ).unwrap();
      handleResetPasswordModalClose();
      setShowResetPasswordSuccessModal(true);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Open Reassign all tasks modal
   * @param userID the user id to reassign all tasks from
   */
  function reassignAllTasksInit(userID: number) {
    setSelectedUser(users[userID]);
    setIsReassignAllTasksModalOpen(true);
  }

  /**
   * Reassign all tasks
   */
  async function reassignAllTasks(canReassign: boolean) {
    try {
      if (canReassign && selectedUser?.id) {
        await dispatch(postUserReassignTask(selectedUser?.id as number)).unwrap();
        toast("All tasks have been reassigned");
      }
      setIsReassignAllTasksModalOpen(false);
      setSelectedUser(undefined);
      dispatch(resetUsersError());
    } catch (error: any) {
      console.error(error.message);
    }
  }

  // Sort users by name
  useEffect(() => {
    setSortedUsers(sortUsersByName(users));
  }, [users]);

  // Create field options for notify groups
  const notifyGroupOptions: IFieldOption[] = useMemo(
    () =>
      chain(notifyGroups)
        .filter((notifyGroup) => !notifyGroup.isRestricted)
        .map((notifyGroup) => ({
          label: notifyGroup.name,
          value: notifyGroup.id,
        }))
        .sortBy("label")
        .value(),
    [notifyGroups],
  );

  // Prepend the default "Select..." option to the notifyGroupOptions
  const isLoadingNotifyGroups = notifyGroupsStatus === cStatusType.Loading;
  // Recalculate only when the dependencies change
  const modifiedNotifyGroupOptions = useMemo(() => {
    const loadingOption = isLoadingNotifyGroups
      ? [{ label: <Spinner size={cSizeType.Small} />, value: "", isDisabled: true }]
      : [];
    // Add blank option to the select
    return [{ label: "Select...", value: "" }, ...loadingOption, ...notifyGroupOptions];
  }, [notifyGroupOptions, isLoadingNotifyGroups]);

  // Create field options for security groups
  const securityGroupOptions: IFieldOption[] = useMemo(
    () =>
      map(securityGroups, (securityGroup) => {
        return {
          label: securityGroup.name,
          value: securityGroup.id,
        };
      }),
    [securityGroups],
  );

  // Create field options for replacement users
  const replacementUserOptions: IFieldOption[] = useMemo(
    () =>
      chain(users)
        .orderBy([(user) => user?.lastName?.toLowerCase(), (user) => user?.firstName?.toLowerCase()])
        .reduce((acc: IFieldOption[], user: IUser) => {
          if (replacementUserIDs?.includes(user.id)) {
            acc.push({ label: `${user.lastName}, ${user.firstName} `, value: user.id });
          }
          return acc;
        }, [])
        .value(),
    [users, replacementUserIDs],
  );

  // Sort the error codes by the code
  const sortedErrorCodes = useMemo(() => sortBy(errorCodes, "code"), [errorCodes]);

  return (
    <>
      <Admin
        users={sortedUsers}
        loggedInUser={loggedInUser as IUser}
        notifyGroupOptions={modifiedNotifyGroupOptions}
        securityGroupOptions={securityGroupOptions}
        allowSecurityGroups={allowSecurityGroups}
        editUserInit={editUserInit}
        editUser={editUser}
        securityGroupIDs={securityGroupIDs}
        disableUserInit={disableUserInit}
        enableUserInit={enableUserInit}
        deleteUserInit={deleteUserInit}
        resetPasswordInit={resetPasswordInit}
        reassignAllTasksInit={reassignAllTasksInit}
        errorCodes={sortedErrorCodes}
        usersError={usersError}
      />

      <DisableUserModal
        isOpen={Boolean(selectedUser && isDisableUserModalOpen)}
        handleClose={(canDisable: boolean) => disableUser(canDisable)}
        user={selectedUser}
        error={usersError}
      />

      <EnableUserModal
        isOpen={Boolean(selectedUser && isEnableUserModalOpen)}
        handleClose={(canEnable: boolean) => enableUser(canEnable)}
        user={selectedUser}
      />

      <DeleteUserModal
        isOpen={Boolean(selectedUser && isDeleteUserModalOpen)}
        handleClose={(canDelete: boolean, replacementID?: number) => deleteUser(canDelete, replacementID)}
        user={selectedUser}
        replacementUserOptions={replacementUserOptions}
        error={usersError}
        status={deleteUserStatus}
        replacementUsersStatus={usersStatus}
      />

      <ResetPasswordModal
        isOpen={Boolean(selectedUser && isResetPasswordModalOpen)}
        handleClose={handleResetPasswordModalClose}
        selectedUser={selectedUser}
        handleResetPassword={resetPassword}
        isLoading={userStatus === cStatusType.Loading}
        error={userError}
      />

      <ReassignAllTasksModal
        isOpen={isReassignAllTasksModalOpen}
        handleClose={reassignAllTasks}
        user={selectedUser}
        error={usersError}
        status={usersStatus}
      />

      <SuccessModal
        isOpen={Boolean(selectedUser && showResetPasswordSuccessModal)}
        handleClose={handleShowResetPasswordModalClose}
        header="Mail Sent"
        message={
          <>
            A notification email has been sent to{" "}
            <b>
              {selectedUser?.firstName} {selectedUser?.lastName}
            </b>{" "}
            to inform them to reset their password
          </>
        }
      />
    </>
  );
}

export default AdminContainer;
