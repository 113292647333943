import { cThemeColorType } from "../../../../../app/constants";
import Button from "../../../../../components/Button/Button";
import Div from "../../../../../components/Div/Div";
import { EIcon } from "../../../../../components/Icon/Icon";
import { EESignState } from "../../../../../modules/esignSlice";
import styles from "../ESignWorkflow.shared.module.scss";

interface IESignWorkflowRecipientsFooter {
  handleAddRecipient: () => void;
  handleAddGroup: () => void;
  state: EESignState;
}

/**
 * ESign add recipients footer
 * @param handleAddRecipient Function to open the add recipient modal
 * @param handleAddGroup     Function to open the add group modal
 * @param state              The esign state
 * @returns JSX.Element
 */
function ESignWorkflowRecipientsFooter({ handleAddRecipient, handleAddGroup, state }: IESignWorkflowRecipientsFooter) {
  return (
    <Div className={styles.workflowRecipientsFooter}>
      <Button
        color={cThemeColorType.Secondary}
        icon={EIcon.Add}
        onClick={handleAddRecipient}
        disabled={state !== EESignState.Configuration}
        testId="add-recipient-button"
      >
        Add recipient
      </Button>
      <Button
        color={cThemeColorType.Secondary}
        icon={EIcon.Add}
        onClick={handleAddGroup}
        disabled={state !== EESignState.Configuration}
        testId="add-group-button"
      >
        Add group
      </Button>
    </Div>
  );
}

export default ESignWorkflowRecipientsFooter;
