import { isBefore } from "date-fns";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { EventType, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { cMonoColorType, cStatusType, cThemeColorType } from "../../../../app/constants";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Button, { cButtonType } from "../../../../components/Button/Button";
import Div from "../../../../components/Div/Div";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../components/Modal/Modal";
import Spinner from "../../../../components/Spinner/Spinner";
import Tabs from "../../../../components/Tabs/Tabs";
import Typography from "../../../../components/Typography/Typography";
import {
  EESignState,
  IESignGroup,
  postDeleteGroupRecipient,
  postDeleteRecipient,
  postEditESign,
  postESign,
  postESignStart,
  postReorderRecipients,
  resetESign,
  selectESign,
  selectESignStartStatus,
} from "../../../../modules/esignSlice";
import { dateWithoutTime } from "../../../../utils/date/date";
import AddRecipientModal from "../AddRecipientModal/AddRecipientModal";
import AddESignGroupModal from "./AddESignGroupModal/AddESignGroupModal";
import CancelESignModal from "./CancelESignModal/CancelESignModal";
import ConfirmDeleteActivityModal, {
  EActivityDeleteType,
} from "./ConfirmDeleteRecipientModal/ConfirmDeleteActivityModal";
import ESignHistory from "./ESignHistory/ESignHistory";
import styles from "./ESignWorkflow.shared.module.scss";
import ESignWorkflowRecipients from "./ESignWorkflowRecipients/ESignWorkflowRecipients";
import ESignWorkflowRecipientsFooter from "./ESignWorkflowRecipientsFooter/ESignWorkflowRecipientsFooter";
import ESignWorkflowSettings from "./ESignWorkflowSettings/ESignWorkflowSettings";

export type Inputs = {
  dueDate: string | null | undefined;
  autoExpire: boolean | null | undefined;
  autoRemind: boolean | null | undefined;
};

const defaultValues = {
  dueDate: null,
  autoExpire: false,
  autoRemind: false,
};

export interface IESignWorkflowModal {
  isOpen: boolean;
  handleClose: () => void;
  esignID?: number | null;
  documentID: number;
}

/**
 * The esign workflow modal
 * @param isOpen      Is the modal open?
 * @param handleClose Function to close the modal
 * @param esignID     The esign id
 * @param documentID  The document id
 * @returns JSX.Element
 */
function ESignWorkflowModal({ isOpen, handleClose, esignID, documentID }: IESignWorkflowModal) {
  const {
    register, // Register prop for form inputs
    handleSubmit, // Submit handler wrapper
    formState: { errors }, // Errors that may occur
    watch,
    setValue,
    reset,
    resetField,
  } = useForm<Inputs>({ defaultValues, mode: "onChange" });
  const dispatch = useAppDispatch();
  const esign = useAppSelector(selectESign);
  const [isAddRecipientModalOpen, setIsAddRecipientModalOpen] = useState(false);
  const [isAddGroupModalOpen, setIsAddGroupModalOpen] = useState(false);
  const [isDeleteActivityModalOpen, setIsDeleteActivityModalOpen] = useState(false);
  const [isCancelESignModalOpen, setIsCancelESignModalOpen] = useState(false);
  const [recipientID, setRecipientID] = useState<number | null>(null);
  const [deleteActivityType, setDeleteActivityType] = useState<EActivityDeleteType | null>(null);
  const [group, setGroup] = useState<IESignGroup | undefined>(undefined);
  const status = useAppSelector(selectESignStartStatus);
  const { dueDate, autoExpire, autoRemind } = watch();
  const [type, setType] = useState<EventType | undefined>(undefined);

  useEffect(() => {
    if (esignID) {
      // If the settings were changed by a user we can send them to the api
      if (type === "change" && esign) {
        // Reset switches if date is cleared
        if (!dueDate) {
          resetField("autoExpire");
          resetField("autoRemind");
        }
        dispatch(
          postEditESign({
            dueDate: dueDate,
            autoExpire: dueDate ? Boolean(autoExpire) : null,
            autoRemind: dueDate ? Boolean(autoRemind) : null,
            id: esignID,
          }),
        );
      }
    }
  }, [dueDate, autoExpire, autoRemind]);

  /**
   * Handle modal close
   */
  function handleModalClose() {
    toast.dismiss();
    handleClose();
    reset();
    dispatch(resetESign());
  }

  useEffect(() => {
    // Watch for form changes
    const subscription = watch((_, { type }) => {
      // Set the change event type so we know the values were updated by a user and not on load (to prevent edit api being called on load)
      setType(type);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  /**
   * Fetches the current esign
   */
  async function fetchEsign() {
    try {
      if (esignID) {
        await dispatch(postESign(esignID)).unwrap();
      }
    } catch (error) {
      handleClose();
    }
  }

  useEffect(() => {
    // Fetch existing esign using the id
    fetchEsign();
  }, [esignID]);

  useEffect(() => {
    // Set form values from the fetched esign on load
    if (esign?.state) {
      const { settings } = esign;
      if (
        esign?.state === EESignState.Configuration &&
        settings.dueDate &&
        isBefore(new Date(settings.dueDate), dateWithoutTime())
      ) {
        setValue("dueDate", null); // Reset the value of the 'dueDate' field to null if the due date is in the past
      } else {
        setValue("dueDate", settings.dueDate, { shouldValidate: true });
      }
      setValue("autoExpire", settings.autoExpire);
      setValue("autoRemind", settings.autoRemind);
    }
  }, [esign, esign?.state]);

  /**
   * Opens the add recipient modal
   */
  function handleAddRecipient() {
    toast.dismiss();
    setIsAddRecipientModalOpen(true);
  }

  /**
   * Closes the add recipient modal
   */
  function handleAddRecipientModalclose() {
    setIsAddRecipientModalOpen(false);
    setRecipientID(null);
    setGroup(undefined);
  }

  /**
   * Starts the delete activity process
   * @param id The recipient id
   */
  function handleDeleteActivity(id: number, activityType: EActivityDeleteType) {
    setRecipientID(id);
    setDeleteActivityType(activityType);
    setIsDeleteActivityModalOpen(true);
  }

  /**
   * Starts the delete group activity process
   * @param group    The esign group
   * @param personID The person id being deleted
   */
  function handleDeleteGroupActivity(group: IESignGroup, personID: number) {
    setGroup(group);
    setRecipientID(personID);
    setDeleteActivityType(EActivityDeleteType.Recipient);
    setIsDeleteActivityModalOpen(true);
  }

  /**
   * Opens the delete recipient confirmation modal then deletes the recipient if the user confirms
   * @param canDelete Can the recipient be deleted?
   */
  async function handleDeleteRecipientModalClose(canDelete: boolean) {
    if (canDelete && recipientID) {
      try {
        if (group) {
          await dispatch(postDeleteGroupRecipient({ groupID: group?.id as number, personID: recipientID })).unwrap();
        } else {
          await dispatch(postDeleteRecipient(recipientID)).unwrap();
        }
        toast.dismiss();
        fetchEsign();
        setRecipientID(null);
        setGroup(undefined);
        setIsDeleteActivityModalOpen(false);
      } catch (error) {
        console.error(error);
      }
    } else {
      setRecipientID(null);
      setGroup(undefined);
      setIsDeleteActivityModalOpen(false);
    }
  }

  /**
   * Handles reordering esign recipients
   * @param activities The updated activity ids
   */
  function handleRecipientsReorder(activities: number[]) {
    if (esignID) {
      dispatch(postReorderRecipients({ id: esignID, activities }));
    }
  }

  /**
   * Opens the add group modal
   */
  function handleAddGroup() {
    toast.dismiss();
    setIsAddGroupModalOpen(true);
  }

  /**
   * Closes the add group modal
   */
  function handleAddGroupModalClose() {
    setIsAddGroupModalOpen(false);
    setGroup(undefined);
  }

  /**
   * Edits a group
   * @param group The esign group
   */
  function handleEditGroup(group: IESignGroup) {
    toast.dismiss();
    setGroup(group);
    setIsAddGroupModalOpen(true);
  }

  /**
   * Adds a recipient to a group
   * @param group The esign group
   */
  function handleAddRecipientToGroup(group: IESignGroup) {
    toast.dismiss();
    setGroup(group);
    setIsAddRecipientModalOpen(true);
  }

  /**
   * Function called on update success
   */
  function onUpdateSuccess() {
    fetchEsign();
  }

  /**
   * Cancels an esign process
   */
  function handleCancelESign() {
    setIsCancelESignModalOpen(true);
  }

  function handleCancelESignModalClose() {
    setIsCancelESignModalOpen(false);
  }

  // Page tabs and tab content
  const tabs = [
    {
      header: <Typography>Signing workflow</Typography>,
      contents: (
        <Div spacing={{ p: 6 }}>
          {esign ? (
            <>
              <ESignWorkflowSettings register={register} errors={errors} formValues={watch()} state={esign.state} />
              <ESignWorkflowRecipients
                signersApprovers={esign.signersApprovers}
                viewers={esign.viewers}
                handleDeleteActivity={handleDeleteActivity}
                handleRecipientsReorder={handleRecipientsReorder}
                handleEditGroup={handleEditGroup}
                handleAddRecipientToGroup={handleAddRecipientToGroup}
                handleDeleteGroupActivity={handleDeleteGroupActivity}
                state={esign.state}
              />
              <Div className={styles.footerWrapper}>
                <ESignWorkflowRecipientsFooter
                  handleAddRecipient={handleAddRecipient}
                  handleAddGroup={handleAddGroup}
                  state={esign.state}
                />
              </Div>
            </>
          ) : (
            <Spinner />
          )}
        </Div>
      ),
    },
    {
      header: <Typography>History</Typography>,
      contents: (
        <Div spacing={{ p: 6 }}>
          <ESignHistory history={esign?.history} />
        </Div>
      ),
    },
  ];

  /**
   * Submit the form
   */
  const onSubmit: SubmitHandler<Inputs> = async () => {
    if (esignID) {
      await dispatch(postESignStart(esignID)).unwrap();
    }
  };

  /**
   * Renders the modal actions based on esign state
   * @returns JSX.Element
   */
  function renderModalActions() {
    switch (esign?.state) {
      case EESignState.Configuration:
        return (
          <ModalActions hasBoxShadow>
            <Button
              formId="signingworkflowForm"
              type={cButtonType.SubmitType}
              testId="esign-workflow-modal-submit-button"
              isLoading={status === cStatusType.Loading}
              disabled={!isEmpty(errors)}
            >
              Initiate Signing Process
            </Button>
          </ModalActions>
        );
      case EESignState.InProgress:
        return (
          <ModalActions hasBoxShadow>
            <Button
              color={cThemeColorType.Secondary}
              onClick={handleCancelESign}
              testId="esign-workflow-modal-cancel-button"
            >
              Cancel Signing Process
            </Button>
          </ModalActions>
        );
      default:
        return <></>;
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} handleClose={handleModalClose} size="xxl" scrollable testId="signing-workflow-modal">
        <ModalHeader hasBoxShadow handleClose={handleModalClose}>
          <ModalTitle>UpSign</ModalTitle>
        </ModalHeader>
        <ModalContent bgColor={cMonoColorType.Light} spacing={{ p: 0 }}>
          <form id="signingworkflowForm" onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <Div>
              <Tabs items={tabs} sticky />
            </Div>
          </form>
        </ModalContent>
        {renderModalActions()}
      </Modal>
      <AddRecipientModal
        isOpen={isAddRecipientModalOpen}
        handleClose={handleAddRecipientModalclose}
        id={group?.id || (esignID as number)}
        onSubmitSuccess={onUpdateSuccess}
        action={group?.type}
        group={group}
      />
      <ConfirmDeleteActivityModal
        isOpen={Boolean(isDeleteActivityModalOpen && deleteActivityType)}
        handleClose={(canDelete) => handleDeleteRecipientModalClose(canDelete)}
        type={deleteActivityType}
      />
      <AddESignGroupModal
        isOpen={isAddGroupModalOpen}
        handleClose={handleAddGroupModalClose}
        esignID={esignID}
        group={group}
      />
      <CancelESignModal
        isOpen={isCancelESignModalOpen}
        handleClose={handleCancelESignModalClose}
        esignID={esignID}
        documentID={documentID}
      />
    </>
  );
}

export default ESignWorkflowModal;
