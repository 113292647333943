import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";
import { cThemeColorType } from "../../../../../app/constants";
import { EButtonVariant } from "../../../../../components/Button/Button";
import Div from "../../../../../components/Div/Div";
import Icon, { EIcon } from "../../../../../components/Icon/Icon";
import Popover from "../../../../../components/Popover/Popover";
import Typography from "../../../../../components/Typography/Typography";
import {
  EESignActionType,
  EESignState,
  IESignGroup,
  IESignRecipient,
  TSignerApprover,
} from "../../../../../modules/esignSlice";
import { isButton } from "../../../../../utils/isButton/isButton";
import { EActivityDeleteType } from "../ConfirmDeleteRecipientModal/ConfirmDeleteActivityModal";
import styles from "../ESignWorkflow.shared.module.scss";
import ESignWorkflowGroup from "../ESignWorkflowGroup/ESignWorkflowGroup";
import ESignWorkflowRecipient from "./ESignWorkflowRecipient/ESignWorkflowRecipient";

/**
 * Extend dnd-kit PointerSensor so we can keep buttons clickable
 */
class MyPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: "onPointerDown" as const,
      handler: ({ nativeEvent: event }: any) => {
        if (isButton(event.target)) {
          return false;
        }
        return true;
      },
    },
  ];
}

interface IESignWorkflowRecipients {
  signersApprovers: TSignerApprover[];
  viewers: IESignRecipient[];
  handleDeleteActivity: (id: number, activityType: EActivityDeleteType) => void;
  handleRecipientsReorder: (activities: number[]) => void;
  handleEditGroup: (group: IESignGroup) => void;
  handleAddRecipientToGroup: (group: IESignGroup) => void;
  handleDeleteGroupActivity: (group: IESignGroup, recipientID: number) => void;
  state: EESignState;
}

/**
 * ESign workflow recipients
 * @param signersApprovers           The list of signers and approvers
 * @param viewers                    The list of viewers
 * @param handleDeleteActivity       Function to delete an activity
 * @param handleRecipientsReorder    Function to send reordered recipients
 * @param handleEditGroup            Function to edit a group
 * @param handleAddRecipientToGroup  Function to add a recipient to a group
 * @param handleDeleteGroupActivity  Function to delete a recipient from a group
 * @param state                      The esign state
 * @returns JSX.Element
 */
function ESignWorkflowRecipients({
  signersApprovers,
  viewers,
  handleDeleteActivity,
  handleRecipientsReorder,
  handleEditGroup,
  handleAddRecipientToGroup,
  handleDeleteGroupActivity,
  state,
}: IESignWorkflowRecipients) {
  const [sortedSignersApprovers, setSortedSignersApprovers] = useState<TSignerApprover[]>([]);
  const sensors = useSensors(useSensor(MyPointerSensor));
  const accordions = document.querySelectorAll(`.${styles.accordion}`);

  useEffect(() => {
    // Sort signers/approvers by activity number
    const sorted = signersApprovers?.slice().sort((a, b) => {
      if (a.activityNumber && b.activityNumber) {
        if (a.activityNumber > b.activityNumber) {
          return 1;
        } else if (a.activityNumber < b.activityNumber) {
          return -1;
        }
      }
      return 0;
    });
    setSortedSignersApprovers(sorted);
  }, [signersApprovers]);

  /**
   * Collapses Accordions when an item is dragged
   * @param event The drag start event
   */
  function handleDragStart() {
    accordions.forEach((accordion) => {
      accordion.classList.add(styles.collapsed);
    });
  }

  /**
   * Changes the order of the list, then sends the new order to the server
   * @param event The drag end event
   */
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active.id !== over?.id) {
      setSortedSignersApprovers((items) => {
        const activeIndex = items.findIndex((item) => item.id === active.id);
        const overIndex = items.findIndex((item) => item.id === over?.id);
        const newArray = arrayMove(items, activeIndex, overIndex);
        const activities = newArray.map((item) => item.id);
        handleRecipientsReorder(activities);
        return newArray;
      });
    }
    // Expand accordions that were closed on DragStartEvent
    accordions.forEach((accordion) => {
      accordion.classList.remove(styles.collapsed);
    });
  }

  return (
    <>
      <Div display={{ base: "flex" }}>
        <Typography weight="medium" spacing={{ mb: 5, mr: 4 }}>
          Recipients (to complete in order)
        </Typography>
        <Popover
          popoverContents={
            <>
              <Typography>
                Recipients will be notified to sign or approve in the order they appear in the list. Once a recipient
                has signed or approved, the next recipient in line will be automatically notified.
              </Typography>
              <br />
              <Typography>
                You can add recipients in any order and then re-order them by dragging them to the desired position.
              </Typography>
              <br />
              <Typography>
                Viewers can view in any order and do not form part of the sequential workflow of signers and approvers.
              </Typography>
            </>
          }
          buttonContents={<Icon icon={EIcon.Help} color={cThemeColorType.Secondary} />}
          buttonProps={{ variant: EButtonVariant.Link }}
          className={styles.popover}
        />
      </Div>
      <Div className={styles.recipients}>
        <DndContext
          collisionDetection={closestCenter}
          sensors={sensors}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToParentElement, restrictToVerticalAxis]}
          onDragStart={handleDragStart}
        >
          <SortableContext items={sortedSignersApprovers} strategy={verticalListSortingStrategy}>
            {sortedSignersApprovers.map((recipient, index) => (
              <Fragment key={recipient.id}>
                {recipient.type === EESignActionType.Group ? (
                  <ESignWorkflowGroup
                    group={recipient}
                    handleDeleteActivity={handleDeleteActivity}
                    handleDeleteGroupActivity={handleDeleteGroupActivity}
                    handleEditGroup={handleEditGroup}
                    handleAddRecipientToGroup={handleAddRecipientToGroup}
                    arrayIndex={index}
                    isSortable={state === EESignState.Configuration}
                    state={state}
                  />
                ) : (
                  <ESignWorkflowRecipient
                    recipient={recipient}
                    handleDeleteActivity={handleDeleteActivity}
                    handleDeleteGroupActivity={handleDeleteGroupActivity}
                    arrayIndex={index}
                    isSortable={state === EESignState.Configuration}
                    state={state}
                  />
                )}
              </Fragment>
            ))}
          </SortableContext>
        </DndContext>
      </Div>
      {viewers.length > 0 && (
        <Div className={classNames(styles.recipients, styles.viewers)}>
          {viewers.map((recipient) => (
            <ESignWorkflowRecipient
              recipient={recipient}
              handleDeleteActivity={handleDeleteActivity}
              handleDeleteGroupActivity={handleDeleteGroupActivity}
              isSortable={false}
              key={recipient.id}
              state={state}
            />
          ))}
        </Div>
      )}
    </>
  );
}

export default ESignWorkflowRecipients;
