import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  openInformationUpdatedModal,
  postDocumentNote,
  selectDocumentsAddNoteError,
} from "../../../../modules/documentsSlice";
import { isOutdatedContentError } from "../../../../utils/errors/errors";
import AddNoteModal from "../AddNoteModal/AddNoteModal";

interface IAddNoteModalContainer {
  isOpen: boolean;
  handleClose: () => void;
  documentID?: number;
  onAddNoteSuccess?: Function;
}

/**
 * Container for document add note modal
 * @param isOpen           Is the modal open
 * @param handleClose      Close the modal
 * @param documentID       The document ID to target
 * @param onAddNoteSuccess Callback on success
 * @returns JSX.Element
 */
function AddNoteModalContainer({
  isOpen,
  handleClose,
  documentID,
  onAddNoteSuccess,
}: IAddNoteModalContainer): JSX.Element {
  const dispatch = useAppDispatch();
  const error = useAppSelector(selectDocumentsAddNoteError);

  /**
   * Submit the document note
   * @param note The note text to submit
   */
  async function handleAddNote(note: string) {
    try {
      await dispatch(postDocumentNote({ documentID: documentID as number, note })).unwrap();
      toast("Note added");
      onAddNoteSuccess && onAddNoteSuccess();
      handleClose();
    } catch (error: any) {
      console.error(error);
      if (isOutdatedContentError(parseInt(error))) {
        dispatch(openInformationUpdatedModal());
      }
    }
  }

  return <AddNoteModal isOpen={isOpen} handleClose={handleClose} handleAddNote={handleAddNote} error={error} />;
}

export default AddNoteModalContainer;
