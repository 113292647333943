import { cSizeType, cThemeColorType } from "../../app/constants";
import Icon, { EIcon } from "../Icon/Icon";
import SignedFile from "../SignedFile/SignedFile";
interface IIconFromFileType {
  fileType: string;
  size?: cSizeType;
  isSigned?: boolean;
}

/**
 * A component that renders an Icon based on the mine type e.g. application/pdf
 * @param fileType The file type to render
 * @param size     The icon size
 * @param isSigned Is the file signed?
 * @returns JSX.Element
 */
function IconFromMimeType({ fileType, size = cSizeType.Large, isSigned = false }: IIconFromFileType) {
  let icon;

  switch (fileType) {
    case "application/pdf":
      icon = <Icon icon={EIcon.MimePdf} size={size} />;
      break;
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/msword":
      icon = <Icon icon={EIcon.MimeDocx} size={size} />;
      break;
    case "application/octet-stream":
    case "application/vnd.ms-office":
    case "application/x-dosexec":
    case "application/x-executable":
      icon = <Icon icon={EIcon.MimeExe} size={size} />;
      break;
    case "application/postscript":
      icon = <Icon icon={EIcon.MimeEps} size={size} />;
      break;
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      icon = <Icon icon={EIcon.MimeXlsx} size={size} />;
      break;
    case "application/zip":
      icon = <Icon icon={EIcon.MimeZip} size={size} />;
      break;
    case "image/gif":
    case "image/jpeg":
    case "image/png":
      icon = <Icon icon={EIcon.MimeImage} size={size} />;
      break;
    case "audio/mp4":
    case "video/mp4":
      icon = <Icon icon={EIcon.MimeAudio} size={size} />;
      break;
    case "text/html":
      icon = <Icon icon={EIcon.MimeHtml} size={size} />;
      break;
    case "text/plain":
      icon = <Icon icon={EIcon.MimeTxt} size={size} />;
      break;
    default:
      icon = <Icon icon={EIcon.Document} size={size} color={cThemeColorType.Secondary} />;
      break;
  }

  if (isSigned) {
    return <SignedFile>{icon}</SignedFile>;
  } else {
    return icon;
  }
}

export default IconFromMimeType;
