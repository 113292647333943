import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../app/api";
import { documentStatesUrl } from "../app/apiUrls";
import { cStatusType } from "../app/constants";
import { RootState } from "../app/store";
import createIdList from "../utils/createIdList/createIdList";

/**
 * Describe a document state
 */
export interface IState {
  id: number;
  displayName: string;
}

/**
 * Describe the document stages object
 */
interface IDocumentStatesState {
  entries: Record<number, IState>; // Document stages records
  status: cStatusType; // API call status
  fetched: boolean; // Have entries been fetched?
  error?: string;
}

/**
 * Initial state
 */
const initialState: IDocumentStatesState = {
  entries: {},
  status: cStatusType.Idle,
  fetched: false,
};

/**
 * Thunk for fetching document stages
 */
export const postDocumentStates = createAsyncThunk(
  "documentStates/postDocumentStates",
  async (data: number[] | undefined, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = documentStatesUrl;
      const response = await api({ endpoint, dispatch, body: { id: data } });
      return createIdList(response.data.state);
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Document states reducer
 */
export const documentStatesSlice = createSlice({
  name: "documentStates", // The name of the slice
  initialState, // Set the initialState
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // Set status to loading when the promise is pending
      .addCase(postDocumentStates.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      // Set status to failed if the promise is rejected
      .addCase(postDocumentStates.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Set status back to idle once the promise has been fulfilled
      .addCase(postDocumentStates.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.entries = { ...state.entries, ...action.payload };
        state.fetched = true; // Set fetched to true
      });
  },
});

// Select the status
export const selectDocumentStatesStatus = (state: RootState) => state.documentStates.status;
// Select all documentStages
export const selectDocumentStates = (state: RootState) => state.documentStates.entries;
// Select fetched
export const selectDocumentStatesFetched = (state: RootState) => state.documentStates.fetched;
// Select error
export const selectDocumentStatesError = (state: RootState) => state.documentStates.error;

export default documentStatesSlice.reducer;
