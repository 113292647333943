import { useState } from "react";
import { cThemeColorType } from "../../../../app/constants";
import { EPopoverPlacement } from "../../../../app/types";
import { EButtonVariant } from "../../../../components/Button/Button";
import Icon, { EIcon } from "../../../../components/Icon/Icon";
import Popover from "../../../../components/Popover/Popover";
import { IDocumentAction, IDocumentTermination } from "../../../../modules/documentsSlice";
import { EWalkType } from "../../../../modules/walkSlice";
import Contents from "./Contents/Contents";

interface IDocumentActionPopoverProps {
  handleTransferOwnershipClick: (id: number) => void;
  handleAddNoteClick: (id: number) => void;
  handleAddEventClick: (id: number) => void;
  handleAttachSupportingDocClick: (id: number) => void;
  handleTerminateDocumentClick: (id: number) => void;
  handleRenameDocumentClick: (id: number) => void;
  id: number;
  action?: Record<string, IDocumentAction>;
  handleDocumentAmend: (id: number, action: EWalkType, compositeState: number) => void;
  handleCloneDocumentClick: (id: number) => void;
  termination?: IDocumentTermination;
  isSigned?: boolean;
  signedDate: string | null;
  compositeState: number;
}

/**
 * Document list item action list popover
 * @param handleTransferOwnershipClick   Open the handle transfer ownership modal
 * @param handleAddNoteClick             Open the add note modal
 * @param handleAddEventClick            Open the add event modal
 * @param handleAttachSupportingDocClick Open the attach supporting file modal
 * @param handleTerminateDocumentClick   Open the terminate document modal
 * @param handleRenameDocumentClick      Open the terminate document modal
 * @param id                             Current document ID target
 * @param action                         The action items
 * @param handleDocumentAmend            Start a document amend walk
 * @param handleCloneDocumentClick       Start a document clone walk
 * @param termination                    The document termination
 * @param isSigned                       Has the document been signed
 * @param signedDate                     Date of signature
 * @param compositeState                 The composite state of the document
 * @returns JSX.Element
 */
function DocumentActionPopover({
  handleTransferOwnershipClick,
  handleAddNoteClick,
  handleAddEventClick,
  handleAttachSupportingDocClick,
  handleTerminateDocumentClick,
  handleRenameDocumentClick,
  id,
  action,
  handleDocumentAmend,
  handleCloneDocumentClick,
  termination,
  isSigned,
  signedDate,
  compositeState,
}: IDocumentActionPopoverProps): JSX.Element {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false); // Popover open / closed state

  /**
   * Closes the popover then calls handleTransferOwnershipClick on the parent
   */
  function handleTransferOwnership(): void {
    setIsPopoverOpen(false);
    handleTransferOwnershipClick(id);
  }

  /**
   * Closes the popover then calls handleAddNoteClick on the parent
   */
  function handleAddNote(): void {
    setIsPopoverOpen(false);
    handleAddNoteClick(id);
  }

  /**
   * Closes the popover then calls handleAddEventClick on the parent
   */
  function handleAddEvent(): void {
    setIsPopoverOpen(false);
    handleAddEventClick(id);
  }

  /**
   * Draft new document version
   */
  function handleDraftNewVersion(): void {
    setIsPopoverOpen(false);
    handleDocumentAmend(id, EWalkType.Draft, compositeState);
  }

  /**
   * Update interview data (amend) walk
   */
  function handleUpdateInterviewData(): void {
    setIsPopoverOpen(false);
    handleDocumentAmend(id, EWalkType.Data, compositeState);
  }

  /**
   * Upload new document version
   */
  function handleUploadNewVersion(): void {
    setIsPopoverOpen(false);
    handleDocumentAmend(id, EWalkType.File, compositeState);
  }

  /**
   * Closes the popover then calls handleAttachSupportingDocClick on the parent
   */
  function handleAttachSupportingDoc(): void {
    setIsPopoverOpen(false);
    handleAttachSupportingDocClick(id);
  }

  /**
   * Closes the popover then calls handleTerminateDocumentClick on the parent
   */
  function handleTerminateDocument(): void {
    setIsPopoverOpen(false);
    handleTerminateDocumentClick(id);
  }

  /**
   * Closes the popover then calls handleRenameDocumentClick on the parent
   */
  function handleRenameDocument(): void {
    setIsPopoverOpen(false);
    handleRenameDocumentClick(id);
  }

  /**
   * Closes the popover then calls handleCloneDocumentClick on the parent
   */
  function handleCloneDocument(): void {
    setIsPopoverOpen(false);
    handleCloneDocumentClick(id);
  }

  /**
   * Closes the popover then calls handleWordCollaborationClick on the parent
   */
  function handleWordCollaboration(): void {
    setIsPopoverOpen(false);
    handleDocumentAmend(id, EWalkType.Collaboration, compositeState);
  }

  return (
    <Popover
      popoverPlacement={EPopoverPlacement.Bottom}
      popoverContents={
        <Contents
          handleTransferOwnership={handleTransferOwnership}
          handleAddNote={handleAddNote}
          handleAddEvent={handleAddEvent}
          handleDraftNewVersion={handleDraftNewVersion}
          handleUpdateInterviewData={handleUpdateInterviewData}
          handleUploadNewVersion={handleUploadNewVersion}
          handleWordCollaboration={handleWordCollaboration}
          handleAttachSupportingDoc={handleAttachSupportingDoc}
          handleTerminateDocument={handleTerminateDocument}
          handleRenameDocument={handleRenameDocument}
          handleCloneDocument={handleCloneDocument}
          testId="document-action-popover"
          action={action}
          termination={termination}
          isSigned={isSigned}
          signedDate={signedDate}
        />
      }
      buttonContents={<Icon icon={EIcon.ActionsMenu} color={cThemeColorType.Primary} />}
      buttonProps={{
        variant: EButtonVariant.Round,
        color: cThemeColorType.Secondary,
      }}
      width="345px"
      isOpen={isPopoverOpen}
      setIsOpen={setIsPopoverOpen}
    />
  );
}

export default DocumentActionPopover;
