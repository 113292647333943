import { cStatusType, cThemeColorType } from "../../../../../app/constants";
import Button from "../../../../../components/Button/Button";
import Div from "../../../../../components/Div/Div";
import InfoBox, { EInfoBoxVariant } from "../../../../../components/InfoBox/InfoBox";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../../components/Modal/Modal";
import Typography from "../../../../../components/Typography/Typography";
import { EDocumentDeleteType } from "../DeleteDocument";
import styles from "./DeleteDocumentConfirmationModal.module.scss";

interface IDeleteDocumentConfirmationModal {
  isOpen: boolean;
  handleClose: (canDelete: boolean) => void;
  type: EDocumentDeleteType;
  documentDescription?: string;
  documentName?: string;
  status?: cStatusType;
  urn: string;
}

/**
 * A modal to confirm deletion of a document
 * @param isOpen              Is the modal open?
 * @param handleClose         Function to handle modal close
 * @param type                The type of document being deleted
 * @param documentDescription The document description
 * @param documentName        The supporting or document type name
 * @param status              The delete status
 * @param urn                 The document urn
 * @returns JSX.Element
 */
function DeleteDocumentConfirmationModal({
  isOpen,
  handleClose,
  type,
  documentDescription,
  documentName,
  status,
  urn,
}: IDeleteDocumentConfirmationModal) {
  /**
   * Renders the correct modal content based on the document type being deleted
   * @returns JSX.Element
   */
  function renderContent() {
    switch (type) {
      case EDocumentDeleteType.Primary:
        return (
          <>
            <Typography weight="bold">The following document will be deleted:</Typography>
            <Typography spacing={{ mt: 4 }}>{documentName}</Typography>
            <Typography>{documentDescription}</Typography>
            <Div spacing={{ mt: 5 }}>
              <InfoBox variant={EInfoBoxVariant.Warning}>
                <ul>
                  <li>All versions of this document will be removed from the system.</li>
                  <li>All notes will be removed.</li>
                  <li>All supporting documents will be removed.</li>
                  <li>All future notifications will be disregarded.</li>
                </ul>
              </InfoBox>
            </Div>
          </>
        );
      default:
        return (
          <Div testId="supporting-document-content">
            <Typography>
              <span className={styles.bold}>{documentName}</span>&nbsp;
              <span>will be deleted from Primary Document</span>&nbsp;
              <span className={styles.bold}>{documentDescription}</span>
            </Typography>
          </Div>
        );
    }
  }

  return (
    <Modal isOpen={isOpen} handleClose={() => handleClose(false)} size="md" testId="delete-document-confirmation-modal">
      <ModalHeader>
        <ModalTitle>
          Delete {type === EDocumentDeleteType.Primary ? "Primary Document" : "Supporting Document"}: URN {urn}
        </ModalTitle>
      </ModalHeader>
      <ModalContent>
        <Div>
          {renderContent()}
          <Typography weight="bold" spacing={{ mt: 6 }}>
            Are you sure you want to continue?
          </Typography>
        </Div>
      </ModalContent>
      <ModalActions>
        <Button onClick={() => handleClose(false)} color={cThemeColorType.Secondary}>
          Cancel
        </Button>
        <Button
          isLoading={status === cStatusType.Loading}
          onClick={() => handleClose(true)}
          color={cThemeColorType.Danger}
          testId="delete-document-confirmation-modal-submit-button"
        >
          Delete
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default DeleteDocumentConfirmationModal;
