import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../app/api";
import { documentStagesUrl } from "../app/apiUrls";
import { cStatusType } from "../app/constants";
import { RootState } from "../app/store";
import createIdList from "../utils/createIdList/createIdList";

/**
 * Describe a document stage
 */
export interface IStage {
  id: number;
  displayName: string;
}

/**
 * Describe the document stages state object
 */
interface IDocumentStagesState {
  entries: Record<number, IStage>; // Document stages records
  status: cStatusType; // API call status
  fetched: boolean; // Have entries been fetched?
  error?: string;
}

/**
 * Initial state
 */
const initialState: IDocumentStagesState = {
  entries: {},
  status: cStatusType.Idle,
  fetched: false,
};

/**
 * Thunk for fetching document stages
 */
export const postDocumentStages = createAsyncThunk(
  "documentStages/postDocumentStages",
  async (data: number[] | undefined, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = documentStagesUrl;
      const response = await api({ endpoint, dispatch, body: { id: data } });

      return createIdList(response.data.stage);
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Documents reducer
 */
export const documentStagesSlice = createSlice({
  name: "documentStages", // The name of the slice
  initialState, // Set the initialState
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // Set status to loading when the promise is pending
      .addCase(postDocumentStages.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      // Set status to failed if the promise is rejected
      .addCase(postDocumentStages.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Set status back to idle once the promise has been fulfilled
      .addCase(postDocumentStages.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.entries = { ...state.entries, ...action.payload };
        state.fetched = true; // Set fetched to true
      });
  },
});

// Select the status
export const selectDocumentStagesStatus = (state: RootState) => state.documentStages.status;
// Select all documentStages
export const selectDocumentStages = (state: RootState) => state.documentStages.entries;
// Select fetched
export const selectDocumentStagesFetched = (state: RootState) => state.documentStages.fetched;
// Select error
export const selectDocumentStagesError = (state: RootState) => state.documentStages.error;

export default documentStagesSlice.reducer;
