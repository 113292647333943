import Div from "../../../../../../components/Div/Div";
import Typography from "../../../../../../components/Typography/Typography";
import { ISpawnItem } from "../../../../../../modules/matterTasksSlice";
import MatterBoxWrapper from "../MatterBoxWrapper/MatterBoxWrapper";
import MatterTask from "../MatterTask/MatterTask";
import { IMatterTasksColumn } from "../MatterTasksColumn";
import styles from "./MatterResources.module.scss";

type TMatterResources = {
  matterItems: ISpawnItem[];
  matterID: number;
} & Pick<IMatterTasksColumn, "handleDraftDocument">;

/**
 * Render the Resources component for the Matter Details page
 * @param matterItems           The items to do
 * @param handleDraftDocument   Start spawn walk
 * @param matterID              Wrapping matter ID
 * @returns JSX.Element
 */
function MatterResources({ matterItems, handleDraftDocument, matterID }: TMatterResources): JSX.Element {
  const isEmpty = matterItems && matterItems.length === 0;

  return (
    <MatterBoxWrapper
      header={
        <Div display={{ base: "flex" }} alignItems={{ base: "center" }}>
          <Typography weight="medium">Resources</Typography>
        </Div>
      }
    >
      <Div scroll={{ y: true }} testId="matter-resources-scroll" className={isEmpty ? styles.empty : ""}>
        <Div spacing={{ px: 5 }} className={isEmpty ? styles.content : ""}>
          {matterItems &&
            matterItems.map((matterItem) => (
              <MatterTask
                key={matterItem.id}
                {...matterItem}
                handleDraftDocument={handleDraftDocument}
                matterID={matterID}
              />
            ))}
        </Div>
      </Div>
    </MatterBoxWrapper>
  );
}

export default MatterResources;
