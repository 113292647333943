import { SubmitHandler, useForm } from "react-hook-form";
import { cThemeColorType } from "../../../../app/constants";
import Button, { cButtonType } from "../../../../components/Button/Button";
import DisplayError from "../../../../components/DisplayError/DisplayError";
import FormField, { cFormFieldType } from "../../../../components/FormField/FormField";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../components/Modal/Modal";
import { isOutdatedContentError } from "../../../../utils/errors/errors";

/**
 * Form input
 */
type Inputs = {
  note: string;
};

interface IAddNoteModal {
  isOpen: boolean;
  handleClose: () => void;
  handleAddNote: (note: string) => void;
  error?: string;
}

/**
 * Document add note modal
 * @param isOpen        Is the modal open
 * @param handleClose   Close the modal
 * @param handleAddNote Handle form submission
 * @param error         Any errors
 * @returns JSX.Element
 */
function AddNoteModal({ isOpen, handleClose, handleAddNote, error }: IAddNoteModal): JSX.Element {
  const {
    register, // Register prop for form inputs
    handleSubmit, // Submit handler wrapper
    formState: { errors }, // Errors that may occur
  } = useForm<Inputs>();

  /**
   * Submit the note
   * @param note Note content
   */
  const onSubmit: SubmitHandler<Inputs> = async ({ note }) => {
    handleAddNote(note);
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} testId="add-note-modal" size="sm">
      <ModalHeader>
        <ModalTitle>Add a note</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <form id="add-note-form" onSubmit={handleSubmit(onSubmit)}>
          <FormField
            name="note"
            register={register}
            type={cFormFieldType.Textarea}
            error={errors.note}
            spacing={{ mb: 5 }}
            testId="add-note-field"
            fullWidth
            required
          />
          {error && !isOutdatedContentError(parseInt(error)) && <DisplayError>{error}</DisplayError>}
        </form>
      </ModalContent>
      <ModalActions>
        <Button onClick={handleClose} color={cThemeColorType.Secondary}>
          Cancel
        </Button>
        <Button formId="add-note-form" type={cButtonType.SubmitType}>
          Save
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default AddNoteModal;
