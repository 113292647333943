import { useState } from "react";
import { cStatusType, cThemeColorType } from "../../../../../app/constants";
import Button, { cButtonType } from "../../../../../components/Button/Button";
import Div from "../../../../../components/Div/Div";
import { EIcon } from "../../../../../components/Icon/Icon";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../../components/Modal/Modal";
import Typography from "../../../../../components/Typography/Typography";
import { EWalkStage, EWalkType } from "../../../../../modules/walkSlice";
import WalkDiscardModal from "../../../../WalkContainer/Walk/WalkDiscardModal/WalkDiscardModal";
import CollaborationVersionNotes, {
  collaborationVersionNotesFormId,
} from "./CollaborationVersionNotes/CollaborationVersionNotes";
import CollaboratorsList from "./CollaboratorsList/CollaboratorsList";
import styles from "./ManageCollaborationModal.module.scss";

export interface ICollaborator {
  id: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  action: string;
  notified: boolean;
  isOwner?: boolean;
}

interface IManageCollaborationModal {
  isOpen: boolean;
  handleClose: () => void;
  documentType: string;
  documentDescription: string;
  collaborators: ICollaborator[];
  handleDiscardCollaboration: () => void;
  stage: EWalkStage;
  handleVersionNotesSubmit: (versionNotes: string) => void;
  walkStatus: cStatusType;
}

/**
 * Manage collaboration modal
 * @param isOpen                     Is the modal open
 * @param handleClose                Function to close the modal
 * @param documentType               The document type
 * @param documentDescription        The document description
 * @param collaborators              The collaborators
 * @param handleDiscardCollaboration Function to handle discard collaboration
 * @param stage                      The current walk stage
 * @param handleVersionNotesSubmit   Function to handle version notes submit
 * @param walkStatus                 The walk status
 * @returns JSX.Element
 */
function ManageCollaborationModal({
  isOpen,
  handleClose,
  documentType,
  documentDescription,
  collaborators,
  handleDiscardCollaboration,
  stage,
  handleVersionNotesSubmit,
  walkStatus,
}: IManageCollaborationModal): JSX.Element {
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);

  /**
   * Open the WalkDiscardModal
   */
  const openDiscardModal = () => {
    setIsDiscardModalOpen(true);
  };

  /**
   * Handle the discard button click and close the WalkDiscardModal
   */
  const handleDiscardAndCloseModal = () => {
    handleDiscardCollaboration();
    setIsDiscardModalOpen(false);
  };

  const handleCloseFn =
    walkStatus === cStatusType.Loading
      ? () => {}
      : stage === EWalkStage.Collab1
        ? handleClose
        : // If the stage is Action, close the modal (discard collaboration) without extra actions
          stage === EWalkStage.Action
          ? handleDiscardAndCloseModal
          : openDiscardModal; // For other cases, open the WalkDiscardModal

  return (
    <>
      <Modal isOpen={isOpen} handleClose={handleCloseFn} scrollable testId="manage-collaboration-modal" size="xl">
        <ModalHeader handleClose={handleCloseFn}>
          <ModalTitle>Manage Collaboration</ModalTitle>
        </ModalHeader>
        <Div px={{ base: 6 }} pb={{ base: 5 }} border={{ bb: true }}>
          <Typography>{documentType}</Typography>
          <Typography weight="bold">{documentDescription}</Typography>
        </Div>
        {stage === EWalkStage.Collab1 ? (
          <Div px={{ base: 6 }} py={{ base: 5 }} display={{ md: "flex" }}>
            <Button
              color={cThemeColorType.Secondary}
              testId="add-collaborator-btn"
              icon={EIcon.AddUser}
              spacing={{ mr: 5 }}
              className={styles.addCollaboratorBtn}
            >
              Add collaborator
            </Button>
            <Button
              color={cThemeColorType.Secondary}
              icon={EIcon.Wopi}
              testId="open-document-btn"
              className={styles.openDocumentBtn}
            >
              Open in Word for the web
            </Button>
          </Div>
        ) : (
          <></>
        )}
        <ModalContent spacing={stage === EWalkStage.Collab1 ? { p: 0 } : { py: 4, px: 0 }}>
          {stage === EWalkStage.Collab1 ? (
            <CollaboratorsList collaborators={collaborators} />
          ) : (
            <CollaborationVersionNotes handleVersionNotesSubmit={handleVersionNotesSubmit} />
          )}
        </ModalContent>
        <ModalActions hasBoxShadow>
          <Button
            onClick={openDiscardModal}
            color={cThemeColorType.Secondary}
            icon={EIcon.Delete}
            testId="discard-collaboration-btn"
            disabled={walkStatus === cStatusType.Loading}
          >
            Discard
          </Button>
          {stage === EWalkStage.Collab1 ? (
            <Button testId="share-with-collaborators-btn" disabled={walkStatus === cStatusType.Loading}>
              Share with collaborators
            </Button>
          ) : (
            <Button
              formId={collaborationVersionNotesFormId}
              className={styles.versionNotesForwardBtn}
              icon={EIcon.Next}
              type={cButtonType.SubmitType}
              testId="submit-version-notes-btn"
              disabled={walkStatus === cStatusType.Loading}
            />
          )}
        </ModalActions>
      </Modal>
      <WalkDiscardModal
        isOpen={isDiscardModalOpen}
        setIsDiscardModalOpen={setIsDiscardModalOpen}
        handleDiscard={handleDiscardAndCloseModal}
        walkTypeClass={EWalkType.Collaboration}
      />
    </>
  );
}

export default ManageCollaborationModal;
