import { cSizeType } from "../../app/constants";
import Div from "../Div/Div";
import styles from "./ProgressBar.module.scss";

interface IProgressBar {
  percentage: number;
  size?: cSizeType;
}

/**
 * A simple progress bar component
 * @param percentage The number percentage to apply to the progress bar
 * @param size       The size of the progress bar (uses cSizeType enum). Defaults to Medium
 * @returns JSX.Element
 */
function ProgressBar({ percentage, size = cSizeType.Medium }: IProgressBar): JSX.Element {
  const clx = `${styles.className} ${styles[size]}`; // Generic styles and size style

  return (
    <Div className={clx}>
      <Div
        testId="progress-bar"
        className={styles.bar}
        style={{ width: `${percentage}%` }} // Set percentage passed in as width
      ></Div>
    </Div>
  );
}

export default ProgressBar;
