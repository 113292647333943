export type TUnknownEntries = Record<string, { fetchTime: number; [key: string]: unknown }>;

interface IGetExpiredIdCacheList {
  ids: number[];
  entries: TUnknownEntries;
}

/**
 * Given ids and entries, get a list of ids where matching entries are older than five minutes
 * @param ids     An array of IDs
 * @param entries A record set of entries
 * @returns number[]
 */
function getExpiredIdCacheList({ ids, entries }: IGetExpiredIdCacheList): number[] {
  const currentTime = Date.now();
  const fiveMinutes = 5 * 60 * 1000; // 5 minutes in milliseconds

  return ids.reduce((acc: number[], id: number) => {
    const entry = entries[id];

    // If there is no entry, add the id to the list
    if (!entry) {
      acc.push(id);
      return acc;
    }

    // If there is an entry and the fetchTime is older than five minutes, add the id to the list
    if (entry && entry.fetchTime && currentTime - entry.fetchTime > fiveMinutes) {
      acc.push(id);
    }

    return acc;
  }, []);
}

export default getExpiredIdCacheList;
