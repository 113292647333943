import { refreshTokenUrl } from "../../app/apiUrls";
import { APIKEY_EXPIRY } from "../../app/constants";
import { AppStore } from "../../app/store";
import { getRefreshToken, IBearerToken, updateIsLoggedIn } from "../../modules/userSlice";
import { isInvalidSessionError, isInvalidTokenError, isNotLoggedInError } from "../errors/errors";
import { hasBearerTokenExpired } from "../hasBearerTokenExpired/hasBearerTokenExpired";

let isBearerTokenRefreshing = false;
let storedRequests: any[] = [];

/**
 * Returns true if the request url is for the bearer token
 * @param request The request url
 * @returns boolean
 */
function isBearerTokenRequest(request: string) {
  return request.includes(refreshTokenUrl);
}

/**
 * Returns true if the response content type is json
 * @param response The api response
 * @returns boolean
 */
function isJsonResponse(response: Response) {
  const contentType = response.headers.get("content-type");
  return contentType && contentType.indexOf("application/json") !== -1;
}

/**
 * Returns the refreshed bearer token
 * @returns IBearerToken
 */
async function refreshBearerToken(store: AppStore): Promise<IBearerToken> {
  const refreshedToken = await store.dispatch(getRefreshToken()).unwrap();
  return refreshedToken;
}

/**
 * Interceptor to refresh the bearer token
 */
export const registerRefreshInterceptor = (store: AppStore) => {
  const { fetch: originalFetch } = window;

  window.fetch = (...args) => {
    const [request, config] = args;
    const bearerTokenExpiry = localStorage.getItem(APIKEY_EXPIRY);
    const tokenExpired = bearerTokenExpiry && hasBearerTokenExpired(bearerTokenExpiry);

    /**
     * If the token has expired, store the request, refresh the bearer token then call the stored requests
     */
    if (tokenExpired && !isBearerTokenRequest(request as string)) {
      if (!isBearerTokenRefreshing) {
        isBearerTokenRefreshing = true;
        refreshBearerToken(store)
          .then((refreshedToken) => {
            storedRequests.forEach((request) => request.resolve(refreshedToken));
          })
          .catch(() => {
            store.dispatch(updateIsLoggedIn(false));
            localStorage.removeItem(APIKEY_EXPIRY);
            window.location.reload();
          })
          .finally(() => {
            isBearerTokenRefreshing = false;
            storedRequests = [];
          });
      }
      return new Promise((resolve) => {
        storedRequests.push({
          resolve: (refreshedToken: any) => {
            if (config) {
              config.headers = {
                ...config.headers,
                Authorization: `Bearer ${refreshedToken.value}`,
              };
            }
            resolve(originalFetch(request as string, config));
          },
        });
      });
    } else {
      return originalFetch(request as string, config)
        .then((response) => {
          if (isJsonResponse(response)) {
            const clonedResponse = response.clone();
            clonedResponse.json().then((body) => {
              /**
               * Log user out if we get error code 1338, 1339 or 910
               * and the user is logged in
               */
              if (store.getState().user.isLoggedIn === true) {
                if (
                  body?.Data?.Code &&
                  (isNotLoggedInError(body?.Data?.Code) ||
                    isInvalidTokenError(body?.Data?.Code) ||
                    isInvalidSessionError(body?.Data?.Code))
                ) {
                  store.dispatch(updateIsLoggedIn(false));
                  return response;
                }
              }
            });
          }
          return response;
        })
        .catch((error) => {
          return error;
        });
    }
  };
};
