import classNames from "classnames";
import { cMonoColorType, cSizeType, cThemeColorType } from "../../app/constants";
import Div from "../Div/Div";
import Typography from "../Typography/Typography";
import styles from "./Spinner.module.scss";

interface ISpinner {
  icon?: React.ReactNode;
  text?: string;
  color?: cThemeColorType | cMonoColorType;
  size?: cSizeType;
}

function Spinner({ icon, text, color = cThemeColorType.Primary, size = cSizeType.Medium }: ISpinner): JSX.Element {
  const clx = classNames(styles.spinner, styles[color], styles[size]);

  return (
    <Div className={styles.className} testId="spinner">
      <Div className={clx}>
        <Div className={styles.icon}>{icon}</Div>
      </Div>
      {text && (
        <Typography spacing={{ mt: 5 }} className={styles.typography}>
          {text}
        </Typography>
      )}
    </Div>
  );
}

export default Spinner;
