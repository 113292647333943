import { cStatusType, cThemeColorType } from "../../../../../app/constants";
import { useAppSelector } from "../../../../../app/hooks";
import Button from "../../../../../components/Button/Button";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../../components/Modal/Modal";
import { selectESignStatus } from "../../../../../modules/esignSlice";

export enum EActivityDeleteType {
  Recipient = "recipient",
  Group = "group",
}

interface IConfirmDeleteActivityModal {
  isOpen: boolean;
  handleClose: (canDelete: boolean) => void;
  type: EActivityDeleteType | null;
}

/**
 * Confirm deletion of esign activity
 * @param isOpen       Is the modal open?
 * @param handleClose  Function to close the modal
 * @param variant      The delete type
 * @returns JSX.Element
 */
function ConfirmDeleteActivityModal({ isOpen, handleClose, type }: IConfirmDeleteActivityModal): JSX.Element {
  const status = useAppSelector(selectESignStatus);

  return (
    <Modal isOpen={isOpen} handleClose={() => handleClose(false)} testId="confirm-delete-activity-modal" size="sm">
      <ModalHeader>
        <ModalTitle>Remove {type === EActivityDeleteType.Recipient ? "Recipient" : "Group"}</ModalTitle>
      </ModalHeader>
      <ModalContent>
        Are you sure you want to remove this {type === EActivityDeleteType.Recipient ? "recipient" : "group"}?
      </ModalContent>
      <ModalActions>
        <Button
          onClick={() => handleClose(false)}
          color={cThemeColorType.Secondary}
          testId="confirm-delete-activity-modal-close-button"
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleClose(true)}
          isLoading={status === cStatusType.Loading}
          testId="confirm-delete-activity-modal-submit-button"
        >
          <>Remove {type === EActivityDeleteType.Recipient ? "recipient" : "group"}</>
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default ConfirmDeleteActivityModal;
