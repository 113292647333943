import { useState } from "react";
import { IHandlePause } from "../../pages/WalkContainer/WalkContainer";
import Modal from "../Modal/Modal";
import HandledErrorModalActions from "./HandledErrorModalActions/HandledErrorModalActions";
import HandledErrorModalContent from "./HandledErrorModalContent/HandledErrorModalContent";
import HandledErrorModalHeader from "./HandledErrorModalHeader/HandledErrorModalHeader";
import HandledErrorModalIcon from "./HandledErrorModalIcon/HandledErrorModalIcon";

interface IHandledErrorModal {
  isOpen: boolean;
  handleClose: () => void;
  errorCode: number;
  matterRef?: string;
  currentUrl?: string;
  handlePause?: (formData: IHandlePause) => void;
  documentDescription?: string;
  requireDescription?: boolean;
}

/**
 * Handled error modal
 * @param isOpen              Is the modal open?
 * @param handleClose         Function to close the modal
 * @param errorCode           The error code being handled
 * @param matterRef           The matter ref
 * @param currentUrl          The current url
 * @param handlePause         Function to pause active walk
 * @param documentDescription Description of the walk document
 * @param requireDescription  Is the description explicitly required?
 * @returns JSX.Element
 */
function HandledErrorModal({
  isOpen,
  handleClose,
  errorCode,
  matterRef,
  currentUrl,
  handlePause = () => {},
  documentDescription,
  requireDescription,
}: IHandledErrorModal) {
  const [shouldShowPauseForm, setShouldShowPauseForm] = useState<boolean>(false);

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} testId="handled-error-modal">
      <HandledErrorModalIcon errorCode={errorCode} />
      <HandledErrorModalHeader errorCode={errorCode} />
      <HandledErrorModalContent
        errorCode={errorCode}
        matterRef={matterRef}
        currentUrl={currentUrl}
        shouldShowPauseForm={shouldShowPauseForm}
        handlePause={handlePause}
        documentDescription={documentDescription}
        requireDescription={requireDescription}
      />
      <HandledErrorModalActions
        errorCode={errorCode}
        handleClose={handleClose}
        shouldShowPauseForm={shouldShowPauseForm}
        setShouldShowPauseForm={setShouldShowPauseForm}
      />
    </Modal>
  );
}

export default HandledErrorModal;
