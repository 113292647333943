import { isEmpty, isEqual } from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Control, SubmitHandler, useForm } from "react-hook-form";
import { cMonoColorType, cThemeColorType } from "../../../../app/constants";
import Accordion from "../../../../components/Accordion/Accordion";
import Button, { cButtonType, EButtonVariant } from "../../../../components/Button/Button";
import { IDataTable } from "../../../../components/DataTable/DataTable";
import Div from "../../../../components/Div/Div";
import FormField, { cFormFieldType, IFieldOption } from "../../../../components/FormField/FormField";
import Icon, { EIcon } from "../../../../components/Icon/Icon";
import RetiredFooter from "../../../../components/RetiredFooter/RetiredFooter";
import Column from "../../../../components/Row/Column/Column";
import Row from "../../../../components/Row/Row";
import Typography from "../../../../components/Typography/Typography";
import { IPostMatters } from "../../../../modules/mattersSlice";
import { IMatterTypeFilterTag } from "../../../../modules/matterTypesSlice";
import { getFilterValues } from "../../helpers/getFilterValues";
import { IMatters } from "../Matters";
import AdvancedOptionalFiltersContents, {
  IAdvancedOptionalFiltersContents,
} from "./AdvancedOptionalFiltersContents/AdvancedOptionalFiltersContents";
import styles from "./Sidebar.module.scss";

interface ISidebar {
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  typeFilterOptions: IFieldOption[];
  milestoneFilterOptions: IFieldOption[];
  ownerFilterOptions: IFieldOption[];
  onFilter: IDataTable["onFilter"];
  resetMatters: () => void;
  resetFilterData: () => void;
  updateFilterData: (filterData: IPostMatters) => void;
  getTypeOptionalFilters: (id: number) => void;
  matterTypeTags: IMatters["matterTypeTags"];
  isDrawerOpen: boolean;
  handleOptionalFilterFocus: IAdvancedOptionalFiltersContents["handleFocus"];
  resetMilestones: () => void;
}

const defaultValues = {
  matterTypeID: null,
  milestoneIDs: null,
  descriptionContains: null,
  dataContains: null,
  urn: null,
  matterRef: null,
  ownerIDs: null,
};

/**
 * The matters drawer sidebar
 * @param setIsDrawerOpen            Set state function for opening / closing the drawer
 * @param typeFilterOptions          Options for the type filter in the sidebar
 * @param milestoneFilterOptions     Options for the milestone filter in the sidebar
 * @param ownerFilterOptions         Options for the owners filter in the sidebar
 * @param onFilter                   A function to apply the filter
 * @param resetMatters               A function to reset the matters state
 * @param resetFilterData            A function to reset the matters filter data
 * @param updateFilterData           A function to update the matters filter state
 * @param getTypeOptionalFilters     A function to fetch the matter type optional filters
 * @param matterTypeTags             Matter type tags organised by matter type ID
 * @param isDrawerOpen               Is the sidebar open?
 * @param handleOptionalFilterFocus  Function to fetch the dropdown values on focus of the field
 * @param resetMilestones            Function to reset milestone state
 * @returns JSX.Element
 */
function Sidebar({
  setIsDrawerOpen,
  typeFilterOptions,
  milestoneFilterOptions,
  ownerFilterOptions,
  onFilter,
  resetMatters,
  resetFilterData,
  updateFilterData,
  getTypeOptionalFilters,
  matterTypeTags,
  isDrawerOpen,
  handleOptionalFilterFocus,
  resetMilestones,
}: ISidebar): JSX.Element {
  const {
    register, // Register prop for form inputs
    unregister, // Used to unregister fields
    handleSubmit, // Submit handler wrapper
    reset, // Reset form function
    control,
    watch,
    resetField,
  } = useForm<any>({
    defaultValues,
  });
  const [formValues, setFormValues] = useState<any>();
  const { dataContains, urn, descriptionContains, matterTypeID, matterRef, ownerIDs, milestoneIDs } = watch();
  const [currentMatterTypeTags, setCurrentMatterTypeTags] = useState<Record<string, IMatterTypeFilterTag>>({});

  useEffect(() => {
    // Reset milestone ids when matter type changes
    resetField("milestoneIDs");
  }, [matterTypeID]);

  /**
   * Returns true if the form values are empty
   * @returns boolean
   */
  function isFormReset() {
    return (
      (matterTypeID === null || matterTypeID === "") &&
      (milestoneIDs === null || milestoneIDs === undefined || milestoneIDs?.length === 0) &&
      (descriptionContains === null || descriptionContains === undefined || descriptionContains === "") &&
      (dataContains === null || dataContains === undefined || dataContains === "") &&
      (urn === null || urn === undefined || urn === "") &&
      (matterRef === null || matterRef === undefined || matterRef === "") &&
      (ownerIDs === null || ownerIDs === undefined || ownerIDs?.length === 0)
    );
  }

  // Fetch the filter tags when matter type value changes
  useEffect(() => {
    const { matterTypeID, ...tagFields } = watch();

    // Unregister tag fields on matter type change
    if (!isEmpty(tagFields)) {
      Object.keys(tagFields).forEach((key) => {
        unregister(key);
      });
    }

    if (matterTypeID) {
      getTypeOptionalFilters(matterTypeID.value);
    }
  }, [matterTypeID]);

  /**
   * Submit the form
   * @param data The form values on submit
   */
  const onSubmit: SubmitHandler<any> = (data) => {
    if (data.matterTypeID?.value === "") data.matterTypeID = null;
    setFormValues(data);
    // If the user applies a filter, we need to reset the matters, page number and error
    if (!isEqual(formValues, watch())) {
      resetMatters();
    }
    const filterValues = getFilterValues(data);
    updateFilterData(filterValues);
    if (onFilter) onFilter();
  };

  const border = { bb: true };
  const px = { base: 6 as const };
  const py = { base: 5 as const };
  const fieldWrapperProps = { border, py, px };

  /**
   * Handle close button click - close the drawer
   */
  function handleCloseClick(): void {
    setIsDrawerOpen(false);
  }

  /**
   * Handle clear of the sidebar filters
   */
  function handleClear(): void {
    reset();
    resetFilterData();
    setFormValues(undefined);
    resetMatters();
    resetMilestones();
    if (onFilter) onFilter();
  }
  // Get the current matter type tags
  useEffect(() => {
    if (matterTypeID) {
      if (matterTypeTags.hasOwnProperty(matterTypeID.value)) {
        setCurrentMatterTypeTags(matterTypeTags[matterTypeID.value] as Record<string, IMatterTypeFilterTag>);
      } else {
        setCurrentMatterTypeTags({});
      }
    }
  }, [matterTypeTags, matterTypeID]);

  return (
    <Div className={styles.inner} scroll={{ y: true }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Div spacing={{ px: 6 }} border={{ bb: true }} className={styles.header}>
          {isDrawerOpen && (
            <Button
              className={styles.closeBtn}
              onClick={handleCloseClick}
              variant={EButtonVariant.Round}
              color={cMonoColorType.Light}
            >
              <Icon icon={EIcon.Right} color={cMonoColorType.Dark} />
            </Button>
          )}
          <Row justifyContent={{ base: "start" }}>
            <Column all={9}>
              <Row>
                <Div display={{ base: "flex" }} alignItems={{ base: "center" }}>
                  <Typography weight="bold">Filter</Typography>
                  <Div ml={{ base: 5 }}>
                    <Button
                      type={cButtonType.SubmitType}
                      disabled={(isFormReset() && formValues === undefined) || isEqual(formValues, watch())}
                      disabledReason="No changes to apply"
                    >
                      Apply
                    </Button>
                  </Div>
                  <Div ml={{ base: 4 }}>
                    {!isFormReset() && (
                      <Button color={cThemeColorType.Secondary} onClick={handleClear}>
                        Clear
                      </Button>
                    )}
                  </Div>
                </Div>
              </Row>
            </Column>
          </Row>
        </Div>
        <Div {...fieldWrapperProps} testId="matters-sidebar-type-filter">
          <label htmlFor="matterTypeID" className={styles.label}>
            Type
          </label>
          <FormField
            name="matterTypeID"
            type={cFormFieldType.AutoComplete}
            options={typeFilterOptions}
            onInputChange={() => {}}
            register={register}
            fullWidth
            control={control as Control<any, any>}
            selectFooter={<RetiredFooter />}
          />
          {milestoneFilterOptions.length > 0 && (
            <Div mt={{ base: 5 }} testId="milestone-filter">
              <label htmlFor="milestoneIDs" className={styles.label}>
                Milestone
              </label>
              <FormField
                name="milestoneIDs"
                type={cFormFieldType.AutoComplete}
                options={milestoneFilterOptions}
                onInputChange={() => {}}
                register={register}
                fullWidth
                control={control as Control<any, any>}
                isMulti
              />
            </Div>
          )}
          {matterTypeID && matterTypeID.value !== "" && !isEmpty(currentMatterTypeTags) && (
            <Div className={styles.advancedFiltersWrapper} mt={{ base: 5 }}>
              <Accordion
                items={[
                  {
                    header: <Typography weight="medium">Advanced optional filters</Typography>,
                    contents: (
                      <AdvancedOptionalFiltersContents
                        register={register}
                        currentMatterTypeTags={currentMatterTypeTags}
                        control={control}
                        matterTypeID={matterTypeID.value}
                        handleFocus={handleOptionalFilterFocus}
                      />
                    ),
                    className: styles.advancedFiltersAccordion,
                    headerClassName: styles.advancedFiltersHeader,
                    contentsClassName: styles.advancedFiltersContents,
                    itemTestId: "advanced-optional-filters-accordion",
                  },
                ]}
              />
            </Div>
          )}
        </Div>
        <Div {...fieldWrapperProps}>
          <label htmlFor="descriptionContains" className={styles.label}>
            Description contains
          </label>
          <FormField name="descriptionContains" register={register} data-lpignore={true} fullWidth />
        </Div>
        <Div {...fieldWrapperProps}>
          <label htmlFor="dataContains" className={styles.label}>
            Matter data contains
          </label>
          <FormField name="dataContains" register={register} data-lpignore={true} fullWidth />
        </Div>
        <Div {...fieldWrapperProps}>
          <label htmlFor="matterRef" className={styles.label}>
            Matter Ref.
          </label>
          <FormField name="matterRef" register={register} fullWidth testId="matters-sidebar-matterref-input" />
        </Div>
        <Div {...fieldWrapperProps}>
          <label htmlFor="urn" className={styles.label}>
            Matter contains this URN
          </label>
          <FormField
            type={cFormFieldType.Number}
            name="urn"
            register={register}
            fullWidth
            testId="matters-sidebar-urn-contains-input"
          />
        </Div>
        <Div {...fieldWrapperProps}>
          <label htmlFor="ownerIDs" className={styles.label}>
            Owners
          </label>
          <FormField
            type={cFormFieldType.AutoComplete}
            name="ownerIDs"
            options={ownerFilterOptions}
            register={register}
            onInputChange={() => {}}
            control={control as Control<any, any>}
            isMulti={true}
            fullWidth
          />
        </Div>
      </form>
    </Div>
  );
}

export default Sidebar;
