import { cThemeColorType } from "../../../../../app/constants";
import { EPopoverPlacement } from "../../../../../app/types";
import { EButtonVariant } from "../../../../../components/Button/Button";
import Icon, { EIcon } from "../../../../../components/Icon/Icon";
import Popover from "../../../../../components/Popover/Popover";
import Contents from "./Contents/Contents";

interface IErroCodeDescriptionProps {
  description: string;
}

/**
 * Error Codes description Popover
 * @param description The detailed description of the error code
 * @returns JSX.Element
 */
function ErrorCodeDescriptionPopover({ description }: IErroCodeDescriptionProps): JSX.Element {
  return (
    <Popover
      popoverPlacement={EPopoverPlacement.Bottom}
      popoverContents={<Contents userDescription={description} />}
      buttonContents={<Icon icon={EIcon.Info} color={cThemeColorType.Primary} />}
      buttonProps={{
        variant: EButtonVariant.Round,
        color: cThemeColorType.Secondary,
      }}
      width="auto"
    />
  );
}

export default ErrorCodeDescriptionPopover;
