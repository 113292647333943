import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../app/api";
import { configUrl, customerLogoUrl, customerSupportUrl } from "../app/apiUrls";
import { cStatusType } from "../app/constants";
import { RootState } from "../app/store";

/**
 * Allowed login types
 */
export enum ELoginType {
  SAML = "saml",
  Basic = "basic",
}

/**
 * Describe login config object
 */
export interface ILoginConfig {
  type: ELoginType;
  url: string | null;
}

/**
 * Describes the customer slice
 */
interface IConfig {
  recaptchaKey: string;
  systemMode?: string;
  login: ILoginConfig;
  hasSupport: boolean;
  allowDocumentStages: boolean;
  allowSecurityGroups: boolean;
  maxUploadFileSizeMB: number;
  defaultToInbox: boolean;
  streamsEnabled: boolean;
}

/**
 * Describe the customer state object
 */
interface ICustomerState {
  config?: IConfig;
  status: cStatusType;
  isConfigInitialised: boolean;
  error?: string;
  companyLogo?: string;
}

/**
 * Interface for postCustomerSupport params
 */
interface IPostCustomerSupport {
  name: string;
  emailAddress: string;
  phoneNumber?: string;
  supportQuery: string;
  ccof: string;
  userId?: number;
}

/**
 * Initial state
 */
const initialState: ICustomerState = {
  status: cStatusType.Idle,
  isConfigInitialised: false,
};

export const postConfig = createAsyncThunk("config/postConfig", async (ccof: string, { rejectWithValue }) => {
  try {
    const response = await api({ endpoint: configUrl, body: { customerCode: ccof }, includeCredentials: false });
    return response.data;
  } catch (err: any) {
    throw rejectWithValue(err.message);
  }
});

/**
 * Call customer support API
 */
export const postContactSupport = createAsyncThunk(
  "user/postContactSupport",
  async (
    { userId, name, emailAddress, phoneNumber, supportQuery, ccof }: IPostCustomerSupport,
    { rejectWithValue },
  ) => {
    try {
      const body = { userId, name, emailAddress, phoneNumber, supportQuery, customerCode: ccof };
      if (!userId) {
        delete body.userId;
      }
      if (!phoneNumber) {
        delete body.phoneNumber;
      }
      const response = await api({
        endpoint: customerSupportUrl,
        includeCredentials: false,
        body: body,
      });
      return response;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk to get the company logo
 */
export const getCompanyLogo = createAsyncThunk("config/getCompanyLogo", async (_, { rejectWithValue }) => {
  try {
    const response = await api({ endpoint: customerLogoUrl, method: "GET" });
    return response.data.companyLogo;
  } catch (err: any) {
    throw rejectWithValue(err.message);
  }
});

/**
 * Create the reducer slice
 */
export const configSlice = createSlice({
  name: "customer", // The name of the slice
  initialState, // Set the initialState
  reducers: {
    clearCustomerError: (state) => {
      state.error = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(postConfig.pending, (state) => {
        state.status = cStatusType.Loading;
        state.isConfigInitialised = false;
      })
      .addCase(postConfig.rejected, (state) => {
        state.status = cStatusType.Failed;
      })
      .addCase(postConfig.fulfilled, (state, action) => {
        state.config = action.payload;
        state.status = cStatusType.Idle;
        state.isConfigInitialised = true;
      })
      .addCase(postContactSupport.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      // Change status to failed if API call fails
      .addCase(postContactSupport.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Change status to idle and shouldLogout to true if API call is successful
      .addCase(postContactSupport.fulfilled, (state) => {
        state.status = cStatusType.Idle;
      })
      // Change status to loading if API call is pending
      .addCase(getCompanyLogo.pending, (state) => {
        state.status = cStatusType.Loading;
        state.companyLogo = undefined;
      })
      // Change status to failed if API call fails
      .addCase(getCompanyLogo.rejected, (state) => {
        state.status = cStatusType.Failed;
      })
      // Change status to idle if API call is successful
      .addCase(getCompanyLogo.fulfilled, (state, action) => {
        state.companyLogo = action.payload;
        state.status = cStatusType.Idle;
      });
  },
});

export const { clearCustomerError } = configSlice.actions;

export const selectIsConfigInitialised = (state: RootState) => state.customer.isConfigInitialised; // Select isConfigInitialised state
export const selectRecaptchaKey = (state: RootState) => state.customer.config?.recaptchaKey; // Select recaptchaKey state
export const selectLoginConfig = (state: RootState) => state.customer.config?.login; // Select login config state
export const selectHasCustomerSupport = (state: RootState) => state.customer.config?.hasSupport; // Select customer support state
export const selectAllowDocumentStages = (state: RootState) => state.customer.config?.allowDocumentStages; // Select allowDocumentStages state
export const selectAllowSecurityGroups = (state: RootState) => state.customer.config?.allowSecurityGroups; // Select allowSecurityGroups state
export const selectMaxFileUploadSize = (state: RootState) => state.customer.config?.maxUploadFileSizeMB; // Select customer maxFileUploadSize state
export const selectDefaultToInbox = (state: RootState) => state.customer.config?.defaultToInbox; // Select if login landing should default to inbox / documents
export const selectStreamsEnabled = (state: RootState) => state.customer.config?.streamsEnabled; // Select is customer has streams enabled
export const selectCompanyLogo = (state: RootState) => state.customer.companyLogo; // Select customer companyLogo state
export const selectCustomerError = (state: RootState) => state.customer.error; // Select customer error state
export const selectCustomerStatus = (state: RootState) => state.customer.status; // Select customer status state

export default configSlice.reducer;
