import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { keyBy } from "lodash";
import api from "../app/api";
import {
  matterTypeMilestonesUrl,
  matterTypesFilterTagsUrl,
  matterTypesUniqueTagValuesUrl,
  matterTypesUrl,
} from "../app/apiUrls";
import { cAccessType, cStatusType } from "../app/constants";
import { RootState } from "../app/store";
import { IHandleFocus } from "../pages/MattersContainer/Matters/Sidebar/AdvancedOptionalFiltersContents/AdvancedOptionalFiltersContents";
import createIdList from "../utils/createIdList/createIdList";

/**
 * Describes a milestone
 */
export interface IMilestone {
  id: number;
  displayName: string;
}

/**
 * Matter walk types
 */
export enum EMatterTypeOfWalk {
  Draft = "draft",
  File = "file",
}

/**
 * Interface for matter type filter tags
 */
export interface IMatterTypeFilterTag {
  name: string;
  displayName: string;
  hasDropDown: boolean;
  values?: string[] | null;
}

/**
 * Describe a matter type
 */
export interface IMatterType {
  name: string;
  id: number;
  isRetired: boolean;
  state: cAccessType;
  custWalktypeID: number;
  constructorClass: EMatterTypeOfWalk;
  filterTags?: Record<string, IMatterTypeFilterTag>;
  displayMilestones: boolean;
  isLaunchExternally: boolean;
}

/**
 * Describe the matter types state object
 */
interface IMatterTypesState {
  entries: Record<number, IMatterType>; // Matter types records
  status: cStatusType; // API call status
  fetched: boolean; // Have entries been fetched?
  error?: string;
  tagsStatus: cStatusType; // API call status for tags
  tagsError?: string; // Error for tags
  milestones?: IMilestone[];
}

/**
 * Initial state
 */
const initialState: IMatterTypesState = {
  entries: {},
  status: cStatusType.Idle,
  fetched: false,
  tagsStatus: cStatusType.Idle,
};

/**
 * Thunk for fetching matter types
 */
export const postMatterTypes = createAsyncThunk(
  "matterTypes/postMatterTypes",
  async (data: number[] | "all" | undefined, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = matterTypesUrl;
      const response = await api({ endpoint, dispatch, body: { id: data } });
      return createIdList(response.data.matterType);
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching matter types filter tags
 */
export const getMatterTypesFilterTags = createAsyncThunk(
  "matterTypes/getMatterTypesFilterTags",
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = matterTypesFilterTagsUrl;
      const response: any = await api({ endpoint, dispatch, body: { matterTypeID: id } });
      return { matterTypeID: id, tags: keyBy(response.data.tag, "name") };
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching matter type milestones
 */
export const getMatterTypeMilestones = createAsyncThunk(
  "matterTypes/getMatterTypeMilestones",
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = matterTypeMilestonesUrl;
      const response: any = await api({ endpoint, dispatch, body: { matterTypeID: id } });
      return response.data.milestones;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching matter type filter tag values
 */
export const getMatterTypeUniqueTagValues = createAsyncThunk(
  "matterTypes/getMatterTypeUniqueTagValues",
  async ({ matterTypeID, tagName }: IHandleFocus, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = matterTypesUniqueTagValuesUrl;
      const response: any = await api({ endpoint, dispatch, body: { matterTypeID, tagName } });
      return { matterTypeID, tagName, values: response.data.tagValues };
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Matter types reducer
 */
export const matterTypesSlice = createSlice({
  name: "matterTypes", // The name of the slice
  initialState, // Set the initialState
  reducers: {
    resetMilestonesState: (state) => {
      state.milestones = initialState.milestones;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // Set status to loading when the promise is pending
      .addCase(postMatterTypes.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      // Set status to failed if the promise is rejected
      .addCase(postMatterTypes.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Set status back to idle once the promise has been fulfilled
      .addCase(postMatterTypes.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.entries = { ...state.entries, ...action.payload };
        state.fetched = true; // Set fetched to true
      })
      .addCase(getMatterTypesFilterTags.pending, (state) => {
        state.tagsStatus = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(getMatterTypesFilterTags.rejected, (state, action) => {
        state.tagsStatus = cStatusType.Idle;
        state.tagsError = action.payload as string;
      })
      .addCase(getMatterTypesFilterTags.fulfilled, (state, action) => {
        if (state.entries.hasOwnProperty(action.payload.matterTypeID)) {
          state.entries[action.payload.matterTypeID].filterTags = action.payload.tags;
        }
      })
      .addCase(getMatterTypeMilestones.pending, (state) => {
        state.tagsStatus = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(getMatterTypeMilestones.rejected, (state, action) => {
        state.tagsStatus = cStatusType.Idle;
        state.tagsError = action.payload as string;
      })
      .addCase(getMatterTypeMilestones.fulfilled, (state, action) => {
        state.milestones = action.payload;
      })
      .addCase(getMatterTypeUniqueTagValues.pending, (state) => {
        state.tagsStatus = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(getMatterTypeUniqueTagValues.rejected, (state, action) => {
        state.tagsStatus = cStatusType.Idle;
        state.tagsError = action.payload as string;
      })
      .addCase(getMatterTypeUniqueTagValues.fulfilled, (state, action) => {
        state.entries[action.payload.matterTypeID]!.filterTags![action.payload.tagName].values = action.payload.values;
      });
  },
});

export const { resetMilestonesState } = matterTypesSlice.actions;

// Select the status
export const selectMatterTypesStatus = (state: RootState) => state.matterTypes.status;
// Select all matterTypes
export const selectMatterTypes = (state: RootState) => state.matterTypes.entries;
// Select fetched
export const selectMatterTypesFetched = (state: RootState) => state.matterTypes.fetched;
// Select error
export const selectMatterTypesError = (state: RootState) => state.matterTypes.error;
// Select milestones
export const selectMilestones = (state: RootState) => state.matterTypes.milestones;

export default matterTypesSlice.reducer;
