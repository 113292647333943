import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../app/api";
import { eventCustomAddUrl, eventCustomDeleteUrl, eventCustomFetchPossibleRecipientsUrl } from "../app/apiUrls";
import { cStatusType } from "../app/constants";
import { RootState } from "../app/store";
import { IHandleSubmit } from "../pages/WalkContainer/Walk/NewEventModalContainer/NewEventModalContainer";

/**
 * Describe a notification recipient
 */
export interface INotificationRecipient {
  id: number;
  name: string;
  pseudoBoo: boolean;
}

/**
 * Describe the nav bar state object
 */
interface IEventCustomState {
  notificationRecipients: INotificationRecipient[];
  status: cStatusType;
  error?: string;
}

/**
 * Type for event custom add thunk
 */
type TEventCustomAdd = IHandleSubmit & {
  linkedToType: "document" | "matter";
  linkedToID: number;
};

/**
 * Initial state
 */
const initialState: IEventCustomState = { notificationRecipients: [], status: cStatusType.Idle };

/**
 * Post a custom event
 */
export const postEventCustomAdd = createAsyncThunk(
  "eventCustom/postEventCustomAdd",
  async (data: TEventCustomAdd, { dispatch, rejectWithValue, getState }) => {
    try {
      const { documents, matters } = getState() as RootState;

      const endpoint = eventCustomAddUrl;
      let compositeState;

      // Send compositeState so the api knows if the existing data is stale
      if (data.linkedToType === "document") {
        compositeState = documents.entries[data.linkedToID]?.compositeState;
      } else {
        compositeState = matters.entries[data.linkedToID]?.compositeState;
      }

      const response = await api({ endpoint, dispatch, body: { ...data, compositeState } });

      return response.data;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Fetch possible event recipients
 */
export const postPossibleRecipients = createAsyncThunk(
  "eventCustom/fetchPossibleRecipients",
  async (
    { linkedToType, linkedToID }: Pick<TEventCustomAdd, "linkedToType" | "linkedToID">,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const endpoint = eventCustomFetchPossibleRecipientsUrl;
      const response = await api({ endpoint, dispatch, body: { linkedToType, linkedToID } });
      return response.data.recipients;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Delete a custom event
 */
export const postDeleteCustomEvent = createAsyncThunk(
  "eventCustom/postDeleteCustomEvent",
  async ({ id }: { id: number }, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = eventCustomDeleteUrl;
      const response = await api({ endpoint, dispatch, body: { id } });
      return response;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Create eventCustom slice
 */
export const eventCustomSlice = createSlice({
  name: "eventCustom", // The name of the slice
  reducers: {},
  initialState, // Set the initialState
  extraReducers: (builder) => {
    builder
      // Set status to loading when the promise is pending
      .addCase(postEventCustomAdd.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      // Set status to failed if the promise is rejected
      .addCase(postEventCustomAdd.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Set status back to idle once the promise has been fulfilled
      .addCase(postEventCustomAdd.fulfilled, (state) => {
        state.status = cStatusType.Idle;
      })
      .addCase(postPossibleRecipients.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postPossibleRecipients.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postPossibleRecipients.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.notificationRecipients = action.payload;
      })
      .addCase(postDeleteCustomEvent.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postDeleteCustomEvent.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postDeleteCustomEvent.fulfilled, (state) => {
        state.status = cStatusType.Idle;
      });
  },
});

// Select notification recipients
export const selectEventCustomNotificationRecipients = (state: RootState) => state.eventCustom.notificationRecipients;
// Select status
export const selectEventCustomStatus = (state: RootState) => state.eventCustom.status;
// Select error
export const selectEventCustomError = (state: RootState) => state.eventCustom.error;

export default eventCustomSlice.reducer;
