// user
export const loginUrl = "/user/login";
export const samlLoginUrl = "/user/login-saml";
export const userDetailsUrl = "/user/user-details";
export const logoutUrl = "/user/logout";
export const usersUrl = "/user/fetch";
export const passwordResetRequestUrl = "/user/password-reset-request";
export const passwordResetUrl = "/user/password-reset";
export const addUserUrl = "/user/add";
export const editUserUrl = "/user/edit";
export const securityGroupIDsUrl = "/user/fetch-security-group-ids";
export const disableUserUrl = "/user/disable";
export const enableUserUrl = "/user/enable";
export const deleteUserUrl = "/user/delete";
export const replacementUsersUrl = "/user/replacement-users";
export const reassignUserTaskUrl = "/user/reassign-task";
export const refreshTokenUrl = "/user/refresh-bearertoken";

// main-menu
export const mainMenuUrl = "/main-menu/get";

// document
export const documentsPageUrl = "/document/fetch";
export const documentFiltersUrl = "/document/filters";
export const draftUrl = "/document/draft";
export const fileUploadUrl = "/document/file";
export const documentAddNoteUrl = "/document/add-note";
export const documentTimelineUrl = "/document/timeline";
export const downloadTimelineFileUrl = "/document/get-file";
export const interviewLogUrl = "/document/interview-log";
export const transferDocumentUsersUrl = "/document/transfer-users";
export const documentOwnershipTransferUrl = "/document/ownership-transfer";
export const documentAmendUrl = "/document/amend";
export const documentCloneUrl = "/document/clone";
export const terminateDocumentUrl = "/document/terminate";
export const checkDeleteDocumentUrl = "/document/check-delete";
export const deleteDocumentUrl = "/document/delete";
export const attachSupportingDocumentUrl = "/document/supporting-document";
export const renameDocumentUrl = "/document/rename";

// document-type
export const documentTypesUrl = "/document-type/fetch";

// stage
export const documentStagesUrl = "/stage/fetch";

// state
export const documentStatesUrl = "/state/fetch";

// category
export const categoriesUrl = "/category/fetch";

// customer
export const configUrl = "/customer/configuration";
export const customerSupportUrl = "/customer/support";
export const customerLogoUrl = "/customer/logo";

// walk
export const walkActionUrl = "/walk/action";
export const walkHelpUrl = "/walk/help";

// notify groups
export const notifyGroupsUrl = "/notify-group/fetch";

// security groups
export const securityGroupsUrl = "/security-group/fetch";

// matters
export const mattersUrl = "/matter/fetch";
export const matterFiltersUrl = "/matter/filters";
export const mattersCreateUrl = "/matter/create";
export const matterTagsUrl = "/matter/tags";
export const matterDocumentsUrl = "/matter/documents";
export const matterTimelineUrl = "/matter/timeline";
export const matterTasksUrl = "/matter/tasks";
export const matterSpawnUrl = "/matter/spawn";
export const matterLinkedUrl = "/matter/linked-matters";
export const transferMatterUsersUrl = "/matter/transfer-users";
export const matterOwnershipTransferUrl = "/matter/ownership-transfer";
export const matterGetIdUrl = "/matter/get-id";
export const matterReassignTaskUrl = "/matter/reassign-task";

// matter-type
export const matterTypesUrl = "/matter-type/fetch";
export const matterTypesFilterTagsUrl = "/matter-type/get-filter-tags";
export const matterTypeMilestonesUrl = "/matter-type/milestones";
export const matterTypesUniqueTagValuesUrl = "/matter-type/get-unique-tag-values";

// event-custom
export const eventCustomAddUrl = "/event-custom/add";
export const eventCustomFetchPossibleRecipientsUrl = "/event-custom/fetch-possible-recipients";
export const eventCustomDeleteUrl = "/event-custom/delete";

// report
export const userActivityReportUrl = "/report/user-activity";

// notification
export const notificationsUrl = "/notification/fetch";
export const overdueNotificationsUrl = "/notification/fetch-overdue";
export const deleteNotificationUrl = "/notification/delete";
export const taskNotificationsUrl = "/notification/fetch-tasks";

// analytics
export const analyticsUrl = "/analytics/fetch";
export const packagedReportFiltersUrl = "/analytics/packaged-report-filters";
export const packagedReportRunUrl = "/analytics/packaged-report-run";
export const documentTypeCustomExportsUrl = "/analytics/document-type-custom-exports";

// esign
export const esignCreateUrl = "/esign/create";
export const esignEditUrl = "/esign/edit";
export const esignFetchUrl = "/esign/fetch";
export const esignAddRecipientUrl = "/esign/add-sign-activity-person";
export const esignAddGroupRecipientUrl = "/esign/add-group-person";
export const esignFilterPeopleByEmailUrl = "/esign/filter-people-by-email";
export const esignDeleteActivityUrl = "/esign/delete-activity";
export const esignDeleteGroupActivityUrl = "/esign/delete-group-person";
export const esignReorderRecipientsUrl = "/esign/order-activities";
export const esignAddGroupUrl = "/esign/add-group";
export const esignEditGroupUrl = "/esign/edit-group";
export const esignStartUrl = "/esign/start";
export const esignCancelUrl = "/esign/cancel";

// Error codes
export const errorCodesUrl = "/error-code/fetch";
