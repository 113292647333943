import { IXDirection } from "../../../../app/types";
import Typography from "../../../Typography/Typography";
import styles from "./UserDetails.module.scss";

interface IUserDetails {
  userName: string;
  isAdmin: boolean;
  align: IXDirection;
}

/**
 * Render user's name and role
 * @param userName The user's name to render
 * @param isAdmin  Is the logged in user an administrator?
 * @param align    How the text should be aligned
 * @returns JSX.Element
 */
function UserDetails({ userName, isAdmin, align }: IUserDetails): JSX.Element {
  return (
    <>
      <Typography align={align} weight="medium" className={styles.userName}>
        {userName}
      </Typography>
      {isAdmin && (
        <Typography align={align} className={styles.userRole}>
          Administrator
        </Typography>
      )}
    </>
  );
}

export default UserDetails;
