import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { collaborators } from "../../../../mocks/collaborators";
import { postDocumentsByPage } from "../../../../modules/documentsSlice";
import {
  EWalkAction,
  EWalkStage,
  EWalkType,
  postWalkAction,
  selectWalkData,
  selectWalkStatus,
} from "../../../../modules/walkSlice";
import ManageCollaborationModal from "./ManageCollaborationModal/ManageCollaborationModal";

interface IManageCollaborationModalContainer {
  isOpen: boolean;
  handleClose: () => void;
}

/**
 * Manage collaboration modal container
 * @param isOpen              Is the modal open
 * @param handleClose         Function to close the modal
 * @returns JSX.Element
 */
function ManageCollaborationModalContainer({ isOpen, handleClose }: IManageCollaborationModalContainer): JSX.Element {
  const dispatch = useAppDispatch();
  const walkData = useAppSelector(selectWalkData);
  const walkStatus = useAppSelector(selectWalkStatus);

  /**
   * Handle pause collaboration
   */
  async function handlePauseCollaboration() {
    await dispatch(postWalkAction({ type: EWalkAction.Pause })).unwrap();
    await dispatch(postDocumentsByPage(1)).unwrap();
    handleClose();
  }

  /**
   * Handle discard collaboration
   */
  async function handleDiscardCollaboration() {
    await dispatch(postWalkAction({ type: EWalkAction.Discard })).unwrap();
    handleClose();
  }

  /**
   * Handle version notes submit
   * @param versionNotes The version notes
   */
  async function handleVersionNotesSubmit(versionNotes: string) {
    dispatch(postWalkAction({ formData: { versionNotes, action: EWalkType.Collaboration }, type: EWalkAction.Action }));
  }

  return (
    <ManageCollaborationModal
      isOpen={isOpen}
      handleClose={handlePauseCollaboration}
      handleDiscardCollaboration={handleDiscardCollaboration}
      documentType={walkData?.documentType as string}
      documentDescription={walkData?.documentDescription as string}
      collaborators={collaborators}
      stage={walkData?.stage as EWalkStage}
      handleVersionNotesSubmit={handleVersionNotesSubmit}
      walkStatus={walkStatus}
      // TODO: Integrate collaborators from the API
    />
  );
}

export default ManageCollaborationModalContainer;
