import { Navigate } from "react-router-dom";
import { cRouteType } from "../../app/constants";
import { useAppSelector } from "../../app/hooks";
import { selectRedirectUrl, selectUser } from "../../modules/userSlice";

interface IAdminProvider {
  children: React.ReactNode;
}

/**
 * Provider for handling admin access
 * @param children Wrapped children
 * @returns JSX.Element
 */
function AdminProvider({ children }: IAdminProvider): JSX.Element {
  const user = useAppSelector(selectUser);
  const redirectUrl = useAppSelector(selectRedirectUrl);

  /**
   * Get the last redirect URL from the store or default to the documents page
   * @returns string
   */
  function getRedirectUrl() {
    if (redirectUrl && !redirectUrl.includes(cRouteType.Admin)) {
      return redirectUrl;
    } else {
      return `/${cRouteType.Document}`;
    }
  }

  // If the user is an admin
  if (user?.isAdmin === true) {
    // Display the content
    return <>{children}</>;
  } else {
    // Otherwise, redirect to the stored redirect URL or the documents page
    return <Navigate to={getRedirectUrl()} />; // Redirect to the login page and with a redirect url if one exists in the store
  }
}

export default AdminProvider;
