import { format, parseISO, startOfYear } from "date-fns";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { cAccessType, cMonoColorType, cSizeType, cThemeColorType, CUSTOMER_CODE } from "../../../../app/constants";
import { useAppDispatch, useAppSelector, useQuery } from "../../../../app/hooks";
import { EPopoverPlacement } from "../../../../app/types";
import Button, { EButtonVariant } from "../../../../components/Button/Button";
import Div from "../../../../components/Div/Div";
import { ETimelineType } from "../../../../components/Events/TimelineEvents";
import Icon, { EIcon } from "../../../../components/Icon/Icon";
import Popover from "../../../../components/Popover/Popover";
import RenameDocumentModal from "../../../../components/RenameDocumentModal/RenameDocumentModal";
import Spinner from "../../../../components/Spinner/Spinner";
import TableCard from "../../../../components/TableCard/TableCard";
import TruncatedPopover from "../../../../components/TruncatedPopover/TruncatedPopover";
import Typography from "../../../../components/Typography/Typography";
import { postDocumentTransferUsers } from "../../../../modules/documentsSlice";
import { selectDocumentTypes } from "../../../../modules/documentTypesSlice";
import { postRenameMatterDocument, selectMatterDocumentRenameStatus } from "../../../../modules/matterDocumentsSlice";
import { EWalkType } from "../../../../modules/walkSlice";
import AddNoteModalContainer from "../../../DocumentsContainer/Documents/AddNoteModalContainer/AddNoteModalContainer";
import AttachSupportingDocModal from "../../../DocumentsContainer/Documents/AttachSupportingDocModal/AttachSupportingDocModal";
import DocumentActionPopover from "../../../DocumentsContainer/Documents/DocumentActionPopover/DocumentActionPopover";
import ManageCollaborationModalContainer from "../../../DocumentsContainer/Documents/ManageCollaborationModalContainer/ManageCollaborationModalContainer";
import TerminateDocumentModal from "../../../DocumentsContainer/Documents/TerminateDocumentModal/TerminateDocumentModal";
import TransferOwnershipModal from "../../../DocumentsContainer/Documents/TransferOwnershipModal/TransferOwnershipModal";
import NewEventModalContainer from "../../../WalkContainer/Walk/NewEventModalContainer/NewEventModalContainer";
import { IMatterComponent } from "../Matter";
import styles from "./MatterDocuments.module.scss";

/**
 * Primary documents within a matter
 * @param documents                  Array of documents
 * @param rawDocuments               Raw document records
 * @param handleDocumentAmend        Handle document amend actions (draft | file | update interview data)
 * @param handleCloneDocumentClick   Start a document clone walk
 * @param matterId                   The matter ID
 * @param onTransferOwnershipSuccess Reload the matter documents on ownership transfer
 * @param resumeWalk                 Function to resume a paused walk
 * @param getMatterData              Get the base matter data
 * @returns JSX.Element
 */
function MatterDocuments({
  documents,
  rawDocuments,
  handleDocumentAmend,
  handleCloneDocumentClick,
  matterId,
  onTransferOwnershipSuccess,
  resumeWalk,
  getMatterData,
}: Pick<
  IMatterComponent,
  | "documents"
  | "rawDocuments"
  | "handleDocumentAmend"
  | "handleCloneDocumentClick"
  | "onTransferOwnershipSuccess"
  | "resumeWalk"
  | "getMatterData"
> & { matterId: string }): JSX.Element {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const navigate = useNavigate();

  const documentTypes = useAppSelector(selectDocumentTypes);
  const renameStatus = useAppSelector(selectMatterDocumentRenameStatus);

  const [documentID, setDocumentID] = useState<number>();
  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState<boolean>(false);
  const [isAttachSupportingDocModalOpen, setIsAttachSupportingDocModalOpen] = useState<boolean>(false);
  const [isTransferOwnershipModalOpen, setIsTransferOwnershipModalOpen] = useState<boolean>(false);
  const [isNewEventModalOpen, setIsNewEventModalOpen] = useState<boolean>(false);
  const [isTerminateDocumentModalOpen, setIsTerminateDocumentModalOpen] = useState(false);
  const [isRenameDocumentModalOpen, setIsRenameDocumentModalOpen] = useState(false);
  const [isWordCollaborationModalOpen, setIsWordCollaborationModalOpen] = useState(false);

  const columns = [
    {
      heading: "Date",
      width: "140px",
      testId: "table-filter-date",
    },
    {
      heading: "Type",
    },
    {
      heading: "Name",
    },
    {
      heading: "Owner",
    },
    {
      heading: null,
      width: "60px",
    },
  ];

  /**
   * Open the transfer ownership modal
   * @param documentID The target document ID
   */
  async function handleTransferOwnershipClick(documentID: number) {
    if (rawDocuments) {
      await dispatch(postDocumentTransferUsers(documentID)).unwrap();
      setIsTransferOwnershipModalOpen(true);
      setDocumentID(documentID);
    }
  }

  /**
   * Open the add note modal
   * @param documentID The target document ID
   */
  function handleAddNoteClick(documentID: number): void {
    setIsAddNoteModalOpen(true);
    setDocumentID(documentID);
  }

  /**
   * Open the add event modal
   * @param documentID The target document ID
   */
  function handleAddEventClick(documentID: number): void {
    setIsNewEventModalOpen(true);
    setDocumentID(documentID);
  }

  /**
   * Handles attach supporting file
   * @param documentID The document id
   */
  async function handleAttachSupportingDoc(documentID: number): Promise<void> {
    setDocumentID(documentID);
    setIsAttachSupportingDocModalOpen(true);
  }

  /**
   * Handles terminate document
   * @param documentID The document id
   */
  async function handleTerminateDocument(documentID: number): Promise<void> {
    setDocumentID(documentID);
    setIsTerminateDocumentModalOpen(true);
  }

  /**
   * Close the terminate document modal
   */
  function handleTerminateDocumentModalClose(): void {
    setIsTerminateDocumentModalOpen(false);
    setDocumentID(undefined);
  }

  /**
   * Handles rename document
   * @param documentID The document id
   */
  function handleRenameDocument(documentID: number) {
    setDocumentID(documentID);
    setIsRenameDocumentModalOpen(true);
  }

  /**
   * Handles rename document
   * @param description The new document description
   */
  async function handleRenameDocumentSave(description: string): Promise<void> {
    await dispatch(
      postRenameMatterDocument({ matterID: Number(matterId), ID: documentID as number, description }),
    ).unwrap();

    getMatterData();
    setIsRenameDocumentModalOpen(false);
    setDocumentID(undefined);
  }

  /**
   * Close the rename document modal
   */
  function handleRenameDocumentModalClose() {
    setIsRenameDocumentModalOpen(false);
  }

  /**
   * Close the word collaboration modal
   */
  function handleWordCollaborationModalClose() {
    setIsWordCollaborationModalOpen(false);
  }

  /**
   * Sets the table row index when clicked
   * @param tableRowIndex The row index clicked
   */
  function handleTableRowClick(tableRowIndex: number): void {
    const docId = documents![tableRowIndex].id;
    const customerCode = query.get("ccof") || localStorage.getItem(CUSTOMER_CODE);
    navigate(`${docId}?ccof=${customerCode}`);
  }

  // Map data table rows into expected format
  const rows =
    documents?.map(
      ({
        lastModifiedDate,
        description,
        documentType,
        owner,
        id,
        action,
        isSigned,
        signedDate,
        termination,
        walkInProgress,
        compositeState,
      }) => {
        // Setting date column contents...
        let dateCol;

        // If there is an in progress walk on the document
        if (walkInProgress) {
          // and the matter is owned by the current user
          if (walkInProgress.ownedByMe === true) {
            // If the WIP state is not enabled, then the document type is either
            // in progress or locked for enhancement so display a disabled button with a tooltip
            if (walkInProgress.state !== cAccessType.Enabled) {
              // Determine the tooltip text and whether to show the resume button
              let tooltip = "Loading...";

              if (walkInProgress.tooltip === "Resume" && documentType) {
                tooltip = `${documentType} is currently locked for enhancement`;
              } else {
                tooltip = walkInProgress.tooltip;
              }

              dateCol = (
                <Popover
                  variant="tooltip"
                  popoverPlacement={EPopoverPlacement.Top}
                  width="auto"
                  popoverContents={tooltip}
                  buttonContents="Resume"
                  buttonProps={{
                    size: cSizeType.Small,
                    disabled: true,
                  }}
                />
              );
            } else {
              // If the action type is collaboration, then display a popover button with the collaboration icon
              if (walkInProgress.action === EWalkType.Collaboration) {
                dateCol = (
                  <Popover
                    variant="tooltip"
                    popoverPlacement={EPopoverPlacement.Top}
                    width="auto"
                    buttonContents={<Icon icon={EIcon.Collaboration} />}
                    buttonProps={{
                      variant: EButtonVariant.Square,
                      onClick: (e) => {
                        e.stopPropagation();
                      },
                      testId: "resume-collaboration-btn",
                    }}
                    popoverContents="Manage Collaboration"
                  />
                );
              } else {
                // Show paused text
                dateCol = (
                  <Button
                    size={cSizeType.Small}
                    onClick={(event) => {
                      event.stopPropagation();
                      if (walkInProgress.wipID) {
                        const compositeState =
                          rawDocuments && rawDocuments[id] && rawDocuments[id].compositeState
                            ? rawDocuments[id].compositeState
                            : undefined;
                        resumeWalk(walkInProgress.wipID, matterId, compositeState);
                      }
                    }}
                    testId="resume-button"
                  >
                    Resume
                  </Button>
                );
              }
            }
            // If not owned by the current user, set to user disable button
          } else {
            dateCol = (
              <Popover // Create the popover and button
                variant="tooltip"
                popoverPlacement={EPopoverPlacement.Top}
                width="auto"
                popoverContents={walkInProgress.tooltip}
                divProps={{
                  icon: EIcon.UserDisable,
                  iconColor: cThemeColorType.Secondary,
                }}
              />
            );
          }
          // If no walk in progress, set to created date
        } else {
          // If there is a last modified date
          if (lastModifiedDate) {
            // If previous year, display the year, otherwise omit the year
            const dateFormat = new Date(lastModifiedDate) < startOfYear(new Date()) ? "dd MMM yyyy" : "dd MMM";
            dateCol = (
              <Popover
                variant="tooltip"
                popoverPlacement={EPopoverPlacement.Top}
                width="auto"
                divProps={{
                  text: format(parseISO(lastModifiedDate), dateFormat),
                }}
                popoverContents={format(parseISO(lastModifiedDate), "EEE dd MMM yyyy, HH:mm")}
              />
            );
          } else {
            dateCol = "";
          }
        }

        return [
          <>
            <Div className={styles.group}>
              <Div display={{ base: "block", md: "none" }}>
                <Typography variant="small" color={cMonoColorType.Light}>
                  Date:&nbsp;
                </Typography>
              </Div>
              {dateCol}
            </Div>
          </>,
          <>
            <Div className={styles.group}>
              <Div display={{ base: "block", md: "none" }}>
                <Typography variant="small" color={cMonoColorType.Light}>
                  Type:&nbsp;
                </Typography>
              </Div>
              {documentType ? (
                <TruncatedPopover testId="document-type">{documentType}</TruncatedPopover>
              ) : (
                <Spinner size={cSizeType.Small} />
              )}
            </Div>
          </>,
          <>
            <Div className={styles.group}>
              <Div display={{ base: "block", md: "none" }}>
                <Typography variant="small" color={cMonoColorType.Light}>
                  Name:&nbsp;
                </Typography>
              </Div>
              <Div display={{ base: "flex" }} alignItems={{ base: "center" }}>
                {termination?.terminated && (
                  <>
                    <Icon icon={EIcon.DocumentTerminated} color={cThemeColorType.Secondary} />
                    &nbsp;&nbsp;
                  </>
                )}
                <TruncatedPopover testId="document-description">{description}</TruncatedPopover>
              </Div>
            </Div>
          </>,
          <>
            <Div className={styles.group}>
              <Div display={{ base: "block", md: "none" }}>
                <Typography variant="small" color={cMonoColorType.Light}>
                  Owner:&nbsp;
                </Typography>
              </Div>
              <TruncatedPopover testId="document-owner">{owner}</TruncatedPopover>
            </Div>
          </>,
          <DocumentActionPopover
            key={id}
            id={id}
            handleTransferOwnershipClick={handleTransferOwnershipClick}
            handleAddNoteClick={handleAddNoteClick}
            handleAddEventClick={handleAddEventClick}
            handleDocumentAmend={handleDocumentAmend}
            handleCloneDocumentClick={handleCloneDocumentClick}
            handleAttachSupportingDocClick={handleAttachSupportingDoc}
            handleTerminateDocumentClick={handleTerminateDocument}
            handleRenameDocumentClick={handleRenameDocument}
            action={action}
            termination={termination}
            isSigned={isSigned}
            signedDate={signedDate}
            compositeState={compositeState}
          />,
        ];
      },
    ) || [];

  return (
    <Div className={styles.tableWrapper}>
      <TableCard
        columns={columns}
        rows={rows}
        variant="timeline"
        onRowClick={handleTableRowClick}
        testId="matter-documents-table"
        hasClickableRows
      />
      <AddNoteModalContainer
        isOpen={isAddNoteModalOpen}
        handleClose={() => setIsAddNoteModalOpen(false)}
        documentID={documentID}
      />

      <AttachSupportingDocModal
        isOpen={isAttachSupportingDocModalOpen}
        handleClose={() => setIsAttachSupportingDocModalOpen(false)}
        documentID={documentID}
      />

      <TransferOwnershipModal
        type={ETimelineType.Document}
        isOpen={isTransferOwnershipModalOpen}
        handleClose={() => setIsTransferOwnershipModalOpen(false)}
        documentID={documentID as number}
        documents={rawDocuments}
        onSubmitSuccess={onTransferOwnershipSuccess}
      />

      <NewEventModalContainer
        isOpen={isNewEventModalOpen}
        handleClose={() => setIsNewEventModalOpen(false)}
        id={Number(documentID)}
        linkedToType="document"
      />

      <TerminateDocumentModal
        isOpen={Boolean(isTerminateDocumentModalOpen && rawDocuments)}
        handleClose={handleTerminateDocumentModalClose}
        documentType={rawDocuments ? documentTypes[rawDocuments[documentID as number]?.documentType?.id]?.name : ""}
        document={rawDocuments && rawDocuments[documentID as number]}
        matterID={Number(matterId)}
      />

      <RenameDocumentModal
        isOpen={Boolean(isRenameDocumentModalOpen && rawDocuments)}
        handleClose={handleRenameDocumentModalClose}
        documentName={rawDocuments ? rawDocuments[documentID as number]?.description : ""}
        handleRenameDocumentSave={handleRenameDocumentSave}
        status={renameStatus}
      />

      <ManageCollaborationModalContainer
        isOpen={isWordCollaborationModalOpen}
        handleClose={handleWordCollaborationModalClose}
      />
    </Div>
  );
}

export default MatterDocuments;
