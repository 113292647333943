import { addDays, format, nextSunday, startOfDay } from "date-fns";
import { endOfWeekWithOptions, startOfWeekWithOptions } from "date-fns/fp";
import { useEffect } from "react";
import { cStatusType } from "../../../../app/constants";
import { EEmptyType } from "../../../../app/types";
import DisplayError from "../../../../components/DisplayError/DisplayError";
import Div from "../../../../components/Div/Div";
import EmptyState from "../../../../components/EmptyState/EmptyState";
import Column from "../../../../components/Row/Column/Column";
import Row from "../../../../components/Row/Row";
import Spinner from "../../../../components/Spinner/Spinner";
import { INotifications, INotificationsState } from "../../../../modules/notificationsSlice";
import { ENotificationType } from "../../NotificationsContainer";
import EntriesHeader from "../EntriesHeader/EntriesHeader";
import ListEntry from "../ListEntry/ListEntry";
import styles from "../Notifications.module.scss";

export type TListLayout = {
  register: Function;
  handleDeleteNotification: (id: number, notificationType: ENotificationType) => void;
} & INotifications &
  Pick<INotificationsState, "pendingEntriesLoadingStatus" | "overdueLoadingStatus" | "taskEntriesLoadingStatus">;

/**
 * Display notifications in a list layout
 * @param pendingEntries              The pending notification entries
 * @param overdueEntries              The overdue notification entries
 * @param taskEntries                 The task notification entries
 * @param inViewPendingRef            React intersection observer ref for pending notifications
 * @param inViewOverdueRef            React intersection observer ref for overdue notifications
 * @param inViewTaskRef               React intersection observer ref for task notifications
 * @param register                    Register function from React Hook Form
 * @param overdueLoadingStatus        Loading status of overdue notification
 * @param pendingEntriesLoadingStatus Loading status of pending notifications
 * @param taskEntriesLoadingStatus    Loading status of task notifications
 * @param pendingError                Pending notification error message
 * @param overdueError                Overdue notification error message
 * @param taskError                   Task notification error message
 * @param handleDeleteNotification    Function to delete the notification
 * @returns JSX.Element
 */
function ListLayout({
  pendingEntries,
  overdueEntries,
  taskEntries,
  inViewPendingRef,
  inViewOverdueRef,
  inViewTaskRef,
  register,
  overdueLoadingStatus,
  pendingError,
  overdueError,
  taskError,
  pendingEntriesLoadingStatus,
  handleDeleteNotification,
  taskEntriesLoadingStatus,
}: TListLayout &
  Pick<
    INotificationsState,
    | "pendingEntriesLoadingStatus"
    | "overdueLoadingStatus"
    | "pendingError"
    | "overdueError"
    | "taskEntriesLoadingStatus"
    | "taskError"
  >): JSX.Element {
  const sectionSpacing = 7;
  const thisWeek = endOfWeekWithOptions({ weekStartsOn: 1 })(new Date()); // End of this week (Sunday 23:59:59)
  const thisWeekStart = startOfWeekWithOptions({ weekStartsOn: 1 })(new Date()); // Start of this week (Monday 00:00:00)
  const thisWeekStop = endOfWeekWithOptions({ weekStartsOn: 1 })(new Date()); // End of this week (Sunday 23:59:59)
  const nextWeekSundayStart = addDays(startOfDay(nextSunday(new Date())), 7); // Get following week Sunday (next week Sunday 00:00:00)

  // Get this week's entries (entry date is more than today and less than end of current week)
  const thisWeekEntries = Object.values(pendingEntries).filter(
    (entry) => entry.deadlineDTM >= thisWeekStart && entry.deadlineDTM <= thisWeekStop,
  );

  // Get next week's entries (entry date is more than end of current week and less than next week Sunday)
  const nextWeekEntries = Object.values(pendingEntries).filter(
    (entry) => entry.deadlineDTM > thisWeek && entry.deadlineDTM <= nextWeekSundayStart,
  );

  // Get entries after next week's (entry date is more than next week Sunday)
  const futureEntries = Object.values(pendingEntries).filter((entry) => entry.deadlineDTM > nextWeekSundayStart);
  const firstElement = Array.from(futureEntries)[0];
  const afterdate = firstElement?.deadlineDTM;

  /**
   * Prevent body from scrolling so that columns can scroll independently
   */
  function handleOverflow() {
    const mobileBreakpoint = 768;
    const innerWidth = window.innerWidth;

    if (innerWidth > mobileBreakpoint) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleOverflow);
    return () => window.removeEventListener("resize", handleOverflow);
  }, []);

  useEffect(() => {
    handleOverflow();
  }, []);

  const leftCol = (
    <Column all={12} lg={7} xl={8} className={styles.leftCol}>
      {taskEntries.length >= 1 && (
        <Div pb={{ base: sectionSpacing }}>
          <EntriesHeader>Task assignments</EntriesHeader>
          {taskEntries
            .sort(({ deadlineDTM: firstDate }, { deadlineDTM: secondDate }) => {
              return firstDate > secondDate ? 1 : -1;
            })
            .map(
              // "Task" entries
              (entry) => (
                <ListEntry
                  {...entry}
                  register={register}
                  key={entry.id}
                  handleDeleteNotification={() => handleDeleteNotification(entry.id, ENotificationType.Task)}
                />
              ),
            )}
        </Div>
      )}

      {thisWeekEntries.length >= 1 && (
        <Div pb={{ base: sectionSpacing }}>
          <EntriesHeader>This week</EntriesHeader>
          {thisWeekEntries
            .sort(({ deadlineDTM: firstDate }, { deadlineDTM: secondDate }) => {
              return firstDate > secondDate ? 1 : -1;
            })
            .map(
              // "This week" entries
              (entry) => (
                <ListEntry
                  {...entry}
                  register={register}
                  key={entry.id}
                  handleDeleteNotification={() => handleDeleteNotification(entry.id, ENotificationType.Pending)}
                />
              ),
            )}
        </Div>
      )}
      {nextWeekEntries.length >= 1 && (
        <Div pb={{ base: sectionSpacing }}>
          <EntriesHeader>Next week</EntriesHeader>
          {nextWeekEntries
            .sort(({ deadlineDTM: firstDate }, { deadlineDTM: secondDate }) => {
              return firstDate > secondDate ? 1 : -1;
            })
            .map(
              // "Next week" entries
              (entry) => (
                <ListEntry
                  {...entry}
                  register={register}
                  key={entry.id}
                  handleDeleteNotification={() => handleDeleteNotification(entry.id, ENotificationType.Pending)}
                />
              ),
            )}
        </Div>
      )}
      {futureEntries.length >= 1 && (
        <Div pb={{ base: 0 }}>
          <EntriesHeader>From {format(afterdate, "dd LLL")}</EntriesHeader>
          {futureEntries
            .sort(({ deadlineDTM: firstDate }, { deadlineDTM: secondDate }) => {
              return firstDate > secondDate ? 1 : -1;
            })
            .map(
              // "From..." entries
              (entry) => (
                <ListEntry
                  {...entry}
                  register={register}
                  key={entry.id}
                  handleDeleteNotification={() => handleDeleteNotification(entry.id, ENotificationType.Pending)}
                />
              ),
            )}
        </Div>
      )}

      {pendingEntries.length === 0 && pendingEntriesLoadingStatus === cStatusType.Idle && (
        <EmptyState emptyType={EEmptyType.NotificationsNotFound} name="pending notifications" />
      )}

      <Div ref={inViewPendingRef} py={{ base: 1 }} testId="react-intersection-observer-pending" />

      {pendingError && (
        <Div p={{ base: 5 }} display={{ base: "flex" }} justifyContent={{ base: "center" }}>
          <DisplayError>{pendingError}</DisplayError>
        </Div>
      )}

      {pendingEntriesLoadingStatus === cStatusType.Loading && (
        <Div p={{ base: 8 }}>
          <Spinner />
        </Div>
      )}

      <Div ref={inViewTaskRef} py={{ base: 1 }} testId="react-intersection-observer-tasks" />

      {taskError && (
        <Div p={{ base: 5 }} display={{ base: "flex" }} justifyContent={{ base: "center" }}>
          <DisplayError>{taskError}</DisplayError>
        </Div>
      )}

      {taskEntriesLoadingStatus === cStatusType.Loading && (
        <Div p={{ base: 8 }}>
          <Spinner />
        </Div>
      )}
    </Column>
  );

  const rightCol = (
    <Column all={12} lg={5} xl={4} className={styles.rightCol}>
      {overdueEntries.length >= 1 && (
        <Div pb={{ base: sectionSpacing }}>
          <EntriesHeader>Overdue</EntriesHeader>
          {overdueEntries
            .sort(({ deadlineDTM: firstDate }, { deadlineDTM: secondDate }) => {
              return firstDate < secondDate ? 1 : -1;
            })
            .map(
              // "Overdue" entries
              (entry) => (
                <ListEntry
                  {...entry}
                  register={register}
                  key={entry.id}
                  compact
                  handleDeleteNotification={() => handleDeleteNotification(entry.id, ENotificationType.Overdue)}
                />
              ),
            )}
          {overdueEntries.length === 0 && overdueLoadingStatus === cStatusType.Idle && (
            <EmptyState emptyType={EEmptyType.NotificationsNotFound} name="overdue notifications" />
          )}
        </Div>
      )}
      <Div ref={inViewOverdueRef} py={{ base: 1 }} testId="react-intersection-observer-overdue" />
      {overdueError && (
        <Div p={{ base: 5 }} display={{ base: "flex" }} justifyContent={{ base: "center" }}>
          <DisplayError>{overdueError}</DisplayError>
        </Div>
      )}
      {overdueLoadingStatus === cStatusType.Loading && (
        <Div p={{ base: 8 }}>
          <Spinner />
        </Div>
      )}
    </Column>
  );

  return (
    <Row testId="notifications-list" className={styles.scrollingColumnsContainer} justifyContent={{ base: "center" }}>
      {overdueEntries.length >= 1 ? (
        <>
          {leftCol}
          {rightCol}
        </>
      ) : (
        <>{leftCol}</>
      )}
    </Row>
  );
}

export default ListLayout;
