import styles from "./RetiredFooter.module.scss";

/**
 * "Retired" legend used in select dropdowns
 * @returns JSX.Element
 */
function RetiredFooter(): JSX.Element {
  return (
    <div className={styles.className}>
      <div className={styles.content}>
        <hr />
        <i>* Retired</i>
      </div>
    </div>
  );
}

export default RetiredFooter;
