import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { cStatusType, cThemeColorType } from "../../../../../app/constants";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import Button, { cButtonType, EButtonVariant } from "../../../../../components/Button/Button";
import DiscardChangesModal from "../../../../../components/DiscardChangesModal/DiscardChangesModal";
import Div from "../../../../../components/Div/Div";
import FormField, { cFormFieldType } from "../../../../../components/FormField/FormField";
import Label from "../../../../../components/FormField/Label/Label";
import Icon, { EIcon } from "../../../../../components/Icon/Icon";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../../components/Modal/Modal";
import Popover from "../../../../../components/Popover/Popover";
import {
  IESignGroup,
  postAddESignGroup,
  postEditESignGroup,
  selectESignStatus,
} from "../../../../../modules/esignSlice";
import { areSomeDefined } from "../../../../../utils/areSomeDefined/areSomeDefined";
import styles from "../ESignWorkflow.shared.module.scss";

/**
 * The esign group action types
 */
export enum EESignGroupActionType {
  Sign = "sign",
  Approve = "approve",
}

/**
 * Form inputs
 */
type Inputs = {
  name: string;
  type: string;
  required: number | undefined;
};

interface IAddESignGroupModal {
  isOpen: boolean;
  handleClose: () => void;
  esignID?: number | null;
  group?: IESignGroup;
}

/**
 * Confirm deletion of esign recipient
 * @param isOpen       Is the modal open?
 * @param handleClose  Function to close the modal
 * @param esignID      The esign id
 * @param group        The esign group
 * @returns JSX.Element
 */
function AddESignGroupModal({ isOpen, handleClose, esignID, group }: IAddESignGroupModal): JSX.Element {
  const spacing = { mb: 5 }; // Field spacing
  const [defaultValues, setDefaultValues] = useState<Inputs>({
    name: "",
    type: "",
    required: undefined,
  });
  const {
    register, // Register prop for form inputs
    handleSubmit, // Submit handler wrapper
    formState: { errors }, // Errors that may occur
    watch,
    reset,
  } = useForm<Inputs>({ defaultValues });
  const [isDiscardChangesModalOpen, setIsDiscardChangesModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const status = useAppSelector(selectESignStatus);
  const isEditGroup = !!group;
  const [title, setTitle] = useState("");

  useEffect(() => {
    // If we have a group prop, use it to populate the form
    if (group) {
      setTitle("Edit Group");
      const { name, groupType, required } = group;
      setDefaultValues({
        name,
        type: groupType,
        required,
      });
    } else {
      setTitle("Add a group of signers or approvers");
    }
  }, [group]);

  useEffect(() => {
    // If the default values change, reset the form to those values
    reset(defaultValues);
  }, [defaultValues]);

  /**
   * Handles Modal close
   */
  function handleModalClose() {
    if ((isEditGroup && !isEqual(defaultValues, watch())) || (!isEditGroup && areSomeDefined(watch()))) {
      setIsDiscardChangesModalOpen(true);
    } else {
      handleClose();
      reset();
    }
  }

  /**
   * Handles DiscardChangesModal close
   * @param canDiscard Can we discard the changes?
   */
  function handleDiscardChangesModalClose(canDiscard: boolean) {
    if (canDiscard) {
      handleClose();
      reset();
    }
    setIsDiscardChangesModalOpen(false);
  }

  /**
   * Submit the form
   * @param data The form values on submit
   */
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const { name, type, required } = data;
      if (isEditGroup) {
        await dispatch(
          postEditESignGroup({
            id: group.id,
            name,
            type: type as EESignGroupActionType,
            required: required as number,
          }),
        ).unwrap();
      } else {
        if (esignID) {
          await dispatch(
            postAddESignGroup({
              id: esignID,
              name,
              type: type as EESignGroupActionType,
              required: required as number,
            }),
          ).unwrap();
        }
      }
      handleClose();
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} handleClose={handleModalClose} testId="add-esign-group-modal" size="md">
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
        </ModalHeader>
        <ModalContent>
          <form id="add-group-form" onSubmit={handleSubmit(onSubmit)}>
            <FormField
              label="Group name"
              name="name"
              register={register}
              error={errors.name}
              spacing={spacing}
              fullWidth
              required
            />
            <FormField
              testId="action-buttons"
              label="Required action"
              name="type"
              register={register}
              type={cFormFieldType.Buttons}
              options={[
                {
                  label: "Sign",
                  value: EESignGroupActionType.Sign,
                  icon: EIcon.Sign,
                },
                {
                  label: "Approve",
                  value: EESignGroupActionType.Approve,
                  icon: EIcon.Approve,
                },
              ]}
              error={errors.type}
              spacing={spacing}
              required
            />
            <Div display={{ base: "flex" }}>
              <Div spacing={{ mr: 6 }}>
                <Label htmlFor="required">Number of actions required</Label>
              </Div>
              <Popover
                className={styles.popover}
                width="27rem"
                popoverContents={`Group signing is a feature that allows you to simultaneously send a document to a group of persons, where a specified number of persons in the group will be required to sign/approve the document.

                For example, a document is sent to 4 directors, any 2 of whose signatures/approvals are required.`}
                buttonContents={<Icon icon={EIcon.Help} color={cThemeColorType.Secondary} />}
                buttonProps={{ variant: EButtonVariant.Link }}
              />
            </Div>
            <FormField
              inputClassName={styles.number}
              type={cFormFieldType.Number}
              name="required"
              register={register}
              error={errors.required}
              spacing={spacing}
              required
              validate={(value: number) => {
                if (value < 1) {
                  return "Number of actions cannot be less than 1";
                } else if (value > 999) {
                  return "Maximum number of actions supported is 999";
                }
              }}
            />
          </form>
        </ModalContent>
        <ModalActions>
          <Button
            onClick={handleModalClose}
            color={cThemeColorType.Secondary}
            testId="add-esign-group-modal-close-button"
          >
            Cancel
          </Button>
          <Button
            formId="add-group-form"
            type={cButtonType.SubmitType}
            isLoading={status === cStatusType.Loading}
            testId="add-esign-group-modal-submit-button"
            disabled={isEditGroup && isEqual(defaultValues, watch())}
          >
            Save
          </Button>
        </ModalActions>
      </Modal>
      {isDiscardChangesModalOpen && (
        <DiscardChangesModal
          isOpen={isDiscardChangesModalOpen}
          handleClose={(canDiscard) => handleDiscardChangesModalClose(canDiscard)}
        />
      )}
    </>
  );
}

export default AddESignGroupModal;
