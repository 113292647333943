import { lazy, Suspense } from "react";
import Div from "../../Div/Div";
import { TInput } from "./Input";

const LazyInput = lazy(() => import("./Input"));

const Input = (props: TInput) => (
  <Suspense fallback={<Div />}>
    <LazyInput {...props} />
  </Suspense>
);

export default Input;
