import { useEffect } from "react";
import { Control, SubmitHandler, useForm } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import { cStatusType, cThemeColorType, CUSTOMER_SUPPORT_TEL_NO } from "../../../../app/constants";
import { IUser } from "../../../../modules/usersSlice";
import { isValidEmail } from "../../../../utils/isValidEmail/isValidEmail";
import Button, { cButtonType } from "../../../Button/Button";
import DisplayError from "../../../DisplayError/DisplayError";
import Div from "../../../Div/Div";
import FormField, { cFormFieldType } from "../../../FormField/FormField";
import Label from "../../../FormField/Label/Label";
import Typography from "../../../Typography/Typography";
import styles from "./SupportForm.module.scss";

export type Inputs = {
  userId: number;
  name: string;
  phoneNumber: string;
  emailAddress: string;
  supportQuery: string;
};

interface ISupportForm {
  status: cStatusType;
  customerError?: string;
  isLoggedIn: boolean;
  user?: IUser;
  submitSupportForm: (data: Inputs) => void;
}

/**
 * The support form
 * @param status                The status of the form submission
 * @param customerError         The customer error message
 * @param isLoggedIn            If the user is logged in
 * @param user                  The user details
 * @param submitSupportForm     Submits the support form
 * @returns JSX.Element
 */
function SupportForm({ status, customerError, isLoggedIn, user, submitSupportForm }: ISupportForm): JSX.Element {
  const spacing = { mb: 5 };
  const isSubmitting = status === cStatusType.Loading;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm<Inputs>();

  /**
   * Updates the form values from user data
   */
  function updateFormValuesFromUser() {
    if (user) {
      setValue("userId", user.id);
      setValue("name", `${user.firstName} ${user.lastName}`);
      if (user.emailAddress) {
        setValue("emailAddress", user.emailAddress);
      }
      if (user.phoneNumber) {
        setValue("phoneNumber", user.phoneNumber);
      }
    }
  }

  /**
   * Update the form values from user data on user change
   */
  useEffect(() => {
    if (isLoggedIn && user) {
      updateFormValuesFromUser();
    }
  }, [isLoggedIn, user]);

  /**
   * Submit the form
   * @param data The form values on submit
   */
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    submitSupportForm(data);
  };

  return (
    <Div className={styles.className} testId="customer-support-form">
      <Typography weight="bold" className={styles.supportNumber} spacing={spacing}>
        Call us on <span>&nbsp;</span>
        <Typography color={cThemeColorType.Primary}>
          <a className={styles.supportNumberLink} href={`tel:${CUSTOMER_SUPPORT_TEL_NO}`}>
            {CUSTOMER_SUPPORT_TEL_NO}
          </a>
        </Typography>
      </Typography>
      <Typography spacing={spacing} weight="bold">
        Or let us contact you:
      </Typography>

      {isLoggedIn && user && (
        <Div>
          <Label>Email address</Label>
          <Typography>{user.emailAddress}</Typography>
        </Div>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          label="User id"
          name="userId"
          register={register}
          error={errors.userId}
          spacing={spacing}
          required={isLoggedIn}
          fullWidth
          hidden
        />
        <FormField
          label="Name"
          name="name"
          register={register}
          error={errors.name}
          spacing={spacing}
          required={!isLoggedIn}
          fullWidth
          hidden={isLoggedIn}
        />
        <FormField
          label="Phone number"
          name="phoneNumber"
          type={cFormFieldType.Telephone}
          register={register}
          error={errors.phoneNumber}
          spacing={spacing}
          fullWidth
          defaultValue={user?.phoneNumber}
          control={control as Control<any, any>}
          validate={(value: string) => {
            if (value) {
              if (value !== null && !isValidPhoneNumber(value)) {
                return "Phone number is invalid";
              }
            }
          }}
        />
        <FormField
          label="Email address"
          name="emailAddress"
          register={register}
          error={errors.emailAddress}
          spacing={spacing}
          required={!isLoggedIn}
          fullWidth
          hidden={isLoggedIn}
          validate={(value: string) => {
            if (!isLoggedIn && !isValidEmail(value)) {
              return "Email is invalid";
            }
          }}
        />
        <FormField
          label="What do you need help with?"
          name="supportQuery"
          type={cFormFieldType.Textarea}
          register={register}
          error={errors.supportQuery}
          spacing={spacing}
          required
          fullWidth
        />
        <Button type={cButtonType.SubmitType} isLoading={isSubmitting}>
          Send
        </Button>
        {customerError && <DisplayError>{customerError}</DisplayError>}
      </form>
    </Div>
  );
}

export default SupportForm;
