import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../app/api";
import { notifyGroupsUrl } from "../app/apiUrls";
import { cStatusType } from "../app/constants";
import { RootState } from "../app/store";
import createIdList from "../utils/createIdList/createIdList";

/**
 * Describes a notify group
 */
export interface INotifyGroup {
  id: number;
  name: string;
  canBeDeleted: boolean;
  isRestricted: boolean;
}

/**
 * Describes the notifiy group state object
 */
interface INotifyGroupsState {
  entries: Record<number, INotifyGroup>; // Notify group records
  status: cStatusType; // API call status
  fetched: boolean; // Have entries been fetched?
  error?: string;
}

/**
 * Initial state
 */
const initialState: INotifyGroupsState = {
  entries: {},
  status: cStatusType.Idle,
  fetched: false,
};

/**
 * Thunk for fetching notify groups
 */
export const postNotifyGroups = createAsyncThunk(
  "notifyGroups/postNotifyGroups",
  async (data: number | "all", { dispatch, rejectWithValue }) => {
    try {
      const endpoint = notifyGroupsUrl;
      const response = await api({ endpoint, dispatch, body: { id: data } });
      return createIdList(response.data.notifyGroup);
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Notify groups reducer
 */
export const notifyGroupsSlice = createSlice({
  name: "notifyGroups", // The name of the slice
  initialState, // Set the initialState
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // Set status to loading when the promise is pending
      .addCase(postNotifyGroups.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      // Set status to failed if the promise is rejected
      .addCase(postNotifyGroups.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Set status back to idle once the promise has been fulfilled
      .addCase(postNotifyGroups.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.entries = { ...state.entries, ...action.payload };
        state.fetched = true; // Set fetched to true
      });
  },
});

// Select all notifyGroups
export const selectNotifyGroups = (state: RootState) => state.notifyGroups.entries;
export const selectNotifyGroupsStatus = (state: RootState) => state.notifyGroups.status;

export default notifyGroupsSlice.reducer;
