import classNames from "classnames";
import { differenceInCalendarDays, endOfDay, endOfWeek, format, startOfDay } from "date-fns";
import { cThemeColorType } from "../../../../app/constants";
import { useAppSelector } from "../../../../app/hooks";
import Button from "../../../../components/Button/Button";
import Div from "../../../../components/Div/Div";
import Icon, { EIcon } from "../../../../components/Icon/Icon";
import Column from "../../../../components/Row/Column/Column";
import Row from "../../../../components/Row/Row";
import rowStyles from "../../../../components/Row/Row.module.scss";
import Typography from "../../../../components/Typography/Typography";
import { ENotificationWarningLevel, INotificationsEntry } from "../../../../modules/notificationsSlice";
import { selectUser } from "../../../../modules/userSlice";
import { selectUsers } from "../../../../modules/usersSlice";
import { ENotificationType } from "../../NotificationsContainer";
import CompactContent from "./CompactContent/CompactContent";
import styles from "./ListEntry.module.scss";
import StandardContent from "./StandardContent/StandardContent";

type TListEntry = {
  register: Function;
  compact?: boolean;
  handleDeleteNotification: () => void;
} & INotificationsEntry;

/**
 * A single inbox list entry
 * @param assignedTo                The task assignee
 * @param deadlineDTM               The due date of the entry
 * @param transaction               Detail of the related transaction
 * @param description               The Notification description
 * @param remainingDays             Days to go until or days passed since the deadline
 * @param documentType              The associated document type name
 * @param matterType                The associated matter type name
 * @param status                    The status of the notification
 * @param documentDescription       The document description
 * @param matterDescription         The matter description
 * @param urn                       The URN number of the document
 * @param compact                   Boolean to display the entry as compact
 * @param invalidCode               Reason for invalidation
 * @param currentState              The current state of the notification
 * @param notificationType          The type of notification
 * @param handleDeleteNotification  Function to delete the notification
 * @param document                  The document related to the notification
 * @returns JSX.Element
 */
function ListEntry({
  deadlineDTM,
  transaction,
  remainingDays,
  description,
  currentState,
  notificationType,
  documentType,
  matterType,
  invalidCode,
  documentDescription,
  matterDescription,
  document,
  urn,
  status,
  compact,
  handleDeleteNotification,
}: TListEntry): JSX.Element {
  const users = useAppSelector(selectUsers); // Get all users
  const user = useAppSelector(selectUser); // Get the session user
  const endOfToday = endOfDay(new Date()); // Get following day
  // Calculate the number of days remaining (integer)
  let remainingDaysCalc = differenceInCalendarDays(deadlineDTM, new Date());

  if (notificationType === ENotificationType.Task) {
    // If notification is a task...
    currentState = ENotificationWarningLevel.Task; // set color to task color
  } else if (deadlineDTM < startOfDay(new Date())) {
    // If date is less than start of today...
    currentState = ENotificationWarningLevel.Expired; // set color to black
  } else if (deadlineDTM >= startOfDay(new Date()) && deadlineDTM < endOfToday) {
    // If date is bigger than or equal to start of today and smaller than the end of today...
    currentState = ENotificationWarningLevel.WarningHigh; // set color to yellow
  } else if (deadlineDTM < endOfWeek(new Date()) && deadlineDTM > endOfToday) {
    // If date is less than end of week...
    currentState = ENotificationWarningLevel.WarningMedium; // set color to yellow
  } else {
    currentState = ENotificationWarningLevel.WarningLow; // set color to green
  }

  // The text to display after the # remaining days number
  // if the notification is a task notification, display "Assigned to me" instead of the remaining days
  if (transaction?.interviewSpawn) {
    const assignedTo = transaction.interviewSpawn.assignedTo;
    // if the assignedTo matches the session user, show "me", else show the assignees name
    if (assignedTo !== undefined) {
      remainingDays =
        assignedTo === user?.id ? (
          "Assigned to me"
        ) : (
          <>
            <Icon icon={EIcon.Reassign} color={cThemeColorType.Secondary} />
            <Div testId="reassigned-user-name" display={{ base: "inline" }} spacing={{ pl: 3 }}>
              {`Assigned to ${users[assignedTo]?.firstName || ""} ${users[assignedTo]?.lastName || ""}`}
            </Div>
          </>
        );
    }
  } else {
    if (remainingDaysCalc === 0) {
      remainingDays = "Due today";
    } else if (remainingDaysCalc === 1) {
      remainingDays = "Due tomorrow";
    } else if (remainingDaysCalc === -1) {
      remainingDaysCalc = remainingDaysCalc * -1; // show overdue day as a positive integer
      remainingDays = `${remainingDaysCalc} day overdue`;
    } else if (remainingDaysCalc > 1) {
      remainingDays = `${remainingDaysCalc} days remaining`;
    } else if (remainingDaysCalc < 0) {
      remainingDaysCalc = remainingDaysCalc * -1; // show overdue days as a positive integer
      remainingDays = `${remainingDaysCalc} days overdue`;
    } else {
      remainingDays = "";
    }
  }

  const currentStateClx = classNames(
    styles.date, // Date container generic class
    styles.currentState, // Date border generic class
    styles[`${currentState}`], // Date border colour class
    transaction?.interviewSpawn && styles.task, // Apply task style if notification is a task
  );

  return (
    <Div spacing={{ mb: 3 }}>
      <Row alignItems={{ base: "center" }}>
        <Column className={styles.overflowFix}>
          <Div className={styles.content}>
            <Row
              className={rowStyles.noWrap}
              alignItems={compact ? { base: "stretch" } : { base: "center" }}
              justifyContent={{ base: "space-between" }}
            >
              <Column grow={false} alignSelf={{ base: "stretch" }}>
                <Row
                  // Date wrapper
                  justifyContent={{ base: "center" }}
                  className={styles.dateRow}
                >
                  <Column>
                    <Div className={currentStateClx} spacing={{ px: 5, py: 4 }}>
                      <Typography className={styles.dateWeekDay} weight="medium" testId="deadline-date-weekday">
                        {format(deadlineDTM, "eee")}
                      </Typography>
                      <Typography className={styles.dateDay} weight="bold" testId="deadline-date-day">
                        {format(deadlineDTM, "dd")}
                      </Typography>
                      <Typography
                        className={styles.dateMonth}
                        weight="bold"
                        casing="upper"
                        testId="deadline-date-month"
                      >
                        {format(deadlineDTM, "LLL")}
                      </Typography>
                      <Typography className={styles.dateYear} testId="deadline-date-year">
                        {format(deadlineDTM, "yyyy")}
                      </Typography>
                    </Div>
                  </Column>
                </Row>
              </Column>
              <Column className={styles.overflowFix}>
                <Div py={{ base: 4 }}>
                  <Row alignItems={{ base: "center" }} className={styles.noWrap}>
                    {compact ? (
                      // Display compact content if compact prop is true
                      <CompactContent
                        transaction={transaction}
                        description={description}
                        documentType={documentType}
                        urn={urn}
                        documentDescription={documentDescription}
                        remainingDays={remainingDays}
                        status={status}
                        invalidCode={invalidCode}
                        matterType={matterType}
                        document={document}
                      />
                    ) : (
                      // Otherwise, display standard content
                      <StandardContent
                        transaction={transaction}
                        description={description}
                        documentType={documentType}
                        urn={urn}
                        documentDescription={documentDescription}
                        matterDescription={matterDescription}
                        remainingDays={remainingDays}
                        status={status}
                        invalidCode={invalidCode}
                        matterType={matterType}
                        document={document}
                      />
                    )}
                  </Row>
                </Div>
              </Column>
              <Column all={"auto"} grow={false} alignSelf={{ base: "flex-start", lg: "center" }}>
                <Div p={{ base: 5 }} className={styles.listAction}>
                  <Button
                    color={cThemeColorType.Secondary}
                    testId="delete-notification-button"
                    icon={EIcon.Delete}
                    onClick={handleDeleteNotification}
                  />
                </Div>
              </Column>
            </Row>
          </Div>
        </Column>
      </Row>
    </Div>
  );
}

export default ListEntry;
