import { createAsyncThunk, createSlice, ListenerEffectAPI, ThunkDispatch, UnknownAction } from "@reduxjs/toolkit";
import api from "../app/api";
import {
  analyticsUrl,
  documentTypeCustomExportsUrl,
  packagedReportFiltersUrl,
  packagedReportRunUrl,
} from "../app/apiUrls";
import { cStatusType } from "../app/constants";
import { RootState } from "../app/store";
import { postDocumentStages } from "./documentStagesSlice";
import { postDocumentStates } from "./documentStatesSlice";
import { postDocumentTypes } from "./documentTypesSlice";
import { postUsersByID } from "./usersSlice";

/**
 * Describe the optional filters
 */
interface IOptionalFilters {
  documentTypeIDs: number[];
  descriptionContains: {
    description: string | null;
    caseSensitive: boolean;
  };
  documentDataContains: {
    documentData: string | null;
    caseSensitive: boolean;
  };
  stateIDs: number[];
  stageIDs: number[];
  ownerIDs: number[];
}

/**
 * Describe a packaged report filter set
 */
export type IPackagedReportFilters = Partial<
  Record<
    EReportCodes,
    {
      date: string;
      documentClass: "draft" | "file" | null;
      optionalFilters: IOptionalFilters;
      dateFilter: "Original Creation Date" | "Last Modified Date";
      startDate: string;
      endDate: string;
    }
  >
>;

/**
 * Possible document classes
 */
export enum EDocumentClasses {
  Draft = "draft",
  File = "file",
  All = "all",
}

/**
 * Possible report codes
 */
export enum EReportCodes {
  Osr008 = "OSR008",
  Osr002 = "OSR002",
  Osr001 = "OSR001",
  Osr009 = "OSR009",
  Osr006 = "OSR006",
  Osr003 = "OSR003",
  Osr004 = "OSR004",
  Osr005 = "OSR005",
  Osr010 = "OSR010",
  Osr007 = "OSR007",
}

interface IReportBase {
  name: string;
  code: string; // Unified property for handling both id and code
}

/**
 * Describe a packaged report type
 */
export interface IPackagedReport extends IReportBase {
  code: EReportCodes;
}

/**
 * Describe a document analytics type
 */
export interface IDocumentAnalytics extends IReportBase {
  id: number;
  access: "enabled" | "inaccessible";
}

/**
 * Describe the custom exports type
 */
export interface ICustomExport {
  id: number;
  name: string;
}

/**
 * Describe packaged reports
 */
export interface IPackagedReports {
  entries: IPackagedReport[];
  filters: IPackagedReportFilters;
}

/**
 * Describe custom exports
 */
export interface ICustomExports {
  entries: ICustomExport[];
  documentTypeID: number;
}

/**
 * Describe custom exports state
 */
export interface ICustomExportsState {
  entries: Record<number, ICustomExport>; // record for custom exports
  status: cStatusType; // API call status
  fetched: boolean;
  error?: string;
}

/**
 * Describe document analytics state
 */
export interface IDocumentAnalyticsState {
  entries: Record<number, IDocumentAnalytics>; // records
  status: cStatusType; // API call status
  fetched: boolean;
  error?: string;
  customExports: ICustomExport[];
  customExportsStatus: cStatusType; // API call status for custom exports
  customExportsError?: string; // Error for custom exports
  selectedDocumentAnalyticsId: number | null;
}

/**
 * Interface for the packaged reports state
 */
export interface IPackagedReportsState {
  entries: Record<number, IPackagedReport>; // records
  status: cStatusType; // API call status
  fetched: boolean;
  error?: string;
  filtersLoadingStatus: cStatusType; // API call status for filters
  filtersError?: string; // Error for filters
  filters: IPackagedReportFilters | null;
  runStatus: cStatusType; // API call status for running the packaged report
  runError?: string; // Error for running the packaged report
  selectedCode: EReportCodes | null;
}

/**
 * Interface for the analytics state
 */
export interface IAnalyticsState {
  packagedReports: IPackagedReportsState;
  documentAnalytics: IDocumentAnalyticsState;
}

/**
 * Initial state for the analytics
 */
const initialState: IAnalyticsState = {
  packagedReports: {
    entries: {},
    filters: null,
    status: cStatusType.Idle,
    fetched: false,
    filtersLoadingStatus: cStatusType.Idle,
    runStatus: cStatusType.Idle,
    selectedCode: null,
  },
  documentAnalytics: {
    entries: {},
    status: cStatusType.Idle,
    fetched: false,
    customExports: [],
    customExportsStatus: cStatusType.Idle,
    selectedDocumentAnalyticsId: null,
  },
};

/**
 * Thunk for fetching analytics
 */
export const fetchAnalyticsData = createAsyncThunk(
  "analytics/fetchAnalyticsData",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = analyticsUrl;
      const response = await api({ endpoint, dispatch, method: "GET" });
      const { packagedReports, documentAnalytics } = response.data;
      return { packagedReports, documentAnalytics };
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching packaged report filters
 */
export const postPackagedReportFilters = createAsyncThunk(
  "analytics/postPackagedReportFilters",
  async (code: EReportCodes, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = packagedReportFiltersUrl;
      const response = await api({ endpoint, dispatch, body: { code } });

      return response.data;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for running a packaged report
 */
export const postRunPackagedReport = createAsyncThunk(
  "analytics/postRunPackagedReport",
  async (payload: IPackagedReportFilters, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = packagedReportRunUrl;
      const response = await api({ endpoint, dispatch, body: payload });

      return response.data;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching custom exports for document analytics
 */
export const postDocumentTypeCustomExports = createAsyncThunk(
  "analytics/postDocumentTypeCustomExports",
  async (documentTypeID: number, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = documentTypeCustomExportsUrl;
      const response = await api({ endpoint, dispatch, body: { documentTypeID } });

      return { documentTypeID, customExports: response.data.customExports };
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  },
);

/**
 * Fetch the packaged report filter items after the filter IDs have been updated
 * @param _           The action
 * @param listenerApi The listener API
 * @returns void
 */
export const fetchPackagedReportFiltersEffect = (
  _: UnknownAction,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>,
) => {
  const { dispatch, getState, cancelActiveListeners } = listenerApi;
  const state = getState() as RootState;
  const {
    analytics: {
      packagedReports: { selectedCode, filters },
    },
  } = state;

  /**
   * Get the filter IDs
   * @param key The key to get the filter IDs for
   * @returns The filter IDs
   */
  function getFilterIds(key: string): number[] {
    return selectedCode !== null && filters?.[selectedCode as EReportCodes]
      ? (filters?.[selectedCode as EReportCodes]?.optionalFilters[key as keyof IOptionalFilters] as number[])
      : [];
  }

  const documentTypeIds = getFilterIds("documentTypeIDs");
  const documentOwnerIds = getFilterIds("ownerIDs");
  const documentStateIds = getFilterIds("stateIDs");
  const documentStageIds = getFilterIds("stageIDs");

  cancelActiveListeners();

  if (documentTypeIds) {
    dispatch(postDocumentTypes({ ids: documentTypeIds }));
  }

  if (documentOwnerIds) {
    dispatch(postUsersByID({ ids: documentOwnerIds }));
  }

  if (documentStateIds) {
    dispatch(postDocumentStates(documentStateIds));
  }

  if (documentStageIds) {
    dispatch(postDocumentStages(documentStageIds));
  }
};

// Create the analytics slice
export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    // Set the selected code
    updateSelectedCode: (state, action) => {
      state.packagedReports.selectedCode = action.payload;
    },
    // Set the selected document analytics ID
    updateSelectedDocumentAnalyticsId: (state, action) => {
      state.documentAnalytics.selectedDocumentAnalyticsId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Get analytics data
      .addCase(fetchAnalyticsData.pending, (state) => {
        state.packagedReports.status = cStatusType.Loading;
        state.packagedReports.error = undefined;

        state.documentAnalytics.status = cStatusType.Loading;
        state.documentAnalytics.error = undefined;
      })
      .addCase(fetchAnalyticsData.rejected, (state, action) => {
        state.packagedReports.status = cStatusType.Failed;
        state.packagedReports.error = action.payload as string;

        state.documentAnalytics.status = cStatusType.Failed;
        state.documentAnalytics.error = action.payload as string;
      })
      .addCase(fetchAnalyticsData.fulfilled, (state, action) => {
        state.packagedReports.status = cStatusType.Idle;
        state.packagedReports.entries = { ...state.packagedReports.entries, ...action.payload.packagedReports };
        state.packagedReports.fetched = true;

        state.documentAnalytics.status = cStatusType.Idle;
        state.documentAnalytics.entries = { ...state.documentAnalytics.entries, ...action.payload.documentAnalytics };
        state.documentAnalytics.fetched = true;
      })
      // Fetch document type custom exports
      .addCase(postDocumentTypeCustomExports.pending, (state) => {
        state.documentAnalytics.customExportsStatus = cStatusType.Loading;
        state.documentAnalytics.customExportsError = undefined;
      })
      .addCase(postDocumentTypeCustomExports.fulfilled, (state, action) => {
        state.documentAnalytics.customExportsStatus = cStatusType.Idle;
        state.documentAnalytics.customExports = action.payload.customExports;
      })
      .addCase(postDocumentTypeCustomExports.rejected, (state, action) => {
        state.documentAnalytics.customExportsStatus = cStatusType.Failed;
        state.documentAnalytics.customExportsError = action.payload as string;
      })
      // Fetch filters
      .addCase(postPackagedReportFilters.pending, (state) => {
        state.packagedReports.filtersLoadingStatus = cStatusType.Loading;
        state.packagedReports.error = undefined;
      })
      .addCase(postPackagedReportFilters.rejected, (state, action) => {
        state.packagedReports.filtersLoadingStatus = cStatusType.Failed;
        state.packagedReports.error = action.payload as string;
      })
      .addCase(postPackagedReportFilters.fulfilled, (state, action) => {
        state.packagedReports.filtersLoadingStatus = cStatusType.Idle;
        state.packagedReports.filters = { ...state.packagedReports.filters, ...action.payload };
        state.packagedReports.fetched = true;
      })
      // Run report
      .addCase(postRunPackagedReport.pending, (state) => {
        state.packagedReports.runStatus = cStatusType.Loading;
        state.packagedReports.runError = undefined;
      })
      .addCase(postRunPackagedReport.rejected, (state, action) => {
        state.packagedReports.runStatus = cStatusType.Failed;
        state.packagedReports.runError = action.payload as string;
      })
      .addCase(postRunPackagedReport.fulfilled, (state) => {
        state.packagedReports.runStatus = cStatusType.Idle;
      });
  },
});

// Export the actions
export const { updateSelectedCode, updateSelectedDocumentAnalyticsId } = analyticsSlice.actions;

// Select document analytics
export const selectAllDocumentAnalytics = (state: RootState) => state.analytics.documentAnalytics.entries;
export const selectDocumentAnalyticsStatus = (state: RootState) => state.analytics.documentAnalytics.status;
export const selectDocumentAnalyticsLoading = (state: RootState) => state.analytics.documentAnalytics.fetched;
export const selectDocumentAnalyticsError = (state: RootState) => state.analytics.documentAnalytics.error;

// Select custom exports
export const selectAllCustomExports = (state: RootState) => state.analytics.documentAnalytics.customExports;
export const selectCustomExportsStatus = (state: RootState) => state.analytics.documentAnalytics.customExportsStatus;
export const selectCustomExportsError = (state: RootState) => state.analytics.documentAnalytics.customExportsError;

// Select document analytics ID
export const selectSelectedDocumentAnalyticsId = (state: RootState) =>
  state.analytics.documentAnalytics.selectedDocumentAnalyticsId;

// Select packaged reports
export const selectAllPackagedReports = (state: RootState) => state.analytics.packagedReports.entries;
export const selectPackagedReportStatus = (state: RootState) => state.analytics.packagedReports.status;
export const selectPackagedReportsLoading = (state: RootState) => state.analytics.packagedReports.fetched;
export const selectPackagedReportsError = (state: RootState) => state.analytics.packagedReports.error;
export const selectAllPackagedReportFilters = (state: RootState) => state.analytics.packagedReports.filters;

// Select packaged reports filters
export const selectPackagedReportFiltersStatus = (state: RootState) =>
  state.analytics.packagedReports.filtersLoadingStatus;
export const selectPackagedReportFiltersError = (state: RootState) => state.analytics.packagedReports.filtersError;

// Select packaged reports run
export const selectRunPackagedReportStatus = (state: RootState) => state.analytics.packagedReports.runStatus;
export const selectRunPackagedReportError = (state: RootState) => state.analytics.packagedReports.runError;

// Select packaged reports selected code
export const selectSelectedCode = (state: RootState) => state.analytics.packagedReports.selectedCode;

export default analyticsSlice.reducer;
