import { isBefore, subSeconds } from "date-fns";

/**
 * Returns true if the bearer token has expired or will expire in the next 10 seconds
 * @param bearerTokenExpiry The api key expiry datetime
 * @returns boolean
 */
export function hasBearerTokenExpired(bearerTokenExpiry: string) {
  const expiry = subSeconds(new Date(bearerTokenExpiry), 10);
  return isBefore(expiry, new Date());
}
