import { chain } from "lodash";
import { IUsersState } from "../../modules/usersSlice";

/**
 * Sorts the users by last name, first name
 */
function sortUsersByName(users: IUsersState["entries"]) {
  const sortedUsers = chain(users)
    // Filter out users with no lastName - e.g. EMBED user
    .filter((user) => !!user?.lastName)
    // Sort by lastName, firstName ignoring case
    .orderBy([(user) => user.lastName?.toLowerCase(), (user) => user.firstName.toLowerCase()])
    .value();

  return sortedUsers;
}

export default sortUsersByName;
