import Div from "../../../../../../components/Div/Div";
import FormField, { cFormFieldType } from "../../../../../../components/FormField/FormField";
import { IMatterTypeFilterTag } from "../../../../../../modules/matterTypesSlice";
import { IAdvancedOptionalFiltersContents } from "../AdvancedOptionalFiltersContents";
import styles from "./AdvancedOptionalField.module.scss";

/**
 * A single matter filter advanced optional field
 * @param name The name of the field
 * @param displayName  The name of the field
 * @param hasDropDown  Should the field be a select dropdown or text input?
 * @param values       Possible select dropdown values
 * @param register     React Hook Form register func
 * @param control      React Hook Form control func
 * @param handleFocus  Function to fetch the tag values on focus of a select field
 * @param matterTypeID The currently selected matter type ID
 * @returns JSX.Element
 */
function AdvancedOptionalField({
  name,
  displayName,
  hasDropDown,
  values,
  register,
  control,
  handleFocus,
  matterTypeID,
}: IMatterTypeFilterTag &
  Pick<IAdvancedOptionalFiltersContents, "register" | "control" | "handleFocus" | "matterTypeID">): JSX.Element {
  const fieldOptions = values
    ? values?.map((value) => {
        return {
          label: value,
          value,
        };
      })
    : [];

  fieldOptions.unshift({ label: "All", value: "" });

  return hasDropDown === true ? (
    <Div className={styles.className}>
      <FormField
        label={displayName}
        name={name}
        register={register}
        control={control}
        options={fieldOptions}
        type={cFormFieldType.Select}
        onFocus={() => handleFocus({ matterTypeID, tagName: name })}
        key={name}
        fullWidth
      />
    </Div>
  ) : (
    <Div className={styles.className}>
      <FormField label={displayName} name={name} register={register} fullWidth />
    </Div>
  );
}

export default AdvancedOptionalField;
