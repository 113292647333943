import { map } from "lodash";
import { Control } from "react-hook-form";
import Div from "../../../../../components/Div/Div";
import { IMatterTypeFilterTag } from "../../../../../modules/matterTypesSlice";
import AdvancedOptionalField from "./AdvancedOptionalField/AdvancedOptionalField";

export interface IHandleFocus {
  matterTypeID: number;
  tagName: string;
}

export interface IAdvancedOptionalFiltersContents {
  register: Function;
  currentMatterTypeTags: Record<string, IMatterTypeFilterTag>;
  matterTypeID: number;
  control: Control<any, any>;
  handleFocus: ({ matterTypeID, tagName }: IHandleFocus) => void;
}

/**
 * Contents for matters advanced optional filters
 * @param register              React Hook Form register func
 * @param currentMatterTypeTags Matter type tags for the current matter type selection
 * @param matterTypeID          The currently selected matter type ID
 * @param control               React Hook Form control func
 * @param handleFocus           Function to fetch the tag values on focus of a select field
 * @returns JSX.Element
 */
function AdvancedOptionalFiltersContents({
  register,
  currentMatterTypeTags,
  matterTypeID,
  control,
  handleFocus,
}: IAdvancedOptionalFiltersContents): JSX.Element {
  return (
    <Div>
      {map(currentMatterTypeTags, (props, i) => (
        <AdvancedOptionalField
          {...props}
          register={register}
          control={control}
          handleFocus={handleFocus}
          matterTypeID={matterTypeID}
          key={i}
        />
      ))}
    </Div>
  );
}

export default AdvancedOptionalFiltersContents;
