import { filter, isNil, map } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { cMonoColorType, cStatusType } from "../../../../app/constants";
import { IXDirection } from "../../../../app/types";
import Button, { EButtonVariant } from "../../../../components/Button/Button";
import DisplayError from "../../../../components/DisplayError/DisplayError";
import Div from "../../../../components/Div/Div";
import drawerStyles from "../../../../components/Drawer/Drawer.module.scss";
import Container from "../../../../components/Grid/Container/Container";
import Icon, { EIcon } from "../../../../components/Icon/Icon";
import Spinner from "../../../../components/Spinner/Spinner";
import TableCard from "../../../../components/TableCard/TableCard";
import Typography from "../../../../components/Typography/Typography";
import { IErrorCode, selectErrorCodesStatus } from "../../../../modules/errorCodesSlice";
import sharedStyles from "../Admin.module.scss";
import SearchForm from "../Users/SearchForm/SearchForm";
import ErrorCodeDescriptionPopover from "./ErrorCodeDescriptionPopover/ErrorCodeDescriptionPopover";

/**
 * Key for getting missing entries
 */
export enum ErrorCodeKey {
  ErrorCode = "errorCode",
}

/**
 * Interface for error codes in the admin section
 */
interface IAdminErrorCodes {
  errorCodes: Record<number, IErrorCode>;
  openDrawer: () => void;
}

/**
 * Displays the list of error codes in the admin page
 * @param errorCodes  The list of error codes
 * @param openDrawer  Function to open the sidebar
 * @returns JSX.Element
 */

export function ErrorCodes({ errorCodes, openDrawer }: IAdminErrorCodes) {
  const errorCodesStatus = useSelector(selectErrorCodesStatus);
  const error = useSelector(selectErrorCodesStatus);
  const [filteredErrorCodes, setFilteredErrorCodes] = useState<Record<number, IErrorCode>>(errorCodes);

  // Set filter result on error code load
  useEffect(() => {
    setFilteredErrorCodes(errorCodes);
  }, [errorCodes]);

  // Scroll to the first table row when filtered
  useEffect(() => {
    const firstTableRow = document.querySelector("table tbody tr:first-child");
    if (firstTableRow) {
      firstTableRow.scrollIntoView({ behavior: "auto", block: "center" });
    }
  }, [filteredErrorCodes]);

  // Set loading and error status
  if (errorCodesStatus === cStatusType.Loading) {
    return (
      <Div p={{ base: 5 }} display={{ base: "flex" }} justifyContent={{ base: "center" }}>
        <Spinner />
      </Div>
    );
  }

  if (errorCodesStatus === cStatusType.Failed) {
    return (
      <Div p={{ base: 5 }} display={{ base: "flex" }} justifyContent={{ base: "center" }}>
        <DisplayError>{error}</DisplayError>
      </Div>
    );
  }

  const columns = [
    {
      heading: "Code",
      testId: "table-card-filter-date",
      width: "120px",
    },
    {
      heading: "Message",
      testId: "table-card-filter-type",
    },
    {
      heading: "Detail",
      testId: "table-card-filter-name",
      width: "80px",
    },
  ];

  /**
   * Filter the error codes list by search query
   * @param value The search value
   */
  function handleSearch(value?: string | null): void {
    if (errorCodes) {
      // If no value, reset to original state
      if (isNil(value)) {
        setFilteredErrorCodes(errorCodes);
        // Otherwise, filter by Code, Text, and Detail
      } else {
        const result = filter(errorCodes, ({ code, text, userDescription }: IErrorCode) => {
          if (code.toString().toLowerCase().includes(value.toLowerCase())) return true;
          if (text.toLowerCase().includes(value.toLowerCase())) return true;
          if (userDescription.toLowerCase().includes(value.toLowerCase())) return true;
          return false;
        });

        setFilteredErrorCodes(result);
      }
    }
  }

  const rows = map(filteredErrorCodes, ({ text, code, userDescription }) => {
    return [
      <>
        <Div className={sharedStyles.group}>
          <Div display={{ base: "block", md: "none" }}>
            <Typography variant="small" color={cMonoColorType.Light}>
              Code:&nbsp;
            </Typography>
          </Div>
          {code}
        </Div>
      </>,
      <>
        <Div className={sharedStyles.group}>
          <Div display={{ base: "block", md: "none" }}>
            <Typography variant="small" color={cMonoColorType.Light}>
              Text:&nbsp;
            </Typography>
          </Div>
          {text}
        </Div>
      </>,
      <>
        <Div className={sharedStyles.group}>
          <Div display={{ base: "block", md: "none" }}>
            <Typography variant="small" color={cMonoColorType.Light}>
              Detail:&nbsp;
            </Typography>
          </Div>
        </Div>
        <>
          <ErrorCodeDescriptionPopover description={userDescription} />
        </>
      </>,
    ];
  });

  return (
    <>
      <Div border={{ bb: true }} className={sharedStyles.actionBar}>
        <Div display={{ base: "flex" }} alignItems={{ base: "center" }}>
          <Button
            onClick={openDrawer}
            variant={EButtonVariant.Round}
            icon={EIcon.DrawerExpandRight}
            color={cMonoColorType.Dark}
            className={drawerStyles.openDrawerBtn}
            testId="open-drawer-button"
          />
          <Div className={sharedStyles.searchWrapper}>
            <SearchForm handleSearch={handleSearch} />
          </Div>
        </Div>
      </Div>
      <Container width={"xl"} mx={{ base: 0 }} px={{ base: 0 }}>
        <Div className={sharedStyles.tableContainer}>
          <TableCard columns={columns} rows={rows} testId="admin-error-codes-table" />

          {rows.length === 0 && (
            <Div pt={{ base: 8 }} className={sharedStyles.emptyStateWrapper}>
              <Icon icon={EIcon.Search} className={sharedStyles.emptyStateIcon} />
              <Typography align={IXDirection.Center} fontStyle="italic">
                Nothing found matching your search.
              </Typography>
            </Div>
          )}
        </Div>
      </Container>
    </>
  );
}
export default ErrorCodes;
