import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { postMatterTypes, selectMatterTypes } from "../../../../modules/matterTypesSlice";
import { postCreateMatter, selectWalkStatus } from "../../../../modules/walkSlice";
import NewMatterModal from "../NewMatterModal/NewMatterModal";

interface INewMatterModalContainer {
  isOpen: boolean;
  handleClose: () => void;
}

/**
 * Container for new matter modal
 * @param isOpen      Is the modal open?
 * @param handleClose Handle close of the modal
 * @returns JSX.Element
 */
function NewMatterModalContainer({ isOpen, handleClose }: INewMatterModalContainer): JSX.Element {
  const dispatch = useAppDispatch();
  const matterTypes = useAppSelector(selectMatterTypes);
  const walkStatus = useAppSelector(selectWalkStatus);

  // Fetch all matter types on open
  useEffect(() => {
    if (isOpen) {
      dispatch(postMatterTypes("all"));
    }
  }, [isOpen]);

  /**
   * Create a new matter walk
   * @param matterTypeID The ID of the matter type
   */
  function createMatter(matterTypeID: number): void {
    dispatch(postCreateMatter(matterTypeID));
  }

  return (
    <NewMatterModal
      isOpen={isOpen}
      handleClose={handleClose}
      matterTypes={matterTypes}
      createMatter={createMatter}
      walkStatus={walkStatus}
    />
  );
}

export default NewMatterModalContainer;
