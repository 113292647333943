import { cSizeType, cThemeColorType } from "../../app/constants";
import Div from "../Div/Div";
import Icon, { EIcon } from "../Icon/Icon";
import styles from "./SuccessIcon.module.scss";

interface ISuccessIcon {
  variant?: "regular" | "solid";
  size?: cSizeType;
}

/**
 * Success Icon
 * @param variant The icon variant
 * @param size    The icon size
 * @returns JSX.Element
 */
function SuccessIcon({ variant = "regular", size = cSizeType.Large }: ISuccessIcon): JSX.Element {
  return (
    <>
      {variant === "regular" ? (
        <Div className={styles.regular}>
          <Icon icon={EIcon.Success} color={cThemeColorType.Primary} size={size} />
        </Div>
      ) : (
        <Div className={styles.solid}>
          <Icon icon={EIcon.Success} size={size} />
        </Div>
      )}
    </>
  );
}

export default SuccessIcon;
