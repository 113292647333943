import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../app/api";
import { matterTimelineUrl } from "../app/apiUrls";
import { cStatusType } from "../app/constants";
import { RootState } from "../app/store";
import { convertTimelineData, TTimeline } from "./documentsSlice";

/**
 * The internals of an entry
 */
interface IMattersTimelineEntry {
  timeline: TTimeline[];
}

/**
 * Describe the matters timeline state object
 */
interface IMattersTimelineState {
  entries: Record<number, IMattersTimelineEntry>; // Object keyed by parent matter
  status: cStatusType; // API call status
  error?: string;
}

/**
 * Initial state
 */
const initialState: IMattersTimelineState = {
  entries: {},
  status: cStatusType.Idle,
};

/**
 * Thunk for fetching the timeline for a single matter by ID
 */
export const postMatterTimeline = createAsyncThunk(
  "matters/postMatterTimeline",
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await api({ endpoint: matterTimelineUrl, dispatch, body: { id } });
      return { id, timeline: convertTimelineData(response) };
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Create the state slice
 */
export const mattersTimelineSlice = createSlice({
  name: "mattersTimeline", // The name of the slice
  initialState, // Set the initialState
  reducers: {
    resetMattersTimeline: (state) => {
      state.entries = {};
      state.error = undefined;
      state.status = cStatusType.Idle;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postMatterTimeline.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postMatterTimeline.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postMatterTimeline.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.entries = { ...state.entries, [action.payload.id]: action.payload.timeline };
      });
  },
});

export const { resetMattersTimeline } = mattersTimelineSlice.actions;

export const selectMattersTimelineStatus = (state: RootState) => state.mattersTimeline.status;
export const selectMattersTimelineError = (state: RootState) => state.mattersTimeline.error;
export const selectMattersTimeline = (state: RootState) => state.mattersTimeline.entries;

export default mattersTimelineSlice.reducer;
