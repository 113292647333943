import { SubmitHandler, useForm } from "react-hook-form";
import Div from "../../../../../../components/Div/Div";
import FormField, { cFormFieldType } from "../../../../../../components/FormField/FormField";
import styles from "./CollaborationVersionNotes.module.scss";

type Inputs = {
  versionNotes: string;
};

interface ICollaborationVersionNotes {
  handleVersionNotesSubmit: (versionNotes: string) => void;
}

export const collaborationVersionNotesFormId = "collaboration-version-notes-form";

/**
 * Collaboration version notes form
 * @param handleVersionNotesSubmit Function to handle version notes submit
 * @returns JSX.Element
 */
function CollaborationVersionNotes({ handleVersionNotesSubmit }: ICollaborationVersionNotes): JSX.Element {
  const {
    register, // Register prop for form inputs
    handleSubmit, // Submit handler wrapper
    formState: { errors }, // Errors that may occur
  } = useForm<Inputs>({ mode: "all" });

  /**
   * Submit the form
   * @param data The form values on submit
   */
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const { versionNotes } = data;
    handleVersionNotesSubmit(versionNotes);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={collaborationVersionNotesFormId}>
      <Div p={{ base: 6 }}>
        <FormField
          register={register}
          name="versionNotes"
          label="Add notes for this new version"
          type={cFormFieldType.Textarea}
          error={errors.versionNotes}
          inputClassName={styles.versionNotes}
          fullWidth
          required
          autoFocus
        />
      </Div>
    </form>
  );
}

export default CollaborationVersionNotes;
