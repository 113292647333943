import { format } from "date-fns";
import { Fragment } from "react";
import { cMonoColorType } from "../../../../../app/constants";
import { TFilterType } from "../../../../../app/types";
import Div from "../../../../../components/Div/Div";
import TableCard from "../../../../../components/TableCard/TableCard";
import Typography from "../../../../../components/Typography/Typography";
import { EESignHistoryActionType, IESignHistoryEntry } from "../../../../../modules/esignSlice";
import styles from "../ESignWorkflow.shared.module.scss";

interface IESignHistory {
  history?: IESignHistoryEntry[][];
}

/**
 * The esign history
 * @param The esign history
 * @returns JSX.Element
 */
function ESignHistory({ history }: IESignHistory) {
  const columns = [
    {
      heading: "Date" as const,
      filterType: TFilterType.Date as const,
      width: "200px",
    },
    {
      heading: "Action",
      filterType: TFilterType.Input as const,
      width: "200px",
    },
    {
      heading: "User" as const,
      filterType: TFilterType.Input as const,
      width: "260px",
    },
    {
      heading: "Note" as const,
      filterType: TFilterType.Input as const,
    },
  ];

  const groups = history?.map((group, index) => {
    return {
      id: index,
      cancelledIndex: group.findIndex((row) => row.type === EESignHistoryActionType.Cancelled),
      rows: group.map(({ date, description, firstName, lastName, type }) => {
        return [
          <>
            <Div className={styles.historyGroup}>
              <Div display={{ base: "block", md: "none" }}>
                <Typography variant="small" color={cMonoColorType.Light}>
                  Date:&nbsp;
                </Typography>
              </Div>
              {format(new Date(date), "dd MMM yyyy")}
            </Div>
          </>,
          <>
            <Div className={styles.historyGroup}>
              <Div display={{ base: "block", md: "none" }}>
                <Typography variant="small" color={cMonoColorType.Light}>
                  Action:&nbsp;
                </Typography>
              </Div>
              {type}
            </Div>
          </>,
          <>
            <Div className={styles.historyGroup}>
              <Div display={{ base: "block", md: "none" }}>
                <Typography variant="small" color={cMonoColorType.Light}>
                  User:&nbsp;
                </Typography>
              </Div>
              {firstName} {lastName}
            </Div>
          </>,
          <>
            <Div className={styles.historyGroup}>
              <Div display={{ base: "block", md: "none" }}>
                <Typography variant="small" color={cMonoColorType.Light}>
                  Note:&nbsp;
                </Typography>
              </Div>
              {description}
            </Div>
          </>,
        ];
      }),
    };
  });

  return (
    <>
      {groups?.map((group) => (
        <Fragment key={group.id}>
          {group.rows.length > 0 && (
            <Div pb={{ base: 6 }} key={group.rows[0][0].key + group.id.toString()}>
              <TableCard
                columns={columns}
                rows={group.rows}
                rowIndexAndClassName={{ index: group.cancelledIndex, className: styles.cancelled }}
                variant="timeline"
                testId="history-table"
              />
            </Div>
          )}
        </Fragment>
      ))}
    </>
  );
}

export default ESignHistory;
