import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../app/api";
import { categoriesUrl } from "../app/apiUrls";
import { cStatusType } from "../app/constants";
import { RootState } from "../app/store";
import { IID } from "../app/types";
import createIdList from "../utils/createIdList/createIdList";

export interface ICategory {
  name: string;
  documentTypes?: IID[];
  id: number;
}

/**
 * Describe the document type categories state object
 */
interface ICategoriesState {
  entries: Record<number, ICategory>; // Document types records
  status: cStatusType; // API call status
  fetched: boolean; // Have entries been fetched?
  error?: string;
}

/**
 * Initial state
 */
const initialState: ICategoriesState = {
  entries: {},
  status: cStatusType.Idle,
  fetched: false,
};

/**
 * Thunk for fetching document type categories
 */
export const getCategories = createAsyncThunk("categories/getCategories", async (_, { dispatch, rejectWithValue }) => {
  try {
    const endpoint = categoriesUrl;
    const response = await api({ endpoint, dispatch, body: { id: "all" } });

    return createIdList(response.data.categories);
  } catch (err: any) {
    throw rejectWithValue(err.message);
  }
});

/**
 * Categories reducer
 */
export const categoriesSlice = createSlice({
  name: "categories", // The name of the slice
  initialState, // Set the initialState
  reducers: {
    // Update the modal is open state
    update: (state, action: PayloadAction<ICategory>) => {
      state.entries[action.payload.id] = { ...state.entries[action.payload.id], ...action.payload };
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // Set status to loading when the promise is pending
      .addCase(getCategories.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      // Set status to failed if the promise is rejected
      .addCase(getCategories.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Set status back to idle once the promise has been fulfilled
      .addCase(getCategories.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.entries = action.payload;
        state.fetched = true; // Set fetched to true
      });
  },
});

export const { update } = categoriesSlice.actions;

// Select the status
export const selectCategoriesStatus = (state: RootState) => state.categories.status;
// Select all categories
export const selectCategories = (state: RootState) => state.categories.entries;
// Select fetched
export const selectCategoriesFetched = (state: RootState) => state.categories.fetched;
// Select error
export const selectCategoriesError = (state: RootState) => state.categories.error;

export default categoriesSlice.reducer;
