import classNames from "classnames";
import { Link } from "react-router-dom";
import styles from "./Breadcrumb.module.scss";

export interface IBreadcrumbProps {
  links: {
    id: string | number;
    label?: string;
    url: string;
  }[];
}

/**
 * A Breadcrumb component that indicates the current page’s location within a navigational hierarchy
 * @param links An array of links to display
 * @returns JSX.Element
 */

function Breadcrumb({ links }: IBreadcrumbProps) {
  return (
    <nav aria-label="breadcrumb" data-testid="breadcrumb-nav">
      <ol className={styles.breadcrumb}>
        {links.map(({ id, label, url }, index) => (
          <li key={`${id}-${index}`} className={classNames({ [styles.active]: index === links.length - 1 })}>
            {index === links.length - 1 ? (
              <span data-testid="breadcrumb-active">{label}</span>
            ) : (
              <Link role="link" to={url} data-testid="breadcrumb-link">
                {label}
              </Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}

export default Breadcrumb;
