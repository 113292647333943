import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import classNames from "classnames";
import { cAccessType, cSizeType } from "../../../../../../app/constants";
import Div from "../../../../../../components/Div/Div";
import Icon, { EIcon } from "../../../../../../components/Icon/Icon";
import SuccessIcon from "../../../../../../components/SuccessIcon/SuccessIcon";
import Typography from "../../../../../../components/Typography/Typography";
import { EESignActionType, EESignState, IESignGroup, IESignRecipient } from "../../../../../../modules/esignSlice";
import { EActivityDeleteType } from "../../ConfirmDeleteRecipientModal/ConfirmDeleteActivityModal";
import styles from "../../ESignWorkflow.shared.module.scss";

interface IESignWorkflowRecipient {
  recipient: IESignRecipient;
  handleDeleteActivity: (id: number, activityType: EActivityDeleteType) => void;
  handleDeleteGroupActivity: (group: IESignGroup, recipientID: number) => void;
  isSortable?: boolean;
  arrayIndex?: number;
  group?: IESignGroup;
  state: EESignState;
}

/**
 * An esign recipient
 * @param recipient                 The recipient to display
 * @param handleDeleteActivity      Function to delete an activity
 * @param handleDeleteGroupActivity Function to delete a group activity
 * @param isSortable                Is the recipient sortable?
 * @param arrayIndex                The index in the array
 * @param group                     The esign group
 * @param state                     The esign state
 * @returns JSX.Element
 */
function ESignWorkflowRecipient({
  recipient,
  handleDeleteActivity,
  handleDeleteGroupActivity,
  isSortable = true,
  arrayIndex,
  group,
  state,
}: IESignWorkflowRecipient) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: recipient.id });
  const sortableAttributes = isSortable ? { ...attributes, ...listeners } : null;

  // Use default dnd-kit styles
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  /**
   * Handles delete click
   * @param event The mouse event
   */
  function handleDeleteClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.stopPropagation();
    if (group) {
      handleDeleteGroupActivity(group, recipient.id);
    } else {
      handleDeleteActivity(recipient.id, EActivityDeleteType.Recipient);
    }
  }

  /**
   * Renders the action type
   * @returns JSX.Element
   */
  function renderActionType() {
    if (recipient.type === EESignActionType.Sign) {
      // sign
      if (recipient.complete) {
        return (
          <Div display={{ base: "flex" }} alignItems={{ base: "center" }}>
            <Div spacing={{ mr: 4 }}>
              <SuccessIcon variant="solid" size={cSizeType.Small} />
            </Div>
            <Div className={styles.complete}>Signed</Div>
          </Div>
        );
      } else {
        return <Div className={styles.recipientType}>Signer</Div>;
      }
    } else if (recipient.type === EESignActionType.Approve) {
      // approve
      if (recipient.complete) {
        return (
          <Div display={{ base: "flex" }} alignItems={{ base: "center" }}>
            <Div spacing={{ mr: 4 }}>
              <SuccessIcon variant="solid" size={cSizeType.Small} />
            </Div>
            <Div className={styles.complete}>Approved</Div>
          </Div>
        );
      } else {
        return <Div className={styles.recipientType}>Approver</Div>;
      }
    } else {
      // view
      return <Div className={styles.recipientType}>Viewer</Div>;
    }
  }

  return (
    <div
      ref={isSortable ? setNodeRef : null}
      style={isSortable ? style : {}}
      {...sortableAttributes}
      className={classNames(
        styles.workflowRecipient,
        styles.recipient,
        group && styles.group,
        isSortable && styles.sortable,
        isDragging && styles.active,
      )}
      data-testid="workflow-recipient"
    >
      <Div className={styles.numberWrapper}>
        {recipient.type && typeof arrayIndex === "number" && (
          <>
            <Icon icon={EIcon.Drag} />
            <Div className={styles.indexNumber}>{arrayIndex + 1}</Div>
          </>
        )}
      </Div>
      <Div className={styles.contentWrapper}>
        {renderActionType()}
        <Typography weight="medium">
          {recipient.person.firstName} {recipient.person.lastName}
        </Typography>
        <Div>{recipient.person.emailAddress}</Div>
        <Div>{recipient.person.phoneNumber}</Div>
        <Div>{recipient.allowProxy ? <Div>Proxy allowed</Div> : <Div></Div>}</Div>
      </Div>
      {recipient.action?.delete?.state === cAccessType.Enabled && state === EESignState.Configuration && (
        <>
          <Div
            role="button"
            className={styles.deleteRecipientButton}
            onClick={(event) => handleDeleteClick(event)}
            testId="delete-recipient-button"
          >
            <Icon icon={EIcon.Close} />
          </Div>
          <Div className={styles.emptyDiv} /> {/* Used to ensure close button is aligned with group close button */}
        </>
      )}
    </div>
  );
}

export default ESignWorkflowRecipient;
