import { clone, filter, isEqual, isNil, map } from "lodash";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { cMonoColorType, cStatusType } from "../../../../app/constants";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { IXDirection } from "../../../../app/types";
import Button, { EButtonVariant } from "../../../../components/Button/Button";
import DiscardChangesModal from "../../../../components/DiscardChangesModal/DiscardChangesModal";
import DisplayError from "../../../../components/DisplayError/DisplayError";
import Div from "../../../../components/Div/Div";
import drawerStyles from "../../../../components/Drawer/Drawer.module.scss";
import { IFieldOption } from "../../../../components/FormField/FormField";
import Icon, { EIcon } from "../../../../components/Icon/Icon";
import Spinner from "../../../../components/Spinner/Spinner";
import SuccessModal from "../../../../components/SuccessModal/SuccessModal";
import TableCard from "../../../../components/TableCard/TableCard";
import tableStyles from "../../../../components/TableCard/TableCard.module.scss";
import Typography from "../../../../components/Typography/Typography";
import { selectStreamsEnabled } from "../../../../modules/customerSlice";
import {
  closeAddUserModal,
  closeEditUserModal,
  EUserStatus,
  IUser,
  postAddUser,
  postEditUser,
  resetAddUserError,
  resetEditUser,
  selectShowAddUserModal,
  selectShowEditUserModal,
  selectUsersStatus,
  updateResetUserSearch,
} from "../../../../modules/usersSlice";
import { areSomeDefined } from "../../../../utils/areSomeDefined/areSomeDefined";
import sharedStyles from "../Admin.module.scss";
import AddUserModal from "./AddUserModal/AddUserModal";
import SearchForm from "./SearchForm/SearchForm";
import styles from "./Users.module.scss";
import UsersActionPopover from "./UsersActionPopover/UsersActionPopover";

export type Inputs = {
  id: number | undefined;
  firstName: string;
  lastName: string;
  preferredName: string;
  emailAddress: string;
  confirmEmailAddress: string;
  phoneNumber: string | null;
  isAdmin: boolean;
  notifyGroup: IFieldOption | null;
  securityGroups: IFieldOption[] | null;
  isTeamLead?: boolean;
};

interface IAdminUsers {
  users: Record<number, IUser>;
  loggedInUser: IUser;
  notifyGroupOptions: IFieldOption[];
  securityGroupOptions: IFieldOption[];
  allowSecurityGroups: boolean;
  editUserInit: (userID: number) => void;
  editUser?: IUser;
  securityGroupIDs?: number[];
  openDrawer: () => void;
  disableUserInit: (userID: number) => void;
  enableUserInit: (userID: number) => void;
  deleteUserInit: (userID: number) => void;
  resetPasswordInit: (userID: number) => void;
  reassignAllTasksInit: (userID: number) => void;
  error?: string;
}

/**
 * Displays the list of users in the admin page
 * @param users                The list of users
 * @param loggedInUser         The logged in user
 * @param notifyGroupOptions   The notify group options
 * @param securityGroupOptions The security group options
 * @param allowSecurityGroups  Are security groups allowed?
 * @param editUserInit         Function to start the edit user process
 * @param editUser             The edit user info
 * @param securityGroupIDs     The securityGroupIDs for the edit user
 * @param openDrawer           Open the drawer
 * @param disableUserInit      Function to start the disable user process
 * @param enableUserInit       Function to start the enable a user process
 * @param deleteUserInit       Function to start the delete a user process
 * @param resetPasswordInit    Function to start the reset password process
 * @param reassignAllTasksInit Function to start the reassign all tasks process
 * @param error                The error message
 * @returns JSX.Element
 */
export function Users({
  users,
  loggedInUser,
  notifyGroupOptions,
  securityGroupOptions,
  allowSecurityGroups,
  editUserInit,
  editUser,
  securityGroupIDs,
  openDrawer,
  disableUserInit,
  enableUserInit,
  deleteUserInit,
  resetPasswordInit,
  reassignAllTasksInit,
  error,
}: IAdminUsers) {
  const userStatus = useAppSelector(selectUsersStatus);
  const streamsEnabled = useAppSelector(selectStreamsEnabled);

  const [filteredUsers, setFilteredUsers] = useState<Record<number, IUser>>(users);

  // Set filter result on user load
  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  // Scroll to the first table row when filtered
  useEffect(() => {
    const firstTableRow = document.querySelector("table tbody tr:first-child");
    if (firstTableRow) {
      firstTableRow.scrollIntoView({ behavior: "auto", block: "center" });
    }
  }, [filteredUsers]);

  const columns = [
    {
      heading: "User" as const,
      testId: "admin-users-user",
    },
    {
      heading: "Login",
      testId: "admin-users-login",
    },
    {
      heading: "Email" as const,
      testId: "admin-users-email",
    },
    {
      heading: null,
      width: "80px",
      testId: "admin-users-action",
      className: tableStyles.actionsMenuColumn,
    },
  ];

  const rows = map(filteredUsers, ({ id, firstName, lastName, loginName, emailAddress, status }) => {
    return [
      <>
        <Div className={sharedStyles.group}>
          <Div display={{ base: "block", md: "none" }}>
            <Typography variant="small" color={cMonoColorType.Light}>
              User:&nbsp;
            </Typography>
          </Div>
          {status === EUserStatus.Locked && <Icon icon={EIcon.UserDisable} className={styles.userDisabled} />}
          {`${lastName}, ${firstName}`}
        </Div>
      </>,
      <>
        <Div className={sharedStyles.group}>
          <Div display={{ base: "block", md: "none" }}>
            <Typography variant="small" color={cMonoColorType.Light}>
              Login:&nbsp;
            </Typography>
          </Div>
          {loginName}
        </Div>
      </>,
      <>
        <Div className={sharedStyles.group}>
          <Div display={{ base: "block", md: "none" }}>
            <Typography variant="small" color={cMonoColorType.Light}>
              Email:&nbsp;
            </Typography>
          </Div>
          {emailAddress}
        </Div>
      </>,
      <>
        <UsersActionPopover
          key={loginName}
          userID={id}
          loggedInUser={loggedInUser}
          editUserInit={editUserInit}
          disableUserInit={disableUserInit}
          status={status as EUserStatus}
          enableUserInit={enableUserInit}
          resetPasswordInit={resetPasswordInit}
          deleteUserInit={deleteUserInit}
          reassignAllTasksInit={reassignAllTasksInit}
          streamsEnabled={streamsEnabled}
        />
      </>,
    ];
  });

  const isEdit = !!editUser;

  const defaultValues = {
    id: undefined,
    firstName: "",
    lastName: "",
    preferredName: "",
    emailAddress: "",
    confirmEmailAddress: "",
    phoneNumber: null,
    isAdmin: false,
    notifyGroup: null,
    securityGroups: null,
  };

  const {
    register, // Register prop for form inputs
    handleSubmit, // Submit handler wrapper
    formState: { errors }, // Errors that may occur
    watch,
    reset,
    control,
    setValue,
    getValues,
  } = useForm<Inputs>({
    defaultValues: defaultValues,
  }); // AddUserModal form

  useEffect(() => {
    return () => reset(); // Reset AddUserModal form on unmount
  }, []);

  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isDiscardChangesModalOpen, setIsDiscardChangesModalOpen] = useState(false);
  const [formValues, setFormValues] = useState<Inputs | undefined>(defaultValues);
  const originalFormValues = clone(formValues);
  const showEditUserModal = useAppSelector(selectShowEditUserModal);
  const showAddUserModal = useAppSelector(selectShowAddUserModal);
  const dispatch = useAppDispatch();
  const formWatch = watch as any; // Get error with watch - Type instantiation is excessively deep and possibly infinite.
  const formSetValue = setValue as any; // Get error with setValue - Type instantiation is excessively deep and possibly infinite.
  const notifyGroup = formWatch("notifyGroup");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  useEffect(() => {
    // Reset notifyGroup to null if the user unselects it
    if (notifyGroup?.value === "") {
      formSetValue("notifyGroup", null);
    }
  }, [notifyGroup]);

  /**
   * Populates the form with the edit user info
   * @param editUser The edit user info
   */
  function populateEditUserForm(editUser: IUser) {
    formSetValue("id", editUser.id);
    formSetValue("firstName", editUser.firstName, { shouldValidate: true });
    formSetValue("lastName", editUser.lastName, { shouldValidate: true });
    formSetValue("preferredName", editUser.preferredName, { shouldValidate: true });
    formSetValue("emailAddress", editUser.emailAddress, { shouldValidate: true });
    formSetValue("confirmEmailAddress", editUser.emailAddress, { shouldValidate: true });
    formSetValue("phoneNumber", editUser.phoneNumber);
    formSetValue("isAdmin", editUser.isAdmin);
    formSetValue("isTeamLead", editUser.isTeamLead);
    if (editUser.notifyGroupID) {
      formSetValue(
        "notifyGroup",
        notifyGroupOptions.find((group) => group.value === editUser.notifyGroupID),
        { shouldValidate: true },
      );
    }
    if (securityGroupIDs) {
      const securityGroups = securityGroupOptions.reduce((acc: IFieldOption[], currentValue: IFieldOption) => {
        if (securityGroupIDs.includes(currentValue.value as number)) {
          acc = [...acc, currentValue];
        }
        return acc;
      }, []);
      formSetValue("securityGroups", securityGroups);
    }
  }

  useEffect(() => {
    if (editUser) {
      populateEditUserForm(editUser);
      setIsAddUserModalOpen(true);
      setFormValues(getValues());
    }
  }, [editUser, securityGroupIDs]);

  /**
   * Submit the AddUserModal form
   * @param data The form values on submit
   */
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const { firstName, lastName, notifyGroup, securityGroups } = data;
      const notifyGroupID = notifyGroup?.value as number;
      const securityGroupIDs = securityGroups?.map((group) => group.value as number);
      setFirstName(firstName);
      setLastName(lastName);
      if (isEdit) {
        await dispatch(
          postEditUser({
            ...data,
            notifyGroupID: notifyGroupID,
            securityGroupIDs: securityGroupIDs && securityGroupIDs.length > 0 ? securityGroupIDs : undefined,
          }),
        ).unwrap();
      } else {
        await dispatch(
          postAddUser({
            ...data,
            notifyGroupID: notifyGroupID,
            securityGroupIDs: securityGroupIDs && securityGroupIDs.length > 0 ? securityGroupIDs : undefined,
          }),
        ).unwrap();
      }
      reset();
      setIsAddUserModalOpen(false);
      dispatch(updateResetUserSearch(true));
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Returns true if the form values have not changed from the original values
   * @returns boolean
   */
  function isFormReset() {
    const {
      firstName,
      lastName,
      preferredName,
      emailAddress,
      confirmEmailAddress,
      phoneNumber,
      notifyGroup,
      securityGroups,
      isAdmin,
      isTeamLead,
    } = watch();
    return (
      firstName === originalFormValues?.firstName &&
      lastName === originalFormValues?.lastName &&
      preferredName === originalFormValues?.preferredName &&
      emailAddress === originalFormValues?.emailAddress &&
      confirmEmailAddress === originalFormValues?.confirmEmailAddress &&
      phoneNumber === originalFormValues?.phoneNumber &&
      isEqual(notifyGroup, originalFormValues?.notifyGroup) &&
      isEqual(securityGroups, originalFormValues?.securityGroups) &&
      isAdmin === originalFormValues?.isAdmin &&
      isTeamLead === originalFormValues?.isTeamLead
    );
  }

  /**
   * Handles AddUserModal open
   */
  function handleAddUserModalOpen() {
    setIsAddUserModalOpen(true);
  }

  /**
   * Returns true if adding a user and at the form has been updated from the original state
   * @returns boolean
   */
  function isAddUserAndSomeValuesAreDefined() {
    return !isEdit && areSomeDefined(watch());
  }

  /**
   * Returns true if editing a user and at the form has been updated from the original state
   * @returns boolean
   */
  function isEditUserAndSomeValuesHaveChanged() {
    return isEdit && !isFormReset();
  }

  /**
   * Handles AddUserModal close
   */
  function handleAddUserModalClose() {
    if (isAddUserAndSomeValuesAreDefined() || isEditUserAndSomeValuesHaveChanged()) {
      setIsDiscardChangesModalOpen(true);
    } else {
      setIsAddUserModalOpen(false);
    }
    dispatch(resetAddUserError());
  }

  /**
   * Handles DiscardChangesModal close
   */
  function handleDiscardChangesModalClose(canDiscard: boolean) {
    if (canDiscard) {
      reset();
      setIsAddUserModalOpen(false);
    }
    setIsDiscardChangesModalOpen(false);
  }

  /**
   * Dispatch edit user reset to reducer
   */
  function dispatchResetEditUser() {
    dispatch(resetEditUser());
  }

  /**
   * Closes the add user modal
   */
  function handleShowAddUserModalClose() {
    dispatch(closeAddUserModal());
    setFirstName("");
    setLastName("");
  }

  /**
   * Closes the edit user modal
   */
  function handleShowEditUserModalClose() {
    dispatch(closeEditUserModal());
  }

  /**
   * Filter by search query
   * @param value The search value
   */
  function handleSearch(value?: string | null): void {
    if (users) {
      // If no value, reset to original state
      if (isNil(value)) {
        setFilteredUsers(users);
        // Otherwise, filter by name and surname, email address, and login name
      } else {
        const result = filter(users, ({ firstName, lastName, emailAddress, loginName }: IUser) => {
          const fullName = `${firstName} ${lastName}`;
          if (fullName.toLowerCase().includes(value.toLowerCase())) return true;
          if (emailAddress?.toLowerCase().includes(value.toLowerCase())) return true;
          if (loginName?.toLowerCase().includes(value.toLowerCase())) return true;

          return false;
        });

        setFilteredUsers(result);
      }
    }
  }

  return (
    <>
      <Div border={{ bb: true }} className={sharedStyles.actionBar}>
        <Div display={{ base: "flex" }} alignItems={{ base: "center" }}>
          <Button
            onClick={openDrawer}
            variant={EButtonVariant.Round}
            icon={EIcon.DrawerExpandRight}
            color={cMonoColorType.Dark}
            className={drawerStyles.openDrawerBtn}
          />
          <Button
            icon={EIcon.AddUser}
            testId="add-user-button"
            onClick={handleAddUserModalOpen}
            className={styles.addUserBtn}
          >
            Add user
          </Button>
          <Div className={sharedStyles.searchWrapper} pl={{ base: 7 }}>
            <SearchForm handleSearch={handleSearch} />
          </Div>
        </Div>
      </Div>
      {error && (
        <Div pl={{ base: 8 }}>
          <DisplayError>{error}</DisplayError>
        </Div>
      )}
      <Div className={sharedStyles.tableContainer}>
        {rows && userStatus === cStatusType.Loading && (
          <Div p={{ base: 5 }} display={{ base: "flex" }} justifyContent={{ base: "center" }}>
            <Spinner />
          </Div>
        )}
        {rows && rows.length > 0 && <TableCard columns={columns} rows={rows} testId="admin-users-table" />}
        {rows && userStatus === cStatusType.Idle && rows.length === 0 && (
          <Div pt={{ base: 8 }} className={sharedStyles.emptyStateWrapper}>
            <Icon icon={EIcon.Search} className={sharedStyles.emptyStateIcon} />
            <Typography align={IXDirection.Center} fontStyle="italic">
              Nothing found matching your search.
            </Typography>
          </Div>
        )}
      </Div>
      <AddUserModal
        isOpen={isAddUserModalOpen}
        handleClose={handleAddUserModalClose}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        register={register}
        errors={errors}
        watch={watch}
        control={control}
        notifyGroupOptions={notifyGroupOptions}
        securityGroupOptions={securityGroupOptions}
        allowSecurityGroups={allowSecurityGroups}
        isFormReset={isFormReset}
        dispatchResetEditUser={dispatchResetEditUser}
        isEdit={isEdit}
        reset={reset}
      />
      <DiscardChangesModal
        isOpen={isDiscardChangesModalOpen}
        handleClose={(canDiscard) => handleDiscardChangesModalClose(canDiscard)}
      />
      <SuccessModal
        isOpen={showAddUserModal}
        handleClose={handleShowAddUserModalClose}
        header="Mail Sent"
        message={`A welcome email for login instructions has been sent to ${firstName} ${lastName}`}
      />
      <SuccessModal
        isOpen={showEditUserModal}
        handleClose={handleShowEditUserModalClose}
        header="Mail Sent"
        message={
          <>
            A notification email has been sent to{" "}
            <b>
              {firstName} {lastName}
            </b>{" "}
            confirming their login name change
          </>
        }
      />
    </>
  );
}

export default Users;
