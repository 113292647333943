import classNames from "classnames";
import {
  useMargin,
  useMarginB,
  useMarginL,
  useMarginR,
  useMarginT,
  useMarginX,
  useMarginY,
  usePadding,
  usePaddingB,
  usePaddingL,
  usePaddingR,
  usePaddingT,
  usePaddingX,
  usePaddingY,
  useSpacing,
} from "../../app/hooks";
import {
  IMargin,
  IMarginB,
  IMarginL,
  IMarginR,
  IMarginT,
  IMarginX,
  IMarginY,
  IPadding,
  IPaddingB,
  IPaddingL,
  IPaddingR,
  IPaddingT,
  IPaddingX,
  IPaddingY,
} from "../../app/styleTypes";
import { ISpacing } from "../../app/types";
import styles from "./Box.module.scss";

interface IBoxProps {
  children: React.ReactNode;
  className?: string;
  spacing?: ISpacing;
  p?: IPadding;
  py?: IPaddingY;
  px?: IPaddingX;
  pt?: IPaddingT;
  pr?: IPaddingR;
  pb?: IPaddingB;
  pl?: IPaddingL;
  m?: IMargin;
  my?: IMarginY;
  mx?: IMarginX;
  mt?: IMarginT;
  mr?: IMarginR;
  mb?: IMarginB;
  ml?: IMarginL;
}

/**
 * A white box with rounded corners
 * @param children  The box contents
 * @param className Optional className(s) to add to the box
 * @param spacing   useSpace values
 * @param p         Set Padding object
 * @param py        Set Padding-y object
 * @param px        Set Padding-x object
 * @param pt        Set Padding-top object
 * @param pr        Set Padding-right object
 * @param pb        Set Padding-bottom object
 * @param pl        Set Padding-left object
 * @param m         Set Margin object
 * @param my        Set Margin-y object
 * @param mx        Set Margin-x object
 * @param mt        Set Margin-top object
 * @param mr        Set Margin-right object
 * @param mb        Set Margin-bottom object
 * @param ml        Set Margin-left object
 * @returns JSX.Element
 */
function Box({
  children,
  className,
  spacing,
  p,
  px,
  py,
  pt,
  pr,
  pb,
  pl,
  m,
  mx,
  my,
  mt,
  mr,
  mb,
  ml,
}: IBoxProps): JSX.Element {
  const spacingClx = useSpacing(spacing); // Get the spacing classes
  const pClx = usePadding(p); // Get padding classes
  const pyClx = usePaddingY(py); // Get padding-y classes
  const pxClx = usePaddingX(px); // Get padding-x classes
  const ptClx = usePaddingT(pt); // Get padding-top classes
  const prClx = usePaddingR(pr); // Get padding-right classes
  const pbClx = usePaddingB(pb); // Get padding-bottom classes
  const plClx = usePaddingL(pl); // Get padding-left classes
  const mClx = useMargin(m); // Get margin classes
  const myClx = useMarginY(my); // Get margin-y classes
  const mxClx = useMarginX(mx); // Get margin-x classes
  const mtClx = useMarginT(mt); // Get margin-top classes
  const mrClx = useMarginR(mr); // Get margin-right classes
  const mbClx = useMarginB(mb); // Get margin-bottom classes
  const mlClx = useMarginL(ml); // Get margin-left classes
  const clx = classNames(
    // Combine classes
    styles.className,
    className, // Custom
    spacingClx, // Spacing
    pClx, // Padding
    pyClx, // Padding
    pxClx, // Padding
    ptClx, // Padding
    prClx, // Padding
    pbClx, // Padding
    plClx, // Padding
    mClx, // Margin
    myClx, // Margin
    mxClx, // Margin
    mtClx, // Margin
    mrClx, // Margin
    mbClx, // Margin
    mlClx, // Margin
  );

  return <div className={clx}>{children}</div>;
}

export default Box;
