import { FloatingArrow, Middleware, offset, useFloating, useHover, useInteractions } from "@floating-ui/react";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { Ref } from "react";
import { EPopoverPlacement } from "../../../app/types";
import Button, { IButton } from "../../Button/Button";
import Div from "../../Div/Div";
import Icon from "../../Icon/Icon";
import { IDivProps } from "../Popover";
import popoverStyles from "../Popover.module.scss";

interface ITooltip {
  buttonContents?: JSX.Element | string;
  buttonProps?: IButton;
  buttonClx: string;
  middleware: Array<Middleware | null | undefined | false>;
  popoverClx: string;
  divProps?: IDivProps;
  showPopover: boolean;
  setShowPopover: React.Dispatch<React.SetStateAction<boolean>>;
  width?: string;
  popoverContents: React.ReactNode | string;
  arrowRef: Ref<SVGSVGElement>;
  popoverPlacement?: EPopoverPlacement;
}

/**
 * Renders a tooltip component.
 *
 * @param buttonContents   The contents of the tooltip button.
 * @param buttonProps      The props for the tooltip button.
 * @param buttonClx        The class name for the tooltip button.
 * @param middleware       The middleware for the tooltip.
 * @param popoverClx       The class name for the popover.
 * @param divProps         The props for the tooltip div.
 * @param showPopover      Indicates whether the popover is shown or hidden.
 * @param setShowPopover   Callback function to set the visibility of the popover.
 * @param width            The width of the popover.
 * @param popoverContents  The contents of the popover.
 * @param arrowRef         The ref for the floating arrow.
 * @param popoverPlacement The placement of the popover.
 * @returns The rendered tooltip component.
 */
function Tooltip({
  buttonContents,
  buttonProps = {} as IButton,
  buttonClx,
  middleware,
  popoverClx,
  divProps,
  showPopover,
  setShowPopover,
  width,
  popoverContents,
  arrowRef,
  popoverPlacement,
}: ITooltip): JSX.Element {
  middleware.push(offset(1));

  // Get the floating UI context
  const { refs, floatingStyles, context } = useFloating({
    open: showPopover,
    onOpenChange: setShowPopover,
    placement: popoverPlacement,
    middleware,
  });

  const interactions = [useHover(context)];

  const { getReferenceProps, getFloatingProps } = useInteractions(interactions);

  return (
    <Div role="tooltip" className={popoverStyles.tooltipPropContainer}>
      {!isEmpty(buttonProps) || buttonContents ? (
        <Button {...buttonProps} className={buttonClx} ref={refs.setReference} {...getReferenceProps()}>
          <span className={popoverStyles.buttonContents}>{buttonContents}</span>
        </Button>
      ) : (
        <div
          className={classNames(popoverStyles.tooltipContainer, divProps?.text && popoverStyles.ellipsesTooltip)}
          ref={refs.setReference}
          {...getReferenceProps()}
        >
          {divProps?.icon && <Icon icon={divProps?.icon} color={divProps?.iconColor} />}
          {divProps?.text && <>{divProps?.text}</>}
          {divProps?.disabledTooltip && <>{divProps?.disabledTooltip}</>}
        </div>
      )}

      {showPopover && (
        <Div
          className={classNames(popoverClx, popoverStyles.className)}
          ref={refs.setFloating}
          style={{ ...floatingStyles, width }}
          {...divProps}
          {...getFloatingProps()}
        >
          {popoverContents}
          <FloatingArrow ref={arrowRef} context={context} className={popoverStyles.tooltipArrow} />
        </Div>
      )}
    </Div>
  );
}

export default Tooltip;
