import { cMonoColorType } from "../../../../../app/constants";
import Column from "../../../../../components/Row/Column/Column";
import Typography from "../../../../../components/Typography/Typography";

interface ITaskInfoLabel {
  children: React.ReactNode;
}

/**
 * A label for task information in the reassign tasks modal
 * @param children The children to render
 * @returns JSX.Element
 */
function TaskInfoLabel({ children }: ITaskInfoLabel): JSX.Element {
  return (
    <Column md={3}>
      <Typography color={cMonoColorType.Light}>{children}</Typography>
    </Column>
  );
}

export default TaskInfoLabel;
