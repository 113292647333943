import { Control, DeepRequired, FieldErrorsImpl, UseFormRegister, UseFormResetField } from "react-hook-form";
import { cMonoColorType } from "../../app/constants";
import Div from "../Div/Div";
import FormField, { cFormFieldType } from "../FormField/FormField";
import Typography from "../Typography/Typography";
import styles from "./FileUploadFormField.module.scss";

interface IFileUpload {
  control: Control<any, any>;
  register: UseFormRegister<any>;
  errors: FieldErrorsImpl<DeepRequired<any>>;
  resetField: UseFormResetField<any>;
  maxFileUploadSize: number;
}

/**
 * The file upload form field component
 * @param control           React hook form control function
 * @param register          React hook form register function
 * @param errors            React hook form errors
 * @param resetField        React hook form reset field
 * @param maxFileUploadSize The max file size that can be uploaded
 * @returns JSX.Element
 */
function FileUploadFormField({ control, register, errors, resetField, maxFileUploadSize }: IFileUpload) {
  return (
    <Div spacing={{ mb: 5 }} className={styles.className} testId="file-upload-formfield">
      <FormField
        name="file"
        type={cFormFieldType.File}
        register={register}
        label={
          <Div spacing={{ mb: 5 }}>
            <Typography>Select your file to upload</Typography>
            <Typography weight="normal" color={cMonoColorType.Light} spacing={{ mb: 3 }}>
              (max size {maxFileUploadSize}mb)
            </Typography>
          </Div>
        }
        control={control as Control<any, any>}
        error={errors.file as any}
        maxFileUploadSize={maxFileUploadSize}
        resetField={resetField}
        required
      />
    </Div>
  );
}

export default FileUploadFormField;
