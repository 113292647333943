import { chain } from "lodash";
import { useEffect, useState } from "react";
import { Control, SubmitHandler, useForm } from "react-hook-form";
import { cSizeType, cStatusType, cThemeColorType } from "../../../../app/constants";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Button, { cButtonType } from "../../../../components/Button/Button";
import DiscardChangesModal from "../../../../components/DiscardChangesModal/DiscardChangesModal";
import Div from "../../../../components/Div/Div";
import { ETimelineType } from "../../../../components/Events/TimelineEvents";
import FormField, { cFormFieldType, IFieldOption } from "../../../../components/FormField/FormField";
import Label from "../../../../components/FormField/Label/Label";
import InfoBox from "../../../../components/InfoBox/InfoBox";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../components/Modal/Modal";
import Spinner from "../../../../components/Spinner/Spinner";
import Typography from "../../../../components/Typography/Typography";
import {
  IDocument,
  openInformationUpdatedModal,
  postDocumentOwnershipTransfer,
  selectDocumentReplacementUserIDs,
  selectDocumentsStatus,
} from "../../../../modules/documentsSlice";
import { IMatter, postMatterOwnershipTransfer, selectMatterReplacementUserIDs } from "../../../../modules/mattersSlice";
import { IUser, postUsers, selectUsers } from "../../../../modules/usersSlice";
import { errorToast } from "../../../../toast/toast";
import { areSomeDefined } from "../../../../utils/areSomeDefined/areSomeDefined";
import { isOutdatedContentError } from "../../../../utils/errors/errors";
import styles from "./TransferOwnershipModal.module.scss";

// Form inputs
type Inputs = {
  user: IFieldOption | null;
  transferNote: string;
};

// Generic properties for component
interface ITransferOwnershipModalGeneric {
  isOpen: boolean;
  handleClose: () => void;
  onSubmitSuccess?: Function;
}

// Either this is a document or matter transfer ownership
type TTransferOwnershipModalType =
  | {
      type: ETimelineType.Document;
      documentID?: number;
      documents?: Record<string, IDocument>;
      matterID?: never;
      matters?: never;
    }
  | {
      type: ETimelineType.Matter;
      matterID: number;
      matters?: Record<number, IMatter>;
      documentID?: never;
      documents?: never;
    };

// Combine the interface and type
export type TTransferOwnershipModal = ITransferOwnershipModalGeneric & TTransferOwnershipModalType;

/**
 * Render a modal to transfer ownership
 * @param isOpen	        Is the modal open?
 * @param handleClose	    Function to close the modal
 * @param onSubmitSuccess	Function called on submit success
 * @param type      	    Document or matter transfer ownership
 * @param documentID      The document ID
 * @param documents       Document records
 * @param matterID        The matter ID
 * @param matters         Matter records
 * @returns JSX.Element
 */
function TransferOwnershipModal({
  isOpen,
  handleClose,
  onSubmitSuccess,
  type,
  documentID,
  documents,
  matterID,
  matters,
}: TTransferOwnershipModal): JSX.Element {
  const {
    register, // Register prop for form inputs
    handleSubmit, // Submit handler wrapper
    formState: { errors }, // Errors that may occur
    control,
    reset,
    watch,
  } = useForm<Inputs>();
  const [isDiscardChangesModalOpen, setIsDiscardChangesModalOpen] = useState(false);
  const [record, setRecord] = useState<IDocument | IMatter>();
  const [replacementUserOptions, setReplacementUserOptions] = useState<IFieldOption[]>([]);
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsers);
  const replacementUserIDs = useAppSelector(
    type === ETimelineType.Document ? selectDocumentReplacementUserIDs : selectMatterReplacementUserIDs,
  );
  const documentsStatus = useAppSelector(selectDocumentsStatus);
  const [areUsersLoading, setAreUsersLoading] = useState(false);

  // Fetch users on open for replacement user options
  useEffect(() => {
    if (isOpen) {
      handleUsersFetch();
    }
  }, [isOpen]);

  /**
   * Set the user loading status before fetching users and reset it after
   * @returns Promise<void>
   */
  async function handleUsersFetch() {
    try {
      setAreUsersLoading(true);
      await dispatch(postUsers({}));
      setAreUsersLoading(false);
    } catch (error: any) {
      setAreUsersLoading(false);
    }
  }

  // Set the record once record set exists
  useEffect(() => {
    if (type === ETimelineType.Document) {
      if (documents && documentID) {
        setRecord(documents[documentID]);
      }
    } else {
      if (matters && matterID) {
        setRecord(matters[matterID]);
      }
    }
  }, [documents, documentID, matters, matterID]);

  /**
   * Handles Modal close
   */
  function handleModalClose() {
    if (areSomeDefined(watch())) {
      setIsDiscardChangesModalOpen(true);
    } else {
      handleClose();
      reset();
    }
  }

  /**
   * Handles DiscardChangesModal close
   */
  function handleDiscardChangesModalClose(canDiscard: boolean) {
    if (canDiscard) {
      handleClose();
      reset();
    }
    setIsDiscardChangesModalOpen(false);
  }

  // Set the replacement user options on users state update
  useEffect(() => {
    if (areUsersLoading === false) {
      // Put replacement user options into field option format
      const userOptions: IFieldOption[] = chain(users)
        .orderBy([(user) => user?.lastName?.toLowerCase(), (user) => user?.firstName?.toLowerCase()])
        .reduce((acc: IFieldOption[], user: IUser) => {
          if (replacementUserIDs?.includes(user.id)) {
            acc.push({ label: `${user.lastName}, ${user.firstName} `, value: user.id });
          }
          return acc;
        }, [])
        .value();

      userOptions.unshift({ label: "Select...", value: "" });
      setReplacementUserOptions(userOptions);
    } else {
      // If users are loading, set a single option to show loading
      setReplacementUserOptions([{ label: <Spinner size={cSizeType.Small} />, value: "", disabled: true }]);
    }
  }, [users, areUsersLoading]);

  /**
   * Submit the form
   * @param data The form values on submit
   */
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      if (record) {
        const { id, ownerUser } = record;
        const { user, transferNote } = data;

        if (type === ETimelineType.Document) {
          await dispatch(
            postDocumentOwnershipTransfer({
              documentID: id,
              fromUserID: ownerUser.id,
              toUserID: user?.value as number,
              transferNote,
            }),
          ).unwrap();
        } else {
          await dispatch(
            postMatterOwnershipTransfer({
              matterID: id,
              fromUserID: ownerUser.id,
              toUserID: user?.value as number,
              transferNote,
            }),
          ).unwrap();
        }
        reset();
        handleClose();
        onSubmitSuccess && onSubmitSuccess();
      }
    } catch (error: any) {
      console.error(error);
      const errorCode = parseInt(error);
      if (isOutdatedContentError(errorCode)) {
        dispatch(openInformationUpdatedModal());
      } else {
        errorToast(error);
      }
    }
  };

  const titleCaseType = type.charAt(0).toUpperCase() + type.slice(1);

  return (
    <>
      <Modal isOpen={isOpen} handleClose={handleModalClose} size="md" testId="transfer-ownership-modal">
        <ModalHeader>
          <ModalTitle>Transfer {titleCaseType} Ownership</ModalTitle>
        </ModalHeader>
        <ModalContent>
          <Div spacing={{ mb: 5 }}>
            <InfoBox>
              <ul>
                <li>Ownership of this {type} will transfer to the replacement user.</li>
                <li>Future {titleCaseType} Owner notifications will go to the replacement user.</li>
              </ul>
            </InfoBox>
          </Div>
          <Label>Transferring</Label>
          <Typography>{record?.description}</Typography>
          <Div spacing={{ mt: 5 }}>
            <form id="transferOwnershipForm" onSubmit={handleSubmit(onSubmit)}>
              <Label>To</Label>
              <Typography className={styles.small} fontStyle="italic">
                This {type} can only be transferred to users who have viewing rights to the {type} and are listed here:
              </Typography>
              <FormField
                name="user"
                type={cFormFieldType.AutoComplete}
                register={register}
                options={replacementUserOptions}
                error={errors.user as any}
                control={control as Control<any, any>}
                required
                spacing={{ mt: 3, mb: 5 }}
                onInputChange={() => {}}
              />
              <FormField
                testId="transfer-note"
                label="Transfer note (Optional)"
                name="transferNote"
                type={cFormFieldType.Textarea}
                register={register}
                error={errors.transferNote}
                spacing={{ mb: 5 }}
                fullWidth
              />
            </form>
          </Div>
        </ModalContent>
        <ModalActions>
          <Button
            color={cThemeColorType.Secondary}
            onClick={handleModalClose}
            testId="transfer-ownership-modal-cancel-button"
          >
            Cancel
          </Button>
          <Button
            formId="transferOwnershipForm"
            type={cButtonType.SubmitType}
            testId="transfer-ownership-modal-submit-button"
            isLoading={documentsStatus === cStatusType.Loading}
          >
            Transfer
          </Button>
        </ModalActions>
      </Modal>
      <DiscardChangesModal
        isOpen={isDiscardChangesModalOpen}
        handleClose={(canDiscard) => handleDiscardChangesModalClose(canDiscard)}
      />
    </>
  );
}

export default TransferOwnershipModal;
