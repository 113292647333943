import classNames from "classnames";
import { cMonoColorType } from "../../../app/constants";
import {
  useMargin,
  useMarginB,
  useMarginL,
  useMarginR,
  useMarginT,
  useMarginX,
  useMarginY,
  usePadding,
  usePaddingB,
  usePaddingL,
  usePaddingR,
  usePaddingT,
  usePaddingX,
  usePaddingY,
} from "../../../app/hooks";
import {
  IMargin,
  IMarginB,
  IMarginL,
  IMarginR,
  IMarginT,
  IMarginX,
  IMarginY,
  IPadding,
  IPaddingB,
  IPaddingL,
  IPaddingR,
  IPaddingT,
  IPaddingX,
  IPaddingY,
} from "../../../app/styleTypes";
import utilityStyles from "../../../scss/generic/Utilities.module.scss";
import Div from "../../Div/Div";
import styles from "./Container.module.scss";

/**
 * Container width
 */
export type Width = "fluid" | "sm" | "md" | "lg" | "xl" | "xxl";

interface IContainer {
  width?: Width;
  fullViewportHeight?: boolean;
  bgColor?: cMonoColorType;
  children?: React.ReactNode;
  className?: string;
  p?: IPadding;
  py?: IPaddingY;
  px?: IPaddingX;
  pt?: IPaddingT;
  pr?: IPaddingR;
  pb?: IPaddingB;
  pl?: IPaddingL;
  m?: IMargin;
  my?: IMarginY;
  mx?: IMarginX;
  mt?: IMarginT;
  mr?: IMarginR;
  mb?: IMarginB;
  ml?: IMarginL;
  testId?: string;
}

/**
 * Grid container
 * @param width               Width of the container
 * @param fullViewportHeight  Should the container's min-height be the full viewport?
 * @param bgColor             Background color
 * @param children            Children (usually a Row followed by Columns)
 * @param className           Custom class
 * @param p                   Set Padding object
 * @param py                  Set Padding-y object
 * @param px                  Set Padding-x object
 * @param pt                  Set Padding-top object
 * @param pr                  Set Padding-right object
 * @param pb                  Set Padding-bottom object
 * @param pl                  Set Padding-left object
 * @param m                   Set Margin object
 * @param my                  Set Margin-y object
 * @param mx                  Set Margin-x object
 * @param mt                  Set Margin-top object
 * @param mr                  Set Margin-right object
 * @param mb                  Set Margin-bottom object
 * @param ml                  Set Margin-left object
 * @param testId              Test ID
 * @returns JSX.Element
 */
function Container({
  width,
  fullViewportHeight,
  children,
  className,
  bgColor,
  p,
  px,
  py,
  pt,
  pr,
  pb,
  pl,
  m,
  mx,
  my,
  mt,
  mr,
  mb,
  ml,
  testId,
}: IContainer): JSX.Element {
  const pClx = usePadding(p); // Get padding classes
  const pyClx = usePaddingY(py); // Get padding-y classes
  const pxClx = usePaddingX(px); // Get padding-x classes
  const ptClx = usePaddingT(pt); // Get padding-top classes
  const prClx = usePaddingR(pr); // Get padding-right classes
  const pbClx = usePaddingB(pb); // Get padding-bottom classes
  const plClx = usePaddingL(pl); // Get padding-left classes
  const mClx = useMargin(m); // Get margin classes
  const myClx = useMarginY(my); // Get margin-y classes
  const mxClx = useMarginX(mx); // Get margin-x classes
  const mtClx = useMarginT(mt); // Get margin-top classes
  const mrClx = useMarginR(mr); // Get margin-right classes
  const mbClx = useMarginB(mb); // Get margin-bottom classes
  const mlClx = useMarginL(ml); // Get margin-left classes

  const clx = classNames(
    bgColor && utilityStyles[`bg-${bgColor}`], // Apply background color
    width && styles[`container-${width}`], // Apply the container width styles
    !width && styles.container, // If no container width value is provided, add the generic container styles
    fullViewportHeight === true && styles.fullViewportHeight, // If fullViewPortHeight is true, add the "vh-100" style
    className, // Add custom class if exists
    pClx, // Padding
    pyClx, // Padding
    pxClx, // Padding
    ptClx, // Padding
    prClx, // Padding
    pbClx, // Padding
    plClx, // Padding
    mClx, // Margin
    myClx, // Margin
    mxClx, // Margin
    mtClx, // Margin
    mrClx, // Margin
    mbClx, // Margin
    mlClx, // Margin
  );

  return (
    <Div className={clx} testId={testId}>
      {children}
    </Div>
  );
}

export default Container;
