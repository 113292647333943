import { subMonths } from "date-fns";
import { SubmitHandler, useForm } from "react-hook-form";
import { cMonoColorType, cStatusType } from "../../../../app/constants";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Button, { cButtonType, EButtonVariant } from "../../../../components/Button/Button";
import Div from "../../../../components/Div/Div";
import FormField, { cFormFieldType } from "../../../../components/FormField/FormField";
import { EIcon } from "../../../../components/Icon/Icon";
import Typography from "../../../../components/Typography/Typography";
import { postUserActivityReport, selectReportStatus } from "../../../../modules/reportSlice";
import styles from "./UserActivityReport.module.scss";

type Inputs = {
  startDate: string;
  endDate: string;
};

interface IUserActivityReport {
  openDrawer: () => void;
}

/**
 * User activity report page
 * @param openDrawer A function to open the drawer
 * @returns JSX.Element
 */
function UserActivityReport({ openDrawer }: IUserActivityReport) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<Inputs>();

  const startDate = watch("startDate");
  const endDate = watch("endDate");
  const dispatch = useAppDispatch();
  const reportStatus = useAppSelector(selectReportStatus);

  /**
   * Submit the form
   * @param data The form values on submit
   */
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const { startDate, endDate } = data;
    dispatch(postUserActivityReport({ startDate, endDate }));
  };

  return (
    <Div testId="user-activity-report">
      <Div border={{ bb: true }} className={styles.actionBar}>
        <Div display={{ base: "flex" }} alignItems={{ base: "center" }}>
          <Button
            onClick={openDrawer}
            variant={EButtonVariant.Round}
            icon={EIcon.DrawerExpandRight}
            color={cMonoColorType.Dark}
          />
        </Div>
      </Div>

      <Div px={{ base: 7 }} py={{ base: 6, sm: 8 }}>
        <Typography variant="h4">Choose a start and end date to download a User Activity Report</Typography>

        <Div spacing={{ mt: 6 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Div display={{ base: "flex" }} flexDirection={{ base: "column", sm: "row" }}>
              <FormField
                name="startDate"
                label="Start date"
                register={register}
                type={cFormFieldType.DatePicker}
                error={errors.startDate}
                required
                minDate={subMonths(new Date(), 6)}
                maxDate={endDate ? new Date(endDate) : new Date()}
                defaultActiveStartDate={endDate ? new Date(endDate) : undefined}
                testId="user-activity-report-start-date"
                spacing={{ mb: 6, mr: 7 }}
              />

              <FormField
                name="endDate"
                label="End date"
                register={register}
                type={cFormFieldType.DatePicker}
                error={errors.endDate}
                required
                minDate={startDate ? new Date(startDate) : subMonths(new Date(), 6)}
                maxDate={new Date()}
                testId="user-activity-report-end-date"
                spacing={{ mb: 6 }}
              />
            </Div>

            <Button type={cButtonType.SubmitType} isLoading={reportStatus === cStatusType.Loading}>
              Download
            </Button>
          </form>
        </Div>
      </Div>
    </Div>
  );
}

export default UserActivityReport;
