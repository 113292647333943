import { lazy, Suspense } from "react";
import Div from "../../components/Div/Div";
import Spinner from "../../components/Spinner/Spinner";

const LazyAnalyticsContainer = lazy(() => import("./AnalyticsContainer"));

const AnalyticsContainer = (props: JSX.IntrinsicAttributes) => (
  <Suspense
    fallback={
      <Div p={{ base: 6 }}>
        <Spinner />
      </Div>
    }
  >
    <LazyAnalyticsContainer {...props} />
  </Suspense>
);

export default AnalyticsContainer;
