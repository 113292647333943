import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { cMonoColorType, cStatusType } from "../../../../app/constants";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Button, { cButtonType, EButtonVariant } from "../../../../components/Button/Button";
import DisplayError from "../../../../components/DisplayError/DisplayError";
import Div from "../../../../components/Div/Div";
import FormField, { cFormFieldType } from "../../../../components/FormField/FormField";
import { EIcon } from "../../../../components/Icon/Icon";
import Typography from "../../../../components/Typography/Typography";
import {
  postCheckDeleteDocument,
  postDeleteDocument,
  resetDocumentsError,
  selectDeleteDocumentStatus,
  selectDocumentsError,
  selectDocumentsStatus,
} from "../../../../modules/documentsSlice";
import styles from "./DeleteDocument.module.scss";
import DeleteDocumentConfirmationModal from "./DeleteDocumentConfirmationModal/DeleteDocumentConfirmationModal";

/**
 * Document delete type
 */
export enum EDocumentDeleteType {
  Primary = "primary",
  Supporting = "supporting",
}

/**
 * Form inputs
 */
type Inputs = {
  type: EDocumentDeleteType;
  urn: string;
};

/**
 * Form default values
 */
const defaultValues = {
  type: EDocumentDeleteType.Primary,
};

interface IDeleteDocument {
  openDrawer: () => void;
}

/**
 * Delete a document page
 * @param openDrawer A function to open the drawer
 * @returns JSX.Element
 */
function DeleteDocument({ openDrawer }: IDeleteDocument) {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    reset,
  } = useForm<Inputs>({ mode: "onChange", defaultValues });
  const dispatch = useAppDispatch();
  const status = useAppSelector(selectDocumentsStatus);
  const deleteStatus = useAppSelector(selectDeleteDocumentStatus);
  const documentsError = useAppSelector(selectDocumentsError);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
  const [documentDescription, setDocumentDescription] = useState<string | undefined>(undefined);
  const [documentName, setDocumentName] = useState<string | undefined>(undefined);
  const type = watch("type");
  const urn = watch("urn");

  useEffect(() => {
    return () => {
      // Reset the documents error during cleanup
      dispatch(resetDocumentsError());
    };
  }, []);

  useEffect(() => {
    if (!urn) {
      // Reset the documents error if the urn is blank
      dispatch(resetDocumentsError());
    }
  }, [urn]);

  /**
   * Submit the form
   * @param The form data
   */
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const { type, urn } = data;
      const foundDocument = await dispatch(postCheckDeleteDocument({ type, urn })).unwrap();
      if (foundDocument) {
        setDocumentDescription(foundDocument.documentDescription);
        setDocumentName(foundDocument.supportingDocumentName || foundDocument.documentTypeName);
        setIsDeleteConfirmationModalOpen(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Resets delete document state
   */
  function resetDeleteDocumentState() {
    setIsDeleteConfirmationModalOpen(false);
    setDocumentDescription(undefined);
    setDocumentName(undefined);
  }

  /**
   * Handles delete document confirmation
   * @param canDelete Can the document be deleted?
   */
  async function handleDeleteDocumentConfirmation(canDelete: boolean) {
    if (canDelete) {
      const { type, urn } = watch();
      await dispatch(postDeleteDocument({ type, urn })).unwrap();
      toast(`${type === EDocumentDeleteType.Primary ? "Primary" : "Supporting"} Document deleted`);
      resetDeleteDocumentState();
      reset();
    } else {
      resetDeleteDocumentState();
    }
  }

  return (
    <Div testId="delete-document">
      <Div border={{ bb: true }} className={styles.actionBar}>
        <Div display={{ base: "flex" }} alignItems={{ base: "center" }}>
          <Button
            onClick={openDrawer}
            variant={EButtonVariant.Round}
            icon={EIcon.DrawerExpandRight}
            color={cMonoColorType.Dark}
            testId="open-drawer-button"
          />
        </Div>
      </Div>
      <Div px={{ base: 7 }} py={{ base: 6, sm: 8 }}>
        <Typography variant="h4">What kind of document is it?</Typography>
        <Div spacing={{ mt: 6 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormField
              name="type"
              register={register}
              type={cFormFieldType.Radio as const} // Use radio field
              options={[
                {
                  label: "Primary document",
                  value: EDocumentDeleteType.Primary,
                },
                {
                  label: "Supporting document",
                  value: EDocumentDeleteType.Supporting,
                },
              ]}
              error={errors.type}
              required
              spacing={{ mb: 6 }}
            />
            <FormField
              type={cFormFieldType.Number}
              name="urn"
              label="URN"
              register={register}
              error={errors.urn}
              required
              testId="urn"
              spacing={{ mb: 6 }}
            />
            {documentsError && <DisplayError>{documentsError}</DisplayError>}
            <Button
              type={cButtonType.SubmitType}
              isLoading={status === cStatusType.Loading}
              disabled={!isValid}
              spacing={{ mt: 6 }}
              testId="delete-document-submit-button"
            >
              Delete
            </Button>
          </form>
        </Div>
      </Div>
      {isDeleteConfirmationModalOpen && (
        <DeleteDocumentConfirmationModal
          isOpen
          handleClose={(canDelete: boolean) => handleDeleteDocumentConfirmation(canDelete)}
          type={type}
          documentDescription={documentDescription}
          documentName={documentName}
          status={deleteStatus}
          urn={urn}
        />
      )}
    </Div>
  );
}

export default DeleteDocument;
