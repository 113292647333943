import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../app/api";
import { documentTypesUrl } from "../app/apiUrls";
import { cStatusType } from "../app/constants";
import { RootState } from "../app/store";
import createIdList from "../utils/createIdList/createIdList";
import getExpiredIdCacheList, { TUnknownEntries } from "../utils/getExpiredIdCacheList/getExpiredIdCacheList";
import { IDocumentTypesState } from "./documentTypesSlice";

/**
 * Initial state
 */
const initialState: IDocumentTypesState = {
  entries: {},
  status: cStatusType.Idle,
  fetched: false,
};

/**
 * Interface for post matter document types function params
 */
interface IPostMatterDocumentTypes {
  ids: number[];
  force?: boolean;
}

/**
 * Thunk for fetching matter document types
 */
export const postMatterDocumentTypes = createAsyncThunk(
  "matterDocumentTypes/postMatterDocumentTypes",
  async ({ ids, force = false }: IPostMatterDocumentTypes, { dispatch, rejectWithValue, getState }) => {
    try {
      let id = ids;
      // If we are not forcing the fetch, check if we have the data in the cache
      // (force will fetch the data regardless of the cache state)
      if (!force) {
        const state = getState();

        const {
          matterDocumentTypes: { entries },
        } = state as RootState;

        // Get the expired ids
        id = getExpiredIdCacheList({ ids, entries: entries as unknown as TUnknownEntries });
      }

      if (!id.length) {
        return {};
      }

      const endpoint = documentTypesUrl;
      const response = await api({ endpoint, dispatch, body: { id } });

      return createIdList(response.data.documentType);
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Matter document types reducer
 */
export const matterDocumentTypesSlice = createSlice({
  name: "matterDocumentTypes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postMatterDocumentTypes.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postMatterDocumentTypes.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postMatterDocumentTypes.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.entries = { ...state.entries, ...action.payload };
        state.fetched = true;
      });
  },
});

// Select the status
export const selectMatterDocumentTypesStatus = (state: RootState) => state.matterDocumentTypes.status;
// Select all matterDocumentTypes
export const selectMatterDocumentTypes = (state: RootState) => state.matterDocumentTypes.entries;
// Select fetched
export const selectMatterDocumentTypesFetched = (state: RootState) => state.matterDocumentTypes.fetched;
// Select error
export const selectMatterDocumentTypesError = (state: RootState) => state.matterDocumentTypes.error;

export default matterDocumentTypesSlice.reducer;
