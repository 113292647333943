import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { EPopoverPlacement } from "../../app/types";
import Div from "../Div/Div";
import Popover from "../Popover/Popover";
import styles from "./TruncatedPopover.module.scss";

interface ITruncatedPopover {
  children: React.ReactNode;
  className?: string;
  testId?: string;
}

/**
 * Shows the children in a popover if the text is truncated
 * @param children  The text to show the popover
 * @param className Custom wrapper class
 * @param testId    The test id
 * @returns JSX.Element
 */
function TruncatedPopover({ children, className, testId }: ITruncatedPopover) {
  const ref = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  /**
   * Check whether the element width is greater than the parent
   */
  function isTextTruncated(current: HTMLDivElement) {
    const { parentElement } = current;
    return parentElement && parentElement.clientWidth < current.scrollWidth;
  }

  /**
   * Handles window resize
   */
  function handleResize() {
    // Set truncated true if the element width is greater than the parent
    const { current } = ref;
    if (current && isTextTruncated(current)) {
      setIsTruncated(true);
    } else {
      setIsTruncated(false);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    handleResize();
  }, [children, ref.current]);

  return (
    <Div className={classNames(styles.truncate, className)} testId={testId?.replace(/ +/g, "-")}>
      {isTruncated ? (
        <Popover
          variant="tooltip"
          popoverPlacement={EPopoverPlacement.Top}
          width="auto"
          divProps={{
            text: children,
          }}
          popoverContents={children}
        />
      ) : (
        <>{children}</>
      )}

      {/* Render the children hidden so it still takes up all the width */}
      <Div ref={ref} className={styles.hidden}>
        {children}
      </Div>
    </Div>
  );
}

export default TruncatedPopover;
