/**
 * Converts bytes to mb
 */
export function bytesToMb(bytes: number) {
  return bytes / 1024 / 1024;
}

/**
 * Formats file bytes to a size with or without units
 * @param bytes The file bytes
 * @param withUnit Should we return the units e.g. KB?
 * @param decimalPoints The decimal points
 * @returns string
 */
export function formatFileSize(bytes: number, withUnit = false, decimalPoints = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  if (withUnit) {
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPoints))} ${sizes[i]}`;
  }
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPoints))}`;
}
