import { lazy, Suspense } from "react";
import Div from "../../Div/Div";
import { ITelProps } from "./Tel";

const LazyTel = lazy(() => import("./Tel"));

const Tel = (props: ITelProps) => (
  <Suspense fallback={<Div />}>
    <LazyTel {...props} />
  </Suspense>
);

export default Tel;
