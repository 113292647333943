import { lazy, Suspense } from "react";
import Div from "../../Div/Div";
import { IRadioButtonProps } from "./RadioButton";

const LazyRadioButton = lazy(() => import("./RadioButton"));

const RadioButton = (props: IRadioButtonProps) => (
  <Suspense fallback={<Div />}>
    <LazyRadioButton {...props} />
  </Suspense>
);

export default RadioButton;
