import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { Control, SubmitHandler, useForm } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import { cStatusType, cThemeColorType } from "../../../../app/constants";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Button, { cButtonType } from "../../../../components/Button/Button";
import DiscardChangesModal from "../../../../components/DiscardChangesModal/DiscardChangesModal";
import DisplayError from "../../../../components/DisplayError/DisplayError";
import FormField, { cFormFieldType } from "../../../../components/FormField/FormField";
import { EIcon } from "../../../../components/Icon/Icon";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../components/Modal/Modal";
import {
  EESignActionType,
  IESignGroup,
  postAddESignGroupRecipient,
  postAddESignRecipient,
  postAddFilterPeopleByEmail,
  resetFilteredEmailAddressOptions,
  resetFoundESignUser,
  resetGroupRecipientError,
  selectAddGroupRecipientError,
  selectESignStatus,
  selectFilteredEmailAddressOptions,
  selectFoundESignUser,
} from "../../../../modules/esignSlice";
import { isValidEmail } from "../../../../utils/isValidEmail/isValidEmail";

/**
 * Form inputs
 */
type Inputs = {
  ema: string;
  confirmEmailAddress: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  action: string;
  allowProxy: boolean;
};

interface IAddRecipientModal {
  isOpen: boolean;
  handleClose: () => void;
  id: number;
  onSubmitSuccess: () => void;
  action?: Exclude<EESignActionType, EESignActionType.View>;
  group?: IESignGroup;
}

/**
 * The add recipient modal
 * @param isOpen          Is the modal open?
 * @param handleClose     Function to close the modal
 * @param id              The esign or group id the recipient is being added to
 * @param onSubmitSuccess Function called after a successful form submit
 * @param action          EESignActionType
 * @param group           The group (if recipient is being added to a group)
 * @returns JSX.Element
 */
function AddRecipientModal({ isOpen, handleClose, id, group, onSubmitSuccess, action }: IAddRecipientModal) {
  const spacing = { mb: 5 }; // Field spacing
  const [isDiscardChangesModalOpen, setIsDiscardChangesModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const signatureStatus = useAppSelector(selectESignStatus);
  const minEmailSearchLength = 5;
  const filteredEmailAddressOptions = useAppSelector(selectFilteredEmailAddressOptions);
  const foundESignUser = useAppSelector(selectFoundESignUser);
  const addGroupRecipientError = useAppSelector(selectAddGroupRecipientError);
  const [defaultValues, setDefaultValues] = useState<Inputs>({
    ema: "",
    confirmEmailAddress: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    action: "",
    allowProxy: false,
  });

  const {
    register, // Register prop for form inputs
    handleSubmit, // Submit handler wrapper
    formState: { errors }, // Errors that may occur
    watch,
    control,
    reset,
    setValue,
    resetField,
  } = useForm<Inputs>({ defaultValues });

  const emailAddress = watch("ema");
  const selectedAction = watch("action");
  const isAddGroupRecipient = !!action;

  useEffect(() => {
    // If action is passed as a prop, use it to populate the form value
    if (action) {
      setDefaultValues({
        ema: "",
        confirmEmailAddress: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        action: action,
        allowProxy: false,
      });
    }
  }, [action]);

  useEffect(() => {
    // If the default values change, reset the form to those values
    reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    // Clear group recipient error on load
    dispatch(resetGroupRecipientError());
  }, []);

  /**
   * Populates the form with values from the user found during the autocomplete search
   */
  function populateFormWithFoundUserDetails() {
    setValue("ema", foundESignUser.emailAddress, { shouldValidate: true });
    setValue("confirmEmailAddress", foundESignUser.emailAddress, { shouldValidate: true });
    setValue("firstName", foundESignUser.firstName, { shouldValidate: true });
    setValue("lastName", foundESignUser.lastName, { shouldValidate: true });
    setValue("phoneNumber", foundESignUser.phoneNumber, { shouldValidate: true });
  }

  /**
   * Clears the autofill email address options
   */
  function clearAutofillOptions() {
    dispatch(resetFilteredEmailAddressOptions());
  }

  useEffect(() => {
    // If we have found a user using the email search, use it to populate the form
    if (foundESignUser) {
      populateFormWithFoundUserDetails();
      clearAutofillOptions();
    }
  }, [foundESignUser]);

  useEffect(() => {
    // If the selected action is view, reset allow proxy
    if (selectedAction === EESignActionType.View) {
      resetField("allowProxy");
    }
  }, [selectedAction]);

  useEffect(() => {
    // If the email address changes, search for a user
    if (emailAddress.length >= minEmailSearchLength) {
      try {
        dispatch(postAddFilterPeopleByEmail(emailAddress));
      } catch (error) {
        console.error(error);
      }
    }
  }, [emailAddress]);

  /**
   * Submit the form
   * @param data The form values on submit
   */
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      if (isAddGroupRecipient) {
        await dispatch(postAddESignGroupRecipient({ id, emailAddress: data.ema, ...data })).unwrap();
      } else {
        await dispatch(postAddESignRecipient({ id, emailAddress: data.ema, ...data })).unwrap();
      }
      onSubmitSuccess();
      handleClose();
      reset();
      clearAutofillOptions();
      dispatch(resetFoundESignUser());
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Handles Modal close
   */
  function handleModalClose() {
    if (!isEqual(watch(), defaultValues)) {
      setIsDiscardChangesModalOpen(true);
    } else {
      handleClose();
      reset();
      clearAutofillOptions();
      dispatch(resetFoundESignUser());
    }
  }

  /**
   * Handles DiscardChangesModal close
   * @param canDiscard Can we discard the changes?
   */
  function handleDiscardChangesModalClose(canDiscard: boolean) {
    if (canDiscard) {
      handleClose();
      reset();
      clearAutofillOptions();
      dispatch(resetFoundESignUser());
    }
    setIsDiscardChangesModalOpen(false);
  }

  return (
    <>
      <Modal isOpen={isOpen} handleClose={handleModalClose} testId="add-recipient-modal">
        <ModalHeader>
          {group && isAddGroupRecipient ? (
            <ModalTitle>{`Add a recipient to ${group.name}`}</ModalTitle>
          ) : (
            <ModalTitle>Add a recipient</ModalTitle>
          )}
        </ModalHeader>
        <ModalContent>
          <form id="add-recipient-form" onSubmit={handleSubmit(onSubmit)}>
            <FormField
              label="Email"
              name="ema"
              register={register}
              error={errors.ema}
              spacing={spacing}
              options={filteredEmailAddressOptions}
              clearOptions={clearAutofillOptions}
              fullWidth
              control={control as any}
              validate={(value: string) => {
                if (!isValidEmail(value)) {
                  return "Email is invalid";
                }
              }}
              autoComplete="off"
            />
            <FormField
              label="Confirm email"
              name="confirmEmailAddress"
              register={register}
              error={errors.confirmEmailAddress}
              spacing={spacing}
              required
              fullWidth
              validate={(value: string) => {
                if (!isValidEmail(value)) {
                  return "Email is invalid";
                }
                if (watch("ema") !== value) {
                  return "Emails do no match";
                }
              }}
              disabled={!!foundESignUser}
            />
            <FormField
              label="First name"
              name="firstName"
              register={register}
              error={errors.firstName}
              spacing={spacing}
              required
              fullWidth
              data-lpignore={true}
            />
            <FormField
              label="Last name"
              name="lastName"
              register={register}
              error={errors.lastName}
              spacing={spacing}
              required
              fullWidth
              data-lpignore={true}
            />
            <FormField
              label="Contact number"
              name="phoneNumber"
              type={cFormFieldType.Telephone}
              register={register}
              error={errors.phoneNumber}
              spacing={spacing}
              fullWidth
              control={control as Control<any, any>}
              validate={(value: string) => {
                if (value) {
                  if (value !== null && !isValidPhoneNumber(value)) {
                    return "Contact number is invalid";
                  }
                }
              }}
              required
            />
            <FormField
              testId="action-buttons"
              name="action"
              label="Action"
              register={register}
              type={cFormFieldType.Buttons}
              options={[
                {
                  label: "Sign",
                  value: EESignActionType.Sign,
                  icon: EIcon.Sign,
                },
                {
                  label: "Approve",
                  value: EESignActionType.Approve,
                  icon: EIcon.Approve,
                },
                {
                  label: "View only",
                  value: EESignActionType.View,
                  icon: EIcon.VisibilityOn,
                },
              ]}
              error={errors.action}
              spacing={{ mb: 7 }}
              required={!action}
              hidden={isAddGroupRecipient}
            />
            <FormField
              name="allowProxy"
              type={cFormFieldType.Switch}
              register={register}
              error={errors.allowProxy}
              spacing={spacing}
              fullWidth
              options={[
                {
                  label: "Allow proxy",
                  value: "true",
                },
              ]}
              disabled={selectedAction === "" || selectedAction === EESignActionType.View}
              hidden={isAddGroupRecipient}
            />
          </form>
          {addGroupRecipientError && <DisplayError>{addGroupRecipientError}</DisplayError>}
        </ModalContent>
        <ModalActions>
          <Button
            color={cThemeColorType.Secondary}
            onClick={handleModalClose}
            testId="add-recipient-modal-close-button"
          >
            Cancel
          </Button>
          <Button
            formId="add-recipient-form"
            type={cButtonType.SubmitType}
            testId="add-recipient-modal-submit-button"
            isLoading={signatureStatus === cStatusType.Loading}
          >
            Add
          </Button>
        </ModalActions>
      </Modal>
      <DiscardChangesModal
        isOpen={isDiscardChangesModalOpen}
        handleClose={(canDiscard) => handleDiscardChangesModalClose(canDiscard)}
      />
    </>
  );
}

export default AddRecipientModal;
