import { cSizeType } from "../../../app/constants";
import Div from "../../../components/Div/Div";
import Spinner from "../../../components/Spinner/Spinner";
import Typography from "../../../components/Typography/Typography";
import { ILog } from "../../../modules/interviewLogSlice";
import styles from "./InterviewLog.module.scss";

interface IInterviewLog {
  documentTypeName: string;
  urn: string;
  interviewLog: ILog[];
  matterRef?: string;
}

/**
 * Interview log component
 * @param documentTypeName The name of the associated document type
 * @param urn              The document URN
 * @param interviewLog     The interview log
 * @param matterRef        The associated matter ref code (if any)
 * @returns JSX.Element
 */
function InterviewLog({ documentTypeName, urn, interviewLog, matterRef }: IInterviewLog) {
  /**
   * Renders the interview log based on log type (input, question)
   * @param log The log
   * @returns JSX.Element
   */
  function renderLog(log: ILog) {
    if (log.input) {
      const { input } = log;
      return (
        <Div className={styles.input} key={input.data}>
          <Div className={styles.question}>{input.prompt}</Div>
          <Div className={styles.response}>{input.data}</Div>
        </Div>
      );
    } else if (log.question) {
      const { question } = log;
      return (
        <Div className={styles.input} key={question.option}>
          <div className={styles.question} dangerouslySetInnerHTML={{ __html: question.prompt as string }} />
          <Div className={styles.response}>{question.option}</Div>
        </Div>
      );
    }
    return null;
  }

  return (
    <>
      <Div className={styles.header}>
        <Div></Div> {/* Invisible flex item */}
        {documentTypeName ? <Typography>{documentTypeName}</Typography> : <Spinner size={cSizeType.Small} />}
        <Div>
          {matterRef && (
            <>
              <span className={styles.urnLabel}>Matter Ref</span> <span>{matterRef}</span>
            </>
          )}
          <span className={styles.urnLabel}>URN</span> <span>{urn}</span>
        </Div>
      </Div>
      <Div className={styles.content}>{interviewLog?.map((log) => renderLog(log))}</Div>
    </>
  );
}

export default InterviewLog;
