import { cStatusType } from "../../../../../app/constants";
import DisplayError from "../../../../../components/DisplayError/DisplayError";
import Div from "../../../../../components/Div/Div";
import TimelineEvents, { ETimelineType } from "../../../../../components/Events/TimelineEvents";
import Spinner from "../../../../../components/Spinner/Spinner";
import { TTimeline } from "../../../../../modules/documentsSlice";
import { IUser } from "../../../../../modules/usersSlice";

interface IMatterTimeline {
  timeline: TTimeline[];
  users: Record<number, IUser>;
  handleDeleteBtnClick: (eventId: number) => void;
  mattersTimelineStatus: cStatusType;
  mattersTimelineError?: string;
}

/**
 * Matter timeline component
 * @param timeline               Timeline entries
 * @param handleDeleteBtnClick   Open the "are you sure" modal
 * @param mattersTimelineStatus  Matters timeline API status
 * @param mattersTimelineError   Matters timeline error
 * @returns JSX.Element
 */
function MatterTimeline({
  timeline,
  handleDeleteBtnClick,
  mattersTimelineStatus,
  mattersTimelineError,
}: IMatterTimeline): JSX.Element {
  return (
    <Div>
      {mattersTimelineStatus === cStatusType.Loading ? (
        <Div display={{ base: "flex" }} pt={{ base: 5 }} justifyContent={{ base: "center" }}>
          <Spinner />
        </Div>
      ) : (
        <TimelineEvents
          timeline={timeline}
          timelineType={ETimelineType.Matter}
          showSigned={false}
          handleDeleteEvent={handleDeleteBtnClick}
          handleFileDownload={() => {}}
          handleInterviewLog={() => {}}
          handleESignEvent={() => {}}
        />
      )}
      {mattersTimelineError && <DisplayError>{mattersTimelineError}</DisplayError>}
    </Div>
  );
}

export default MatterTimeline;
