import classNames from "classnames";
import { DeepRequired, FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { cThemeColorType } from "../../../../../app/constants";
import { EButtonVariant } from "../../../../../components/Button/Button";
import Div from "../../../../../components/Div/Div";
import FormField, { cFormFieldType } from "../../../../../components/FormField/FormField";
import Label from "../../../../../components/FormField/Label/Label";
import Icon, { EIcon } from "../../../../../components/Icon/Icon";
import Popover from "../../../../../components/Popover/Popover";
import Typography from "../../../../../components/Typography/Typography";
import { EESignState } from "../../../../../modules/esignSlice";
import { dateWithoutTime } from "../../../../../utils/date/date";
import { Inputs } from "../ESignWorkflowModal";
import styles from "./ESignWorkflowSettings.module.scss";

interface ISigningWorkflowSettings {
  register: UseFormRegister<Inputs>;
  errors: FieldErrorsImpl<DeepRequired<Inputs>>;
  formValues: Inputs;
  state: EESignState;
}

/**
 * esign workflow settings
 * @param register   React hook form register function
 * @param errors     React hook form errors
 * @param formValues The current form values
 * @param state      The esign state
 * @returns JSX.Element
 */
function ESignWorkflowSettings({ register, errors, formValues, state }: ISigningWorkflowSettings) {
  return (
    <>
      <Div spacing={{ mb: 8 }}>
        <Typography weight="medium" spacing={{ mb: 5, mr: 4 }}>
          Settings
        </Typography>
        <Div className={styles.controlsWrapper}>
          <Div className={classNames(styles.control, styles.datepicker)}>
            <Div className={styles.formField}>
              <Div spacing={{ mr: 6 }}>
                <Label>Due date</Label>
              </Div>
              <Div spacing={{ mr: 6 }}>
                {state === EESignState.Configuration ? (
                  <FormField
                    testId="due-date-datepicker"
                    name="dueDate"
                    register={register}
                    type={cFormFieldType.DatePicker}
                    error={errors.dueDate}
                    disabled={state !== EESignState.Configuration}
                    minDate={new Date()}
                  />
                ) : (
                  // display a static dueDate
                  <Typography>
                    {formValues.dueDate ? dateWithoutTime(formValues.dueDate).toLocaleDateString() : ""}
                  </Typography>
                )}
              </Div>
            </Div>
            <Popover
              width="300px"
              popoverContents="The date by which you want your document signed and/or approved."
              buttonContents={<Icon icon={EIcon.Help} color={cThemeColorType.Secondary} />}
              buttonProps={{ variant: EButtonVariant.Link }}
            />
          </Div>
          <Div className={classNames(styles.control, styles.autoExpire)} border={{ br: true }}>
            <Div className={styles.formField}>
              <Div spacing={{ mr: 6 }} className={styles.labelWrapper}>
                <Label>Auto expire on due date</Label>
              </Div>
              <Div spacing={{ mr: 6 }}>
                <FormField
                  testId="auto-expire-switch"
                  name="autoExpire"
                  register={register}
                  type={cFormFieldType.Switch}
                  error={errors.autoExpire}
                  options={[
                    {
                      label: "",
                      value: "true",
                    },
                  ]}
                  disabled={!formValues.dueDate || state !== EESignState.Configuration}
                />
              </Div>
            </Div>
            <Popover
              width="280px"
              popoverContents="If enabled, the signature process will stop on the due date, preventing further signatures."
              buttonContents={<Icon icon={EIcon.Help} color={cThemeColorType.Secondary} />}
              buttonProps={{ variant: EButtonVariant.Link }}
            />
          </Div>
          <Div className={classNames(styles.control, styles.autoRemind)}>
            <Div className={styles.formField}>
              <Div spacing={{ mr: 6 }} className={styles.labelWrapper}>
                <Label>Auto remind recipients daily</Label>
              </Div>
              <Div spacing={{ mr: 6 }}>
                <FormField
                  testId="auto-remind-switch"
                  name="autoRemind"
                  register={register}
                  type={cFormFieldType.Switch}
                  error={errors.autoRemind}
                  options={[
                    {
                      label: "",
                      value: "true",
                    },
                  ]}
                  disabled={!formValues.dueDate || state !== EESignState.Configuration}
                />
              </Div>
            </Div>
            <Popover
              width="280px"
              popoverContents="An email will be sent daily to remind recipients of their pending workflow action, except the Updraft initiator, unless they are part of the workflow."
              buttonContents={<Icon icon={EIcon.Help} color={cThemeColorType.Secondary} />}
              buttonProps={{ variant: EButtonVariant.Link }}
            />
          </Div>
        </Div>
      </Div>
    </>
  );
}

export default ESignWorkflowSettings;
