import classNames from "classnames";
import { Link } from "react-router-dom";
import { cAccessType } from "../../../app/constants";
import Button, { EButtonVariant } from "../../Button/Button";
import Div from "../../Div/Div";
import { EIcon } from "../../Icon/Icon";
import Contents from "./Contents/Contents";
import styles from "./ListItem.module.scss";

/**
 * Generic props for list items
 */
export interface IListItemGeneric {
  icon?: EIcon;
  text: string;
  access?: cAccessType;
  testId?: string;
  arrow?: boolean;
}

/**
 * Action props for list items -
 * link will wrap using React Router's Link component and
 * onClick will wrap using Button component
 */
type TListItemAction =
  | {
      link: string;
      onClick?: never;
    }
  | {
      link?: never;
      onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    };

/**
 * Union type
 */
export type TListItem = IListItemGeneric & TListItemAction;

/**
 * A single list item for a link list
 * @param icon    The icon to display in the link
 * @param text    The text to display in the link
 * @param access  The accessibility state for the user
 * @param testId  The test id for the link
 * @param arrow   Whether to display an arrow at the end of the link
 * @param link    The link to navigate to on click or...
 * @param onClick onClick handler function
 * @returns JSX.Element
 */
function ListItem({ icon, text, link, onClick, access, testId, arrow }: TListItem): JSX.Element | null {
  let contents;

  if (access === cAccessType.Inaccessible) return null;

  if (access === cAccessType.Denied || access === cAccessType.Disabled) {
    contents = (
      <Div
        className={classNames(styles.link, styles[access])}
        testId={testId}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
      >
        <Contents icon={icon} text={text} arrow={arrow} />
      </Div>
    );
  } else if (link) {
    contents = (
      <Link to={link} className={styles.link}>
        <Contents icon={icon} text={text} arrow={arrow} />
      </Link>
    );
  } else {
    contents = (
      <Button
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          onClick && onClick(event);
        }}
        variant={EButtonVariant.Link}
        className={styles.link}
        testId={testId}
      >
        <Contents icon={icon} text={text} arrow={arrow} />
      </Button>
    );
  }
  return <li className={styles.className}>{contents}</li>;
}

export default ListItem;
