import classNames from "classnames";
import { useEffect, useState } from "react";
import backdrop from "../../images/backgrounds/backdrop01.jpg";
import logoLight from "../../UpdraftLogoLight.svg";
import Box from "../Box/Box";
import Div from "../Div/Div";
import Container from "../Grid/Container/Container";
import Column from "../Row/Column/Column";
import Row from "../Row/Row";
import styles from "./ExternalPageWrapper.module.scss";
interface IExternalPageWrapper {
  children: JSX.Element | React.ReactNode;
}

/**
 * Wrapper with logo and box for external pages
 * @param children Contents of internal box
 * @returns JSX.Element
 */
function ExternalPageWrapper({ children }: IExternalPageWrapper): JSX.Element {
  const [isBackgroundLoaded, setIsBackgroundLoaded] = useState(false);

  useEffect(() => {
    const bgImage = new Image();
    bgImage.src = backdrop;
    bgImage.addEventListener("load", () => {
      setIsBackgroundLoaded(true);
    });
    return () => {
      bgImage.removeEventListener("load", () => {
        setIsBackgroundLoaded(true);
      });
    };
  }, []);

  return (
    <Div className={styles.className}>
      <Div className={classNames(styles.backdrop, isBackgroundLoaded && styles.loaded)}></Div>
      <Container width="sm">
        <Row justifyContent={{ base: "center" }} alignItems={{ base: "center" }} className={styles.loginBoxCenter}>
          <Column all={12}>
            <Div className={styles.logo}>
              <img src={logoLight} alt="Updraft" data-testid="page-wrapper-logo" />
            </Div>
            <Div display={{ base: "flex" }} alignItems={{ base: "center" }}>
              <Box px={{ base: 6, sm: 8 }} py={{ base: 6, sm: 8 }} className={styles.formBox}>
                {children}
              </Box>
            </Div>
          </Column>
        </Row>
      </Container>
    </Div>
  );
}

export default ExternalPageWrapper;
