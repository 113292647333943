import { useNavigate } from "react-router-dom";
import { cMonoColorType, cRouteType, cSizeType, CUSTOMER_CODE } from "../../../../../app/constants";
import { useQuery } from "../../../../../app/hooks";
import Button, { EButtonVariant } from "../../../../../components/Button/Button";
import Div from "../../../../../components/Div/Div";
import { INotificationsEntry } from "../../../../../modules/notificationsSlice";
import TransactionIcon from "../TransactionIcon/TransactionIcon";
import styles from "./TransactionLink.module.scss";

/**
 * Document type link component in notifications list entry
 * @param documentType         The link to the document
 * @param documentDescription  The link to the document
 * @param matterType           The link to the matter
 * @param status               The status of the notification
 * @param transaction          Detail of the related transaction
 * @param invalidCode          Reason for invalidation
 * @param matterDescription    The link to the matter
 * @param document             The document related to the notification
 * @returns JSX.Element
 */
function TransactionLink({
  documentType,
  matterType,
  documentDescription,
  matterDescription,
  status,
  transaction,
  invalidCode,
  document,
}: Pick<
  INotificationsEntry,
  | "status"
  | "invalidCode"
  | "transaction"
  | "documentType"
  | "documentDescription"
  | "matterType"
  | "matterDescription"
  | "document"
>): JSX.Element {
  const navigate = useNavigate();
  const query = useQuery();

  /**
   * Opens the document information modal or matter page when the button is clicked
   */
  function handleLinkClick(): void {
    const customerCode = query.get("ccof") || localStorage.getItem(CUSTOMER_CODE);

    if (transaction?.hasOwnProperty("matter")) {
      // Redirect to matter
      const id = [transaction?.matter?.id];
      navigate(`/${cRouteType.Matters}/${id}?ccof=${customerCode}`);
    } else if (transaction?.hasOwnProperty("interviewSpawn")) {
      // Redirect to matter
      const id = [transaction?.interviewSpawn?.matterID];
      navigate(`/${cRouteType.Matters}/${id}?ccof=${customerCode}`);
    } else {
      // Open the document information modal
      const id = [transaction?.document?.id];
      navigate(`${id}?ccof=${customerCode}`);
    }
  }

  return (
    <>
      <Div display={{ base: "inline-flex" }} alignItems={{ base: "center" }}>
        <Div mr={{ base: 4 }} testId="transaction-icon">
          <TransactionIcon transaction={transaction} status={status} invalidCode={invalidCode} document={document} />
        </Div>
        <Div data-testid="transaction-link">
          <Button
            variant={EButtonVariant.Outlined}
            color={cMonoColorType.Dark}
            size={cSizeType.Small}
            className={styles.primaryDocLink}
            testId="btn-transaction-link"
            onClick={handleLinkClick}
          >
            <>
              {matterType && transaction?.document?.groupingType?.toLowerCase() === "matter" && (
                <Div className={styles.primaryDocLinkType}>{matterType}</Div>
              )}
              {matterDescription && transaction?.document?.groupingType?.toLowerCase() === "matter" && (
                <Div className={styles.primaryDocLinkType}>{matterDescription}</Div>
              )}
              {documentType && transaction?.document && <Div className={styles.primaryDocLinkType}>{documentType}</Div>}
              {documentType && transaction?.document && documentDescription && (
                <Div className={styles.primaryDocLinkDescription}>{documentDescription}</Div>
              )}
              {matterType && transaction?.matter && <Div className={styles.primaryDocLinkType}>{matterType}</Div>}
              {matterType && transaction?.interviewSpawn && (
                <Div className={styles.primaryDocLinkType}>{matterType}</Div>
              )}
              {transaction?.matter && documentType && (
                <Div className={styles.primaryDocLinkDescription} testId="transaction-document-description">
                  {documentDescription}
                </Div>
              )}
              {transaction?.interviewSpawn && documentType && (
                <Div className={styles.primaryDocLinkDescription} testId="transaction-document-description">
                  {documentDescription}
                </Div>
              )}
            </>
          </Button>
        </Div>
      </Div>
    </>
  );
}

export default TransactionLink;
