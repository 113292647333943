import styles from "./Label.module.scss";
export interface ILabel {
  children: string | React.ReactNode | Element;
  htmlFor?: string;
}

/**
 * Form label
 * @param children The label content
 * @param htmlFor  Link the label to the field ID
 * @returns JSX.Element
 */
function Label({ children, htmlFor }: ILabel): JSX.Element {
  return (
    <label
      className={styles.className} // Styles
      htmlFor={htmlFor} // Link to field ID
    >
      {children as React.ReactNode}
    </label>
  );
}

export default Label;
