import { filter, map } from "lodash";
import { cAccessType, cStatusType, cThemeColorType } from "../../../../app/constants";
import { EPopoverPlacement } from "../../../../app/types";
import { EButtonVariant } from "../../../../components/Button/Button";
import Div from "../../../../components/Div/Div";
import Icon, { EIcon } from "../../../../components/Icon/Icon";
import Modal, { ModalContent, ModalHeader, ModalTitle } from "../../../../components/Modal/Modal";
import Popover from "../../../../components/Popover/Popover";
import Column from "../../../../components/Row/Column/Column";
import Row from "../../../../components/Row/Row";
import { EMatterTypeOfWalk, IMatterType } from "../../../../modules/matterTypesSlice";
import styles from "./NewMatterModal.module.scss";

interface INewMatterModal {
  isOpen: boolean;
  handleClose: () => void;
  matterTypes: Record<number, IMatterType>;
  createMatter: (matterTypeID: number) => void;
  walkStatus: cStatusType;
}

/**
 * Render a modal to create a new Matter
 * @param isOpen			 Modal is open state
 * @param handleClose	 Close the modal
 * @param matterTypes	 Matter types list
 * @param createMatter Function to dispatch creation of a new matter
 * @param walkStatus   Walk API call status
 * @returns JSX.Element
 */
function NewMatterModal({ isOpen, handleClose, matterTypes, createMatter, walkStatus }: INewMatterModal): JSX.Element {
  const matterTypesList = filter(
    matterTypes,
    (matterType) => matterType.isRetired === false && matterType.isLaunchExternally === false,
  ); // Don't render retired or externally launched types

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} className={styles.modal} testId="new-matter-modal">
      <ModalHeader handleClose={handleClose}>
        <ModalTitle>Create a new Matter</ModalTitle>
      </ModalHeader>
      <ModalContent>
        {map(matterTypesList, (matterType) => (
          <Row key={matterType.id} alignItems={{ base: "center" }} className={styles.matterRow} testId="new-matter-row">
            <Column>
              <Row
                className={styles.rightWrapper}
                border={{ bb: true }}
                alignItems={{ base: "center" }}
                py={{ base: 4 }}
              >
                {matterType.state === cAccessType.Inaccessible ? (
                  <Column grow={false}>
                    <Popover
                      popoverPlacement={EPopoverPlacement.Top}
                      popoverContents={<Div>Currently locked by Updraft for enhancement</Div>}
                      variant="tooltip"
                      width="auto"
                      buttonProps={{ icon: EIcon.Logout, variant: EButtonVariant.Link }}
                    />
                  </Column>
                ) : (
                  <></>
                )}
                <Column all={8}>
                  <Div>{matterType.name}</Div>
                </Column>
                <Column>
                  <Div
                    display={{ base: "flex" }}
                    justifyContent={{ base: "flex-end" }}
                    className={styles.buttonWrapper}
                  >
                    <Popover // Create the popover and button
                      variant="tooltip"
                      popoverPlacement={EPopoverPlacement.Top}
                      width="auto"
                      popoverContents={
                        matterType.state === cAccessType.Denied ? (
                          <Div>Insufficient privileges</Div>
                        ) : (
                          <Div>Create a New Matter</Div>
                        )
                      }
                      buttonContents={
                        <Icon
                          icon={
                            matterType.constructorClass === EMatterTypeOfWalk.Draft
                              ? EIcon.UpdateDocument
                              : EIcon.UploadDocument
                          }
                        />
                      }
                      buttonProps={{
                        color: cThemeColorType.Secondary,
                        disabled: matterType.state !== cAccessType.Enabled || walkStatus === cStatusType.Loading,
                        testId:
                          matterType.constructorClass === EMatterTypeOfWalk.Draft
                            ? "draft-matter-button"
                            : "upload-matter-button",
                        onClick: () => createMatter(matterType.id),
                      }}
                    />
                  </Div>
                </Column>
              </Row>
            </Column>
          </Row>
        ))}
      </ModalContent>
    </Modal>
  );
}

export default NewMatterModal;
