import { cSizeType, cThemeColorType } from "../../../../../../app/constants";
import { IXDirection } from "../../../../../../app/types";
import Badge from "../../../../../../components/Badge/Badge";
import Div from "../../../../../../components/Div/Div";
import Icon, { EIcon } from "../../../../../../components/Icon/Icon";
import Typography from "../../../../../../components/Typography/Typography";
import { ISpawnItem } from "../../../../../../modules/matterTasksSlice";
import MatterBoxWrapper from "../MatterBoxWrapper/MatterBoxWrapper";
import MatterTask from "../MatterTask/MatterTask";
import { IMatterTasksColumn } from "../MatterTasksColumn";
import styles from "./MatterToDo.module.scss";

type TMatterToDo = {
  todoItems: ISpawnItem[];
  matterID: number;
} & Pick<IMatterTasksColumn, "handleDraftDocument" | "taskAssignees" | "setSelectedTask">;

/**
 * Matter to do block on single matter page
 * @param todoItems           The items to do
 * @param handleDraftDocument Start spawn walk
 * @param matterID            Wrapping matter ID
 * @param taskAssignees       The assignees for the tasks
 * @param setSelectedTask     Set the selected task for reassignment modal
 * @returns JSX.Element
 */
function MatterToDo({
  todoItems,
  handleDraftDocument,
  matterID,
  taskAssignees,
  setSelectedTask,
}: TMatterToDo): JSX.Element {
  return (
    <MatterBoxWrapper
      header={
        <Div display={{ base: "flex" }} alignItems={{ base: "center" }} className={styles.header}>
          <Typography weight="medium" spacing={{ mr: 4 }}>
            Tasks to do
          </Typography>
          {todoItems && todoItems.length > 0 && (
            <Badge color={cThemeColorType.Danger}>
              <span>{todoItems.length}</span>
            </Badge>
          )}
        </Div>
      }
    >
      <Div
        spacing={{ px: 5 }}
        className={todoItems && todoItems.length === 0 ? styles.content : ""}
        testId="matter-todo-scroll"
      >
        {todoItems && todoItems.length > 0 ? (
          todoItems.map((todoItem) => {
            const taskAssignee = taskAssignees?.find((assignee) => assignee.id === todoItem.assigneeUserID);
            return (
              <MatterTask
                key={todoItem.id}
                {...todoItem}
                handleDraftDocument={handleDraftDocument}
                matterID={matterID}
                taskAssignee={taskAssignee}
                setSelectedTask={setSelectedTask}
              />
            );
          })
        ) : (
          <Div className={styles.tasksEmpty}>
            <Icon size={cSizeType.Large} icon={EIcon.TasksCompleted} className={styles.emptyIcon} />
            <Typography align={IXDirection.Center} className={styles.emptyText}>
              No current tasks
            </Typography>
          </Div>
        )}
      </Div>
    </MatterBoxWrapper>
  );
}

export default MatterToDo;
