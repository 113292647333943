import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ITableCard, ITableCardColumn } from "../TableCard";
import TableCardFilter from "./TableCardFilter/TableCardFilter";

interface ITableCardFilterRow {
  columns: ITableCardColumn[];
  onFilter?: ITableCard["onFilter"];
  testId?: string;
}

/**
 * Render the filter row of a data table
 * @param columns Columns data
 * @returns JSX.Element
 */
function TableCardFilterRow({ columns, onFilter }: ITableCardFilterRow): JSX.Element {
  const {
    register, // Register prop for form inputs
    watch, // Watch for changes
    control,
  } = useForm<any>();

  useEffect(() => {
    // Use effect to prevent unnecessary rerenders
    const subscription = watch(() => {
      // Create subscription to watch
      if (onFilter) onFilter(); // Run onFilter function on change
    });

    return () => subscription.unsubscribe(); // Destroy subscription on unmount
  }, [watch]); // Watch function is a dep

  return (
    <>
      {columns.map(({ filterType, filterOptions, heading, onInputChange, fieldFullWidth, align }, i) => (
        <TableCardFilter
          filterType={filterType}
          filterOptions={filterOptions}
          heading={heading}
          register={register}
          onInputChange={onInputChange}
          fieldFullWidth={fieldFullWidth}
          align={align}
          key={i}
          control={control}
          colspan={columns[i].colspan}
          columns={[]}
          testId={columns[i].testId}
        />
      ))}
    </>
  );
}

export default TableCardFilterRow;
