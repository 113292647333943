import { createAsyncThunk, createSlice, isAllOf, PayloadAction } from "@reduxjs/toolkit";
import { addSeconds } from "date-fns";
import zxcvbn from "zxcvbn";
import api from "../app/api";
import {
  loginUrl,
  logoutUrl,
  passwordResetRequestUrl,
  passwordResetUrl,
  refreshTokenUrl,
  samlLoginUrl,
  userDetailsUrl,
} from "../app/apiUrls";
import { APIKEY, APIKEY_EXPIRY, cStatusType } from "../app/constants";
import { RootState } from "../app/store";
import { IUser } from "./usersSlice";
import { EWalkEndType, postWalkAction } from "./walkSlice";

/**
 * Interface for user state
 */
interface IUserState {
  isLoggedIn: boolean;
  status: cStatusType;
  passwordScore?: zxcvbn.ZXCVBNScore;
  showRecaptcha?: boolean;
  error?: string;
  redirectUrl?: string;
  details?: IUser;
  isTokenRefreshing?: boolean;
  resumeWalk?: boolean;
}

/**
 * Interface for postLogin params
 */
interface IPostLogin {
  username: string;
  password: string;
  ccof: string;
  recaptcha?: string;
}

/**
 * Interface for SAML login params
 */
interface IPostSAMLLogin {
  samlkey: string;
}

/**
 * Interface for postPasswordResetRequest params
 */
interface IPostPasswordResetRequest {
  username: string;
  ccof: string;
}

/**
 * Interface for postPasswordReset params
 */
interface IPostPasswordReset {
  password: string;
  rsn: string;
  ccof: string;
}

/**
 * Describes a bearer token
 */
export interface IBearerToken {
  value: string;
  lifetimeSeconds: number;
}

/**
 * The login response from the server
 */
interface LoginResponse {
  bearerToken: IBearerToken;
  resumeWalk: boolean;
}

/**
 * Sets the bearer token expiry date based on the lifetime of the token
 * @param lifetimeSeconds The lifetime of the bearer token in seconds
 */
function setBearerTokenExpiryDateTime(lifetimeSeconds: number) {
  const apiKeyExpiry = addSeconds(new Date(), lifetimeSeconds);
  localStorage.setItem(APIKEY_EXPIRY, apiKeyExpiry.toString());
}

/**
 * Handles the login
 * @param loginResponse The login response from the server
 */
function handleLogin(loginResponse: LoginResponse) {
  localStorage.setItem(APIKEY, loginResponse.bearerToken.value);
  setBearerTokenExpiryDateTime(loginResponse.bearerToken.lifetimeSeconds);
}

/**
 * Initial state of reducer
 */
const initialState: IUserState = {
  isLoggedIn: false,
  status: cStatusType.Idle,
  passwordScore: 0,
  showRecaptcha: false,
  isTokenRefreshing: false,
  resumeWalk: false,
};

/**
 * Call login API
 */
export const postLogin = createAsyncThunk(
  "user/postLogin",
  async ({ username, password, recaptcha, ccof }: IPostLogin, { rejectWithValue }) => {
    const auth: string = btoa(`${username}:${password}`);

    try {
      const response = await api({
        endpoint: loginUrl,
        headers: { Authorization: `Basic ${auth}` },
        body: recaptcha ? { customerCode: ccof, recaptchaToken: recaptcha } : { customerCode: ccof },
        includeCredentials: false,
      });

      if (response.ok) {
        handleLogin(response.data);
      }

      return response.data;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Call user details API
 */
export const getUserDetails = createAsyncThunk("user/getUserDetails", async (_, { rejectWithValue }) => {
  try {
    const response = await api({
      endpoint: userDetailsUrl,
      method: "GET",
    });

    return response.data;
  } catch (err: any) {
    throw rejectWithValue(err.message);
  }
});

/**
 * Call refresh token API
 */
export const getRefreshToken = createAsyncThunk("user/getRefreshToken", async (_, { rejectWithValue }) => {
  try {
    const response = await api({
      method: "GET",
      endpoint: refreshTokenUrl,
      includeCredentials: false,
    });

    if (response.ok) {
      localStorage.setItem(APIKEY, response.data.value);
      setBearerTokenExpiryDateTime(response.data.lifetimeSeconds);
    }

    return response.data;
  } catch (err: any) {
    throw rejectWithValue(err.message);
  }
});

/**
 * Login via SAML
 */
export const postSamlLogin = createAsyncThunk(
  "user/postSamlLogin",
  async ({ samlkey }: IPostSAMLLogin, { rejectWithValue }) => {
    try {
      const response = await api({
        endpoint: samlLoginUrl,
        body: {
          samlkey,
        },
        includeCredentials: false,
      });

      if (response.ok) {
        handleLogin(response.data);
      }

      return response.data;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Call logout API
 */
export const getLogout = createAsyncThunk("user/getLogout", async (_, { rejectWithValue }) => {
  try {
    const response = await api({
      method: "GET",
      endpoint: logoutUrl,
    });
    return response;
  } catch (err: any) {
    throw rejectWithValue(err.message);
  }
});

/**
 * Call password reset request API
 */
export const postPasswordResetRequest = createAsyncThunk(
  "user/postPasswordResetRequest",
  async ({ username: usernameEmail, ccof }: IPostPasswordResetRequest, { rejectWithValue }) => {
    try {
      const response = await api({
        endpoint: passwordResetRequestUrl,
        includeCredentials: false,
        body: {
          loginName: usernameEmail,
          customerCode: ccof,
        },
      });
      return response;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Call reset password API
 */
export const postPasswordReset = createAsyncThunk(
  "user/postPasswordReset",
  async ({ password, rsn, ccof }: IPostPasswordReset, { rejectWithValue }) => {
    try {
      const response = await api({
        endpoint: passwordResetUrl,
        includeCredentials: false,
        body: {
          customerCode: ccof,
          resetKey: rsn,
          password,
        },
      });
      return response;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Returns true if server returns error code 1333 or 1337
 * @param errorCode The error code
 * @returns boolean
 */
function isMaxViolationsExceededError(errorCode: number) {
  const violationErrors = [1333, 1337];
  return violationErrors.includes(errorCode);
}

/**
 * Handles server error codes
 * @param errorCode The error code
 * @param state     User state
 */
function handleErrorCode(errorCode: number, state: IUserState) {
  if (isMaxViolationsExceededError(errorCode)) {
    state.showRecaptcha = true;
  } else {
    state.showRecaptcha = false;
  }
}

/**
 * Clear user-related localStorage items
 */
function clearUserStorage() {
  localStorage.removeItem(APIKEY);
  localStorage.removeItem(APIKEY_EXPIRY);
}

/**
 * User slice of state
 */
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // Update isLoggedIn
    updateIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    updatePasswordScore: (state, action: PayloadAction<zxcvbn.ZXCVBNScore>) => {
      state.passwordScore = action.payload;
    },
    updateRedirectUrl: (state, action: PayloadAction<string>) => {
      state.redirectUrl = action.payload;
    },
    clearUserError: (state) => {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      // Change status to loading when API call is pending
      .addCase(postLogin.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
        state.isLoggedIn = false;
      })
      // Change status to failed and isLoggedIn to false if API call fails
      .addCase(postLogin.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.isLoggedIn = false;
        state.error = action.payload as string;
        const errorCode = parseInt(state.error);
        handleErrorCode(errorCode, state);
      })
      // Change status to idle and isLoggedIn to true if API call is successful
      .addCase(postLogin.fulfilled, (state) => {
        state.status = cStatusType.Idle;
        state.isLoggedIn = true;
      })
      // Change status to loading when API call is pending
      .addCase(postSamlLogin.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
        state.isLoggedIn = false;
      })
      // Change status to failed and isLoggedIn to false if API call fails
      .addCase(postSamlLogin.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.isLoggedIn = false;
        state.error = action.payload as string;
      })
      // Change status to idle and isLoggedIn to true if API call is successful
      .addCase(postSamlLogin.fulfilled, (state) => {
        state.status = cStatusType.Idle;
        state.isLoggedIn = true;
      })
      // Change status to loading when API call is pending
      .addCase(getRefreshToken.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
        state.isTokenRefreshing = true;
      })
      // Change status to failed and isLoggedIn to false if API call fails
      .addCase(getRefreshToken.rejected, (state) => {
        state.status = cStatusType.Failed;
        state.isLoggedIn = false;
        state.isTokenRefreshing = false;
      })
      // Change status to idle and isLoggedIn to true if API call is successful
      .addCase(getRefreshToken.fulfilled, (state) => {
        state.status = cStatusType.Idle;
        state.isLoggedIn = true;
        state.isTokenRefreshing = false;
      })
      // Change status to loading when API call is pending
      .addCase(getLogout.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      // Change status to failed if API call fails
      .addCase(getLogout.rejected, (state) => {
        state.status = cStatusType.Failed;
        clearUserStorage();
        state.isLoggedIn = false;
        state.showRecaptcha = false;
      })
      // Change status to idle and isLoggedIn to false if API call is successful
      .addCase(getLogout.fulfilled, (state) => {
        state.status = cStatusType.Idle;
        clearUserStorage();
        state.isLoggedIn = false;
        state.showRecaptcha = false;
      })
      // Change status to pending if API call is successful
      .addCase(postPasswordResetRequest.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      // Change status to failed if API call fails
      .addCase(postPasswordResetRequest.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Change status to idle if API call is successful
      .addCase(postPasswordResetRequest.fulfilled, (state) => {
        state.status = cStatusType.Idle;
      })
      // Change status to pending if API call is successful
      .addCase(postPasswordReset.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      // Change status to failed if API call fails
      .addCase(postPasswordReset.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Change status to idle and shouldLogout to true if API call is successful
      .addCase(postPasswordReset.fulfilled, (state) => {
        state.status = cStatusType.Idle;
      })
      // Change status to pending if API call is successful
      .addCase(getUserDetails.pending, (state) => {
        state.status = cStatusType.Loading;
        state.details = undefined;
      })
      // Change status to failed if API call fails
      .addCase(getUserDetails.rejected, (state) => {
        state.status = cStatusType.Failed;
        state.isLoggedIn = false;
      })
      // Change status to idle and update user if API call is successful
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.details = action.payload.user;
        state.resumeWalk = action.payload.resumeWalk;
        state.isLoggedIn = true;
      })
      .addMatcher(isAllOf(postWalkAction.fulfilled), (state, action) => {
        // If walk action payload is an end type, set resume walk to false
        if ([EWalkEndType.Discarded, EWalkEndType.Paused, EWalkEndType.Done].includes(action.payload)) {
          state.resumeWalk = false;
        }
      });
  },
});

export const { updateIsLoggedIn, updatePasswordScore, updateRedirectUrl, clearUserError } = userSlice.actions;

export const selectIsLoggedIn = (state: RootState) => state.user.isLoggedIn; // Select isLoggedIn state
export const selectUser = (state: RootState) => state.user.details; // Select user details state
export const selectUserStatus = (state: RootState) => state.user.status; // Select login status state
export const selectUserError = (state: RootState) => state.user.error; // Select login error state
export const selectPasswordScore = (state: RootState) => state.user.passwordScore; // Select passwordScore state
export const selectShowRecaptcha = (state: RootState) => state.user.showRecaptcha; // Select showRecaptcha state
export const selectRedirectUrl = (state: RootState) => state.user.redirectUrl; // Select redirectUrl state
export const selectIsTokenRefreshing = (state: RootState) => state.user.isTokenRefreshing; // Select isTokenRefreshing state
export const selectResumeWalk = (state: RootState) => state.user.resumeWalk; // Select resumeWalk state

export default userSlice.reducer;
