export function areSomeDefined(values: any[] | any) {
  let areDefined = false;
  if (Array.isArray(values)) {
    areDefined = values.some((value) => value !== null && value !== undefined && value !== "" && value !== false);
  } else {
    areDefined = Object.values(values).some(
      (value) => value !== null && value !== undefined && value !== "" && value !== false,
    );
  }
  return areDefined;
}
