import classNames from "classnames";
import { ITableCardColumn, ITableCardColumnCells } from "../TableCard";
import TableCardColumnCell from "./TableCardColumnCell/TableCardColumnCell";
import styles from "./TableCardColumnCell/TableCardColumnCell.module.scss";

interface ITableCardRow {
  cells: ITableCardColumnCells;
  columns: ITableCardColumn[];
  rowIndex: number;
  onRowClick?: (rowIndex: number) => void | undefined;
  isClickable?: boolean;
  rowIndexAndClassName?: { index: number; className: string };
}

/**
 * Render a row of data table cells
 * @param cells                The row cells
 * @param columns              Column data
 * @param rowIndex             The current row index
 * @param onRowClick           A function to handle row clicks
 * @param isClickable          Is the row clickable?
 * @param rowIndexAndClassName Index and className for a row with a custom class
 * @returns JSX.Element
 */
function TableCardRow({
  cells,
  columns,
  rowIndex,
  onRowClick,
  isClickable = false,
  rowIndexAndClassName,
}: ITableCardRow): JSX.Element {
  return (
    <tr
      className={classNames(
        styles.tableCardItem,
        isClickable && styles.clickable,
        rowIndexAndClassName?.index === rowIndex && rowIndexAndClassName.className, // If rowIndexAndClassName index matches the rowIndex, apply the rowIndexAndClassName style
      )}
      role="row"
      onClick={() => onRowClick && onRowClick(rowIndex)}
    >
      {cells.map(
        (
          cell,
          i, // Iterate cells
        ) => (
          <TableCardColumnCell // Render cell
            cell={cell}
            i={i}
            align={columns[i].align} // Pass column alignment
            filterType={columns[i].filterType} // Pass column filter type
            key={i}
            width={columns[i].width}
            className={columns[i]?.className}
          />
        ),
      )}
    </tr>
  );
}

export default TableCardRow;
