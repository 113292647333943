import classNames from "classnames";
import { ReactComponent as Icon } from "../../../icons/userNotified.svg";
import { IIconMime } from "../Icon";
import styles from "./IconInternal.module.scss";

/**
 * User Notified icon
 * @param size  The size of the icon
 * @param className Custom class
 * @returns JSX.Element
 */
function UserNotified({ size, className }: IIconMime): JSX.Element {
  const clx = classNames(styles.className, styles[size], className);
  return <Icon className={clx} />;
}

export default UserNotified;
