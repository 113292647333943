import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../app/api";
import { matterTagsUrl } from "../app/apiUrls";
import { cStatusType } from "../app/constants";
import { RootState } from "../app/store";

/**
 * Describes a tag in a matter
 */
export interface IMatterTag {
  id: number;
  label: string;
  value: string;
}

/**
 * Tags list keyed by parent matter ID
 */
export interface IMatterTagsEntry {
  tags: IMatterTag[];
}

/**
 * Describe the matter tags state object
 */
interface IMatterTagsState {
  entries: Record<number, IMatterTagsEntry>; // Object keyed by parent matter
  status: cStatusType; // API call status
  error?: string;
}

/**
 * Initial state
 */
const initialState: IMatterTagsState = {
  entries: [],
  status: cStatusType.Idle,
};

/**
 * Thunk for fetching tags for a single matter by ID
 */
export const postMatterTags = createAsyncThunk(
  "matters/postMatterTags",
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await api({ endpoint: matterTagsUrl, dispatch, body: { id } });
      return {
        id,
        tags: response.data,
      };
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Create the state slice
 */
export const matterTagsSlice = createSlice({
  name: "matterTags", // The name of the slice
  initialState, // Set the initialState
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postMatterTags.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postMatterTags.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postMatterTags.fulfilled, (state, action) => {
        state.entries = { ...state.entries, [action.payload.id]: action.payload.tags };
      });
  },
});

export const selectMatterTagsStatus = (state: RootState) => state.matterTags.status;
export const selectMatterTagsError = (state: RootState) => state.matterTags.error;
export const selectMatterTags = (state: RootState) => state.matterTags.entries;

export default matterTagsSlice.reducer;
