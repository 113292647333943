import { useEffect, useState } from "react";
import { IXDirection } from "../../../app/types";
import { isRequestBodyError, isUnableToViewMatterError, isWalkError } from "../../../utils/errors/errors";
import { ModalHeader, ModalTitle } from "../../Modal/Modal";
import styles from "./HandledErrorModalHeader.module.scss";

interface IHandledErrorModalTitle {
  errorCode: number;
}

/**
 * Handled error modal title
 * @param errorCode The error code being handled
 * @returns JSX.Element
 */
function HandledErrorModalHeader({ errorCode }: IHandledErrorModalTitle) {
  const [content, setContent] = useState<string | null>(null);

  useEffect(() => {
    // Set the content based on error code
    if (errorCode) {
      switch (true) {
        case isUnableToViewMatterError(errorCode):
          setContent(`You don't have access rights to
          view this Matter`);
          break;
        case isWalkError(errorCode):
          setContent("Our apologies, a problem is preventing further progress on this interview.");
          break;
        case isRequestBodyError(errorCode):
          setContent(`Incorrect url`);
          break;
        default:
          setContent("");
          break;
      }
    }
  }, [errorCode]);

  return (
    <ModalHeader justifyContent={{ base: "center" }}>
      <ModalTitle variant="h3" className={styles.title} align={IXDirection.Center}>
        {content}
      </ModalTitle>
    </ModalHeader>
  );
}
export default HandledErrorModalHeader;
