import {
  createAsyncThunk,
  createSlice,
  isAllOf,
  ListenerEffectAPI,
  ThunkDispatch,
  UnknownAction,
} from "@reduxjs/toolkit";
import { filter, findKey, reduce, uniq } from "lodash";
import React from "react";
import api from "../app/api";
import { deleteNotificationUrl, notificationsUrl, overdueNotificationsUrl, taskNotificationsUrl } from "../app/apiUrls";
import { cStatusType } from "../app/constants";
import { RootState } from "../app/store";
import { ENotificationType } from "../pages/NotificationsContainer/NotificationsContainer";
import { errorToast } from "../toast/toast";
import createIdList from "../utils/createIdList/createIdList";
import { IDocument, postDocumentOwnershipTransfer, postDocumentsById } from "./documentsSlice";
import { postMatterDocumentsByDocumentId } from "./matterDocumentsSlice";
import { postMattersById } from "./mattersSlice";
import { postUsersByID } from "./usersSlice";

/**
 * Possible Notification types (Related Transaction)
 */
export enum ENotificationGroupingType {
  Matter = "matter",
}

/**
 * Possible reasons for invalidated notifications
 */
export enum EInvalidatedReasonType {
  Superseded = "S",
  DocDeleted = "D",
  DocTerminated = "T",
  DocTransferred = "X",
  MatterDeleted = "E",
}

/**
 * Possible status of a notification
 */
export enum ENotificationStatusType {
  Invalidated = "I",
  Read = "R",
  Unread = "U",
}

export interface ITransactionType {
  id: number;
  canViewPrivilege?: boolean; // Does the user have rights to view this document?
  groupingType?: ENotificationGroupingType | string | null; // Grouping object for document
  groupingID?: number | null; // Grouping ID, typically matter ID
  matterID?: number;
  assignedTo?: number;
}

/**
 * Possible warning levels for a notification
 */
export enum ENotificationWarningLevel {
  Pending = "pending",
  WarningLow = "low",
  WarningMedium = "medium",
  WarningHigh = "high",
  Expired = "expired",
  Task = "task",
}

/**
 * Interface for a single notifications entry
 * @param id                  Notification ID
 * @param description         Notification description
 * @param deadlineDTM         Deadline date (this is the event date)
 * @param status              The status of the notification
 * @param currentState        Current warning level
 * @param invalidCode         Reason for invalidation
 * @param remainingDays       Days to go until or days passed since the deadline
 * @param transaction         Detail of the related transaction
 * @param canViewPrivilege    Does the user have rights to view the related transaction?
 * @param documentDescription Description of the related document
 * @param documentType        Type of the related document
 * @param matterType          Type of the related matter
 * @param matterDescription   Description of the related matter
 * @param urn                 URN of the related document
 * @param document            The document related to the notification
 * @param assignedTo          The user ID of the assignee
 * @returns JSX.Element
 */
export interface INotificationsEntry {
  notificationType?: ENotificationType;
  id: number;
  description: string;
  deadlineDTM: Date;
  status: ENotificationStatusType;
  currentState?: ENotificationWarningLevel;
  remainingDays?: string | number | React.ReactNode;
  transaction?: {
    document?: ITransactionType;
    matter?: ITransactionType;
    documentWithinMatter?: ITransactionType;
    interviewSpawn?: ITransactionType;
  };
  invalidCode?: EInvalidatedReasonType | string;
  documentDescription?: string | React.ReactNode;
  matterDescription?: string | React.ReactNode;
  documentType?: string | React.ReactNode;
  matterType?: string | React.ReactNode;
  urn?: number;
  document?: IDocument;
}

/**
 * Describe notifications
 * @param pendingEntries           Array of pending notifications
 * @param overdueEntries           Array of overdue notifications
 * @param taskEntries              Array of task notifications
 * @param inViewPendingRef         Ref for pending notifications
 * @param inViewOverdueRef         Ref for overdue notifications
 * @param inViewTaskRef            Ref for task notifications
 * @param handleDeleteNotification Delete a notification
 * @returns JSX.Element
 */
export interface INotifications {
  pendingEntries: INotificationsEntry[];
  overdueEntries: INotificationsEntry[];
  taskEntries: INotificationsEntry[];
  inViewPendingRef: (node?: Element | null | undefined, current?: number) => void;
  inViewOverdueRef: (node?: Element | null | undefined, current?: number) => void;
  inViewTaskRef: (node?: Element | null | undefined, current?: number) => void;
  handleDeleteNotification: (id: number, notificationType: ENotificationType) => void;
}

/**
 * Describe the notifications state object
 * @returns JSX.Element
 */
export interface INotificationsState {
  pendingEntries: Record<string, INotificationsEntry>; // Array of pending notifications
  overdueEntries: Record<string, INotificationsEntry>; // Array of overdue notifications
  taskEntries: Record<string, INotificationsEntry>; // Array of task notifications
  pendingEntriesLoadingStatus: cStatusType; // API call status for pending notifications
  overdueLoadingStatus: cStatusType; // API call status for overdue notifications
  taskEntriesLoadingStatus: cStatusType; // API call status for task notifications
  fetched: boolean; // Have entries been fetched?
  pendingEntriesPage: number;
  overdueEntriesPage: number;
  taskEntriesPage: number;
  pendingError?: string;
  overdueError?: string;
  taskError?: string;
  deleteLoadingStatus?: cStatusType;
}

/**
 * Initial state of the object
 */
const initialState: INotificationsState = {
  pendingEntries: {},
  overdueEntries: {},
  taskEntries: {},
  pendingEntriesLoadingStatus: cStatusType.Idle,
  overdueLoadingStatus: cStatusType.Idle,
  taskEntriesLoadingStatus: cStatusType.Idle,
  fetched: false,
  pendingEntriesPage: 1,
  overdueEntriesPage: 1,
  taskEntriesPage: 1,
};

/**
 * Thunk for fetching notifications
 */
export const getPendingNotification = createAsyncThunk(
  "notification/getPendingNotification",
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      const endpoint = notificationsUrl;

      const {
        notifications: { pendingEntriesPage },
      } = getState() as RootState;

      const body: { page: number } = {
        page: pendingEntriesPage,
      };
      const response = await api({ endpoint, dispatch, body });
      return createIdList(response.data.notifications);
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching overdue notifications
 */
export const getOverdueNotification = createAsyncThunk(
  "notification/getOverdueNotification",
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      const endpoint = overdueNotificationsUrl;

      const {
        notifications: { overdueEntriesPage },
      } = getState() as RootState;

      const body: { page: number } = {
        page: overdueEntriesPage,
      };
      const response = await api({ endpoint, dispatch, body });
      return createIdList(response.data.notifications);
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching task notifications
 */
export const getTaskNotification = createAsyncThunk(
  "notification/getTaskNotification",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = taskNotificationsUrl;

      const response = await api({ endpoint, dispatch, method: "GET" });
      return createIdList(response.data.notifications);
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for deleting a notification
 */
export const postDeleteNotification = createAsyncThunk(
  "notification/postDeleteNotification",
  async (
    { notificationID, notificationType }: { notificationID: number; notificationType: ENotificationType },
    { dispatch, rejectWithValue },
  ) => {
    try {
      await api({ endpoint: deleteNotificationUrl, dispatch, body: { id: notificationID } });
      return { notificationID, notificationType };
    } catch (err: any) {
      errorToast(err.message);
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Fetch the documents and matters for the notifications
 * @param _           The action
 * @param listenerApi The listener API
 * @returns void
 */
export const fetchNotificationsEffect = (
  action: any,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>,
) => {
  const { dispatch, getState, cancelActiveListeners } = listenerApi;
  cancelActiveListeners();
  const state = getState() as RootState;
  const {
    notifications: { overdueEntries, pendingEntries },
  } = state;

  const notifications = action.type === getOverdueNotification.fulfilled.type ? overdueEntries : pendingEntries;

  if (notifications) {
    // Documents
    const filteredDocumentNotifications = filter(
      notifications,
      (notification) =>
        notification.transaction?.document?.canViewPrivilege === true &&
        notification.transaction?.document?.groupingType !== "matter",
    );
    // Documents within matters
    const filteredMatterDocumentsNotifications = filter(
      notifications,
      (notification) =>
        notification.transaction?.document?.canViewPrivilege === true &&
        notification.transaction?.document?.groupingType === "matter",
    );
    // Matters
    const filteredMatterNotifications = filter(
      notifications,
      (notification) => notification.transaction?.matter?.canViewPrivilege === true,
    );

    // Get unique IDs for Documents
    const uniqDocIds = uniq(
      reduce(
        filteredDocumentNotifications,
        (acc: number[], notification) => {
          if (notification.transaction?.document?.id) {
            acc.push(notification.transaction.document.id);
          }

          return acc;
        },
        [],
      ),
    );

    // Get unique IDs for matters that contain documents
    const uniqMatterDocIds = uniq(
      reduce(
        filteredMatterDocumentsNotifications,
        (acc: number[], notification) => {
          if (notification.transaction?.document?.id) {
            acc.push(notification.transaction.document.id);
          }

          return acc;
        },
        [],
      ),
    );
    // Get unique IDs for Matters
    const uniqMatterIds = uniq(
      reduce(
        filteredMatterNotifications,
        (acc: number[], notification) => {
          if (notification.transaction?.matter?.id) {
            acc.push(notification.transaction.matter.id);
          }

          return acc;
        },
        [],
      ),
    );

    if (uniqDocIds.length > 0) {
      dispatch(postDocumentsById({ ids: uniqDocIds }));
    }

    if (uniqMatterIds.length > 0) {
      dispatch(postMattersById({ ids: uniqMatterIds }));
    }

    if (uniqMatterDocIds.length > 0) {
      dispatch(postMatterDocumentsByDocumentId(uniqMatterDocIds));
    }
  }
};

/**
 * Fetch the matters for task notifications
 * @param _           The action
 * @param listenerApi The listener API
 * @returns void
 */
export const fetchTaskNotificationsEffect = (
  action: any,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>,
) => {
  const { dispatch, getState } = listenerApi;
  const state = getState() as RootState;
  const {
    notifications: { taskEntries },
  } = state;

  if (taskEntries) {
    // Get unique IDs for Matters
    const uniqMatterIds = uniq(
      reduce(
        taskEntries,
        (acc: number[], notification) => {
          if (notification.transaction?.interviewSpawn?.matterID) {
            acc.push(notification.transaction.interviewSpawn.matterID);
          }

          return acc;
        },
        [],
      ),
    );

    if (uniqMatterIds.length > 0) {
      dispatch(postMattersById({ ids: uniqMatterIds }));
    }

    // Get unique IDs for Assignees
    const uniqUserIds = uniq(
      reduce(
        taskEntries,
        (acc: number[], notification) => {
          if (notification.transaction?.interviewSpawn?.assignedTo) {
            acc.push(notification.transaction.interviewSpawn.assignedTo);
          }

          return acc;
        },
        [],
      ),
    );

    if (uniqUserIds.length > 0) {
      dispatch(
        postUsersByID({
          ids: uniqUserIds,
        }),
      );
    }
  }
};

// Create reducer for the notificationsSlice
export const notificationsSlice = createSlice({
  name: "notifications", // The name of the slice
  initialState, // Set the initialState
  reducers: {
    // Reset the pending notifications state
    resetPendingNotificationsState: (state) => {
      state.pendingEntriesPage = 1;
      state.pendingEntries = {};
      state.pendingError = undefined;
    },
    // Reset the overdue notifications state
    resetOverdueNotificationsState: (state) => {
      state.overdueEntriesPage = 1;
      state.overdueEntries = {};
      state.overdueError = undefined;
    },
    // Reset the task notifications state
    resetTaskNotificationsState: (state) => {
      state.taskEntriesPage = 1;
      state.taskEntries = {};
      state.taskError = undefined;
    },
  },
  extraReducers: (builder) => {
    builder

      // Pending notifications

      // Set status to loading when the promise is pending
      .addCase(getPendingNotification.pending, (state) => {
        state.pendingEntriesLoadingStatus = cStatusType.Loading;
        state.pendingError = undefined;
      })

      // Set status to failed if the promise is rejected
      .addCase(getPendingNotification.rejected, (state, action) => {
        state.pendingEntriesLoadingStatus = cStatusType.Failed;
        state.pendingError = action.payload as string;
      })
      // Set status to succeeded if the promise is fulfilled
      .addCase(getPendingNotification.fulfilled, (state, action) => {
        state.pendingEntriesLoadingStatus = cStatusType.Idle;
        state.pendingEntries = { ...state.pendingEntries, ...action.payload };
        state.pendingEntriesPage = state.pendingEntriesPage + 1;
        state.fetched = true; // Set fetched to true
      })

      // Overdue notifications

      // Set status to loading when the promise is pending
      .addCase(getOverdueNotification.pending, (state) => {
        state.overdueLoadingStatus = cStatusType.Loading;
        state.overdueError = undefined;
      })
      // Set status to failed if the promise is rejected
      .addCase(getOverdueNotification.rejected, (state, action) => {
        state.overdueLoadingStatus = cStatusType.Failed;
        state.overdueError = action.payload as string;
      })
      // Set status to succeeded if the promise is fulfilled
      .addCase(getOverdueNotification.fulfilled, (state, action) => {
        state.overdueLoadingStatus = cStatusType.Idle;
        state.overdueEntries = { ...state.overdueEntries, ...action.payload };
        state.overdueEntriesPage = state.overdueEntriesPage + 1;
        state.fetched = true; // Set fetched to true
      })

      // Task notifications

      // Set status to loading when the promise is pending
      .addCase(getTaskNotification.pending, (state) => {
        state.taskEntriesLoadingStatus = cStatusType.Loading;
        state.taskError = undefined;
      })
      // Set status to failed if the promise is rejected
      .addCase(getTaskNotification.rejected, (state, action) => {
        state.taskEntriesLoadingStatus = cStatusType.Failed;
        state.pendingError = action.payload as string;
      })
      // Set status to succeeded if the promise is fulfilled
      .addCase(getTaskNotification.fulfilled, (state, action) => {
        state.taskEntriesLoadingStatus = cStatusType.Idle;
        state.taskEntries = { ...state.taskEntries, ...action.payload };
        state.taskEntriesPage = state.taskEntriesPage + 1;
        state.fetched = true; // Set fetched to true
      })
      // Set status to loading when the promise is pending
      .addCase(postDeleteNotification.pending, (state) => {
        state.deleteLoadingStatus = cStatusType.Loading;
      })
      // Set status to failed if the promise is rejected
      .addCase(postDeleteNotification.rejected, (state) => {
        state.deleteLoadingStatus = cStatusType.Failed;
      })
      // Set status to succeeded if the promise is fulfilled
      .addCase(postDeleteNotification.fulfilled, (state, action) => {
        const { notificationID, notificationType } = action.payload;
        let filteredNotifications;
        let idListNotifications;
        if (notificationType === ENotificationType.Pending) {
          filteredNotifications = filter(state.pendingEntries, (entry) => entry.id !== notificationID);
          idListNotifications = createIdList(filteredNotifications);
          state.pendingEntries = idListNotifications;
        } else if (notificationType === ENotificationType.Task) {
          filteredNotifications = filter(state.taskEntries, (entry) => entry.id !== notificationID);
          idListNotifications = createIdList(filteredNotifications);
          state.taskEntries = idListNotifications;
        } else {
          filteredNotifications = filter(state.overdueEntries, (entry) => entry.id !== notificationID);
          idListNotifications = createIdList(filteredNotifications);
          state.overdueEntries = idListNotifications;
        }
        state.deleteLoadingStatus = cStatusType.Idle;
      })
      .addMatcher(isAllOf(postDocumentOwnershipTransfer.fulfilled), (state, action) => {
        /**
         * Find a notification ID from a document or matter record ID
         * @param entries Pending, overdue or task entries
         * @param recordId The ID of the record attached to the notification
         * @returns number
         */
        function findNotificationId(entries: Record<string, INotificationsEntry>, recordId: number) {
          return Number(
            findKey(entries, (entry) => {
              if (entry.transaction?.hasOwnProperty("document")) {
                return entry.transaction?.document?.id === recordId;
              } else if (entry.transaction?.interviewSpawn) {
                return entry.transaction?.interviewSpawn?.id === recordId;
              } else {
                return entry.transaction?.matter?.id === recordId;
              }
            }),
          );
        }

        // If the user loses privileges to the document or matter, update the canViewPrivilege in the
        // notification to false
        if (action.payload.hasOwnProperty("remove")) {
          const pendingEntryTarget = findNotificationId(state.pendingEntries, action.payload.remove);
          const overdueEntryTarget = findNotificationId(state.overdueEntries, action.payload.remove);
          const taskEntryTarget = findNotificationId(state.taskEntries, action.payload.remove);

          if (pendingEntryTarget) {
            state.pendingEntries[pendingEntryTarget].transaction!.document!.canViewPrivilege = false;
          }

          if (overdueEntryTarget) {
            state.overdueEntries[overdueEntryTarget].transaction!.document!.canViewPrivilege = false;
          }

          if (taskEntryTarget) {
            state.taskEntries[taskEntryTarget].transaction!.interviewSpawn!.canViewPrivilege = false;
          }
        }
      });
  },
});

export const { resetPendingNotificationsState } = notificationsSlice.actions;
export const { resetOverdueNotificationsState } = notificationsSlice.actions;
export const { resetTaskNotificationsState } = notificationsSlice.actions;
export const selectPendingEntriesLoadingStatus = (state: RootState) => state.notifications.pendingEntriesLoadingStatus;
export const selectOverdueLoadingStatus = (state: RootState) => state.notifications.overdueLoadingStatus;
export const selectTaskEntriesLoadingStatus = (state: RootState) => state.notifications.taskEntriesLoadingStatus;
export const selectPendingNotifications = (state: RootState) => state.notifications.pendingEntries;
export const selectOverdueNotifications = (state: RootState) => state.notifications.overdueEntries;
export const selectTaskNotifications = (state: RootState) => state.notifications.taskEntries;
export const selectPendingNotificationsError = (state: RootState) => state.notifications.pendingEntries.pendingEror;
export const selectOverdueNotificationsError = (state: RootState) => state.notifications.overdueEntries.overdueEror;
export const selectTaskNotificationsError = (state: RootState) => state.notifications.taskEntries.taskEror;
export const selectDeleteLoadingStatus = (state: RootState) => state.notifications.deleteLoadingStatus;
export default notificationsSlice.reducer;
