import { useState } from "react";
import { Control, SubmitHandler, useForm } from "react-hook-form";
import { cStatusType, cThemeColorType } from "../../../../../app/constants";
import Button, { cButtonType } from "../../../../../components/Button/Button";
import DiscardChangesModal from "../../../../../components/DiscardChangesModal/DiscardChangesModal";
import DisplayError from "../../../../../components/DisplayError/DisplayError";
import Div from "../../../../../components/Div/Div";
import FormField, { cFormFieldType, IFieldOption } from "../../../../../components/FormField/FormField";
import Label from "../../../../../components/FormField/Label/Label";
import InfoBox, { EInfoBoxVariant } from "../../../../../components/InfoBox/InfoBox";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../../components/Modal/Modal";
import Typography from "../../../../../components/Typography/Typography";
import { IUser } from "../../../../../modules/usersSlice";
import { areSomeDefined } from "../../../../../utils/areSomeDefined/areSomeDefined";

type Inputs = {
  user: IFieldOption | null;
};

const defaultValues = {
  user: null,
};

interface IDeleteUserModal {
  isOpen: boolean;
  handleClose: (canDisable: boolean, replacementID?: number) => void;
  user?: IUser;
  replacementUserOptions: IFieldOption[];
  error?: string;
  status: cStatusType;
  replacementUsersStatus: cStatusType;
}

/**
 * A modal to confirm if the admin wants to delete a user
 * @param isOpen                 Is the modal open?
 * @param handleClose            Function to close the modal
 * @param user                   The user being deleted
 * @param replacementUserOptions The replacement user options
 * @param error                  The error message
 * @param status                 The status of the delete user request
 * @param replacementUsersStatus The status of the replacement users request
 * @returns JSX.Element
 */
function DeleteUserModal({
  isOpen,
  handleClose,
  user,
  replacementUserOptions,
  error,
  status,
  replacementUsersStatus,
}: IDeleteUserModal) {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<Inputs>({ defaultValues });

  const [isDiscardChangesModalOpen, setIsDiscardChangesModalOpen] = useState(false);

  /**
   * Handles DeleteUserModal close
   */
  function handleDeleteUserModalClose() {
    if (areSomeDefined(watch())) {
      setIsDiscardChangesModalOpen(true);
    } else {
      handleClose(false);
      reset();
    }
  }

  /**
   * Handles DiscardChangesModal close
   */
  function handleDiscardChangesModalClose(canDiscard: boolean) {
    if (canDiscard) {
      handleClose(false);
      reset();
    }
    setIsDiscardChangesModalOpen(false);
  }

  /**
   * Submit the form
   * @param data The form values on submit
   */
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    handleClose(true, data.user?.value as number);
    reset();
  };

  return (
    <>
      <Modal isOpen={isOpen} handleClose={() => handleClose(false)} size="lg" testId="delete-user-modal">
        <ModalHeader>
          <ModalTitle>Delete User</ModalTitle>
        </ModalHeader>
        <ModalContent>
          <Typography spacing={{ mb: 5 }}>
            Are you sure you want to delete the user{" "}
            <b>
              {user?.firstName} {user?.lastName}
            </b>{" "}
            (<b>{user?.loginName}</b>)?
          </Typography>
          <Div spacing={{ mb: 5 }}>
            <InfoBox variant={EInfoBoxVariant.Warning}>
              <ul>
                <li>This user will be irrevocably deleted.</li>
                <li>
                  Ownership of the user's documents, matters and embed links will transfer to the replacement user.
                </li>
                <li>The user's in-progress interview will be discarded.</li>
                <li>Future notifications to this user will instead go to the replacement user.</li>
                {user?.isTeamLead && <li>Any tasks assigned to this user will be reassigned.</li>}
              </ul>
            </InfoBox>
          </Div>
          <Div spacing={{ mt: 5 }}>
            <form id="deleteUserForm" onSubmit={handleSubmit(onSubmit)}>
              <Label>Select a replacement user</Label>
              <Typography variant="small" fontStyle="italic">
                Only users belonging to the same security groups as {user?.firstName} can be used as replacements and
                are listed here:
              </Typography>
              <FormField
                name="user"
                type={cFormFieldType.Select}
                register={register}
                options={
                  replacementUsersStatus === cStatusType.Loading
                    ? [{ label: "Loading...", value: "" }]
                    : replacementUserOptions
                }
                error={errors.user as any}
                control={control as Control<any, any>}
                required
                spacing={{ mt: 3, mb: 5 }}
              />
            </form>
            {error && <DisplayError>{error}</DisplayError>}
          </Div>
        </ModalContent>
        <ModalActions>
          <Button
            color={cThemeColorType.Secondary}
            onClick={handleDeleteUserModalClose}
            testId="delete-user-modal-replacement-cancel-button"
            disabled={status === cStatusType.Loading}
          >
            Cancel
          </Button>
          <Button
            color={cThemeColorType.Danger}
            formId="deleteUserForm"
            type={cButtonType.SubmitType}
            isLoading={status === cStatusType.Loading}
          >
            Delete
          </Button>
        </ModalActions>
      </Modal>
      <DiscardChangesModal
        isOpen={isDiscardChangesModalOpen}
        handleClose={(canDiscard) => handleDiscardChangesModalClose(canDiscard)}
      />
    </>
  );
}

export default DeleteUserModal;
