import { useForm } from "react-hook-form";
import Container from "../../../components/Grid/Container/Container";
import { INotifications, INotificationsEntry, INotificationsState } from "../../../modules/notificationsSlice";
import ListLayout from "./ListLayout/ListLayout";
import styles from "./Notifications.module.scss";

/**
 * Notification entries
 * @param pendingEntries              The pending notification entries
 * @param overdueEntries              The overdue notification entries
 * @param inViewPendingRef            React intersection observer ref for pending notifications
 * @param inViewOverdueRef            React intersection observer ref for overdue notifications
 * @param pendingEntriesLoadingStatus Status of pending notifications
 * @param pendingError                Pending notification error message
 * @param overdueError                Overdue notification error message
 * @param pendingEntriesEmptyType     Type of empty state
 * @param overdueEntriesEmptyType     Type of empty state
 * @param overdueLoadingStatus        Status of overdue notifications
 * @param transaction                 The related transaction
 * @param handleDeleteNotification    Function to delete the notification
 * @returns JSX.Element
 */
function Notifications({
  pendingEntries,
  overdueEntries,
  taskEntries,
  inViewPendingRef,
  inViewOverdueRef,
  inViewTaskRef,
  pendingEntriesLoadingStatus,
  taskEntriesLoadingStatus,
  pendingError,
  overdueError,
  taskError,
  overdueLoadingStatus,
  handleDeleteNotification,
}: INotifications &
  Pick<
    INotificationsState,
    | "pendingEntriesLoadingStatus"
    | "overdueLoadingStatus"
    | "taskEntriesLoadingStatus"
    | "pendingError"
    | "overdueError"
    | "taskError"
  > &
  Pick<
    INotificationsState,
    | "pendingEntriesLoadingStatus"
    | "overdueLoadingStatus"
    | "pendingError"
    | "overdueError"
    | "taskEntriesLoadingStatus"
    | "taskError"
  > &
  Pick<INotificationsEntry, "transaction">): JSX.Element {
  const { register } = useForm<any>();

  return (
    <Container width="fluid" className={styles.pageContent} fullViewportHeight>
      <ListLayout
        pendingEntries={pendingEntries}
        overdueEntries={overdueEntries}
        taskEntries={taskEntries}
        register={register}
        inViewPendingRef={inViewPendingRef}
        inViewOverdueRef={inViewOverdueRef}
        inViewTaskRef={inViewTaskRef}
        pendingEntriesLoadingStatus={pendingEntriesLoadingStatus}
        overdueLoadingStatus={overdueLoadingStatus}
        taskEntriesLoadingStatus={taskEntriesLoadingStatus}
        pendingError={pendingError}
        overdueError={overdueError}
        taskError={taskError}
        handleDeleteNotification={handleDeleteNotification}
      />
    </Container>
  );
}

export default Notifications;
