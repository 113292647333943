import classNames from "classnames";
import { differenceInCalendarDays, format, parse } from "date-fns";
import { forOwn, some } from "lodash";
import React from "react";
import { cAccessType, cMonoColorType, cSizeType, cThemeColorType } from "../../app/constants";
import { EPopoverPlacement } from "../../app/types";
import { ETimelineEntryType, ITimelineEvent, ITimelineFile, TTimeline } from "../../modules/documentsSlice";
import NotificationIcon from "../../pages/WalkContainer/Walk/NotificationIcon/NotificationIcon";
import Button, { EButtonVariant } from "../Button/Button";
import Div from "../Div/Div";
import { EIcon } from "../Icon/Icon";
import IconFromMimeType from "../IconFromMimeType/IconFromMimeType";
import Popover from "../Popover/Popover";
import Column from "../Row/Column/Column";
import Row from "../Row/Row";
import TableCard from "../TableCard/TableCard";
import tableStyles from "../TableCard/TableCardRow/TableCardColumnCell/TableCardColumnCell.module.scss";
import Typography from "../Typography/Typography";
import ESignButton from "./ESignButton/ESignButton";
import styles from "./TimelineEvents.module.scss";

/**
 * Document or matter timeline
 */
export enum ETimelineType {
  Document = "document",
  Matter = "matter",
}

interface ITimelineEvents {
  timeline: TTimeline[];
  showSigned: boolean;
  handleDeleteEvent: (eventId: number) => void;
  handleFileDownload: (fileId: number) => void;
  handleInterviewLog: (walkID: number) => void;
  handleESignEvent: (type: ETimelineEntryType, urn?: number, esignID?: number) => void;
  timelineType?: ETimelineType;
}

/**
 * Timeline Events component
 * @param timeline                The document timeline
 * @param showSigned              Filter by signed only
 * @param handleDeleteEvent       Function to delete a timeline event
 * @param handleFileDownload      Function to handle the file download
 * @param handleESignEvent        Function to handle starting a new signature process
 * @param timelineType            Document or matter timeline
 * @returns JXS.Element
 */
function TimelineEvents({
  timeline,
  showSigned,
  handleDeleteEvent,
  handleFileDownload,
  handleInterviewLog,
  handleESignEvent,
  timelineType = ETimelineType.Document,
}: ITimelineEvents) {
  /**
   * Renders the type icon
   * @param type The document timeline type
   * @returns JSX.Element
   */
  function renderTypeIcon(type: ETimelineEntryType) {
    switch (type) {
      case ETimelineEntryType.Assigned:
        return EIcon.Reassign;
      case ETimelineEntryType.Create:
        return EIcon.Creation;
      case ETimelineEntryType.Description:
        return EIcon.Rename;
      case ETimelineEntryType.Discard:
        return EIcon.Delete;
      case ETimelineEntryType.CustomEvent:
        return EIcon.UserGeneratedEvent;
      case ETimelineEntryType.InterviewEvent:
        return EIcon.SystemGeneratedEvent;
      case ETimelineEntryType.Milestone:
        return EIcon.Milestone;
      case ETimelineEntryType.Note:
        return EIcon.Note;
      case ETimelineEntryType.Pause:
        return EIcon.Pause;
      case ETimelineEntryType.StatusChange:
        return EIcon.UpdateStatus;
      case ETimelineEntryType.SupportingDocument:
        return EIcon.Attachment;
      case ETimelineEntryType.TransferOwner:
        return EIcon.TransferOwnership;
      case ETimelineEntryType.TransferWIP:
        return EIcon.TransferOwnership;
      case ETimelineEntryType.VersionData:
        return EIcon.UpdateDocument;
      case ETimelineEntryType.VersionDraft:
        return EIcon.UpdateDocument;
      default:
        return EIcon.UploadDocument; // versionFile
    }
  }

  /**
   * Renders the type hover text
   * @param type        The document timeline type
   * @param createdDate The event created date
   * @returns JSX.Element
   */
  function renderTypeHoverText(type: ETimelineEntryType, createdDate: string) {
    switch (type) {
      case ETimelineEntryType.Assigned:
        return "Assigned";
      case ETimelineEntryType.Create:
        return "Creation";
      case ETimelineEntryType.Description:
        return "Description changed";
      case ETimelineEntryType.Discard:
        return "Document Version creation abandoned";
      case ETimelineEntryType.CustomEvent:
        return `Custom Event added: ${format(new Date(createdDate), "EE d LLL yyyy, h:mmaaa ")}`;
      case ETimelineEntryType.InterviewEvent:
        return "System Event";
      case ETimelineEntryType.Milestone:
        return `Milestone Change: ${format(new Date(createdDate), "EE d LLL yyyy, h:mmaaa")}`;
      case ETimelineEntryType.Note:
        return "Note";
      case ETimelineEntryType.Pause:
        return "Document Version creation paused";
      case ETimelineEntryType.StatusChange:
        return `Status Change: ${format(new Date(createdDate), "EE d LLL yyyy, h:mmaaa")}`;
      case ETimelineEntryType.SupportingDocument:
        return "Supporting document";
      case ETimelineEntryType.TransferOwner:
        return "Transfer of Ownership";
      case ETimelineEntryType.TransferWIP:
        return "In-progress version transferred";
      case ETimelineEntryType.VersionData:
        return "Data changed only";
      case ETimelineEntryType.VersionDraft:
        return "Document Version (drafted)";
      default:
        return "Document Version (filed)"; // versionFile
    }
  }

  /**
   * Renders the timeline files
   * @param files      The timeline files
   * @param signedDate Date of signature
   * @returns JSX.Element
   */
  function renderFiles(files?: { [key: string]: ITimelineFile }, signedDate?: string) {
    const rendered: any[] = [];

    if (files) {
      forOwn(files, (file) => {
        rendered.push(
          // If there is a signedDate, add a tooltip to display it on hover
          file.isSigned && signedDate ? (
            <Popover // Create the popover and button
              variant="tooltip"
              popoverPlacement={EPopoverPlacement.Top}
              width="auto"
              popoverContents={
                <Div>{`${file.name} signed on ${format(
                  parse(signedDate || "", "yyyy-MM-dd", new Date()),
                  "dd MMM yyyy",
                )}`}</Div>
              }
              buttonContents={
                <IconFromMimeType fileType={file.type} size={cSizeType.Medium} isSigned={file.isSigned} />
              }
              buttonProps={{
                variant: EButtonVariant.Square,
                color: cThemeColorType.Secondary,
                spacing: { p: 0, mr: 4 },
                onClick: () => handleFileDownload(file.id),
              }}
            />
          ) : (
            <Button
              variant={EButtonVariant.Square}
              color={cThemeColorType.Secondary}
              spacing={{ p: 0, mr: 4 }}
              onClick={() => handleFileDownload(file.id)}
            >
              <IconFromMimeType fileType={file.type} size={cSizeType.Medium} isSigned={file.isSigned} />
            </Button>
          ),
        );
      });
    }

    return (
      <Div className={styles.files}>
        {rendered.map((file, index) => (
          <React.Fragment key={`${index}${file.type}`}>{file}</React.Fragment>
        ))}
      </Div>
    );
  }

  /**
   * Returns true if delete event is available
   * @param type   ETimelineEntryType
   * @param event  ITimelineEvent
   * @param action Timeline acton
   * @returns boolean
   */
  function canDeleteEvent(
    type: ETimelineEntryType,
    event: ITimelineEvent | undefined,
    action?: { [key: string]: { state: cAccessType } },
  ) {
    return (
      type === ETimelineEntryType.CustomEvent && event?.customEventID && action?.delete?.state === cAccessType.Enabled
    );
  }

  /**
   * Returns true if esign is available
   * @param type   ETimelineEntryType
   * @param action Timeline acton
   * @returns boolean
   */
  function canStartESign(type: ETimelineEntryType, action?: { [key: string]: { state: cAccessType; id?: number } }) {
    return (
      ((type === ETimelineEntryType.SupportingDocument ||
        type === ETimelineEntryType.VersionDraft ||
        type === ETimelineEntryType.VersionFile) &&
        action?.esign?.state === cAccessType.Enabled) ||
      action?.esign?.state === cAccessType.Denied
    );
  }

  /**
   * Create table columns:
   * Set the width property of each column via consts, or use any px or % values.
   * Sizing rule: Since % and px values don't mix well (columns will overlap), use calc() to combine them.
   */
  const typeCol = "60px";
  const dateCol = "120px";
  const notifiedCol = "90px";
  const actionsCol = "90px";
  const widestCol = "100%"; // Takes up all the remaining space
  const remainingCols = "2"; // The total # of columns excluding 'typeCol', 'dateCol', 'notifiedCol' and 'actionsCol', or any other px values (See 'Sizing rule' above)

  const columns = [
    {
      heading: "Type" as const,
      className: styles.center,
      width: typeCol,
    },
    {
      heading: "Date",
      width: dateCol,
    },
    {
      heading: "Detail",
      width: widestCol,
    },
    {
      heading: "User",
      width: `calc(100% / ${remainingCols}`,
    },
    {
      heading: "Notified",
      width: notifiedCol,
      className: styles.center,
    },
    {
      heading: "Actions",
      width: actionsCol,
      className: styles.center,
    },
  ];

  // Add files and urn columns if this is a document timeline
  if (timelineType === ETimelineType.Document) {
    columns.splice(
      3,
      0,
      ...[
        {
          heading: "Files",
          width: `calc(100% / ${remainingCols}`,
        },
        {
          heading: "Urn",
          width: `calc(100% / ${remainingCols}`,
        },
      ],
    );
  }

  const rows = timeline
    .slice()
    .filter((row) => {
      if (!showSigned) {
        return row;
      } else {
        if (!row.files) {
          return false;
        }
        return some(row.files, (file) => file.isSigned);
      }
    })
    .map(({ type, common, interviewLog, userName, event, action, files, urn, signedDate }) => {
      // Calculate the common date's difference in days
      let differenceInDaysCalc;
      if (common.date) {
        differenceInDaysCalc = differenceInCalendarDays(new Date(common.date), new Date());
      } else {
        differenceInDaysCalc = 0;
      }
      // Change the display text based on the difference in days
      let differenceInDays;
      switch (differenceInDaysCalc) {
        case 0:
          differenceInDays = `${format(new Date(common.date), "EEE dd MMM yyyy")} (Today)`;
          break;
        case 1:
          differenceInDays = `${format(new Date(common.date), "EEE dd MMM yyyy")} (Tomorrow)`;
          break;
        case -1:
          differenceInDays = `${format(new Date(common.date), "EEE dd MMM yyyy")} (yesterday)`;
          break;
        default:
          if (differenceInDaysCalc > 1) {
            differenceInDays = `${format(new Date(common.date), "EEE dd MMM yyyy")} (in ${differenceInDaysCalc} days)`;
          } else if (differenceInDaysCalc < 0) {
            differenceInDaysCalc = differenceInDaysCalc * -1; // show past date as a positive integer
            differenceInDays = `${format(new Date(common.date), "EEE dd MMM yyyy")} (${differenceInDaysCalc} days ago)`;
          }
          break;
      }
      const row = [
        <>
          <Div className={styles.group} testId="event-type">
            <Div display={{ base: "block", md: "none" }}>
              <Typography variant="small" color={cMonoColorType.Light}>
                Type:&nbsp;
              </Typography>
            </Div>
            <Popover // Create the popover and button
              variant="tooltip"
              popoverPlacement={EPopoverPlacement.Top}
              width="auto"
              popoverContents={renderTypeHoverText(type, common.createdDate)}
              divProps={{
                icon: renderTypeIcon(type),
              }}
            />
          </Div>
        </>,
        <>
          <Div className={styles.group}>
            <Div display={{ base: "block", md: "none" }}>
              <Typography variant="small" color={cMonoColorType.Light}>
                Date:&nbsp;
              </Typography>
            </Div>
            <Popover
              variant="tooltip"
              popoverPlacement={EPopoverPlacement.Top}
              width="auto"
              divProps={{
                text: format(new Date(common.date), "dd MMM yyyy"),
              }}
              popoverContents={
                [
                  ETimelineEntryType.CustomEvent,
                  ETimelineEntryType.InterviewEvent,
                  ETimelineEntryType.Milestone,
                ].includes(type)
                  ? differenceInDays
                  : format(new Date(common.date), "EEE dd MMM yyyy, HH:mm")
              }
            />
          </Div>
        </>,
        <>
          <Div className={styles.group}>
            <Div display={{ base: "block", md: "none" }}>
              <Typography variant="small" color={cMonoColorType.Light}>
                Detail:&nbsp;
              </Typography>
            </Div>
            <Row alignItems={{ base: "center" }}>
              <Column>
                <Typography
                  className={classNames(styles.description)}
                  fontStyle={type === ETimelineEntryType.SupportingDocument ? "italic" : "normal"}
                >
                  {common.description}
                </Typography>
              </Column>
              <Column all={12} xl={"auto"}>
                {interviewLog?.state === cAccessType.Enabled && (
                  <Div pt={{ base: 4, xl: 0 }} display={{ base: "flex" }} justifyContent={{ base: "start", xl: "end" }}>
                    <Button
                      className="interviewLogBtn"
                      testId="interview-log-button"
                      size={cSizeType.Small}
                      color={cThemeColorType.Secondary}
                      icon={EIcon.Log}
                      onClick={() => handleInterviewLog(interviewLog.walkID)}
                    >
                      Interview log
                    </Button>
                  </Div>
                )}
              </Column>
            </Row>
          </Div>
        </>,
        <>
          <Div className={styles.group}>
            <Div display={{ base: "block", md: "none" }}>
              <Typography variant="small" color={cMonoColorType.Light}>
                User:&nbsp;
              </Typography>
            </Div>
            {userName}
          </Div>
        </>,
        <>
          <Div className={styles.group}>
            <Div display={{ base: "block", md: "none" }}>
              <Typography variant="small" color={cMonoColorType.Light}>
                Notified:&nbsp;
              </Typography>
            </Div>
            {event && (
              <NotificationIcon
                isRetrospective={event.isRetrospective}
                recipients={event.recipients}
                notified={event.notified}
                notifyDate={event.notifyDate}
              />
            )}
          </Div>
        </>,
        <>
          <Div className={styles.group}>
            <Div display={{ base: "block", md: "none" }}>
              <Typography variant="small" color={cMonoColorType.Light}>
                Actions:&nbsp;
              </Typography>
            </Div>
            {canDeleteEvent(type, event, action) && (
              <Button
                testId="delete-event-button"
                variant={EButtonVariant.Square}
                icon={EIcon.Delete}
                color={cMonoColorType.Dark}
                onClick={() => handleDeleteEvent(event?.customEventID as number)}
              />
            )}
            {canStartESign(type, action) && (
              <ESignButton
                type={type}
                urn={urn}
                action={action}
                signedDate={signedDate}
                handleESignEvent={handleESignEvent}
              />
            )}
          </Div>
        </>,
      ];

      // Add files and URN columns if this is a document timeline
      if (timelineType === ETimelineType.Document) {
        row.splice(
          3,
          0,
          ...[
            <>
              <Div className={styles.group}>
                <Div display={{ base: "block", md: "none" }}>
                  <Typography variant="small" color={cMonoColorType.Light}>
                    Files:&nbsp;
                  </Typography>
                </Div>
                {renderFiles(files, signedDate)}
              </Div>
            </>,
            <>
              <Div className={styles.group}>
                <Div display={{ base: "block", md: "none" }}>
                  <Typography variant="small" color={cMonoColorType.Light}>
                    Urn:&nbsp;
                  </Typography>
                </Div>
                {urn}
              </Div>
            </>,
          ],
        );
      }

      return row;
    });

  return (
    <>
      {columns && (
        <Div className={styles.tableWrapper} testId="timeline-events">
          <TableCard
            columns={columns}
            rows={rows}
            className={tableStyles.timeline}
            variant="timeline"
            testId="timeline-events-table"
          />
        </Div>
      )}
    </>
  );
}

export default TimelineEvents;
