import { forOwn } from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { cAccessType, cThemeColorType } from "../../../../app/constants";
import { useAppDispatch } from "../../../../app/hooks";
import { EPopoverPlacement } from "../../../../app/types";
import { EButtonVariant } from "../../../../components/Button/Button";
import Icon, { EIcon } from "../../../../components/Icon/Icon";
import LinkList from "../../../../components/LinkList/LinkList";
import Popover from "../../../../components/Popover/Popover";
import { EMatterActionMenuItemKey, IMatterAction, postMatterTransferUsers } from "../../../../modules/mattersSlice";

interface IMatterActionPopover {
  matterID: number;
  setIsNewEventModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsTransferOwnershipModalOpen: Dispatch<SetStateAction<boolean>>;
  action: Record<string, IMatterAction>;
}

/**
 * Action popover for a matter
 * @param matterID                        Current matter ID
 * @param setIsNewEventModalOpen          Set open state for the add new event modal
 * @param setIsTransferOwnershipModalOpen Set open state for the transfer ownership modal
 * @param action                          The action items
 * @returns JSX.Element
 */
function MatterActionPopover({
  matterID,
  setIsNewEventModalOpen,
  setIsTransferOwnershipModalOpen,
  action,
}: IMatterActionPopover): JSX.Element {
  const dispatch = useAppDispatch();
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false); // Popover open / closed state

  // The default menu items before the access has been set
  const defaultMenuItems: any[] = [
    {
      name: EMatterActionMenuItemKey.AddEvent,
      icon: EIcon.Date,
      text: "Add Event",
      onClick: () => {
        setIsNewEventModalOpen(true);
        setIsPopoverOpen(false);
      },
      access: cAccessType.Inaccessible,
      testId: "add-event-link",
    },
    {
      name: EMatterActionMenuItemKey.TransferOwner,
      icon: EIcon.TransferOwnership,
      text: "Transfer Ownership",
      onClick: async () => {
        await dispatch(postMatterTransferUsers(matterID)).unwrap();
        setIsTransferOwnershipModalOpen(true);
        setIsPopoverOpen(false);
      },
      access: cAccessType.Inaccessible,
      testId: "transfer-ownership-link",
    },
  ];

  const [menuItems, setMenuItems] = useState(defaultMenuItems);

  /**
   * Updates the menu items access using the action object
   */
  function updateMenuItemAccess() {
    forOwn(action, (value: { state: cAccessType }, key: string) => {
      setMenuItems((prev: any[]) => {
        const newState = [...prev];
        const item = newState.find((i) => i.name === key);
        if (item) {
          item.access = value.state;
        }
        return newState;
      });
    });
  }

  useEffect(() => {
    updateMenuItemAccess();
  }, [action]);

  return (
    <Popover
      popoverPlacement={EPopoverPlacement.Bottom}
      popoverContents={<LinkList items={menuItems} />}
      buttonContents={<Icon icon={EIcon.ActionsMenu} color={cThemeColorType.Primary} />}
      buttonProps={{
        variant: EButtonVariant.Round,
        color: cThemeColorType.Secondary,
      }}
      width="320px"
      isOpen={isPopoverOpen}
      setIsOpen={setIsPopoverOpen}
    />
  );
}

export default MatterActionPopover;
