import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { clearCustomerError, selectHasCustomerSupport } from "../../modules/customerSlice";
import Div from "../Div/Div";
import SupportButton from "./SupportButton/SupportButton";
import SupportFormContainer from "./SupportFormContainer/SupportFormContainer";

/**
 * The customer support container
 * @returns JSX.Element | null
 */
function SupportContainer(): JSX.Element | null {
  const hasCustomerSupport = useAppSelector(selectHasCustomerSupport);
  const [isSupportFormOpen, setIsSupportFormOpen] = useState(false);
  const dispatch = useAppDispatch();

  /**
   * Toggles the support form open/closed
   */
  function toggleSupportForm() {
    setIsSupportFormOpen((isOpen) => !isOpen);
  }

  useEffect(() => {
    if (!isSupportFormOpen) {
      dispatch(clearCustomerError());
    }
  }, [isSupportFormOpen]);

  /**
   * Only show support if configured on
   */
  if (!hasCustomerSupport) {
    return null;
  }

  return (
    <Div testId="customer-support-container">
      {isSupportFormOpen && <SupportFormContainer handleToggle={toggleSupportForm} />}
      <SupportButton handleToggle={toggleSupportForm} />
    </Div>
  );
}

export default SupportContainer;
