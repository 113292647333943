import { lazy, Suspense } from "react";
import Spinner from "../../components/Spinner/Spinner";

const LazyWalkContainer = lazy(() => import("./WalkContainer"));

const WalkContainer = (props: JSX.IntrinsicAttributes) => (
  <Suspense fallback={<Spinner />}>
    <LazyWalkContainer {...props} />
  </Suspense>
);

export default WalkContainer;
