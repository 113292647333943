import { find } from "lodash";
import { cAccessType, cRouteType, cSizeType, cThemeColorType, WALK_RETURN_URL } from "../../../../app/constants";
import { useAppDispatch } from "../../../../app/hooks";
import { EPopoverPlacement } from "../../../../app/types";
import Button, { EButtonVariant } from "../../../../components/Button/Button";
import Icon, { EIcon } from "../../../../components/Icon/Icon";
import Popover from "../../../../components/Popover/Popover";
import {
  deleteDocument,
  IDocument,
  openInformationUpdatedModal,
  TDocumentWalkInProgress,
} from "../../../../modules/documentsSlice";
import { deleteMatterDocument } from "../../../../modules/matterDocumentsSlice";
import { EWalkAction, EWalkType, postWalkAction, updateWalkIsActive } from "../../../../modules/walkSlice";
import { isDocumentAbandonedError, isOutdatedContentError } from "../../../../utils/errors/errors";

interface IInProgressByUser {
  walkInProgress: TDocumentWalkInProgress;
  documents: Record<string, IDocument>;
  matterReferenceCode?: string;
  documentType?: string;
}

/**
 * Return a tooltip or resumeWalk for documents in progress
 * @param walkInProgress      The walk in progress object that contains info about the status
 * @param documents           The list of documents
 * @param matterReferenceCode The matter reference code (if matter document)
 * @param documentType        The document type
 * @returns JSX.Element
 */

function InProgressByUser({ walkInProgress, documents, matterReferenceCode, documentType }: IInProgressByUser) {
  const dispatch = useAppDispatch();

  /**
   * Resumes a walk
   * @param wipID          The walk in progress id
   * @param compositeState Matter document composite state for change checking
   */
  async function resumeWalk(wipID: number, compositeState?: number) {
    try {
      await dispatch(postWalkAction({ type: EWalkAction.ResumePaused, wipID, compositeState })).unwrap();
      dispatch(updateWalkIsActive(true));
      const foundDocument = find(documents, (document) => document.walkInProgress?.wipID === wipID);

      // If there is a matter reference code, set the return URL to the matter, otherwise, set it to
      // the document page
      if (matterReferenceCode) {
        localStorage.setItem(WALK_RETURN_URL, `${cRouteType.Matters}/${foundDocument?.groupingID}`);
      } else {
        localStorage.setItem(WALK_RETURN_URL, cRouteType.Document);
      }
    } catch (error) {
      const errorCode = parseInt(error as string, 10);
      // Delete the document if it has been abandonded
      if (isDocumentAbandonedError(errorCode)) {
        const foundDocument = find(documents, (document) => document.walkInProgress?.wipID === wipID);
        if (foundDocument) {
          // If there is a matter reference code, then this is a matter document
          // so delete it from that slice, otherwise delete it from the primary
          // documents slice
          if (matterReferenceCode) {
            dispatch(
              deleteMatterDocument({ documentID: foundDocument.id, matterID: foundDocument?.groupingID as number }),
            );
          } else {
            dispatch(deleteDocument(foundDocument.id.toString()));
          }
        }
      } else if (isOutdatedContentError(errorCode)) {
        dispatch(openInformationUpdatedModal());
      }
      console.error(error);
    }
  }

  // Determine the tooltip text and whether to show the resume button
  let tooltip = "Loading...";

  if (walkInProgress.state !== cAccessType.Enabled) {
    if (walkInProgress.tooltip === "Resume" && documentType) {
      tooltip = `${documentType} is currently locked for enhancement`;
    } else {
      tooltip = walkInProgress.tooltip;
    }
  }

  if (walkInProgress?.ownedByMe === true) {
    const foundDocument = find(documents, (document) => document.walkInProgress?.wipID === walkInProgress.wipID);

    // If the WIP state is not enabled, then the document type is locked for enhancement,
    // so display a disabled button with a tooltip
    if (walkInProgress.state !== cAccessType.Enabled) {
      return (
        <Popover
          variant="tooltip"
          popoverPlacement={EPopoverPlacement.Top}
          width="auto"
          popoverContents={tooltip}
          buttonContents="Resume"
          buttonProps={{
            size: cSizeType.Small,
            disabled: true,
          }}
        />
      );
    }

    // If the action type is collaboration, then display a popover button with the collaboration icon
    if (walkInProgress.action === EWalkType.Collaboration) {
      return (
        <Popover
          variant="tooltip"
          popoverPlacement={EPopoverPlacement.Top}
          width="auto"
          buttonContents={<Icon icon={EIcon.Collaboration} />}
          buttonProps={{
            variant: EButtonVariant.Square,
            onClick: (e) => {
              e.stopPropagation();
            },
            testId: "resume-collaboration-btn",
          }}
          popoverContents="Manage Collaboration"
        />
      );
    }

    // Otherwise display a regular button to resume the walk
    return (
      <Button
        size={cSizeType.Small}
        onClick={(event) => {
          event.stopPropagation();
          if (walkInProgress.wipID) {
            resumeWalk(walkInProgress.wipID, foundDocument?.compositeState);
          }
        }}
        testId="resume-button"
      >
        Resume
      </Button>
    );
  } else if (walkInProgress?.ownedByMe === false) {
    return (
      <Popover
        variant="tooltip"
        popoverPlacement={EPopoverPlacement.Top}
        width="auto"
        popoverContents={tooltip as React.ReactNode}
        divProps={{
          icon: EIcon.UserDisable,
          iconColor: cThemeColorType.Secondary,
        }}
      />
    );
  } else {
    return null;
  }
}

export default InProgressByUser;
