import classNames from "classnames";
import { cMonoColorType } from "../../../../../app/constants";
import Div from "../../../../../components/Div/Div";
import Column from "../../../../../components/Row/Column/Column";
import Typography from "../../../../../components/Typography/Typography";
import {
  EInvalidatedReasonType,
  ENotificationStatusType,
  INotificationsEntry,
} from "../../../../../modules/notificationsSlice";
import InsufficientPermissions from "../InsufficientPermissions/InsufficientPermissions";
import InvalidatedDocument from "../InvalidatedTransaction/InvalidatedTransaction";
import styles from "../ListEntry.module.scss";
import TransactionLink from "../TransactionLink/TransactionLink";

/**
 * Display compact content
 * @param transaction          Detail of the related transaction
 * @param remainingDays        Days to go until or days passed since the deadline
 * @param description          The notification description text
 * @param documentType         The document type text
 * @param matterType           The matter type text
 * @param urn                  The document URN
 * @param invalidCode          Reason for invalidation
 * @param status               The status of the notification
 * @param documentDescription  The document description
 * @param matterDescription    The matter description
 * @param document             The document related to the notification
 * @returns JSX.Element
 */
function CompactContent({
  remainingDays,
  transaction,
  description,
  documentType,
  matterType,
  urn,
  invalidCode,
  status,
  documentDescription,
  matterDescription,
  document,
}: Pick<
  INotificationsEntry,
  | "remainingDays"
  | "transaction"
  | "invalidCode"
  | "status"
  | "description"
  | "documentType"
  | "matterType"
  | "urn"
  | "documentDescription"
  | "matterDescription"
  | "document"
>): JSX.Element {
  /**
   * Get the relevant transaction link
   * @returns JSX.Element
   */
  function getTransactionLink() {
    if (
      (status !== ENotificationStatusType.Invalidated && transaction?.document?.canViewPrivilege === true) ||
      transaction?.matter?.canViewPrivilege === true
    ) {
      return (
        <TransactionLink
          documentType={documentType}
          transaction={transaction}
          documentDescription={documentDescription}
          matterDescription={matterDescription}
          status={status}
          invalidCode={invalidCode}
          matterType={matterType}
          document={document}
        />
      );
    } else if (status === ENotificationStatusType.Invalidated && invalidCode === EInvalidatedReasonType.DocTerminated) {
      return (
        <TransactionLink
          documentType={documentType}
          transaction={transaction}
          documentDescription={documentDescription}
          matterDescription={matterDescription}
          status={status}
          invalidCode={invalidCode}
          matterType={matterType}
          document={document}
        />
      );
    } else if (status === ENotificationStatusType.Invalidated && invalidCode === EInvalidatedReasonType.Superseded) {
      return (
        <>
          <InvalidatedDocument invalidCode={invalidCode} />
          <TransactionLink
            documentType={documentType}
            transaction={transaction}
            documentDescription={documentDescription}
            matterDescription={matterDescription}
            status={status}
            invalidCode={invalidCode}
            matterType={matterType}
            document={document}
          />
        </>
      );
    } else if (status === ENotificationStatusType.Invalidated && invalidCode !== EInvalidatedReasonType.Superseded) {
      return <InvalidatedDocument invalidCode={invalidCode} />;
    } else if (transaction?.document?.canViewPrivilege === false || transaction?.matter?.canViewPrivilege === false) {
      return <InsufficientPermissions urn={urn} />;
    }
  }

  return (
    <>
      <Column spacing={{ px: 5 }} className={styles.overflowFix}>
        <Div display={{ base: "block" }}>
          <Typography
            variant="small"
            weight="medium"
            color={cMonoColorType.Light}
            className={classNames(
              invalidCode === EInvalidatedReasonType.Superseded && styles.strikethrough,
              status === ENotificationStatusType.Invalidated &&
                (invalidCode === EInvalidatedReasonType.DocDeleted ||
                  invalidCode === EInvalidatedReasonType.MatterDeleted) &&
                styles.strikethrough,
            )}
          >
            {remainingDays}
          </Typography>
        </Div>
        <Div spacing={{ pb: 3 }} display={{ base: "inline-flex" }}>
          <Typography
            weight="medium"
            className={classNames(
              styles.notificationTitle,
              invalidCode === EInvalidatedReasonType.Superseded && styles.strikethrough,
              status === ENotificationStatusType.Invalidated &&
                (invalidCode === EInvalidatedReasonType.DocTerminated ||
                  EInvalidatedReasonType.MatterDeleted ||
                  invalidCode === EInvalidatedReasonType.DocDeleted) &&
                styles.strikethrough,
            )}
          >
            {description}
          </Typography>
        </Div>
        <Div spacing={{ py: 4 }}>{getTransactionLink()}</Div>
      </Column>
    </>
  );
}

export default CompactContent;
