import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useRef } from "react";
import { useBorder, useMountTransition, useSpacing } from "../../../app/hooks";
import Div from "../../Div/Div";
import { IAccordionItem } from "../Accordion";
import styles from "./AccordionItem.module.scss";

export type IAccordionItemProps = {
  isOpen: boolean;
  index: number;
  toggle: (i: number) => void;
} & IAccordionItem;

/**
 * A single accordion item
 * NB: Do not use directly! Use with the Accordion component
 * @param header            The header contents (JSX)
 * @param contents          The panel contents (JSX)
 * @param isOpen            Is the panel open?
 * @param index             The index of the item in the accordion array
 * @param toggle            Toggle method passed from Accordion component
 * @param className         Custom wrapper class
 * @param headerClassName   Custom header wrapper class
 * @param contentsClassName Custom contents wrapper class
 * @param spacing           Wrapper spacing
 * @param border            Wrapper border
 * @param itemTestId        A testId used in the tests
 * @returns JSX.Element
 */
function AccordionItem({
  header,
  contents,
  isOpen,
  index,
  toggle,
  className,
  headerClassName,
  contentsClassName,
  spacing,
  border,
  itemTestId,
}: IAccordionItemProps): JSX.Element {
  const hasTransitionedIn = useMountTransition(isOpen, 200); // Use hook to handle animation mount / unmount
  const spacingClx = useSpacing(spacing);
  const borderClx = useBorder(border);
  const contentsEl = useRef<HTMLDivElement>(null);

  const clx = classNames(
    // Wrapper classes
    styles.className, // Generic
    spacingClx, // Spacing
    borderClx, // Border
    className, // Custom
  );

  const iconClx = classNames(
    styles.icon, // Initial icon styles
    isOpen && styles.expanded, // Rotate icon when expanded
  );

  const headerClx = classNames(styles.header, headerClassName);

  const contentsClx = classNames(styles.contents, contentsClassName);

  return (
    <Div className={clx} testId={itemTestId}>
      <Div className={headerClx} onClick={() => toggle(index)}>
        {/* Toggle on click of header */}
        <Div className={styles.headerWrapper}>{header}</Div>
        <button type="button" className={iconClx} onClick={() => toggle(index)} data-testid="accordion-chevron">
          <FontAwesomeIcon icon={faChevronDown} title={itemTestId} />
        </button>
      </Div>
      {(isOpen || hasTransitionedIn) && ( // Mount the contents on open / transition
        <Div className={contentsClx} ref={contentsEl}>
          {contents}
        </Div>
      )}
    </Div>
  );
}

export default AccordionItem;
