import { IXDirection } from "../../app/types";
import Button from "../Button/Button";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalIcon, ModalTitle } from "../Modal/Modal";
import SuccessIcon from "../SuccessIcon/SuccessIcon";
import Typography from "../Typography/Typography";
import styles from "./SuccessModal.module.scss";

interface ISuccessModal {
  isOpen: boolean;
  handleClose: () => void;
  header: string;
  message: string | React.ReactNode;
}

/**
 * The mail sent modal
 * @param isOpen       Is the modal open?
 * @param handleClose  Function to close the modal
 * @param header       The modal header
 * @param message      The modal message
 * @returns JSX.Element
 */
function SuccessModal({ isOpen, handleClose, header, message }: ISuccessModal) {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} className={styles.modal} testId="success-modal">
      <ModalIcon>
        <SuccessIcon />
      </ModalIcon>
      <ModalHeader justifyContent={{ base: "center" }}>
        <ModalTitle variant="h3">{header}</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <Typography align={IXDirection.Center}>{message}</Typography>
      </ModalContent>
      <ModalActions justifyContent={{ base: "center" }}>
        <Button className={styles.button} onClick={handleClose}>
          OK
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default SuccessModal;
