import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { toast } from "react-toastify";
import api from "../app/api";
import {
  esignAddGroupRecipientUrl,
  esignAddGroupUrl,
  esignAddRecipientUrl,
  esignCancelUrl,
  esignCreateUrl,
  esignDeleteActivityUrl,
  esignDeleteGroupActivityUrl,
  esignEditGroupUrl,
  esignEditUrl,
  esignFetchUrl,
  esignFilterPeopleByEmailUrl,
  esignReorderRecipientsUrl,
  esignStartUrl,
} from "../app/apiUrls";
import { cAccessType, cStatusType } from "../app/constants";
import { RootState } from "../app/store";
import { IFieldOption } from "../components/FormField/FormField";
import { EESignGroupActionType } from "../pages/DocumentsContainer/Documents/ESignWorkflowModal/AddESignGroupModal/AddESignGroupModal";
import { errorToast } from "../toast/toast";
import { ETimelineEntryType, updateDocument } from "./documentsSlice";

/**
 * The esign action hint types
 */
export enum EESignActionHintType {
  Signed = "Signed",
  PatialSignatureHistory = "Partial signature history",
  Sign = "Sign",
  BusySigning = "Busy signing",
}

/**
 * The esign action types
 */
export enum EESignActionType {
  Sign = "sign",
  Approve = "approve",
  View = "view",
  Group = "group",
}

/**
 * The esign state type
 */
export enum EESignState {
  Configuration = "configuration",
  InProgress = "inProgress",
  Historic = "historic",
}

/**
 * ESign history action type
 */
export enum EESignHistoryActionType {
  Approved = "Approved",
  Cancelled = "Cancelled",
  Complete = "Complete",
  Preparing = "Preparing",
  Rejected = "Rejected",
  Released = "Released",
  Signed = "Signed",
  Proxied = "Proxied",
  GroupComplete = "Group complete",
}

/**
 * Describes an esign history entry
 */
export interface IESignHistoryEntry {
  date: string;
  type: EESignHistoryActionType;
  description: string;
  firstName: string;
  lastName: string;
}

/**
 * Describes esign settings
 */
interface IESignSettings {
  dueDate: string | null | undefined;
  autoExpire: boolean | null | undefined;
  autoRemind: boolean | null | undefined;
}

/**
 * Describes an esign person
 */
interface IESignPerson {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
}

/**
 * Describes an esign
 */
interface IESign {
  id: number;
  state: EESignState;
  settings: IESignSettings;
  signersApprovers: TSignerApprover[];
  viewers: IESignRecipient[];
  history: IESignHistoryEntry[][];
}

/**
 * Describes esign state
 */
interface IESignState {
  status: cStatusType;
  esignStartstatus?: cStatusType;
  error?: string;
  addGroupRecipientError?: string;
  entry?: IESign;
  activeESignID?: number | null;
  filteredEmailAddressOptions?: IFieldOption[];
  foundESignUser?: any;
  iframeURL?: string;
}

/**
 * Describes and add esign recipient
 */
interface IAddESignRecipient {
  id: number;
  emailAddress: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  action: string;
  allowProxy: boolean;
}

/**
 * Describes an add group request
 */
interface IAddESignGroupRequest {
  id: number;
  name: string;
  type: EESignGroupActionType;
  required: number;
}

/**
 * Describes an esign recipient
 */
export interface IESignRecipient {
  id: number;
  action: { [key: string]: { state: cAccessType; id?: number } };
  person: IESignPerson;
  type: EESignActionType.Approve | EESignActionType.Sign | EESignActionType.View;
  activityNumber: number;
  allowProxy: boolean;
  complete: boolean;
}

/**
 * Describes an esign group
 */
export interface IESignGroup {
  id: number;
  action: Record<
    string,
    {
      state: cAccessType;
    }
  >;
  type: EESignActionType.Group;
  activityNumber: number;
  name: string;
  required: number;
  completed: number;
  persons: IESignRecipient[];
  groupType: EESignActionType;
}

/**
 * Describes a signer/approver/group
 */
export type TSignerApprover =
  | (IESignRecipient & { name: never; required: never; completed: never; persons: never })
  | (IESignGroup & { person: never; allowProxy: never; complete: never });

/**
 * Initial state
 */
const initialState: IESignState = {
  status: cStatusType.Idle,
  entry: undefined,
  filteredEmailAddressOptions: [],
  foundESignUser: undefined,
};

/**
 * Thunk for starting a new esign process
 */
export const postStartNewESign = createAsyncThunk(
  "esign/postStartNewESign",
  async (
    {
      dueDate,
      autoExpire,
      autoRemind,
      timelineEntryType,
      esignUrn,
    }: IESignSettings & { timelineEntryType: ETimelineEntryType; esignUrn: number },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const endpoint = esignCreateUrl;
      const settings = { dueDate: dueDate ? format(new Date(dueDate), "yyyy-MM-dd") : null, autoExpire, autoRemind };
      let body = {};
      if (timelineEntryType === ETimelineEntryType.SupportingDocument) {
        // supporting document
        body = {
          esign: {
            supporting: {
              supportingDocumentID: esignUrn,
            },
          },
          settings,
        };
      } else {
        // versionFile or versionDraft
        body = {
          esign: {
            version: {
              walkID: esignUrn,
            },
          },
          settings,
        };
      }
      const response = await api({
        endpoint,
        dispatch,
        body,
      });
      return response.data;
    } catch (err: any) {
      errorToast(err.message);
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for editing an esign
 */
export const postEditESign = createAsyncThunk(
  "esign/postEditESign",
  async ({ dueDate, autoExpire, autoRemind, id }: IESignSettings & { id: number }, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = esignEditUrl;
      const settings = {
        dueDate: dueDate ? format(new Date(dueDate), "yyyy-MM-dd") : null,
        autoExpire,
        autoRemind,
      };
      await api({
        endpoint,
        dispatch,
        body: {
          id,
          settings,
        },
      });
      return settings;
    } catch (err: any) {
      errorToast(err.message);
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching an esign
 */
export const postESign = createAsyncThunk("esign/postESign", async (id: number, { dispatch, rejectWithValue }) => {
  try {
    const endpoint = esignFetchUrl;
    const response = await api({
      endpoint,
      dispatch,
      body: {
        id,
      },
    });
    return response.data.process;
  } catch (err: any) {
    errorToast(err.message);
    throw rejectWithValue(err.message);
  }
});

/**
 * Thunk for adding an esign recipient
 */
export const postAddESignRecipient = createAsyncThunk(
  "esign/postAddESignRecipient",
  async (
    { id, emailAddress, firstName, lastName, phoneNumber, action, allowProxy }: IAddESignRecipient,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const endpoint = esignAddRecipientUrl;
      const body: any = {
        [action]: {
          id,
          person: {
            emailAddress,
            firstName,
            lastName,
            phoneNumber,
          },
        },
      };
      if (action !== EESignActionType.View) {
        body[action].allowProxy = Boolean(allowProxy);
      }
      await api({
        endpoint,
        dispatch,
        body,
      });
      toast("Recipient added");
    } catch (err: any) {
      errorToast(err.message);
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for adding an esign group recipient
 */
export const postAddESignGroupRecipient = createAsyncThunk(
  "esign/postAddESignGroupRecipient",
  async (
    { id, emailAddress, firstName, lastName, phoneNumber }: Exclude<IAddESignRecipient, "allowProxy" | "action">,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const endpoint = esignAddGroupRecipientUrl;
      const body: any = {
        id,
        person: {
          emailAddress,
          firstName,
          lastName,
          phoneNumber,
        },
      };
      await api({
        endpoint,
        dispatch,
        body,
      });
      toast("Recipient added");
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for filtering esign people by email address
 */
export const postAddFilterPeopleByEmail = createAsyncThunk(
  "esign/postAddFilterPeopleByEmail",
  async (emailToMatch: string, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = esignFilterPeopleByEmailUrl;
      const response = await api({
        endpoint,
        dispatch,
        body: {
          emailToMatch,
        },
      });
      return response.data;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for deleting an esign recipient
 */
export const postDeleteRecipient = createAsyncThunk(
  "esign/postDeleteRecipient",
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = esignDeleteActivityUrl;
      await api({
        endpoint,
        dispatch,
        body: {
          id,
        },
      });
    } catch (err: any) {
      errorToast(err.message);
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for deleting a group esign recipient
 */
export const postDeleteGroupRecipient = createAsyncThunk(
  "esign/postDeleteGroupRecipient",
  async ({ groupID, personID }: { groupID: number; personID: number }, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = esignDeleteGroupActivityUrl;
      await api({
        endpoint,
        dispatch,
        body: {
          groupActivityID: groupID,
          personID,
        },
      });
    } catch (err: any) {
      errorToast(err.message);
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for reordering esign recipients
 */
export const postReorderRecipients = createAsyncThunk(
  "esign/postReorderRecipients",
  async ({ id, activities }: { id: number; activities: number[] }, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = esignReorderRecipientsUrl;
      const response = await api({
        endpoint,
        dispatch,
        body: {
          id,
          activities,
        },
      });
      return response.data.signersApprovers;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for adding an esign group
 */
export const postAddESignGroup = createAsyncThunk(
  "esign/postAddESignGroup",
  async ({ id, name, type, required }: IAddESignGroupRequest, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = esignAddGroupUrl;
      const response = await api({
        endpoint,
        dispatch,
        body: {
          id,
          name,
          type,
          required: Number(required),
        },
      });
      toast("Group added");
      return response.data.signersApprovers;
    } catch (err: any) {
      errorToast(err.message);
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for editing an esign group
 */
export const postEditESignGroup = createAsyncThunk(
  "esign/postEditESignGroup",
  async ({ id, name, type, required }: IAddESignGroupRequest, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = esignEditGroupUrl;
      const response = await api({
        endpoint,
        dispatch,
        body: {
          id,
          name,
          type,
          required: Number(required),
        },
      });
      toast("Group edited");
      return response.data.signersApprovers;
    } catch (err: any) {
      errorToast(err.message);
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for starting an esign process
 */
export const postESignStart = createAsyncThunk(
  "esign/postESignStart",
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = esignStartUrl;
      const response = await api({
        endpoint,
        dispatch,
        body: {
          id,
        },
      });
      dispatch(updateDocument(response.data.document)); // Update document state
      return response.data.iframeURL;
    } catch (err: any) {
      errorToast(err.message);
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for cancelling an esign process
 */
export const postCancelESign = createAsyncThunk(
  "esign/postCancelESign",
  async ({ id, reason }: { id: number; reason?: string }, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = esignCancelUrl;
      const body: any = { id };
      if (reason) {
        body.reason = reason;
      }
      const response = await api({
        endpoint,
        dispatch,
        body,
      });
      dispatch(updateDocument(response.data.document)); // Update document state
      return response.data.process;
    } catch (err: any) {
      errorToast(err.message);
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Create esign slice
 */
export const esignSlice = createSlice({
  name: "esign", // The name of the slice
  initialState, // Set the initialState
  reducers: {
    updateActiveESignID: (state, action: PayloadAction<number | null>) => {
      state.activeESignID = action.payload;
    },
    resetESign: () => {
      return initialState;
    },
    resetFilteredEmailAddressOptions: (state) => {
      state.filteredEmailAddressOptions = [];
    },
    resetFoundESignUser: (state) => {
      state.foundESignUser = undefined;
    },
    resetIFrameURL: (state) => {
      state.iframeURL = undefined;
    },
    resetGroupRecipientError: (state) => {
      state.addGroupRecipientError = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postStartNewESign.pending, (state) => {
        state.status = cStatusType.Loading;
        state.activeESignID = undefined;
        state.error = undefined;
      })
      .addCase(postStartNewESign.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postStartNewESign.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.activeESignID = action.payload.id;
      })
      .addCase(postEditESign.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postEditESign.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postEditESign.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        if (state.entry) {
          state.entry.settings = action.payload;
        }
      })
      .addCase(postESign.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postESign.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postESign.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.entry = action.payload;
      })
      .addCase(postAddESignRecipient.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postAddESignRecipient.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postAddESignRecipient.fulfilled, (state) => {
        state.status = cStatusType.Idle;
      })
      .addCase(postAddESignGroupRecipient.pending, (state) => {
        state.status = cStatusType.Loading;
        state.addGroupRecipientError = undefined;
      })
      .addCase(postAddESignGroupRecipient.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.addGroupRecipientError = action.payload as string;
      })
      .addCase(postAddESignGroupRecipient.fulfilled, (state) => {
        state.status = cStatusType.Idle;
      })
      .addCase(postAddFilterPeopleByEmail.pending, (state) => {
        state.error = undefined;
      })
      .addCase(postAddFilterPeopleByEmail.rejected, (state, action) => {
        state.error = action.payload as string;
      })
      .addCase(postAddFilterPeopleByEmail.fulfilled, (state, action) => {
        const { potentialMatches, person } = action.payload;
        state.filteredEmailAddressOptions = [];
        state.foundESignUser = undefined;
        if (potentialMatches) {
          state.filteredEmailAddressOptions = potentialMatches.map((address: string) => {
            return { label: address, value: address };
          });
        } else if (person) {
          state.filteredEmailAddressOptions = [{ label: person.emailAddress, value: person.emailAddress }];
          state.foundESignUser = {
            emailAddress: person.emailAddress,
            firstName: person.firstName,
            lastName: person.lastName,
            phoneNumber: person.phoneNumber,
          };
        }
      })
      .addCase(postDeleteRecipient.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postDeleteRecipient.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postDeleteRecipient.fulfilled, (state) => {
        state.status = cStatusType.Idle;
      })
      .addCase(postDeleteGroupRecipient.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postDeleteGroupRecipient.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postDeleteGroupRecipient.fulfilled, (state) => {
        state.status = cStatusType.Idle;
      })
      .addCase(postReorderRecipients.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postReorderRecipients.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postReorderRecipients.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        if (state.entry) {
          state.entry.signersApprovers = action.payload;
        }
      })
      .addCase(postAddESignGroup.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postAddESignGroup.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postAddESignGroup.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        if (state.entry) {
          state.entry.signersApprovers = action.payload;
        }
      })
      .addCase(postEditESignGroup.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postEditESignGroup.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postEditESignGroup.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        if (state.entry) {
          state.entry.signersApprovers = action.payload;
        }
      })
      .addCase(postESignStart.pending, (state) => {
        state.esignStartstatus = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postESignStart.rejected, (state, action) => {
        state.esignStartstatus = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postESignStart.fulfilled, (state, action) => {
        state.esignStartstatus = cStatusType.Idle;
        state.iframeURL = action.payload;
      })
      .addCase(postCancelESign.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postCancelESign.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postCancelESign.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.entry = action.payload;
      });
  },
});

export const {
  updateActiveESignID,
  resetESign,
  resetFilteredEmailAddressOptions,
  resetFoundESignUser,
  resetIFrameURL,
  resetGroupRecipientError,
} = esignSlice.actions;

export const selectESignStatus = (state: RootState) => state.esign.status; // Select the status
export const selectESignStartStatus = (state: RootState) => state.esign.esignStartstatus; // Select the startESignstatus
export const selectESign = (state: RootState) => state.esign.entry; // Select the esign entry
export const selectActiveESignID = (state: RootState) => state.esign.activeESignID; // Select the activeESignID
export const selectFilteredEmailAddressOptions = (state: RootState) => state.esign.filteredEmailAddressOptions; // Select the filteredEmailAddressOptions
export const selectFoundESignUser = (state: RootState) => state.esign.foundESignUser; // Select the foundESignUser
export const selectIFrameURL = (state: RootState) => state.esign.iframeURL; // Select the iframeURL
export const selectAddGroupRecipientError = (state: RootState) => state.esign.addGroupRecipientError; // Select the addGroupRecipientError

export default esignSlice.reducer;
