import { sortBy } from "lodash";
import { cStatusType } from "../../../../../app/constants";
import DisplayError from "../../../../../components/DisplayError/DisplayError";
import Column from "../../../../../components/Row/Column/Column";
import Spinner from "../../../../../components/Spinner/Spinner";
import { IMatterTaskEntry, ISpawnItem } from "../../../../../modules/matterTasksSlice";
import { IUser } from "../../../../../modules/usersSlice";
import { IPostMatterSpawn } from "../../../../../modules/walkSlice";
import MatterResources from "./MatterResources/MatterResources";
import styles from "./MatterTasksColumn.module.scss";
import MatterToDo from "./MatterToDo/MatterToDo";

export interface IMatterTasksColumn {
  tasks: IMatterTaskEntry;
  error?: string;
  status: cStatusType;
  matterID: number;
  handleDraftDocument: ({ custWalkTypeID, matterID, spawnID }: IPostMatterSpawn) => void;
  taskAssignees?: IUser[];
  setSelectedTask: (task: Pick<ISpawnItem, "id" | "description" | "name" | "assigneeUserID">) => void;
}

/**
 * Matter tasks and resources column on single matter page
 * @param tasks               The list of tasks
 * @param error               API errors
 * @param status              API loading status
 * @param matterID            ID of wrapping matter
 * @param handleDraftDocument Draft new spawn document
 * @param taskAssignees       The assignees for the tasks
 * @param setSelectedTask     Set the selected task for reassignment modal
 * @returns JSX.Element
 */
function MatterTasksColumn({
  tasks,
  error,
  status,
  matterID,
  handleDraftDocument,
  taskAssignees,
  setSelectedTask,
}: IMatterTasksColumn): JSX.Element {
  // Split spawns by to dos and resource items
  const todoItems = sortBy(
    tasks?.spawnItems?.filter((task) => task.isRequired === true),
    "position",
  );

  const matterItems = sortBy(
    tasks?.spawnItems?.filter((task) => task.isRequired === false),
    "position",
  );

  return (
    <Column all={12} md={3} className={styles.className}>
      {error ? (
        <DisplayError>{error}</DisplayError>
      ) : status === cStatusType.Loading ? (
        <Spinner />
      ) : (
        <>
          <MatterToDo
            todoItems={todoItems}
            handleDraftDocument={handleDraftDocument}
            matterID={matterID}
            taskAssignees={taskAssignees}
            setSelectedTask={setSelectedTask}
          />
          <MatterResources matterItems={matterItems} handleDraftDocument={handleDraftDocument} matterID={matterID} />
        </>
      )}
    </Column>
  );
}

export default MatterTasksColumn;
