import classNames from "classnames";
import React, { AriaRole } from "react";
import { cMonoColorType } from "../../app/constants";
import {
  useAlignContent,
  useAlignItems,
  useAlignSelf,
  useBorder,
  useDisplay,
  useFlexDirection,
  useJustifyContent,
  useJustifyItems,
  useJustifySelf,
  useMargin,
  useMarginB,
  useMarginL,
  useMarginR,
  useMarginT,
  useMarginX,
  useMarginY,
  usePadding,
  usePaddingB,
  usePaddingL,
  usePaddingR,
  usePaddingT,
  usePaddingX,
  usePaddingY,
  useScroll,
  useSpacing,
} from "../../app/hooks";
import {
  IAlignContent,
  IAlignItems,
  IAlignSelf,
  IFlexDirection,
  IJustifyContent,
  IJustifyItems,
  IJustifySelf,
  IMargin,
  IMarginB,
  IMarginL,
  IMarginR,
  IMarginT,
  IMarginX,
  IMarginY,
  IPadding,
  IPaddingB,
  IPaddingL,
  IPaddingR,
  IPaddingT,
  IPaddingX,
  IPaddingY,
  TDisplay,
} from "../../app/styleTypes";
import { IBorder, IScroll, ISpacing } from "../../app/types";
import utilityStyles from "../../scss/generic/Utilities.module.scss";

export interface IDivProps {
  children?: React.ReactNode;
  className?: string;
  spacing?: ISpacing;
  p?: IPadding;
  py?: IPaddingY;
  px?: IPaddingX;
  pt?: IPaddingT;
  pr?: IPaddingR;
  pb?: IPaddingB;
  pl?: IPaddingL;
  m?: IMargin;
  my?: IMarginY;
  mx?: IMarginX;
  mt?: IMarginT;
  mr?: IMarginR;
  mb?: IMarginB;
  ml?: IMarginL;
  alignItems?: IAlignItems;
  alignContent?: IAlignContent;
  alignSelf?: IAlignSelf;
  justifyContent?: IJustifyContent;
  justifyItems?: IJustifyItems;
  justifySelf?: IJustifySelf;
  border?: IBorder;
  bgColor?: cMonoColorType;
  scroll?: IScroll;
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  testId?: string;
  display?: TDisplay;
  flexDirection?: IFlexDirection;
  role?: AriaRole;
  id?: string;
}

/**
 * A simple div with spacing and responsive utilities
 * @param children       The contents of the div
 * @param className      Custom class string
 * @param spacing        Set Spacing object
 * @param p              Set Padding object
 * @param py             Set Padding-y object
 * @param px             Set Padding-x object
 * @param pt             Set Padding-top object
 * @param pr             Set Padding-right object
 * @param pb             Set Padding-bottom object
 * @param pl             Set Padding-left object
 * @param m              Set Margin object
 * @param my             Set Margin-y object
 * @param mx             Set Margin-x object
 * @param mt             Set Margin-top object
 * @param mr             Set Margin-right object
 * @param mb             Set Margin-bottom object
 * @param ml             Set Margin-left object
 * @param border         Set Border object
 * @param bgColor        Set a background color for the div
 * @param scroll         Enable scrolling and direction
 * @param breakpoint     Enable responsive breakpoints
 * @param display        Enable flex-direction styles
 * @param flexDirection  Enable display styles
 * @param alignItems     Enable align-items styles
 * @param alignContent   Enable align-content styles
 * @param alignSelf      Enable align-self styles
 * @param justifyContent Enable justify-content styles
 * @param justifyItems   Enable justify-items styles
 * @param justifySelf    Enable justify-self styles
 * @param style          Custom inline styles
 * @param onClick        Function to run on click of div
 * @param testId         A testId to query in the tests
 * @param role           Aria role for element
 * @returns              JSX.Element
 */

const Div = React.forwardRef<HTMLDivElement, IDivProps>(
  (
    {
      children,
      className,
      spacing,
      p,
      px,
      py,
      pt,
      pr,
      pb,
      pl,
      m,
      mx,
      my,
      mt,
      mr,
      mb,
      ml,
      border,
      bgColor,
      scroll,
      display,
      flexDirection,
      alignItems,
      alignContent,
      alignSelf,
      justifyContent,
      justifyItems,
      justifySelf,
      style,
      onClick,
      testId,
      role,
      onMouseEnter,
      onMouseLeave,
      id,
    },
    ref,
  ): JSX.Element => {
    const spacingClx = useSpacing(spacing); // Get spacing classes
    const pClx = usePadding(p); // Get padding classes
    const pyClx = usePaddingY(py); // Get padding-y classes
    const pxClx = usePaddingX(px); // Get padding-x classes
    const ptClx = usePaddingT(pt); // Get padding-top classes
    const prClx = usePaddingR(pr); // Get padding-right classes
    const pbClx = usePaddingB(pb); // Get padding-bottom classes
    const plClx = usePaddingL(pl); // Get padding-left classes
    const mClx = useMargin(m); // Get margin classes
    const myClx = useMarginY(my); // Get margin-y classes
    const mxClx = useMarginX(mx); // Get margin-x classes
    const mtClx = useMarginT(mt); // Get margin-top classes
    const mrClx = useMarginR(mr); // Get margin-right classes
    const mbClx = useMarginB(mb); // Get margin-bottom classes
    const mlClx = useMarginL(ml); // Get margin-left classes
    const displayClx = useDisplay(display); // Get display classes
    const flexDirectionClx = useFlexDirection(flexDirection); // Get flex-direction classes
    const alignItemsClx = useAlignItems(alignItems); // Get align-items classes
    const alignContentClx = useAlignContent(alignContent); // Get align-content classes
    const alignSelfClx = useAlignSelf(alignSelf); // Get align-self classes
    const justifyItemsClx = useJustifyItems(justifyItems); // Get justify-items classes
    const justifyContentClx = useJustifyContent(justifyContent); // Get justify-content classes
    const justifySelfClx = useJustifySelf(justifySelf); // Get justify-self classes
    const borderClx = useBorder(border); // Get border classes
    const scrollClx = useScroll(scroll); // Get scroll classes
    const clx = classNames(
      // Combine classes
      bgColor && utilityStyles[`bg-${bgColor}`], // Apply background color
      spacingClx, // Spacing
      pClx, // Padding
      pyClx, // Padding
      pxClx, // Padding
      ptClx, // Padding
      prClx, // Padding
      pbClx, // Padding
      plClx, // Padding
      mClx, // Margin
      myClx, // Margin
      mxClx, // Margin
      mtClx, // Margin
      mrClx, // Margin
      mbClx, // Margin
      mlClx, // Margin
      displayClx, // Utilities: display
      flexDirectionClx, // Utilities: flexDirection
      alignItemsClx,
      alignContentClx,
      alignSelfClx,
      justifyItemsClx,
      justifyContentClx,
      justifySelfClx,
      borderClx, // Border
      scrollClx, // Scrollbar
      className, // Custom
    );

    return (
      <div
        data-testid={testId}
        className={clx || undefined}
        style={style}
        ref={ref}
        onClick={onClick}
        onMouseEnter={() => onMouseEnter}
        onMouseLeave={() => onMouseLeave}
        role={role}
        id={id}
      >
        {children}
      </div>
    );
  },
);

Div.displayName = "Div";

export default Div;
