import { keyBy } from "lodash";

/**
 * Create a list keyed by id from a list array and include a fetchTime
 * @param arr A data list array
 * @returns Object
 */
function createIdList(arr: any[]) {
  let y;
  if (arr && arr.length > 0) {
    y = arr.map((x) => {
      x.fetchTime = new Date().getTime();
      return x;
    });
  }

  return keyBy(y, "id");
}

export default createIdList;
