import { format } from "date-fns";
import { useForm } from "react-hook-form";
import { cMonoColorType, cStatusType, cThemeColorType } from "../../../../../app/constants";
import Breadcrumb from "../../../../../components/Breadcrumb/Breadcrumb";
import ConfirmDeleteEventModal from "../../../../../components/ConfirmDeleteEventModal/ConfirmDeleteEventModal";
import Div from "../../../../../components/Div/Div";
import TimelineEvents, { ETimelineType } from "../../../../../components/Events/TimelineEvents";
import FormField, { cFormFieldType } from "../../../../../components/FormField/FormField";
import Modal, { ModalContent, ModalHeader } from "../../../../../components/Modal/Modal";
import RenameDocumentModal from "../../../../../components/RenameDocumentModal/RenameDocumentModal";
import Column from "../../../../../components/Row/Column/Column";
import Row from "../../../../../components/Row/Row";
import Tabs from "../../../../../components/Tabs/Tabs";
import Typography from "../../../../../components/Typography/Typography";
import {
  ETimelineEntryType,
  ETimelineGroup,
  IDocument,
  IDocumentTimelineEntry,
} from "../../../../../modules/documentsSlice";
import { IDocumentType } from "../../../../../modules/documentTypesSlice";
import { IUser } from "../../../../../modules/usersSlice";
import { EWalkType } from "../../../../../modules/walkSlice";
import NewEventModalContainer from "../../../../WalkContainer/Walk/NewEventModalContainer/NewEventModalContainer";
import AddNoteModalContainer from "../../AddNoteModalContainer/AddNoteModalContainer";
import AttachSupportingDocModal from "../../AttachSupportingDocModal/AttachSupportingDocModal";
import DocumentActionPopover from "../../DocumentActionPopover/DocumentActionPopover";
import ESignIFrameModal from "../../ESignIFrameModal/ESignIFrameModal";
import ESignWorkflowModal from "../../ESignWorkflowModal/ESignWorkflowModal";
import InProgressByUser from "../../InProgressByUser/InProgressByUser";
import ManageCollaborationModalContainer from "../../ManageCollaborationModalContainer/ManageCollaborationModalContainer";
import NewESignModal from "../../NewESignModal/NewESignModal";
import TerminateDocumentModal from "../../TerminateDocumentModal/TerminateDocumentModal";
import TransferOwnershipModal from "../../TransferOwnershipModal/TransferOwnershipModal";
import styles from "./DocumentInformationModal.module.scss";

type Inputs = {
  showSigned: string;
};

interface IBreadcrumbLink {
  id: string;
  label?: string;
  url: string;
}

interface IDocumentInformationModal {
  id: number;
  isOpen: boolean;
  isAddNoteModalOpen: boolean;
  isNewEventModalOpen: boolean;
  isTransferOwnershipModalOpen: boolean;
  isTerminateDocumentModalOpen: boolean;
  isAttachSupportingDocModalOpen: boolean;
  isNewESignModalOpen: boolean;
  isESignWorkflowModalOpen: boolean;
  isRenameDocumentModalOpen: boolean;
  isWordCollaborationModalOpen: boolean;
  handleClose: () => void;
  handleTransferOwnershipClick: () => Promise<void>;
  handleAddNoteClick: () => void;
  handleAddNoteModalClose: () => void;
  handleAddEvent: () => void;
  handleNewEventModalClose: () => void;
  handleDocumentAmend: (id: number, action: EWalkType, compositeState: number) => void;
  handleAttachSupportingDocClick: () => void;
  handleAttachSupportingDocModalClose: () => void;
  handleTerminateDocumentClick: () => void;
  handleTerminateDocumentModalClose: () => void;
  handleRenameDocument: () => void;
  handleRenameDocumentModalClose: () => void;
  handleRenameDocumentSave: (description: string) => void;
  handleCloneDocumentClick: (id: number) => void;
  handleDeleteBtnClick: (eventId: number) => void;
  handleDeleteModalClose: () => void;
  handleDeleteEvent: () => void;
  handleFileDownload: (fileID: number) => void;
  handleInterviewLog: (walkID: number) => void;
  handleESignEvent: (type: ETimelineEntryType, urn?: number, esignID?: number) => void;
  handleESignWorkflowModalClose: () => void;
  handleNewESignModalClose: (canGoToESignWorkflow: boolean) => void;
  handleESignIFrameModalClose: (canCloseESignWorkflowModal: boolean) => void;
  handleWordCollaborationModalClose: () => void;
  handleTransferOwnershipModalClose: () => void;
  onUpdateSuccess: () => void;
  selectedDocument?: IDocument;
  timelineEntry?: IDocumentTimelineEntry;
  timelineEntryType?: ETimelineEntryType | null;
  documentTypes: Record<number, IDocumentType>;
  documentType?: IDocumentType;
  documents: Record<number, IDocument>;
  matterReferenceCode: string;
  eventCustomStatus: cStatusType;
  renameStatus?: cStatusType;
  eventCustomError?: string;
  deleteId: number | null;
  esignUrn?: number | null;
  activeESignID?: number | null;
  iframeURL?: string;
  owner?: IUser;
  breadcrumbLinks: IBreadcrumbLink[];
}

/**
 * Document information modal
 * @param id                                  The document ID
 * @param isOpen                              The modal open state
 * @param isAddNoteModalOpen                  The add note modal open state
 * @param isNewEventModalOpen                 The new event modal open state
 * @param isTransferOwnershipModalOpen        The transfer ownership modal open state
 * @param isTerminateDocumentModalOpen        The terminate document modal open state
 * @param isAttachSupportingDocModalOpen      The attach supporting document modal open state
 * @param isNewESignModalOpen                 The new e-sign modal open state
 * @param isESignWorkflowModalOpen            The e-sign workflow modal open state
 * @param isRenameDocumentModalOpen           The rename document modal open state
 * @param isWordCollaborationModalOpen        The word collaboration modal open state
 * @param handleClose                         The modal close function
 * @param handleTransferOwnershipClick        The transfer ownership click function
 * @param handleAddNoteClick                  The add note click function
 * @param handleAddNoteModalClose             The add note modal close function
 * @param handleAddEvent                      The add event click function
 * @param handleNewEventModalClose            The new event modal close function
 * @param handleDocumentAmend                 The document amend function
 * @param handleAttachSupportingDocClick      The attach supporting document click function
 * @param handleAttachSupportingDocModalClose The attach supporting document modal close function
 * @param handleTerminateDocumentClick        The terminate document click function
 * @param handleTerminateDocumentModalClose   The terminate document modal close function
 * @param handleRenameDocument                The rename document click function
 * @param handleRenameDocumentModalClose      The rename document modal close function
 * @param handleRenameDocumentSave            The rename document save function
 * @param handleCloneDocumentClick            The clone document click function
 * @param handleDeleteBtnClick                The delete button click function
 * @param handleDeleteModalClose              The delete modal close function
 * @param handleDeleteEvent                   The delete event function
 * @param handleFileDownload                  The file download function
 * @param handleInterviewLog                  The interview log function
 * @param handleESignEvent                    The e-sign event function
 * @param handleESignWorkflowModalClose       The e-sign workflow modal close function
 * @param handleWordCollaborationModalClose   The word collaboration modal close function
 * @param handleNewESignModalClose            The new e-sign modal close function
 * @param handleESignIFrameModalClose         The e-sign iFrame modal close function
 * @param handleTransferOwnershipModalClose   The transfer ownership modal close function
 * @param onUpdateSuccess                     The update success function
 * @param selectedDocument                    The selected document
 * @param timelineEntry                       The timeline entry
 * @param timelineEntryType                   The timeline entry type
 * @param documentTypes                       All document types
 * @param documentType                        The document type
 * @param documents                           The documents
 * @param matterReferenceCode                 The matter reference code
 * @param eventCustomStatus                   The event custom status
 * @param renameStatus                        The rename status
 * @param eventCustomError                    The event custom error
 * @param deleteId                            The delete ID
 * @param esignUrn                            The e-sign URN
 * @param activeESignID                       The active e-sign ID
 * @param iframeURL                           The iFrame URL
 * @param owner                               The document owner
 * @param breadcrumbLinks The breadcrumb links
 * @returns JSX.Element
 */
function DocumentInformationModal({
  id,
  isOpen,
  isAddNoteModalOpen,
  isNewEventModalOpen,
  isTransferOwnershipModalOpen,
  isTerminateDocumentModalOpen,
  isAttachSupportingDocModalOpen,
  isNewESignModalOpen,
  isESignWorkflowModalOpen,
  isRenameDocumentModalOpen,
  isWordCollaborationModalOpen,
  handleClose,
  handleTransferOwnershipClick,
  handleAddNoteClick,
  handleAddNoteModalClose,
  handleAddEvent,
  handleNewEventModalClose,
  handleDocumentAmend,
  handleAttachSupportingDocClick,
  handleAttachSupportingDocModalClose,
  handleTerminateDocumentClick,
  handleTerminateDocumentModalClose,
  handleRenameDocument,
  handleRenameDocumentModalClose,
  handleRenameDocumentSave,
  handleCloneDocumentClick,
  handleDeleteBtnClick,
  handleDeleteModalClose,
  handleDeleteEvent,
  handleFileDownload,
  handleInterviewLog,
  handleESignEvent,
  handleESignWorkflowModalClose,
  handleWordCollaborationModalClose,
  handleNewESignModalClose,
  handleESignIFrameModalClose,
  handleTransferOwnershipModalClose,
  onUpdateSuccess,
  selectedDocument,
  timelineEntry,
  timelineEntryType,
  documentTypes,
  documentType,
  documents,
  matterReferenceCode,
  eventCustomStatus,
  renameStatus,
  eventCustomError,
  deleteId,
  esignUrn,
  activeESignID,
  iframeURL,
  owner,
  breadcrumbLinks,
}: IDocumentInformationModal) {
  const walkInProgress = selectedDocument?.walkInProgress;

  const {
    register, // Register prop for form inputs
    formState: { errors }, // Errors that may occur
    watch,
  } = useForm<Inputs>();

  const showSigned = watch("showSigned") === "true";

  /**
   * Show signed toggle field
   * @returns JSX.Element
   */
  const ShowSignedToggle = (): JSX.Element => (
    <Div border={{ bb: true }} className={styles.showSignedWrapper}>
      <FormField
        name="showSigned"
        type={cFormFieldType.Switch}
        register={register}
        error={errors.showSigned}
        fullWidth
        options={[
          {
            label: "Show signed documents only",
            value: "true",
          },
        ]}
        testId="show-signed-toggle"
      />
    </Div>
  );

  // Page tabs and tab content
  const tabs = [
    {
      header: <Typography>Timeline</Typography>,
      contents: (
        <Div spacing={{ py: 0, px: 6 }}>
          <ShowSignedToggle />
          {timelineEntry && (
            <TimelineEvents
              timeline={timelineEntry.timeline.filter((entry) => entry.common.group.includes(ETimelineGroup.All))}
              showSigned={showSigned}
              handleDeleteEvent={handleDeleteBtnClick}
              handleFileDownload={handleFileDownload}
              handleInterviewLog={handleInterviewLog}
              handleESignEvent={handleESignEvent}
            />
          )}
        </Div>
      ),
    },
    {
      header: <Typography>Events</Typography>,
      contents: (
        <Div spacing={{ py: 0, px: 6 }}>
          <ShowSignedToggle />
          {timelineEntry && (
            <TimelineEvents
              timeline={timelineEntry.timeline.filter((entry) => entry.common.group.includes(ETimelineGroup.Events))}
              showSigned={showSigned}
              handleDeleteEvent={handleDeleteBtnClick}
              handleFileDownload={handleFileDownload}
              handleInterviewLog={handleInterviewLog}
              handleESignEvent={handleESignEvent}
            />
          )}
        </Div>
      ),
    },
    {
      header: <Typography>Files</Typography>,
      contents: (
        <Div spacing={{ py: 0, px: 6 }}>
          <ShowSignedToggle />
          {timelineEntry && (
            <TimelineEvents
              timeline={timelineEntry.timeline.filter((entry) => entry.common.group.includes(ETimelineGroup.Files))}
              showSigned={showSigned}
              handleDeleteEvent={handleDeleteBtnClick}
              handleFileDownload={handleFileDownload}
              handleInterviewLog={handleInterviewLog}
              handleESignEvent={handleESignEvent}
            />
          )}
        </Div>
      ),
    },
  ];

  return (
    <>
      <Modal size="xxl" isOpen={isOpen} scrollable handleClose={handleClose} testId="document-information-modal">
        <ModalHeader handleClose={handleClose}>
          <Breadcrumb links={breadcrumbLinks} />
        </ModalHeader>
        <Div bgColor={cMonoColorType.White} spacing={{ px: 6 }} border={{ bb: true }}>
          <Div>
            <Typography color={cThemeColorType.Secondary} className={styles.type} variant="h4">
              {documentType?.name}
            </Typography>
            <Row mb={{ base: 5 }}>
              <Column all={12} sm={9}>
                <Div display={{ base: "flex" }} alignItems={{ base: "center" }}>
                  <Div>
                    <Typography variant="h4" className={styles.description} spacing={{ mr: 6 }}>
                      <Div>{selectedDocument?.description}</Div>
                    </Typography>
                  </Div>
                  <Div>
                    <DocumentActionPopover
                      id={Number(id)}
                      handleTransferOwnershipClick={handleTransferOwnershipClick}
                      handleAddNoteClick={handleAddNoteClick}
                      handleAddEventClick={handleAddEvent}
                      handleDocumentAmend={handleDocumentAmend}
                      handleAttachSupportingDocClick={handleAttachSupportingDocClick}
                      handleTerminateDocumentClick={handleTerminateDocumentClick}
                      handleRenameDocumentClick={handleRenameDocument}
                      handleCloneDocumentClick={handleCloneDocumentClick}
                      action={timelineEntry?.action}
                      termination={selectedDocument?.termination}
                      isSigned={selectedDocument?.isSigned}
                      signedDate={selectedDocument?.signedDate === undefined ? null : selectedDocument.signedDate}
                      compositeState={selectedDocument?.compositeState || 0}
                    />
                  </Div>
                </Div>
              </Column>
            </Row>
            {walkInProgress && (
              <Div spacing={{ mb: 5 }}>
                {/* Conditionally render InProgressByUser if there's a walkInProgress */}
                <InProgressByUser
                  walkInProgress={walkInProgress}
                  documents={documents}
                  matterReferenceCode={matterReferenceCode}
                  documentType={documentType?.name} // Assuming documentType is an object with a name property
                />
              </Div>
            )}
          </Div>
          <Div className={styles.headerGrid}>
            <Div>
              <Typography casing="upper" color={cMonoColorType.Light}>
                <small>Primary URN</small>
              </Typography>
              <Typography casing="upper" color={cMonoColorType.Light}>
                <small>Owner</small>
              </Typography>
              {timelineEntry?.stageName && timelineEntry?.stageName !== "Unspecified" && (
                <Typography casing="upper" color={cMonoColorType.Light}>
                  <small>Stage</small>
                </Typography>
              )}
              {(selectedDocument?.termination || selectedDocument?.isSigned) && (
                <Typography casing="upper" color={cMonoColorType.Light}>
                  <small>Status</small>
                </Typography>
              )}
              {matterReferenceCode && (
                <Typography casing="upper" color={cMonoColorType.Light}>
                  <small>Matter Ref</small>
                </Typography>
              )}
            </Div>
            <Div>
              <Div>{timelineEntry?.documentID}</Div>
              <Div>{owner ? `${owner.firstName} ${owner.lastName ?? ""}` : "--"}</Div>
              {timelineEntry?.stageName && timelineEntry?.stageName !== "Unspecified" && (
                <Div>{timelineEntry.stageName}</Div>
              )}
              {(selectedDocument?.termination || selectedDocument?.isSigned) && (
                <Div>
                  {selectedDocument.termination
                    ? selectedDocument.termination.terminated
                      ? "Terminated"
                      : `Terminated: Effective ${format(
                          new Date(selectedDocument.termination.terminateDate),
                          "dd MMM yyyy",
                        )}`
                    : "Signed"}
                </Div>
              )}
              {matterReferenceCode && <Div>{matterReferenceCode}</Div>}
            </Div>
          </Div>
        </Div>
        <ModalContent bgColor={cMonoColorType.Light} spacing={{ p: 0 }}>
          <Tabs items={tabs} sticky />
        </ModalContent>
      </Modal>

      <AddNoteModalContainer
        isOpen={isAddNoteModalOpen}
        handleClose={handleAddNoteModalClose}
        documentID={Number(id)}
        onAddNoteSuccess={onUpdateSuccess}
      />

      <NewEventModalContainer
        isOpen={isNewEventModalOpen}
        handleClose={handleNewEventModalClose}
        id={Number(id)}
        onAddEventSuccess={onUpdateSuccess}
        linkedToType="document"
      />

      <TransferOwnershipModal
        type={ETimelineType.Document}
        isOpen={isTransferOwnershipModalOpen}
        handleClose={handleTransferOwnershipModalClose}
        documentID={Number(id)}
        documents={documents}
        onSubmitSuccess={onUpdateSuccess}
      />

      <TerminateDocumentModal
        isOpen={isTerminateDocumentModalOpen}
        handleClose={handleTerminateDocumentModalClose}
        documentType={documentTypes && documentTypes?.[documents?.[Number(id)]?.documentType.id]?.name}
        document={documents?.[Number(id)]}
        onSubmitSuccess={onUpdateSuccess}
      />

      <ConfirmDeleteEventModal
        isOpen={Boolean(deleteId)}
        handleModalClose={handleDeleteModalClose}
        isLoading={eventCustomStatus === cStatusType.Loading}
        error={eventCustomError}
        handleDeleteEvent={handleDeleteEvent}
      />

      <AttachSupportingDocModal
        isOpen={isAttachSupportingDocModalOpen}
        handleClose={handleAttachSupportingDocModalClose}
        documentID={Number(id)}
        onSubmitSuccess={onUpdateSuccess}
      />

      <NewESignModal
        isOpen={Boolean(isNewESignModalOpen && timelineEntryType && esignUrn)}
        handleClose={(canGoToESignWorkflow: boolean) => handleNewESignModalClose(canGoToESignWorkflow)}
        timelineEntryType={timelineEntryType}
        esignUrn={esignUrn}
        onSubmitSuccess={onUpdateSuccess}
      />

      <ESignWorkflowModal
        isOpen={Boolean(isESignWorkflowModalOpen && activeESignID)}
        handleClose={handleESignWorkflowModalClose}
        esignID={activeESignID}
        documentID={Number(id)}
      />

      <ESignIFrameModal
        isOpen={Boolean(!!iframeURL && activeESignID)}
        handleClose={(canCloseESignWorkflowModal: boolean) => handleESignIFrameModalClose(canCloseESignWorkflowModal)}
        iFrameUrl={iframeURL}
        esignID={activeESignID}
      />

      <RenameDocumentModal
        isOpen={isRenameDocumentModalOpen}
        handleClose={handleRenameDocumentModalClose}
        documentName={selectedDocument?.description as string}
        handleRenameDocumentSave={handleRenameDocumentSave}
        status={renameStatus}
      />

      <ManageCollaborationModalContainer
        isOpen={isWordCollaborationModalOpen}
        handleClose={handleWordCollaborationModalClose}
      />
    </>
  );
}

export default DocumentInformationModal;
