import classNames from "classnames";
import { useState } from "react";
import { cThemeColorType } from "../../app/constants";
import Button from "../Button/Button";
import Div from "../Div/Div";
import styles from "./CopyToClipboard.module.scss";

interface ICopyToClipboard {
  labelText?: string;
  textToCopy: string | number;
}

/**
 * Component to copy text to the clipboard
 * @param labelText  The label text to show what is being copied
 * @param textToCopy The text being copied
 * @returns JSX.Element
 */
function CopyToClipboard({ labelText, textToCopy }: ICopyToClipboard) {
  const copyBtnText = "Copy";
  const copiedBtnText = "Copied";
  const [buttonText, setButtonText] = useState<typeof copyBtnText | typeof copiedBtnText>(copyBtnText);

  /**
   * Copies the textToCopy to the clipboard
   */
  function copyTextToClipboard() {
    navigator.clipboard.writeText(textToCopy.toString());
  }

  /**
   * Handles copy button click
   */
  function handleCopyBtnClick() {
    copyTextToClipboard();
    setButtonText(copiedBtnText);

    setTimeout(() => {
      setButtonText(copyBtnText);
    }, 2000);
  }

  return (
    <Div className={styles.className} testId="copy-to-clipboard">
      <Div className={styles.text}>
        {labelText && (
          <>
            <span data-testid="label-text">{labelText}:</span>
            &nbsp;
          </>
        )}
        <span data-testid="copy-text">{textToCopy}</span>
      </Div>
      <Button
        className={classNames(styles.button, buttonText === copiedBtnText && styles.success)}
        color={cThemeColorType.Secondary}
        onClick={handleCopyBtnClick}
        testId="copy-to-clipboard-submit-button"
      >
        {buttonText}
      </Button>
    </Div>
  );
}
export default CopyToClipboard;
