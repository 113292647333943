import { forwardRef } from "react";
import ClearField from "../../ClearField/ClearField";
import styles from "./ClearButton.module.scss";

/**
 * The clear button on the autocomplete component.
 * Clears the autocomplete selection when clicked.
 */
const ClearButton = forwardRef((_, ref: any) => (
  <div className={styles.clearButtonWrapper}>
    <ClearField
      onClear={() => {
        ref.current?.clearValue();
      }}
      fieldRef={null}
    />
  </div>
));

ClearButton.displayName = "ClearButton";

export default ClearButton;
