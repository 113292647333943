"use client";

import React from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom"; // https://reactrouter.com/web/guides/quick-start
import App from "./App";
import { setupStore } from "./app/store";
import ErrorFallback from "./components/ErrorFallback/ErrorFallback";
import * as serviceWorker from "./serviceWorker";
import { registerRefreshInterceptor } from "./utils/refreshInterceptor/refreshInterceptor";

export const store = setupStore();

/**
 * Register the refresh interceptor
 */
registerRefreshInterceptor(store);

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Router>
          <App />
        </Router>
      </ErrorBoundary>
    </Provider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
serviceWorker.unregister();
