import { toast } from "react-toastify";
import { cSemanticColorType } from "../app/constants";
import Icon, { EIcon } from "../components/Icon/Icon";

/**
 * Displays an error toast
 * @param message The toast message
 * @returns JSX.Element
 */
export function errorToast(message: string) {
  return toast.error(message, {
    icon: <Icon icon={EIcon.Warning} color={cSemanticColorType.Warning} />,
    autoClose: false,
    closeButton: true,
    draggable: false,
    closeOnClick: false,
  });
}
