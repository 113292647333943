import classNames from "classnames";
import { isEmpty } from "lodash";
import { useState } from "react";
import { cSemanticColorType, cSizeType, cStatusType, cThemeColorType } from "../../../../app/constants";
import Button, { EButtonVariant } from "../../../../components/Button/Button";
import DisplayError from "../../../../components/DisplayError/DisplayError";
import Div from "../../../../components/Div/Div";
import { ETimelineType } from "../../../../components/Events/TimelineEvents";
import Icon, { EIcon } from "../../../../components/Icon/Icon";
import Column from "../../../../components/Row/Column/Column";
import Row from "../../../../components/Row/Row";
import Spinner from "../../../../components/Spinner/Spinner";
import TruncatedPopover from "../../../../components/TruncatedPopover/TruncatedPopover";
import Typography from "../../../../components/Typography/Typography";
import TransferOwnershipModal from "../../../DocumentsContainer/Documents/TransferOwnershipModal/TransferOwnershipModal";
import MatterActionPopover from "../../../MattersContainer/Matters/MatterActionPopover/MatterActionPopover";
import { INewEventModal } from "../../../WalkContainer/Walk/NewEventModalContainer/NewEventModal/NewEventModal";
import NewEventModalContainer from "../../../WalkContainer/Walk/NewEventModalContainer/NewEventModalContainer";
import { IMatterComponent } from "../Matter";
import styles from "./MatterHeader.module.scss";
import MoreDetailsModal from "./MoreDetailsModal/MoreDetailsModal";

/**
 * The header section of a single matter
 *
 * @param id			              The matter ID
 * @param urn			              The matter URN
 * @param matterStatus			    The matters loading state
 * @param error     			      Any errors from the matters API
 * @param matterType  		      The matter documentType
 * @param matterTypeStatus      Matter types loading state
 * @param matterTags  		      Tags for this matter
 * @param matterTagsStatus      Matter tags loading state
 * @param title			            The matter title
 * @param displayMilestone		  Should the milestone be displayed?
 * @param milestoneDisplayName  Milestone text to be rendered
 * @param onAddEventSuccess     Reload the timeline when a new event is added
 * @param matters               Matters records
 * @returns JSX.Element
 */
function MatterHeader({
  id,
  urn,
  matterStatus,
  error,
  matterType,
  matterTypeStatus,
  matterTags,
  matterTagsStatus,
  title,
  displayMilestone,
  milestoneDisplayName,
  onAddEventSuccess,
  matters,
}: Pick<
  IMatterComponent,
  | "id"
  | "urn"
  | "matterStatus"
  | "error"
  | "matterType"
  | "matterTypeStatus"
  | "matterTags"
  | "matterTagsStatus"
  | "title"
  | "displayMilestone"
  | "milestoneDisplayName"
  | "matters"
> &
  Pick<INewEventModal, "onAddEventSuccess">): JSX.Element {
  const [isMoreDetailsOpen, setIsMoreDetailsOpen] = useState<boolean>(false); // Is the more details (tags) modal open
  const [isNewEventModalOpen, setIsNewEventModalOpen] = useState<boolean>(false); // Is the new event modal open
  const [isTransferOwnershipModalOpen, setIsTransferOwnershipModalOpen] = useState<boolean>(false); // Is the transfer ownership modal open

  // Group the tags for the tags table rows
  const tagGroups =
    matterTags?.tags.reduce(
      (acc: { label: string; value: string }[][], { label, value }, i) => {
        // Group the tags by odd and even indices
        if (i % 2 === 0) {
          acc[0].push({ label, value });
        } else {
          acc[1].push({ label, value });
        }

        return acc;
      },
      [[], []],
    ) || [];

  /**
   * Open the more details (tags) modal
   */
  function handleMoreDetailsClick(): void {
    setIsMoreDetailsOpen(true);
  }

  /**
   * Get the matter tags table or loading / error variant
   * @returns JSX.Element
   */
  function getMatterTagsTable(): JSX.Element {
    if (matterTagsStatus === cStatusType.Loading && !matterTags) {
      return <Spinner />;
    } else if (isEmpty(matterTags?.tags)) {
      return <></>;
    } else {
      return (
        <Row className={styles.tagsRow}>
          {tagGroups.map((group: { label: string; value: string }[], i: number) => (
            <Column key={i} xs={12} md={6} className={classNames(styles.tagsCol, { [styles.secondTagsCol]: i === 1 })}>
              <Div display={{ base: "flex" }}>
                <Div>
                  {group.map((tag: { label: string; value: string }) => (
                    <Typography key={tag.label} className={styles.label}>
                      {tag.label}
                    </Typography>
                  ))}
                </Div>
                <Div pr={{ base: 5 }} className={styles.valuesWrapper}>
                  {group.map((tag: { label: string; value: string }) => (
                    <TruncatedPopover key={tag.label} className={styles.value} testId={tag.label}>
                      {tag.value}
                    </TruncatedPopover>
                  ))}
                </Div>
              </Div>
            </Column>
          ))}
        </Row>
      );
    }
  }

  return (
    <Div spacing={{ p: 6 }} className={styles.className} testId="matter-header">
      <Div display={{ base: "flex" }} justifyContent={{ base: "space-between" }}>
        <Div spacing={{ mb: 3 }}>
          <Typography className={styles.urn} variant="preHeading" casing="upper" testId="matter-ref">
            {matterStatus === cStatusType.Loading && !urn ? <Spinner size={cSizeType.Small} /> : urn}
          </Typography>
          <Typography color={cThemeColorType.Secondary} className={styles.matterType} variant="h4" weight="normal">
            {matterTypeStatus === cStatusType.Loading && !matterType ? <Spinner size={cSizeType.Small} /> : matterType}
          </Typography>
          <Typography className={styles.title} variant="h4" spacing={{ pb: 5 }}>
            {error ? (
              <DisplayError>{error}</DisplayError>
            ) : matterStatus === cStatusType.Loading && !title ? (
              <Spinner size={cSizeType.Small} />
            ) : (
              title
            )}
          </Typography>
          {displayMilestone && (
            <Div
              display={{ base: "flex" }}
              alignItems={{ base: "center" }}
              spacing={{ pb: 5 }}
              className={styles.milestoneWrapper}
              testId="matter-milestone"
            >
              <Icon icon={EIcon.Milestone} color={cSemanticColorType.Success} />
              <Typography className={styles.milestoneDisplayName}>
                {milestoneDisplayName === null ? "-" : milestoneDisplayName}
              </Typography>
            </Div>
          )}
        </Div>
        <Div>
          {matters[Number(id)] && (
            <MatterActionPopover
              matterID={Number(id)}
              setIsNewEventModalOpen={setIsNewEventModalOpen}
              setIsTransferOwnershipModalOpen={setIsTransferOwnershipModalOpen}
              action={matters[Number(id)].action}
            />
          )}
        </Div>
      </Div>
      <Div display={{ md: "flex" }} alignItems={{ base: "flex-end" }}>
        {getMatterTagsTable()}
        {matterTags && !isEmpty(matterTags.tags) && (
          <Button
            variant={EButtonVariant.Link}
            className={styles.moreDetailsBtn}
            onClick={handleMoreDetailsClick}
            testId="more-details-btn"
          >
            Detailed view
          </Button>
        )}
      </Div>
      <MoreDetailsModal
        tags={matterTags?.tags}
        handleClose={() => setIsMoreDetailsOpen(false)}
        isOpen={isMoreDetailsOpen}
      />
      <NewEventModalContainer
        isOpen={isNewEventModalOpen}
        handleClose={() => setIsNewEventModalOpen(false)}
        id={Number(id)}
        onAddEventSuccess={onAddEventSuccess}
        linkedToType="matter"
      />
      <TransferOwnershipModal
        isOpen={isTransferOwnershipModalOpen}
        handleClose={() => setIsTransferOwnershipModalOpen(false)}
        onSubmitSuccess={onAddEventSuccess}
        type={ETimelineType.Matter}
        matterID={Number(id)}
        matters={matters}
      />
    </Div>
  );
}

export default MatterHeader;
