import classNames from "classnames";
import { ReactComponent as Icon } from "../../../icons/inaccessible.svg";
import { IIconInternal } from "../Icon";
import styles from "./IconInternal.module.scss";

/**
 * Inaccessible icon
 * @param size  The size of the icon
 * @param color The color of the icon
 * @param className Custom class
 * @returns JSX.Element
 */
function Inaccessible({ size, color, className }: IIconInternal): JSX.Element {
  const clx = classNames(styles.className, styles[size], styles[color], className);
  return <Icon className={clx} />;
}

export default Inaccessible;
