import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { openInformationUpdatedModal } from "../../../../modules/documentsSlice";
import {
  postEventCustomAdd,
  postPossibleRecipients,
  selectEventCustomError,
  selectEventCustomNotificationRecipients,
  selectEventCustomStatus,
} from "../../../../modules/eventCustomSlice";
import { EWalkAction, postWalkAction, selectWalkData } from "../../../../modules/walkSlice";
import { isOutdatedContentError } from "../../../../utils/errors/errors";
import NewEventModal, { INewEventModal } from "./NewEventModal/NewEventModal";

export interface IHandleSubmit {
  description: string;
  date: string;
  warningDays: number | string;
  userIDs: number[];
  linkedToType: "document" | "matter";
}

/**
 * Container for new event modal
 * @param isOpen            Is the modal open?
 * @param handleClose       Handle close of the modal
 * @param id                The document or matter id
 * @param linkedToType      Matter or document
 * @returns JSX.Element
 */
function NewEventModalContainer({
  isOpen,
  handleClose,
  id,
  onAddEventSuccess,
  linkedToType,
}: Pick<INewEventModal, "isOpen" | "handleClose" | "id" | "onAddEventSuccess"> & Pick<IHandleSubmit, "linkedToType">) {
  const dispatch = useAppDispatch();
  const walkData = useAppSelector(selectWalkData);
  const notificationRecipients = useAppSelector(selectEventCustomNotificationRecipients);
  const linkedToID = walkData?.documentID || id;
  const eventCustomStatus = useAppSelector(selectEventCustomStatus);
  const eventCustomError = useAppSelector(selectEventCustomError);

  // Fetch notification recipient users when open
  useEffect(() => {
    if (isOpen === true) {
      dispatch(postPossibleRecipients({ linkedToType, linkedToID: linkedToID as number }));
    }
  }, [isOpen]);

  /**
   * Returns true if walkData?.documentID is defined
   * @returns boolean
   */
  function isDuringWalk() {
    return !!walkData?.documentID;
  }

  /**
   * Dispatch the add event form result
   * @param formData Data from the form inputs
   */
  async function dispatchSubmit(formData: IHandleSubmit): Promise<void> {
    return dispatch(
      postEventCustomAdd({
        ...formData,
        linkedToID: linkedToID as number,
        linkedToType,
      }),
    )
      .unwrap()
      .then(() => {
        onAddEventSuccess && onAddEventSuccess();
        if (isDuringWalk()) {
          dispatch(postWalkAction({ type: EWalkAction.Resume, formData: {} }));
        }
        handleClose();
      })
      .catch((error: any) => {
        const errorCode = parseInt(error, 10);
        if (isOutdatedContentError(errorCode)) {
          dispatch(openInformationUpdatedModal());
        }
        console.error(error);
      });
  }

  return (
    <NewEventModal
      isOpen={isOpen}
      handleClose={handleClose}
      eventCustomStatus={eventCustomStatus}
      eventCustomError={eventCustomError}
      notificationRecipients={notificationRecipients}
      dispatchSubmit={dispatchSubmit}
      eventType={linkedToType}
    />
  );
}

export default NewEventModalContainer;
