import { memo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { cStatusType } from "../../../../app/constants";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import DiscardChangesModal from "../../../../components/DiscardChangesModal/DiscardChangesModal";
import Div from "../../../../components/Div/Div";
import Modal, { ModalContent, ModalHeader, ModalTitle } from "../../../../components/Modal/Modal";
import Spinner from "../../../../components/Spinner/Spinner";
import { postCancelESign, resetESign, selectESignStatus } from "../../../../modules/esignSlice";
import styles from "./ESignIFrameModal.module.scss";

export interface IESignIFrameModal {
  isOpen: boolean;
  handleClose: (canCloseESignWorkflowModal: boolean) => void;
  iFrameUrl?: string;
  esignID?: number | null;
}

/**
 * A modal to start the esign process
 * @param isOpen            Is the modal open?
 * @param handleClose       Function to close the modal
 * @param iFrameUrl         The iframe url
 * @param esignID           The esign id
 * @returns JSX.Element
 */
function ESignIFrameModal({ isOpen, handleClose, iFrameUrl, esignID }: IESignIFrameModal) {
  const [isDiscardChangesModalOpen, setIsDiscardChangesModalOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const dispatch = useAppDispatch();
  const status = useAppSelector(selectESignStatus);

  /**
   * Handles iframe load
   */
  function handleIFrameLoad() {
    try {
      setShowSpinner(false);
      const iframeLocation = iFrameRef?.current?.contentWindow?.location;
      // If the origin exists, we are on the same origin (Updraft) so we can close the modal
      if (iframeLocation?.origin && iframeLocation?.origin !== "null") {
        handleClose(true);
        dispatch(resetESign());
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Handles Modal close
   */
  function handleModalClose() {
    setIsDiscardChangesModalOpen(true);
  }

  /**
   * Handles DiscardChangesModal close
   * @param canDiscard Can we discard the changes?
   */
  async function handleDiscardChangesModalClose(canDiscard: boolean) {
    if (canDiscard && esignID) {
      await dispatch(postCancelESign({ id: esignID })).unwrap();
      toast("Signing process cancelled");
      handleClose(false);
    }
    setIsDiscardChangesModalOpen(false);
  }

  return (
    <>
      <Modal isOpen={isOpen} handleClose={handleModalClose} size="xxl" testId="esign-iframe-modal">
        <ModalHeader handleClose={handleModalClose} hasBoxShadow>
          <ModalTitle>Prepare document for e-signature</ModalTitle>
        </ModalHeader>
        <ModalContent spacing={{ p: 0 }}>
          {showSpinner && (
            <Div className={styles.spinnerWrapper}>
              <Spinner />
            </Div>
          )}
          <Div className={styles.iframeWrapper}>
            <iframe
              ref={iFrameRef}
              title="esign-iframe"
              src={iFrameUrl}
              className={styles.iframe}
              onLoad={handleIFrameLoad}
            />
          </Div>
        </ModalContent>
      </Modal>
      <DiscardChangesModal
        isOpen={isDiscardChangesModalOpen}
        handleClose={(canDiscard) => handleDiscardChangesModalClose(canDiscard)}
        isLoading={status === cStatusType.Loading}
      />
    </>
  );
}

export default memo(ESignIFrameModal);
