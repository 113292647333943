import { lazy, Suspense } from "react";
import Spinner from "../../components/Spinner/Spinner";

const LazyLogin = lazy(() => import("./LoginContainer"));

const Login = (props: JSX.IntrinsicAttributes) => (
  <Suspense fallback={<Spinner />}>
    <LazyLogin {...props} />
  </Suspense>
);

export default Login;
