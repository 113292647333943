import { customErrors } from "../../app/customErrors";
import { isDuplicateGroupRecipientError, isInvalidCredentialsError } from "../errors/errors";

/**
 * Returns a custom error message if we have one, else the original message
 * @param errorCode    The error code
 * @param errorMessage The error message
 * @returns string
 */
export function handleErrorMessage(errorCode: number, errorMessage: string) {
  const message = customErrors[errorCode] || errorMessage;
  if (isInvalidCredentialsError(errorCode) || isDuplicateGroupRecipientError(errorCode)) {
    return `${message}`;
  } else {
    return `${errorCode}: ${message}`;
  }
}
