import { matchPath, useLocation } from "react-router-dom";
import { cRouteType } from "../../app/constants";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { resetWalkInProgress } from "../../modules/documentsSlice";
import { postMatterDocuments } from "../../modules/matterDocumentsSlice";
import {
  EWalkAction,
  EWalkStage,
  postWalkAction,
  selectWalkData,
  TWalkData,
  updateWalkIsActive,
  updateWalkResume,
} from "../../modules/walkSlice";
import ResumeWalkModal from "./ResumeWalkModal/ResumeWalkModal";

interface IResumeWalkModalContainer {
  stage?: EWalkStage;
  documentType?: string;
}

/**
 * Container for the resume walk modal
 * @param stage        The walk stage
 * @param discardWalk  The walk document type
 * @returns JSX.Element
 */
function ResumeWalkModalContainer({ stage, documentType }: IResumeWalkModalContainer): JSX.Element {
  const dispatch = useAppDispatch();
  const walkData = useAppSelector(selectWalkData) as TWalkData; // Fetch the walk data
  const location = useLocation();
  const { pathname } = location;

  const matterUrlMatch = matchPath<"id", string>(
    {
      path: `${cRouteType.Matters}/:id`,
      caseSensitive: false,
    },
    pathname,
  );

  /**
   * Reset walk resume and update is active state for walk
   */
  function continueWalk(): void {
    dispatch(updateWalkResume(false));
    dispatch(updateWalkIsActive(true));
  }

  /**
   * Discard the in-progress walk
   */
  async function discardWalk(): Promise<void> {
    await dispatch(postWalkAction({ type: EWalkAction.Discard })).unwrap();
    if (walkData.urn) {
      dispatch(resetWalkInProgress(walkData.urn));
    }
    // If we're discarding a matter interview, refresh list of matter documents
    if (matterUrlMatch) {
      const id = matterUrlMatch.params.id;
      await dispatch(postMatterDocuments(Number(id))).unwrap();
    }
  }

  return (
    <ResumeWalkModal continueWalk={continueWalk} discardWalk={discardWalk} stage={stage} documentType={documentType} />
  );
}

export default ResumeWalkModalContainer;
