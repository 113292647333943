import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../app/api";
import { interviewLogUrl } from "../app/apiUrls";
import { RootState } from "../app/store";
import { errorToast } from "../toast/toast";

/**
 * Describes a log input
 */
interface ILogInput {
  data: string;
  prompt: string;
  option?: never;
}

/**
 * Describes a log question
 */
interface ILogQuestion {
  data?: never;
  prompt: string;
  option: string;
}

/**
 * Describes a log
 */
export interface ILog {
  [key: string]: ILogInput | ILogQuestion;
}

interface IInteviewLogState {
  entry: ILog[];
  error?: string;
}

const initialState: IInteviewLogState = {
  entry: [],
};

/**
 * Thunk for getting the interview log
 */
export const postInterviewLog = createAsyncThunk(
  "documents/postInterviewLog",
  async (walkID: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await api({ endpoint: interviewLogUrl, dispatch, body: { walkID } });
      return response.data.interviewLog;
    } catch (err: any) {
      errorToast(err.message);
      throw rejectWithValue(err.message);
    }
  },
);

export const interviewLogSlice = createSlice({
  name: "interviewLog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Reset error state when the promise is pending
      .addCase(postInterviewLog.pending, (state) => {
        state.error = undefined;
      })
      // Set error to response if the promise is rejected
      .addCase(postInterviewLog.rejected, (state, action) => {
        state.error = action.payload as string;
      })
      // Set the interview log if the promise is fulfilled
      .addCase(postInterviewLog.fulfilled, (state, action) => {
        state.entry = action.payload;
      });
  },
});

// Select log
export const selectInterviewLog = (state: RootState) => state.interviewLog.entry;

export default interviewLogSlice.reducer;
