import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Div from "../../../../../components/Div/Div";
import FormField, { cFormFieldType } from "../../../../../components/FormField/FormField";
import Label from "../../../../../components/FormField/Label/Label";
import Typography from "../../../../../components/Typography/Typography";
import { IHandlePause } from "../../../WalkContainer";
import styles from "./WalkPauseModalContent.module.scss";

type Inputs = {
  documentDescription: string;
  note: string;
};

interface IWalkPauseModalContent {
  handlePause: (formData: IHandlePause) => void;
  documentDescription?: string;
  errorRequireDescription?: boolean;
}

/**
 * Walk pause form
 * @param handlePause             Handle pause of active walk
 * @param documentDescription     Description of the document
 * @param errorRequireDescription Is a description required?
 * @returns JSX.Element
 */
function WalkPauseModalContent({
  handlePause,
  documentDescription,
  errorRequireDescription,
}: IWalkPauseModalContent): JSX.Element {
  const [requireDescription, setRequireDescription] = useState<boolean>(false);

  const {
    register, // Register prop for form inputs
    handleSubmit, // Submit handler wrapper
    formState: { errors }, // Errors that may occur
    reset,
  } = useForm<Inputs>();

  /**
   * Reset form values when unmounted
   */
  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  /**
   * Handle form submission
   * @param formData Form values
   */
  const onSubmit: SubmitHandler<Inputs> = (formData) => {
    handlePause(formData);
  };

  useEffect(() => {
    if (errorRequireDescription === true) {
      setRequireDescription(true);
    } else if (errorRequireDescription === false) {
      setRequireDescription(false);
    } else if (!documentDescription) {
      setRequireDescription(true);
    } else {
      setRequireDescription(false);
    }
  }, [errorRequireDescription, documentDescription]);

  return (
    <form id="walk-pause-modal" className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      {!!documentDescription && (
        <Div spacing={{ mb: 5 }}>
          <Label>Document name</Label>
          <Typography className={styles.description}>{documentDescription}</Typography>
        </Div>
      )}
      <FormField
        label="Please provide a name for this document"
        name="documentDescription"
        register={register}
        error={errors.documentDescription}
        spacing={{ mb: 5 }}
        fullWidth
        required={requireDescription}
        hidden={!requireDescription}
        autoFocus={requireDescription}
      />
      <FormField
        label="Optional additional information"
        name="note"
        register={register}
        type={cFormFieldType.Textarea}
        error={errors.note}
        fullWidth
        autoFocus={!requireDescription}
      />
    </form>
  );
}

export default WalkPauseModalContent;
