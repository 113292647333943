import Div from "../../../../../../components/Div/Div";
import styles from "./MatterBoxWrapper.module.scss";

interface IMatterBoxWrapper {
  header: React.ReactNode;
  children: React.ReactNode;
}

/**
 * Matter tasks box element
 * @param header   Header content
 * @param children Box content
 * @returns JSX.Element
 */
function MatterBoxWrapper({ header, children }: IMatterBoxWrapper): JSX.Element {
  return (
    <Div className={styles.taskPaneSection}>
      <Div className={styles.className}>
        <Div className={styles.header} p={{ base: 5 }}>
          {header}
        </Div>
        <Div className={styles.content}>{children}</Div>
      </Div>
    </Div>
  );
}

export default MatterBoxWrapper;
