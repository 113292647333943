import { lazy, Suspense } from "react";
import Spinner from "../../components/Spinner/Spinner";

const LazyPasswordReset = lazy(() => import("./PasswordReset"));

const PasswordReset = () => (
  <Suspense fallback={<Spinner />}>
    <LazyPasswordReset />
  </Suspense>
);

export default PasswordReset;
