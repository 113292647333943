import { format } from "date-fns";
import { useState } from "react";
import { Control, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { cStatusType, cThemeColorType } from "../../../../../app/constants";
import Button, { cButtonType } from "../../../../../components/Button/Button";
import DiscardChangesModal from "../../../../../components/DiscardChangesModal/DiscardChangesModal";
import DisplayError from "../../../../../components/DisplayError/DisplayError";
import Div from "../../../../../components/Div/Div";
import FormField, { cFormFieldType, IFieldOption } from "../../../../../components/FormField/FormField";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../../components/Modal/Modal";
import { INotificationRecipient } from "../../../../../modules/eventCustomSlice";
import { IHandleSubmit } from "../NewEventModalContainer";
import styles from "./NewEventModal.module.scss";

type Inputs = {
  description: string | null;
  date: string | null;
  warningDays: string | null;
  userIDs: IFieldOption[] | null;
};

const defaultValues = {
  description: null,
  date: null,
  warningDays: "0",
  userIDs: [],
};

export interface INewEventModal {
  isOpen: boolean;
  handleClose: () => void;
  eventCustomStatus: cStatusType;
  eventCustomError?: string;
  notificationRecipients: INotificationRecipient[];
  dispatchSubmit: (data: IHandleSubmit) => Promise<void>;
  id?: number | null;
  onAddEventSuccess?: Function;
  eventType?: string;
}

/**
 * Modal to add a new event
 * @param isOpen                   Is the modal open?
 * @param handleClose              Function to close the modal
 * @param eventCustomStatus        Status of event custom API call
 * @param eventCustomError         Any errors on event custom API calls
 * @param notificationRecipients   User IDs for potential notification recipients
 * @param dispatchSubmit           Dispatch event form function
 * @param eventType                E.g. Matter or Document
 * @returns JSX.Element
 */
function NewEventModal({
  isOpen,
  handleClose,
  eventCustomStatus,
  eventCustomError,
  notificationRecipients,
  dispatchSubmit,
  eventType,
}: INewEventModal) {
  const {
    register, // Register prop for form inputs
    handleSubmit, // Submit handler wrapper
    formState: { errors }, // Errors that may occur
    control,
    reset,
    watch,
  } = useForm<Inputs>({ defaultValues });

  const [isDiscardChangesModalOpen, setIsDiscardChangesModalOpen] = useState(false);

  /**
   * Convert string to sentence case
   */
  function toSentenceCase(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  /**
   * Handles modal close
   */
  function handleModalClose(canDiscard?: boolean) {
    if (canDiscard) {
      handleClose();
      reset();
    } else {
      const { description, date, warningDays, userIDs } = watch();

      if (
        (description === null || description === "") &&
        (date === null || date === "") &&
        (warningDays === null || warningDays === "0") &&
        userIDs?.length === 0
      ) {
        setIsDiscardChangesModalOpen(false);
        handleClose();
      } else {
        setIsDiscardChangesModalOpen(true);
      }
    }
  }

  /**
   * Handles DiscardChangesModal close
   */
  function handleDiscardChangesModalClose(canDiscard?: boolean) {
    if (canDiscard) {
      setIsDiscardChangesModalOpen(false);
      handleModalClose(true);
    } else {
      setIsDiscardChangesModalOpen(false);
    }
  }

  /**
   * Handle form submission
   * @param formData Form values
   */
  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    const data = {
      ...formData,
      userIDs: formData.userIDs?.map(({ value }) => value),
      linkedToType: "document" as const,
      warningDays: parseInt(formData.warningDays as string, 10),
      date: format(new Date(formData.date as string), "yyyy-MM-dd"),
    };

    try {
      await dispatchSubmit(data as IHandleSubmit);
      toast("Event added");
      reset();
    } catch (error) {
      console.error("Error adding event:", error);
    }
  };

  let recipientsOptions;

  if (eventCustomStatus === cStatusType.Loading) {
    recipientsOptions = [
      {
        label: "Loading...",
        value: "",
      },
    ];
  } else {
    recipientsOptions = notificationRecipients.map(({ id, name, pseudoBoo }) => {
      return { label: name, value: id, isInternal: pseudoBoo };
    });
  }

  const isLoading = eventCustomStatus === cStatusType.Loading;

  return (
    <>
      <Modal isOpen={isOpen} handleClose={() => handleModalClose()} testId="new-event-modal" size="md">
        <ModalHeader>
          <ModalTitle>New {eventType ? toSentenceCase(eventType) : ""} Event</ModalTitle>
        </ModalHeader>
        <ModalContent>
          <form id="new-event-modal" onSubmit={handleSubmit(onSubmit)}>
            <FormField
              label="Description"
              name="description"
              register={register}
              error={errors.description}
              spacing={{ mb: 5 }}
              required
              validate={(value: string) => {
                if (value.length > 175) {
                  return "Event descriptions support a maximum length of 175 characters";
                }
              }}
              testId="new-event-modal-description-field"
              fullWidth
            />
            <FormField
              type={cFormFieldType.DatePicker}
              label="Date"
              name="date"
              register={register}
              error={errors.date}
              spacing={{ mb: 5 }}
              minDate={new Date()}
              required
            />
            <FormField
              inputClassName={styles.number}
              type={cFormFieldType.Number}
              label="Notification Grace period"
              name="warningDays"
              register={register}
              error={errors.warningDays}
              spacing={{ mb: 5 }}
              required
              append="days"
              testId="new-event-modal-grace-field"
              validate={(value: number) => {
                if (value < 0) {
                  return "Grace period cannot be less than 0";
                }
              }}
            />
            <Div testId="notification-recipients">
              <FormField
                type={cFormFieldType.AutoComplete}
                label="Notification Recipients"
                name="userIDs"
                options={recipientsOptions}
                register={register}
                error={errors.userIDs as any}
                spacing={{ mb: 5 }}
                control={control as Control<any, any>}
                isMulti
                required
                fullWidth
                onInputChange={() => {}}
              />
            </Div>
          </form>
          {eventCustomError && <DisplayError>{eventCustomError}</DisplayError>}
        </ModalContent>
        <ModalActions>
          <Button
            onClick={() => handleModalClose()}
            color={cThemeColorType.Secondary}
            testId="new-event-modal-cancel-button"
          >
            Cancel
          </Button>
          <Button
            formId="new-event-modal"
            type={cButtonType.SubmitType}
            disabled={isLoading}
            testId="new-event-modal-save-btn"
          >
            Save
          </Button>
        </ModalActions>
      </Modal>
      <DiscardChangesModal
        isOpen={isDiscardChangesModalOpen}
        handleClose={(canDiscard) => handleDiscardChangesModalClose(canDiscard)}
      />
    </>
  );
}

export default NewEventModal;
