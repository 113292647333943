import classNames from "classnames";
import { IBorder } from "../../app/types";

import { AriaRole } from "react";
import {
  useAlignContent,
  useAlignItems,
  useAlignSelf,
  useBorder,
  useJustifyContent,
  useJustifyItems,
  useJustifySelf,
  useMargin,
  useMarginB,
  useMarginL,
  useMarginR,
  useMarginT,
  useMarginX,
  useMarginY,
  usePadding,
  usePaddingB,
  usePaddingL,
  usePaddingR,
  usePaddingT,
  usePaddingX,
  usePaddingY,
} from "../../app/hooks";
import {
  IAlignContent,
  IAlignItems,
  IAlignSelf,
  IJustifyContent,
  IJustifyItems,
  IJustifySelf,
  IMargin,
  IMarginB,
  IMarginL,
  IMarginR,
  IMarginT,
  IMarginX,
  IMarginY,
  IPadding,
  IPaddingB,
  IPaddingL,
  IPaddingR,
  IPaddingT,
  IPaddingX,
  IPaddingY,
} from "../../app/styleTypes";
import Div from "../Div/Div";
import { IColumn } from "./Column/Column";
import styles from "./Row.module.scss";

/**
 * Possible values for justify content prop
 */
export enum EJustifyContent {
  Start = "Start",
  Center = "Center",
  End = "End",
  SpaceBetween = "SpaceBetween",
  SpaceAround = "SpaceAround",
  SpaceEvenly = "SpaceEvenly",
}

/**
 * Possible values for align items prop
 */
export enum EAlignItems {
  Start = "Start",
  Center = "Center",
  End = "End",
  Stretch = "Stretch",
  Baseline = "Baseline",
}

interface IRow {
  children: React.ReactElement<IColumn> | React.ReactElement<IColumn>[];
  alignItems?: IAlignItems;
  alignContent?: IAlignContent;
  alignSelf?: IAlignSelf;
  justifyContent?: IJustifyContent;
  justifyItems?: IJustifyItems;
  justifySelf?: IJustifySelf;
  className?: string;
  p?: IPadding;
  py?: IPaddingY;
  px?: IPaddingX;
  pt?: IPaddingT;
  pr?: IPaddingR;
  pb?: IPaddingB;
  pl?: IPaddingL;
  m?: IMargin;
  my?: IMarginY;
  mx?: IMarginX;
  mt?: IMarginT;
  mr?: IMarginR;
  mb?: IMarginB;
  ml?: IMarginL;
  border?: IBorder;
  role?: AriaRole;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  testId?: string;
}

/**
 * A row of columns - 12 column grid
 * @param children       Columns of the row
 * @param alignItems     Enable align-items styles
 * @param alignContent   Enable align-content styles
 * @param alignSelf      Enable align-self styles
 * @param justifyContent Enable justify-content styles
 * @param justifyItems   Enable justify-items styles
 * @param justifySelf    Enable justify-self styles
 * @param className      Custom class
 * @param p              Set Padding object
 * @param py             Set Padding-y object
 * @param px             Set Padding-x object
 * @param pt             Set Padding-top object
 * @param pr             Set Padding-right object
 * @param pb             Set Padding-bottom object
 * @param pl             Set Padding-left object
 * @param m              Set Margin object
 * @param my             Set Margin-y object
 * @param mx             Set Margin-x object
 * @param mt             Set Margin-top object
 * @param mr             Set Margin-right object
 * @param mb             Set Margin-bottom object
 * @param ml             Set Margin-left object
 * @param border         Set Border object
 * @param role           Set the Aria role to Row for flex tables
 * @param onClick        A function to handle onClick
 * @param testId         Test ID
 * @returns JSX.Element
 */
function Row({
  children,
  alignItems,
  alignContent,
  alignSelf,
  justifyContent,
  justifyItems,
  justifySelf,
  className,
  p,
  px,
  py,
  pt,
  pr,
  pb,
  pl,
  m,
  mx,
  my,
  mt,
  mr,
  mb,
  ml,
  border,
  role,
  onClick,
  testId,
}: IRow): JSX.Element {
  const pClx = usePadding(p); // Get padding classes
  const pyClx = usePaddingY(py); // Get padding-y classes
  const pxClx = usePaddingX(px); // Get padding-x classes
  const ptClx = usePaddingT(pt); // Get padding-top classes
  const prClx = usePaddingR(pr); // Get padding-right classes
  const pbClx = usePaddingB(pb); // Get padding-bottom classes
  const plClx = usePaddingL(pl); // Get padding-left classes
  const mClx = useMargin(m); // Get margin classes
  const myClx = useMarginY(my); // Get margin-y classes
  const mxClx = useMarginX(mx); // Get margin-x classes
  const mtClx = useMarginT(mt); // Get margin-top classes
  const mrClx = useMarginR(mr); // Get margin-right classes
  const mbClx = useMarginB(mb); // Get margin-bottom classes
  const mlClx = useMarginL(ml); // Get margin-left classes
  const borderClx = useBorder(border); // Get border classes
  const alignItemsClx = useAlignItems(alignItems); // Get align-items classes
  const alignContentClx = useAlignContent(alignContent); // Get align-content classes
  const alignSelfClx = useAlignSelf(alignSelf); // Get align-self classes
  const justifyItemsClx = useJustifyItems(justifyItems); // Get justify-items classes
  const justifyContentClx = useJustifyContent(justifyContent); // Get justify-content classes
  const justifySelfClx = useJustifySelf(justifySelf); // Get justify-self classes

  const clx = classNames(
    styles.className, // Base class
    justifyContent && styles[`justify${justifyContent}`], // Justify content if exists

    className, // Add custom class if exists
    pClx, // Padding
    pyClx, // Padding
    pxClx, // Padding
    ptClx, // Padding
    prClx, // Padding
    pbClx, // Padding
    plClx, // Padding
    mClx, // Margin
    myClx, // Margin
    mxClx, // Margin
    mtClx, // Margin
    mrClx, // Margin
    mbClx, // Margin
    mlClx, // Margin
    borderClx, // Border
    alignItemsClx,
    alignContentClx,
    alignSelfClx,
    justifyItemsClx,
    justifyContentClx,
    justifySelfClx,
  );

  return (
    <Div className={clx} role={role} onClick={onClick} testId={testId}>
      {children}
    </Div>
  );
}

export default Row;
