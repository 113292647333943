/**
 * Spacing enum set - 1 - 8
 */
export enum ESpacing {
  _0 = 0,
  _1,
  _2,
  _3,
  _4,
  _5,
  _6,
  _7,
  _8,
}

/**
 * Available spacing object interface
 */
export interface ISpacing {
  // Padding
  p?: ESpacing; // Full
  py?: ESpacing; // Y Axis
  px?: ESpacing; // X Axis
  pt?: ESpacing; // Top
  pr?: ESpacing; // Right
  pb?: ESpacing; // Bottom
  pl?: ESpacing; // Left
  // Margin
  m?: ESpacing; // Full
  my?: ESpacing; // Y Axis
  mx?: ESpacing; // X Axis
  mt?: ESpacing; // Top
  mr?: ESpacing; // Right
  mb?: ESpacing; // Bottom
  ml?: ESpacing; // Left
}

/**
 * Available border object interface
 */
export interface IBorder {
  b?: boolean;
  by?: boolean;
  bx?: boolean;
  bt?: boolean;
  br?: boolean;
  bb?: boolean;
  bl?: boolean;
}

/**
 * Directional interface
 */
export enum IDirection {
  Top = "top",
  Right = "right",
  Bottom = "bottom",
  Left = "left",
}

/**
 * X-axis directional interface
 */
export enum IXDirection {
  Right = "right",
  Center = "center",
  Left = "left",
  Justify = "justify",
}

/**
 * Y-axis directional interface
 */
export enum IYDirection {
  Top = "top",
  Bottom = "bottom",
}

/**
 * Enable scrollbars
 */
export interface IScroll {
  y?: boolean; // Y-Axis
  x?: boolean; // X-Axis
}

/**
 * Popover placement
 */
export enum EPopoverPlacement {
  Top = "top",
  TopStart = "top-start",
  TopEnd = "top-end",
  Bottom = "bottom",
  BottomStart = "bottom-start",
  BottomEnd = "bottom-end",
  Right = "right",
  RightStart = "right-start",
  RightEnd = "right-end",
  Left = "left",
  LeftStart = "left-start",
  LeftEnd = "left-end",
}

/**
 * Describe ID type from APIs
 */
export interface IID {
  id: number;
}

/**
 * List sorting and filtering for tables
 */
export enum TFilterType {
  Date = "date", // Sort by date
  Input = "input", // Filter by input text
  Select = "select", // Filter via select list
  AutoComplete = "autocomplete", // Suggests as you type
  Actions = "actions", // Actions column
}

/**
 * Empty types for lists
 */
export enum EEmptyType {
  End = 1,
  NotFound,
  NotificationsNotFound,
  FilterNotFound,
}

/**
 * Allowing optionals on TS Pick
 */
export type OptionalPick<T, K extends PropertyKey> = Pick<T, Extract<keyof T, K>>;
