import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { cThemeColorType, CUSTOMER_CODE } from "../../../app/constants";
import { useAppDispatch, useAppSelector, useQuery } from "../../../app/hooks";
import {
  postContactSupport,
  selectCustomerError,
  selectCustomerStatus,
  selectRecaptchaKey,
} from "../../../modules/customerSlice";
import { selectIsLoggedIn, selectUser } from "../../../modules/userSlice";
import Box from "../../Box/Box";
import Button, { EButtonVariant } from "../../Button/Button";
import Div from "../../Div/Div";
import Icon, { EIcon } from "../../Icon/Icon";
import Row from "../../Row/Row";
import Typography from "../../Typography/Typography";
import SupportForm, { Inputs } from "./SupportForm/SupportForm";
import styles from "./SupportFormContainer.module.scss";
import SupportSuccess from "./SupportSuccess/SupportSuccess";

interface ISupportForm {
  handleToggle: () => void;
}

/**
 * The customer support form
 * @param handleToggle Toggles the support form open/closed
 * @returns JSX.Element
 */
function SupportFormContainer({ handleToggle }: ISupportForm): JSX.Element {
  const recaptchaKey = useAppSelector(selectRecaptchaKey);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const user = useAppSelector(selectUser);
  const customerError = useAppSelector(selectCustomerError);
  const status = useAppSelector(selectCustomerStatus);
  const [isRecaptchaChecked, setIsRecaptchaChecked] = useState(false);
  const [isSentSuccessfully, setIsSentSuccessfully] = useState(false);
  const query = useQuery();
  const dispatch = useAppDispatch();

  /**
   * Handles recaptcha change
   */
  function handleRecaptchaChange(token: string | null) {
    if (token) {
      setIsRecaptchaChecked(true);
    }
  }

  /**
   * Submit the support form
   * @param data Support form data object
   */
  async function submitSupportForm(data: Inputs) {
    try {
      const customerCode = query.get("ccof") || localStorage.getItem(CUSTOMER_CODE);
      await dispatch(postContactSupport({ ...data, ccof: customerCode || "" })).unwrap();
      setIsSentSuccessfully(true);
    } catch (error) {
      setIsSentSuccessfully(false);
    }
  }

  /**
   * Renders the correct form content
   * @returns JSX.Element
   */
  function renderContent() {
    if (isSentSuccessfully) {
      return <SupportSuccess />;
    } else if (isLoggedIn) {
      return (
        <SupportForm
          user={user}
          customerError={customerError}
          status={status}
          isLoggedIn={isLoggedIn}
          submitSupportForm={submitSupportForm}
        />
      );
    } else if (!isLoggedIn && recaptchaKey && !isRecaptchaChecked) {
      return <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} onChange={handleRecaptchaChange} />;
    } else if (!isLoggedIn && recaptchaKey && isRecaptchaChecked) {
      return (
        <SupportForm
          user={user}
          customerError={customerError}
          status={status}
          isLoggedIn={isLoggedIn}
          submitSupportForm={submitSupportForm}
        />
      );
    }
  }

  return (
    <Div className={styles.className} testId="customer-support-form-container">
      <Div className={styles.header}>
        <Row alignItems={{ base: "center" }}>
          <Div className={styles.logoWraper} spacing={{ mr: 4 }}>
            <img src={require("../../../Updraft-mark.png")} width="24" height="24" alt="Updraft" />
          </Div>
          <Typography className={styles.headerText} weight="bold">
            We're here to help
          </Typography>
        </Row>
        <Button color={cThemeColorType.Secondary} variant={EButtonVariant.Round} onClick={handleToggle}>
          <Icon icon={EIcon.Close} />
        </Button>
      </Div>
      <Box className={styles.content} px={{ base: 6 }} py={{ base: 5 }}>
        {renderContent()}
      </Box>
    </Div>
  );
}

export default SupportFormContainer;
