import classNames from "classnames";
import { useRef } from "react";
import { cMonoColorType } from "../../app/constants";
import { IXDirection } from "../../app/types";
import Button, { EButtonVariant } from "../Button/Button";
import Div from "../Div/Div";
import { EIcon } from "../Icon/Icon";
import styles from "./Drawer.module.scss";

export interface IDrawer {
  children: React.ReactNode | JSX.Element;
  isOpen: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  direction?: Exclude<IXDirection, IXDirection.Center>;
  width?: number | string;
  height?: number | string;
  testId?: string;
  showExpand?: boolean;
  className?: string;
}

/**
 * A (push) drawer. NOTE: wrap this and siblings in DrawerRowWrapper
 * @param children   The contents of the drawer
 * @param isOpen     Is the drawer open?
 * @param setIsOpen  Function to set drawer open state
 * @param direction  What side should the drawer render on?
 * @param width      Custom width
 * @param height     Drawer height
 * @param testId     A testId to query in the tests
 * @param showExpand If true the drawer will sit out by 20px to show that it can expand
 * @param className  Custom wrapper class
 * @returns JSX.Element
 */
function Drawer({
  children,
  isOpen,
  setIsOpen,
  direction = IXDirection.Left,
  width = 250,
  height,
  testId,
  showExpand,
  className,
}: IDrawer): JSX.Element {
  const ref: any = useRef<HTMLDivElement | null>(null); // Ref of drawer
  const clx = classNames(
    // Build class string
    styles.className, // Generic styles
    styles[direction], // Directional styles
    className, // Custom styles
  );

  const key =
    direction === IXDirection.Left // If direction is left
      ? "marginLeft" // Apply margin left style
      : "marginRight"; // Otherwise, apply margin right style
  const marginStyles = { [key]: isOpen ? 0 : -parseInt(width as string, 10) }; // Open or close the drawer based on isOpen
  const inlineStyles = height // If height is supplied
    ? { width, height, ...marginStyles } // Build inline styles with height
    : { width, ...marginStyles }; // Otherwise, build inline styles without height
  const closeIcon = direction === IXDirection.Left ? EIcon.Back : EIcon.Next;

  /**
   * Toggle open state
   */
  function toggleOpen() {
    if (setIsOpen) {
      setIsOpen((isOpen) => !isOpen);
    }
  }

  return (
    <Div className={clx} style={inlineStyles} ref={ref} testId={testId}>
      {children}
      {showExpand && isOpen && (
        <Button
          className={styles.closeBtn}
          onClick={toggleOpen}
          variant={EButtonVariant.Round}
          color={cMonoColorType.Light}
          icon={closeIcon}
        />
      )}
    </Div>
  );
}

export default Drawer;
