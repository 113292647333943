export const collaborators = [
  {
    id: 1,
    firstName: "Tarn",
    lastName: "Hodson",
    emailAddress: "test@test.com",
    phoneNumber: "+27828765142",
    action: "collaborator",
    notified: false,
    isOwner: true,
  },
  {
    id: 2,
    firstName: "John",
    lastName: "Doe",
    emailAddress: "johndoe@test.com",
    phoneNumber: "+1234567890",
    action: "viewer",
    notified: true,
  },
  {
    id: 3,
    firstName: "Jane",
    lastName: "Smith",
    emailAddress: "janesmith@test.com",
    phoneNumber: "+9876543210",
    action: "collaborator",
    notified: false,
  },
  {
    id: 4,
    firstName: "Alice",
    lastName: "Johnson",
    emailAddress: "alicejohnson@test.com",
    phoneNumber: "+5555555555",
    action: "viewer",
    notified: true,
  },
  {
    id: 5,
    firstName: "Bob",
    lastName: "Williams",
    emailAddress: "bobwilliams@test.com",
    phoneNumber: "+9999999999",
    action: "collaborator",
    notified: false,
  },
];
