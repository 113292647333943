import { cThemeColorType } from "../../../../../app/constants";
import { EPopoverPlacement } from "../../../../../app/types";
import { EIcon } from "../../../../../components/Icon/Icon";
import Popover from "../../../../../components/Popover/Popover";
import {
  EInvalidatedReasonType,
  ENotificationGroupingType,
  ENotificationStatusType,
  INotificationsEntry,
} from "../../../../../modules/notificationsSlice";

/**
 * Render the transaction icon for notifications
 * @param transaction The related transaction
 * @param status      The status of the notification
 * @param invalidCode The reason for an invalidated notification
 * @param document    The document related to the notification
 * @returns JSX.Element
 */
function TransactionIcon({
  transaction,
  status,
  invalidCode,
  document,
}: Pick<INotificationsEntry, "transaction" | "status" | "invalidCode" | "document">): JSX.Element {
  let icon;
  let label;

  /**
   * Determine if the transaction is a Document within in a Matter
   * @param transaction The related transaction
   * @returns boolean
   */
  function isDocumentAndResidesInMatter() {
    return transaction?.document?.groupingType === ENotificationGroupingType.Matter;
  }

  /**
   * Determine if the transaction is a Document
   * @param transaction The related transaction
   * @returns boolean
   */
  function isDocument() {
    return transaction?.document;
  }

  /**
   * Determines if the transaction is invalidated due to a Document with a status of Terminated
   * @param status      The status of the notification
   * @param invalidCode The reason for an invalidated notification
   * @returns boolean
   */
  function isInvalidatedDueToTermination(): boolean {
    const isDocTerminated =
      status === ENotificationStatusType.Invalidated && invalidCode === EInvalidatedReasonType.DocTerminated;
    return isDocTerminated;
  }

  /**
   * Determines if the transaction is a Document with a status of Terminated
   * @param document The document related to the notification
   * @returns A boolean indicating whether the transaction is a terminated document.
   */
  function isTerminatedDocument() {
    const isDocumentTerminated = document?.termination?.terminated;
    return isDocumentTerminated;
  }

  /**
   * Determine if the transaction is a Matter
   * @param transaction The related transaction
   * @returns boolean
   */
  function isMatter() {
    return transaction?.matter;
  }

  /**
   * Determine if the transaction is an interviewSpawn
   * @returns boolean
   */
  function isInterviewSpawn() {
    return transaction?.interviewSpawn;
  }

  if (isDocumentAndResidesInMatter()) {
    icon = EIcon.FolderOpen;
    label = "Document within Matter";
  } else if (isMatter() || isInterviewSpawn()) {
    icon = EIcon.Folder;
    label = "Matter";
  } else if (isDocument() && isInvalidatedDueToTermination()) {
    icon = EIcon.DocumentTerminated;
    label = "Document Terminated";
  } else if (isDocument() && isTerminatedDocument()) {
    icon = EIcon.DocumentTerminated;
    label = "Document Terminated";
  } else {
    icon = EIcon.Document;
    label = "Document";
  }

  return (
    <Popover // Create the popover and button
      variant="tooltip"
      popoverPlacement={EPopoverPlacement.Top}
      width="auto"
      popoverContents={label}
      divProps={{
        icon: icon,
        iconColor: cThemeColorType.Secondary,
      }}
    />
  );
}

export default TransactionIcon;
