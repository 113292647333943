import { lazy, Suspense } from "react";
import Div from "../../Div/Div";
import { ILabel } from "./Label";

const LazyLabel = lazy(() => import("./Label"));

const Label = (props: ILabel) => (
  <Suspense fallback={<Div />}>
    <LazyLabel {...props} />
  </Suspense>
);

export default Label;
