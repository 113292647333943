import { some, uniq } from "lodash";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { cRouteType, WALK_RETURN_URL } from "../../../../app/constants";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  ISpawnItem,
  postMatterReassignTask,
  postMatterTasks,
  resetReassignError,
  selectMatterReassignError,
  selectMatterReassignStatus,
  selectMatterTasks,
  selectMatterTasksError,
  selectMatterTasksStatus,
} from "../../../../modules/matterTasksSlice";
import { IUser, postUsersByID, selectUsers } from "../../../../modules/usersSlice";
import { IPostMatterSpawn, postMatterSpawn } from "../../../../modules/walkSlice";
import MatterReassignTasksModal, { EReassignType } from "./MatterReassignTasksModal/MatterReassignTasksModal";
import MatterTasksColumn from "./MatterTasksColumn/MatterTasksColumn";

interface IMatterTasksColumnContainer {
  id: number;
}

/**
 * Container for the matter tasks and resources column
 * @param id The matter ID
 * @returns JSX.Element
 */
function MatterTasksColumnContainer({ id }: IMatterTasksColumnContainer): JSX.Element {
  const dispatch = useAppDispatch();
  const error = useAppSelector(selectMatterTasksError);
  const status = useAppSelector(selectMatterTasksStatus);
  const tasksEntries = useAppSelector(selectMatterTasks);
  const users = useAppSelector(selectUsers);
  const reassignError = useAppSelector(selectMatterReassignError);
  const reassignStatus = useAppSelector(selectMatterReassignStatus);
  const tasks = tasksEntries[id];
  const [selectedTask, setSelectedTask] = useState<Pick<
    ISpawnItem,
    "id" | "description" | "name" | "assigneeUserID"
  > | null>(null);

  // Get the unique assignees
  const taskAssignees = tasks?.spawnItems.reduce((acc: IUser[], task) => {
    if (task.hasOwnProperty("assigneeUserID") && users[task.assigneeUserID as number]) {
      acc.push(users[task.assigneeUserID as number]);
    }

    return acc;
  }, []);

  const location = useLocation();
  const { pathname } = location;

  // Fetch the tasks on render
  useEffect(() => {
    dispatch(postMatterTasks(id));
  }, [id]);

  // Reset the reassign error when the selected task is null (modal is closed)
  useEffect(() => {
    if (selectedTask === null) {
      dispatch(resetReassignError());
    }
  }, [selectedTask]);

  // Fetch the users for the tasks
  useEffect(() => {
    // If any of the tasks have an assignee, fetch the users
    if (tasks?.spawnItems && some(tasks?.spawnItems, "assigneeUserID")) {
      dispatch(
        postUsersByID({
          ids:
            // Get the unique assignees
            uniq(
              // Get the assignees
              tasks.spawnItems.reduce((acc: number[], task) => {
                // If the task has an assignee, add it to the list
                // note: in certain instances, the assignee may be null where the spawn is not auto assigned
                if (task.hasOwnProperty("assigneeUserID") && task?.assigneeUserID !== null) {
                  acc.push(task.assigneeUserID as number);
                }
                return acc;
              }, []),
            ) || [],
        }),
      );
    }
  }, [tasks]);

  /**
   * Draft a spawn document
   * @param custWalkTypeID Walk type ID to start the walk
   * @param matterID       Matter ID to attach the spawn to
   * @param spawnID        Spawn ID that walk is launched from
   * @param compositeState The composite state of the spawn
   */
  async function handleDraftDocument({
    custWalkTypeID,
    matterID,
    spawnID,
    compositeState,
  }: IPostMatterSpawn): Promise<void> {
    await dispatch(postMatterSpawn({ custWalkTypeID, matterID, spawnID, compositeState })).unwrap();
    // Set the return URL for the walk
    if (pathname.includes(cRouteType.Notifications)) {
      localStorage.setItem(WALK_RETURN_URL, `${cRouteType.Notifications}`);
    } else {
      localStorage.setItem(WALK_RETURN_URL, `${cRouteType.Matters}/${id}`);
    }
  }

  /**
   * Reassign task / tasks
   * @param type Assign only this task or assign all user's tasks
   */
  async function reassignTask(type: EReassignType): Promise<void> {
    try {
      const data =
        type === EReassignType.Only
          ? { taskID: selectedTask?.id as number }
          : { assigneeUserID: selectedTask?.assigneeUserID as number };
      await dispatch(postMatterReassignTask(data)).unwrap();
      await dispatch(postMatterTasks(id)).unwrap();
      setSelectedTask(null);
    } catch (error: any) {
      console.error(error.message);
    }
  }

  return (
    <>
      <MatterTasksColumn
        tasks={tasks}
        error={error}
        status={status}
        handleDraftDocument={handleDraftDocument}
        matterID={id}
        taskAssignees={taskAssignees}
        setSelectedTask={setSelectedTask}
      />
      <MatterReassignTasksModal
        isOpen={selectedTask !== null}
        handleClose={() => setSelectedTask(null)}
        taskName={selectedTask?.name || ""}
        taskDescription={selectedTask?.description || ""}
        user={taskAssignees?.find((taskAssignee) => taskAssignee.id === selectedTask?.assigneeUserID) as IUser}
        reassignTask={reassignTask}
        error={reassignError}
        status={reassignStatus}
      />
    </>
  );
}

export default MatterTasksColumnContainer;
