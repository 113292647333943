import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { cMonoColorType, cStatusType, cThemeColorType } from "../../../../../app/constants";
import Button, { cButtonType } from "../../../../../components/Button/Button";
import DisplayError from "../../../../../components/DisplayError/DisplayError";
import Div from "../../../../../components/Div/Div";
import FormField, { cFormFieldType } from "../../../../../components/FormField/FormField";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../../components/Modal/Modal";
import Column from "../../../../../components/Row/Column/Column";
import Row from "../../../../../components/Row/Row";
import Typography from "../../../../../components/Typography/Typography";
import { IUser } from "../../../../../modules/usersSlice";
import styles from "./MatterReassignTasksModal.module.scss";
import TaskInfoLabel from "./TaskInfoLabel";

interface IMatterReassignTasksModal {
  isOpen: boolean;
  handleClose: () => void;
  taskName: string;
  taskDescription: string;
  user?: IUser;
  reassignTask: (type: EReassignType) => void;
  error?: string;
  status: cStatusType;
}

type Inputs = {
  reassign: string;
};

export enum EReassignType {
  Only = "only",
  All = "all",
}

/**
 * A modal to reassign a user's tasks from a matter
 * @param isOpen           Is the modal open?
 * @param handleClose      Function to close the modal
 * @param taskName         The name of the task
 * @param taskDescription  The description of the task
 * @param user             The user the task is assigned to
 * @param reassignTask     Function to reassign the task
 * @param error            Error message
 * @param status           The status of the reassign task API call
 * @returns JSX.Element
 */
function MatterReassignTasksModal({
  isOpen,
  handleClose,
  taskName,
  taskDescription,
  user,
  reassignTask,
  error,
  status,
}: IMatterReassignTasksModal) {
  const {
    register, // Register prop for form inputs
    handleSubmit, // Submit handler wrapper
    formState: { errors },
    reset,
  } = useForm<Inputs>();

  const options = [
    {
      value: EReassignType.Only,
      label: "Reassign this task only",
    },
    {
      value: EReassignType.All,
      label: "Reassign all this user's tasks across all Matters",
    },
  ];

  const formId = "reassign-matter-tasks-form";

  /**
   * Submit the form
   * @param data The form values on submit
   */
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    reassignTask(data.reassign as EReassignType);
  };

  // Reset the form when the modal is closed
  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return (
    <Modal size="md" isOpen={isOpen} handleClose={() => handleClose()} testId="delete-notification-modal">
      <ModalHeader>
        <ModalTitle>Reassign</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <Div className={styles.taskInfo} p={{ base: 5 }}>
          <Row mb={{ base: 5 }}>
            <TaskInfoLabel>Task</TaskInfoLabel>
            <Column>
              <Typography weight="bold">{taskName}</Typography>
              <Typography color={cMonoColorType.Light} className={styles.taskDescription}>
                {taskDescription}
              </Typography>
            </Column>
          </Row>
          <Row>
            <TaskInfoLabel>Assigned to</TaskInfoLabel>
            <Column>
              <Typography weight="bold">{`${user?.firstName} ${user?.lastName}`}</Typography>
            </Column>
          </Row>
        </Div>
        <Div mt={{ base: 7 }}>
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <FormField
              type={cFormFieldType.Radio}
              register={register}
              options={options}
              name="reassign"
              error={errors.reassign}
              required
            />
          </form>
        </Div>
        {error && (
          <Div pt={{ base: 5 }}>
            <DisplayError>{error}</DisplayError>
          </Div>
        )}
      </ModalContent>
      <ModalActions>
        <Button
          color={cThemeColorType.Secondary}
          onClick={() => handleClose()}
          testId="matter-reassign-modal-cancel-btn"
          formId={formId}
          disabled={status === cStatusType.Loading}
        >
          Cancel
        </Button>
        <Button
          type={cButtonType.SubmitType}
          formId={formId}
          testId="reassign-matter-tasks-modal-submit-button"
          isLoading={status === cStatusType.Loading}
        >
          Reassign
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default MatterReassignTasksModal;
