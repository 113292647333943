import { useAppSelector } from "../../app/hooks";
import { selectIsLoading } from "../../modules/loadingSlice";
import Spinner from "../Spinner/Spinner";
import styles from "./SpinnerBackdrop.module.scss";

/**
 * Renders a full screen backdrop with vertically and horizontally centered spinner
 * @returns JSX.Element
 */
function SpinnerBackdrop({ loading }: { loading?: boolean }): JSX.Element | null {
  const isLoading = useAppSelector(selectIsLoading) || loading;

  if (isLoading) {
    return (
      <div className={styles.className}>
        <Spinner />
      </div>
    );
  }
  return null;
}

export default SpinnerBackdrop;
