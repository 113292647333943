import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import SpinnerBackdrop from "../../components/SpinnerBackdrop/SpinnerBackdrop";
import { postDocumentTypes, selectDocumentTypes } from "../../modules/documentTypesSlice";
import { postInterviewLog, selectInterviewLog } from "../../modules/interviewLogSlice";
import { postMattersById, selectMatters } from "../../modules/mattersSlice";
import InterviewLog from "./InterviewLog/InterviewLog";

/**
 * The interview log container
 * @returns JSX.Element
 */
function InterviewLogContainer() {
  const { walkID, documentTypeID, matterId } = useParams<{
    walkID: string;
    documentTypeID: string;
    matterId?: string;
  }>();
  const dispatch = useAppDispatch();
  const [matterRef, setMatterRef] = useState<string | undefined>();
  const interviewLog = useAppSelector(selectInterviewLog);
  const documentTypes = useAppSelector(selectDocumentTypes);
  const matters = useAppSelector(selectMatters);

  // Set the matter reference code if the matter is loaded
  useEffect(() => {
    if (matters) {
      const matter = matters[Number(matterId)];
      setMatterRef(matter?.referenceCode);
    }
  }, [matters]);

  useEffect(() => {
    dispatch(postInterviewLog(Number(walkID)));
    dispatch(postDocumentTypes({ ids: [Number(documentTypeID)] }));

    // Fetch the matter if the matter ID is present
    if (matterId) {
      dispatch(postMattersById({ ids: [Number(matterId)] }));
    }
  }, [walkID, documentTypeID, matterId]);

  if (!interviewLog) {
    return <SpinnerBackdrop />;
  }

  return (
    <InterviewLog
      documentTypeName={documentTypes[Number(documentTypeID)]?.name}
      urn={walkID as string}
      matterRef={matterRef}
      interviewLog={interviewLog}
    />
  );
}

export default InterviewLogContainer;
