import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../app/api";
import { errorCodesUrl } from "../app/apiUrls";
import { cStatusType } from "../app/constants";
import { RootState } from "../app/store";
import createIdList from "../utils/createIdList/createIdList";

/**
 * Define the error code object
 */
export interface IErrorCode {
  id: number;
  code: number;
  text: string | never;
  userDescription: string;
}

/**
 * Define the error codes state
 */
interface IErrorCodesState {
  entries: Record<number, IErrorCode>;
  resetErrorCodeSearch: boolean;
  status: cStatusType;
  fetched: boolean;
  error?: string;
}

/**
 * Define the initial state for error codes
 */
const initialState: IErrorCodesState = {
  entries: {},
  resetErrorCodeSearch: false,
  status: cStatusType.Idle,
  fetched: false,
};

/**
 * Thunk for fetching error codes
 */
export const postErrorCodes = createAsyncThunk(
  "errorCodes/postErrorCodes",
  async ({ data }: { data: number[] | "all" }, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = errorCodesUrl;
      const response = await api({ endpoint, dispatch, body: { id: data } });

      return { errorCodes: createIdList(response.data.errorCodes) };
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Define the error codes slice
 */
export const errorCodesSlice = createSlice({
  name: "errorCodes",
  initialState,
  reducers: {
    updateErrorCodeList: (state, action: PayloadAction<IErrorCode>) => {
      state.entries[action.payload.id] = { ...state.entries[action.payload.id], ...action.payload };
    },
    updateResetErrorCodeSearch: (state, action: PayloadAction<boolean>) => {
      state.resetErrorCodeSearch = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postErrorCodes.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postErrorCodes.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postErrorCodes.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.fetched = true;
        state.entries = { ...state.entries, ...action.payload.errorCodes };
      });
  },
});

export const { updateErrorCodeList, updateResetErrorCodeSearch } = errorCodesSlice.actions;

export const selectAllErrorCodes = (state: RootState) => state.errorCodes.entries;
export const selectErrorCodesStatus = (state: RootState) => state.errorCodes.status;
export const selectErrorCodesFetched = (state: RootState) => state.errorCodes.fetched;
export const selectErrorCodesError = (state: RootState) => state.errorCodes.error;

export default errorCodesSlice.reducer;
