/**
 * Returns a date with the time set to midnight
 * @returns Date
 */
export function dateWithoutTime(date?: string | Date) {
  let newDate;
  if (date) {
    if (typeof date === "string") {
      date = date.replace(/\//g, "-");
      newDate = new Date(date).toISOString().split("T")[0];
    } else {
      newDate = date.toISOString().split("T")[0];
    }
  } else {
    newDate = new Date().toISOString().split("T")[0];
  }
  return new Date(newDate);
}
