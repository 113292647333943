import { useEffect, useState } from "react";
import { IXDirection } from "../../../app/types";
import WalkPauseModalContent from "../../../pages/WalkContainer/Walk/WalkPauseModal/WalkPauseModalContent/WalkPauseModalContent";
import { IHandlePause } from "../../../pages/WalkContainer/WalkContainer";
import { isRequestBodyError, isUnableToViewMatterError, isWalkError } from "../../../utils/errors/errors";
import CopyToClipboard from "../../CopyToClipboard/CopyToClipboard";
import Div from "../../Div/Div";
import { ModalContent } from "../../Modal/Modal";
import Typography from "../../Typography/Typography";
import styles from "./HandledErrorModalContent.module.scss";

interface IHandledErrorModalContent {
  errorCode: number;
  matterRef?: string;
  currentUrl?: string;
  shouldShowPauseForm: boolean;
  handlePause: (formData: IHandlePause) => void;
  documentDescription?: string;
  requireDescription?: boolean;
}

/**
 * Handled error modal content
 * @param errorCode           The error code being handled
 * @param matterRef           The matter ref
 * @param shouldShowPauseForm Should the pause walk form be rendered?
 * @param handlePause         Handle walk pause
 * @param documentDescription Description of the walk document
 * @param requireDescription  Is the description explicitly required?
 * @returns JSX.Element
 */
function HandledErrorModalContent({
  errorCode,
  matterRef,
  currentUrl,
  shouldShowPauseForm,
  handlePause,
  documentDescription,
  requireDescription,
}: IHandledErrorModalContent) {
  const [content, setContent] = useState<JSX.Element | null>(null);
  const strippedUrl = currentUrl?.split("?")[0];

  useEffect(() => {
    // Set the content based on error code
    if (errorCode) {
      switch (true) {
        case isRequestBodyError(errorCode):
          setContent(
            <>
              <Typography align={IXDirection.Center} className={styles.text}>
                {`Please ensure that the url in your browser's address bar is correct and ends with your organisation's unique customer code. For example:`}
                <>
                  <br />
                  <strong>
                    {` ` + (strippedUrl as string) + "?ccof="}
                    <span className={styles.textPrimary}>XXXXXX</span>
                  </strong>
                  <br />
                </>
                where XXXXXX is your customer code.
                <>
                  <p>If the problem persists, please contact your system administrator and provide code 24000</p>
                </>
              </Typography>
            </>,
          );
          break;
        case isUnableToViewMatterError(errorCode):
          setContent(
            <>
              <Typography align={IXDirection.Center} spacing={{ mb: 6 }} className={styles.text}>
                {`To request access, email the below Matter REF to 
                the administrator.`}
              </Typography>
              <CopyToClipboard labelText="Matter REF" textToCopy={matterRef as string} />
            </>,
          );
          break;
        case isWalkError(errorCode):
          if (shouldShowPauseForm) {
            setContent(
              <WalkPauseModalContent
                handlePause={handlePause}
                documentDescription={documentDescription}
                errorRequireDescription={requireDescription}
              />,
            );
          } else {
            const typographyProps = { align: IXDirection.Center, spacing: { mb: 6 }, className: styles.text };
            setContent(
              <>
                <Typography {...typographyProps}>Our team has been notified and will help to resolve it.</Typography>
                <Typography {...typographyProps}>
                  <strong>Please pause this interview for now</strong> and we'll let you know via email as soon as we
                  have an update for you. If you need your document urgently, please contact Updraft support to let our
                  team know.
                </Typography>
                <Typography {...typographyProps}>
                  Thank you for your patience. We apologise for any inconvenience caused.
                </Typography>
              </>,
            );
          }
          break;
        default:
          setContent(<></>);
          break;
      }
    }
  }, [errorCode, shouldShowPauseForm]);

  return (
    <ModalContent>
      <Div display={{ base: "flex" }} flexDirection={{ base: "column" }} alignItems={{ base: "center" }}>
        {content}
      </Div>
    </ModalContent>
  );
}
export default HandledErrorModalContent;
