import { map, pickBy } from "lodash";
import { useAppDispatch } from "../../../../app/hooks";
import { IXDirection } from "../../../../app/types";
import { EMenuItemKey, TMenuItems } from "../../../../modules/mainMenuSlice";
import { getLogout } from "../../../../modules/userSlice";
import Div from "../../../Div/Div";
import LinkList from "../../../LinkList/LinkList";
import { TListItem } from "../../../LinkList/ListItem/ListItem";
import UserDetails from "../UserDetails/UserDetails";
import styles from "./ProfileMenu.module.scss";

interface IProfileMenu {
  userName: string;
  isAdmin: boolean;
  menuItems: TMenuItems;
}

/**
 * Profile menu in nav bar popover
 * @param userName  The user's name to render
 * @param isAdmin   Is the logged in user an administrator?
 * @param menuItems Menu items from state
 * @returns JSX.Element
 */
function ProfileMenu({ userName, isAdmin, menuItems }: IProfileMenu): JSX.Element {
  const dispatch = useAppDispatch();

  // Get only profile menu items
  const profileMenuItems = pickBy(menuItems, (_, key) => {
    return key === EMenuItemKey.Logout;
  });

  /**
   * Handle click of the logout link
   */
  function handleLogoutClick() {
    dispatch(getLogout());
  }

  // Map to array
  const items =
    (map(profileMenuItems, (item, key) => {
      if (key === EMenuItemKey.Logout) {
        return {
          icon: item?.icon,
          text: item.text,
          onClick: handleLogoutClick,
        };
      }

      return {
        icon: item?.icon,
        ...item,
      };
    }) as TListItem[]) || [];

  return (
    <Div>
      <Div className={styles.userDetailsWrapper}>
        <Div border={{ bb: true }} spacing={{ p: 4 }}>
          <UserDetails userName={userName} isAdmin={isAdmin} align={IXDirection.Left} />
        </Div>
      </Div>
      <LinkList items={items} />
    </Div>
  );
}

export default ProfileMenu;
