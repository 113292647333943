import Div from "../../../../../../components/Div/Div";
import { IErrorCode } from "../../../../../../modules/errorCodesSlice";

/**
 * Contents of the Popover
 * @param userDescription The detailed description of the error code
 * @returns JSX.Element
 */
function Contents({ userDescription }: Pick<IErrorCode, "userDescription">): JSX.Element {
  return <Div testId="error-code-description-popover">{userDescription}</Div>;
}

export default Contents;
