import { cSizeType } from "../../../../../app/constants";
import Div from "../../../../../components/Div/Div";
import Modal, { ModalContent, ModalHeader, ModalTitle } from "../../../../../components/Modal/Modal";
import ProgressBar from "../../../../../components/ProgressBar/ProgressBar";

interface IUploadProgressModal {
  uploadProgress: number;
}

/**
 * Walk upload progress modal
 * @param uploadProgress Upload progress percentage
 * @returns JSX.Element
 */
function UploadProgressModal({ uploadProgress }: IUploadProgressModal): JSX.Element {
  return (
    <Modal isOpen size="sm" testId="upload-progress-modal">
      <ModalHeader>
        <ModalTitle>Uploading document</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <Div pb={{ base: 5 }}>
          <ProgressBar percentage={uploadProgress} size={cSizeType.Small} />
        </Div>
      </ModalContent>
    </Modal>
  );
}

export default UploadProgressModal;
