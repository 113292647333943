import classNames from "classnames";
import { IXDirection } from "../../app/types";
import Div from "../Div/Div";
import { IDrawer } from "../Drawer/Drawer";
import styles from "./DrawerRowWrapper.module.scss";

interface IDrawerRowWrapper {
  children: React.ReactNode | JSX.Element;
  direction?: IDrawer["direction"];
  className?: string;
  style?: React.CSSProperties;
}

/**
 * Wrapper for a drawer and siblings
 * @param children  Contents of the drawer row wrapper
 * @param direction The side the drawer will appear
 * @param className Custom class string
 * @param style     Custom inline styles
 * @returns JSX.Element
 */
function DrawerRowWrapper({
  children,
  direction = IXDirection.Left,
  className,
  style,
}: IDrawerRowWrapper): JSX.Element {
  const clx = classNames(
    // Build class string
    styles.className, // Generic styles
    { [styles.right]: direction === IXDirection.Right }, // Apply right side styles if direction equals right
    className, // Custom class string, if any
  );

  return (
    <Div className={clx} style={style}>
      {children}
    </Div>
  );
}

export default DrawerRowWrapper;
