import Div from "../../../Div/Div";
import Icon, { EIcon } from "../../../Icon/Icon";
import Column from "../../../Row/Column/Column";
import Row from "../../../Row/Row";
import Typography from "../../../Typography/Typography";
import { IListItemGeneric } from "../ListItem";

/**
 * Contents for a link list item
 * @param icon     The icon to display
 * @param text     The text to display
 * @param arrow    Whether to display an arrow
 * @returns JSX.Element
 */
function Contents({ icon, text, arrow }: IListItemGeneric): JSX.Element {
  return (
    <Row alignItems={{ base: "center" }} justifyContent={{ base: "space-between" }}>
      <Column grow={false}>
        {icon && (
          <Div spacing={{ pr: 5 }}>
            <Icon icon={icon} />
          </Div>
        )}
      </Column>
      <Column>
        <Div spacing={{ pr: 6 }}>
          <Typography>{text}</Typography>
        </Div>
      </Column>
      {arrow ? (
        <Column grow={false}>
          <Icon icon={EIcon.Right} />
        </Column>
      ) : (
        <></> // Default value when arrow is false or undefined
      )}
    </Row>
  );
}

export default Contents;
