import { lazy, Suspense } from "react";
import Div from "../../Div/Div";
import { ISwitchProps } from "./Switch";

const LazySwitch = lazy(() => import("./Switch"));

const Switch = (props: ISwitchProps) => (
  <Suspense fallback={<Div />}>
    <LazySwitch {...props} />
  </Suspense>
);

export default Switch;
