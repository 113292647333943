import { cThemeColorType } from "../../../../../app/constants";
import Button from "../../../../../components/Button/Button";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../../components/Modal/Modal";
import Typography from "../../../../../components/Typography/Typography";
import { IUser } from "../../../../../modules/usersSlice";

interface IEnableUserModal {
  isOpen: boolean;
  handleClose: (canDisable: boolean) => void;
  user?: IUser;
}

/**
 * A modal to confirm if the admin wants to enable a user
 * @returns JSX.Element
 */
function EnableUserModal({ isOpen, handleClose, user }: IEnableUserModal) {
  return (
    <Modal isOpen={isOpen} handleClose={() => handleClose(false)} testId="enable-user-modal">
      <ModalHeader>
        <ModalTitle>Enable User</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <Typography>
          Account{" "}
          <b>
            {user?.firstName} {user?.lastName}
          </b>{" "}
          (<b>{user?.loginName}</b>) will be able to access the system.
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button color={cThemeColorType.Secondary} onClick={() => handleClose(false)}>
          Go Back
        </Button>
        <Button onClick={() => handleClose(true)}>Enable User</Button>
      </ModalActions>
    </Modal>
  );
}

export default EnableUserModal;
