import { lazy, Suspense } from "react";
import Div from "../../Div/Div";
import { IButtons } from "./Buttons";

const LazyButtons = lazy(() => import("./Buttons"));

const Buttons = (props: IButtons) => (
  <Suspense fallback={<Div />}>
    <LazyButtons {...props} />
  </Suspense>
);

export default Buttons;
