import classNames from "classnames";
import { map, pickBy } from "lodash";
import { memo, useEffect, useState } from "react";
import usePortal from "react-useportal";
import { useMountTransition } from "../../../app/hooks";
import { EPopoverPlacement, IXDirection } from "../../../app/types";
import { EMenuItemKey, TMenuItems } from "../../../modules/mainMenuSlice";
import mark from "../../../Updraft-mark.png";
import logo from "../../../UpdraftLogo.svg";
import Button, { EButtonVariant } from "../../Button/Button";
import Div from "../../Div/Div";
import Icon, { EIcon } from "../../Icon/Icon";
import Popover from "../../Popover/Popover";
import Column from "../../Row/Column/Column";
import Row from "../../Row/Row";
import rowStyles from "../../Row/Row.module.scss";
import MenuItem from "./MenuItem/MenuItem";
import styles from "./NavBar.module.scss";
import ProfileMenu from "./ProfileMenu/ProfileMenu";
import UserDetails from "./UserDetails/UserDetails";

export interface INavBarProps {
  items: TMenuItems;
  userName: string;
  isAdmin: boolean;
  companyLogo?: string;
}

/**
 * Pure nav bar accepting props
 * @param items       The menu items
 * @param userName    The user's name
 * @param isAdmin     Is the logged in user an administrator?
 * @param companyLogo The logo to render in the profile menu
 * @returns JSX.Element
 */
function NavBar({ items, userName, isAdmin, companyLogo }: INavBarProps): JSX.Element {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const hasTransitionedIn = useMountTransition(isMobileMenuOpen, 150); // Get transition state
  const { Portal } = usePortal();

  /**
   * Remove the body overflow inline style
   */
  function removeBodyOverflow() {
    document.body.style.removeProperty("overflow");
  }

  // If mobile menu is open, set body overflow to hidden to disable scrolling
  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      removeBodyOverflow();
    }

    // Remove the inline style when unmounting
    return () => {
      removeBodyOverflow();
    };
  }, [isMobileMenuOpen]);

  /**
   * Handle hamburger click in mobile
   */
  function handleHamburgerClick() {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }

  /**
   * Handle overlay click to close the menu on mobile
   */
  function handleOverlayClick() {
    handleClose();
  }

  const menuClx = classNames(styles.menu, { [styles.menuOpen]: isMobileMenuOpen });
  const overlayClx = classNames(
    styles.menuOverlay,
    { [styles.visible]: isMobileMenuOpen },
    { [styles.in]: hasTransitionedIn },
  );

  // Get only main menu items
  const mainItems = pickBy(items, (_, key) => {
    return (
      key === EMenuItemKey.Notifications ||
      key === EMenuItemKey.MatterMap ||
      key === EMenuItemKey.Documents ||
      key === EMenuItemKey.Administration ||
      key === EMenuItemKey.Analytics
    );
  });

  /**
   * Close the mobile menu
   */
  function handleClose() {
    setIsMobileMenuOpen(false);
  }

  return (
    <Div spacing={{ px: 5 }} className={styles.wrapper}>
      <Row alignItems={{ base: "center" }} justifyContent={{ base: "space-between" }} className={styles.className}>
        <Column grow={false}>
          <Div className={styles.logoWrapper}>
            <img src={logo} className={styles.logo} alt="logo" width={120} />
          </Div>
          <Div className={styles.hamburgerBtnWrapper}>
            <Button
              icon={EIcon.MainMenu}
              variant={EButtonVariant.Link}
              onClick={handleHamburgerClick}
              className={styles.hamburgerBtn}
            />
            {(isMobileMenuOpen || hasTransitionedIn) && (
              <Portal>
                <Div className={overlayClx} onClick={handleOverlayClick} />
              </Portal>
            )}
          </Div>
        </Column>
        <Column grow={true}>
          <ul className={menuClx} data-testid="main-menu">
            {map(mainItems, (item) => (
              <MenuItem {...item} key={item.text} handleClose={handleClose} />
            ))}
          </ul>
          <Div spacing={{ py: 3 }} className={styles.mobileMark}>
            <img src={mark} alt="Updraft" />
          </Div>
        </Column>
        <Column lg={2}>
          <Row alignItems={{ base: "center" }}>
            <Column lg={12}>
              <Row alignItems={{ base: "center" }} justifyContent={{ base: "end" }} className={rowStyles.noWrap}>
                <Column lg={12} className={styles.userCol}>
                  <Div spacing={{ mr: 4 }}>
                    <UserDetails userName={userName} isAdmin={isAdmin} align={IXDirection.Right} />
                  </Div>
                </Column>
                <Column grow={false}>
                  <Popover
                    popoverPlacement={EPopoverPlacement.Bottom}
                    popoverContents={<ProfileMenu userName={userName} isAdmin={isAdmin} menuItems={items} />}
                    buttonContents={
                      <Div className={styles.markWrapper}>
                        <Div className={styles.mark}>
                          <Div className={styles.imageContainer}>
                            {companyLogo ? (
                              <img
                                className={styles.markImg}
                                data-testid="company-logo"
                                width="100%"
                                src={companyLogo}
                                alt="Updraft"
                              />
                            ) : (
                              <Div>
                                <Icon icon={EIcon.User} className={styles.userIcon} />
                              </Div>
                            )}
                          </Div>
                        </Div>
                      </Div>
                    }
                    buttonProps={{ variant: EButtonVariant.Link }}
                    width="250px"
                  />
                </Column>
              </Row>
            </Column>
          </Row>
        </Column>
      </Row>
    </Div>
  );
}

export default memo(NavBar);
