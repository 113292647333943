import { format } from "date-fns";
import Div from "../../../../../../components/Div/Div";
import Typography from "../../../../../../components/Typography/Typography";
import { IDocumentTermination } from "../../../../../../modules/documentsSlice";
import styles from "./StatusRow.module.scss";

interface IStatusRow {
  termination?: IDocumentTermination;
  isSigned?: boolean;
  signedDate: string | null;
}

/**
 * Status row displayed in a document action popover
 * @param termination Document termination information
 * @param isSigned    Has the document been signed
 * @param signedDate  Date of signature
 * @returns JSX.Element
 */
function StatusRow({ termination, isSigned, signedDate }: IStatusRow): JSX.Element {
  let text: string | null = null;

  // If termination information exists
  if (termination) {
    if (termination.terminated) {
      // If the document has already been terminated
      text = "Status: Terminated";
    } else {
      // If the document is pending termination
      text = "Terminated: Effective " + format(new Date(termination.terminateDate), "dd MMM yyyy");
    }
  } else if (isSigned === true) {
    // If the document has been signed
    text = "Signed: " + format(new Date(signedDate as string), "dd MMM yyyy");
  }

  if (text !== null) {
    return (
      <Div display={{ base: "flex" }} spacing={{ mb: 4 }}>
        <Typography fontStyle="italic" className={styles.terminatedText}>
          {text}
        </Typography>
      </Div>
    );
  } else {
    // Return fragment if none of the above conditions are satisfied
    return <></>;
  }
}

export default StatusRow;
