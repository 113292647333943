import { lazy, Suspense } from "react";
import Div from "../../Div/Div";
import { ITextarea } from "./Textarea";

const LazyTextarea = lazy(() => import("./Textarea"));

const Textarea = (props: ITextarea) => (
  <Suspense fallback={<Div />}>
    <LazyTextarea {...props} />
  </Suspense>
);

export default Textarea;
