import { memo, useEffect, useState } from "react";
import { cStatusType } from "../../app/constants";
import { useAppSelector } from "../../app/hooks";
import { selectCompanyLogo } from "../../modules/customerSlice";
import { selectMainMenuItems, selectMainMenuStatus, TMenuItems } from "../../modules/mainMenuSlice";
import { selectUser } from "../../modules/userSlice";
import NavBar from "./NavBar/NavBar";

/**
 * The app nav bar container
 * @returns JSX.Element
 */
function NavBarContainer(): JSX.Element {
  const items = useAppSelector(selectMainMenuItems);
  const companyLogo = useAppSelector(selectCompanyLogo);
  const user = useAppSelector(selectUser);
  const status = useAppSelector(selectMainMenuStatus);
  const [userName, setUserName] = useState<string>("");
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  // Set user name and admin status
  useEffect(() => {
    if (user) {
      setUserName(`${user.firstName} ${user.lastName}`);
      setIsAdmin(user.isAdmin ? true : false);
    } else {
      setUserName("");
      setIsAdmin(false);
    }
  }, [user]);

  return (
    <NavBar
      items={status === cStatusType.Idle ? items : ([] as unknown as TMenuItems)}
      userName={userName}
      isAdmin={isAdmin}
      companyLogo={companyLogo}
    />
  );
}

export default memo(NavBarContainer);
