import { format } from "date-fns";
import { useEffect, useState } from "react";
import { cAccessType, cThemeColorType } from "../../../app/constants";
import { EPopoverPlacement } from "../../../app/types";
import { ETimelineEntryType } from "../../../modules/documentsSlice";
import { EESignActionHintType } from "../../../modules/esignSlice";
import Div from "../../Div/Div";
import Icon, { EIcon } from "../../Icon/Icon";
import Popover from "../../Popover/Popover";
import styles from "./ESignButton.module.scss";

interface IRenderESignButton {
  type: ETimelineEntryType;
  urn?: number;
  action?: { [key: string]: { state: cAccessType; id?: number; hint?: EESignActionHintType } };
  signedDate?: string;
  handleESignEvent: (type: ETimelineEntryType, urn?: number, id?: number) => void;
}

/**
 * The esign button
 * @param type             ETimelineEntryType
 * @param urn              The urn
 * @param action           Timeline action
 * @param signedDate       The date the document was signed
 * @param handleESignEvent Function to handle the esign event
 * @returns JSX.Element
 */
function ESignButton({ type, urn, action, signedDate, handleESignEvent }: IRenderESignButton) {
  let color;
  let tooltip;
  let icon;
  let testId;

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (action?.esign?.state === cAccessType.Denied) {
      setDisabled(true);
    }
  }, [action]);

  const hint = action?.esign?.hint;

  switch (true) {
    case action?.esign?.state === cAccessType.Denied:
      icon = EIcon.Sign;
      tooltip = "Insufficient privileges";
      testId = "signing-denied-button";
      break;
    case hint === EESignActionHintType.BusySigning:
      color = cThemeColorType.Secondary;
      tooltip = "Signing in progress";
      icon = EIcon.SigningInProgress;
      testId = "signing-in-progress-button";
      break;
    case hint === EESignActionHintType.Signed:
      color = cThemeColorType.Secondary;
      tooltip = signedDate ? `Signed:  ${format(new Date(signedDate), "dd MMM yyyy")}` : "Signing complete";
      icon = EIcon.SigningComplete;
      testId = "signing-complete-button";
      break;
    case hint === EESignActionHintType.PatialSignatureHistory:
      color = cThemeColorType.Secondary;
      tooltip = "Signing cancelled";
      icon = EIcon.SigningCancelled;
      testId = "signing-cancelled-button";
      break;
    default:
      color = cThemeColorType.Primary;
      tooltip = "Start new signing process";
      icon = EIcon.Sign;
      testId = "start-new-esign-button";
      break;
  }

  return (
    <Div display={{ base: "flex" }} justifyContent={{ base: "center" }} className={styles.buttonWrapper}>
      <Popover
        variant="tooltip"
        popoverPlacement={EPopoverPlacement.Top}
        width="auto"
        popoverContents={<Div>{tooltip}</Div>}
        buttonContents={<Icon icon={icon} />}
        buttonProps={{
          color: color,
          testId: testId,
          onClick: () => handleESignEvent(type, urn, action?.esign?.id),
          disabled: disabled,
        }}
      />
    </Div>
  );
}

export default ESignButton;
