import { isEmpty, omit, reduce } from "lodash";
import { IPostMatters } from "../../../modules/mattersSlice";

/**
 * Gets the neccessary data from the sidebar to use as a filter values
 * @param data the form data
 * @returns IPostMatters
 */
export function getFilterValues(data: any): IPostMatters {
  const filterValues: IPostMatters = {};
  if (data.matterTypeID) {
    filterValues.matterType = { id: 0 }; // Set to 0 as required by interface
    filterValues.matterType.id = data.matterTypeID.value as number;

    // Get the tags only
    const tagData = omit(data, [
      "matterTypeID",
      "descriptionContains",
      "dataContains",
      "urn",
      "matterRef",
      "ownerIDs",
      "milestoneIDs",
    ]);

    // Convert into API format
    if (!isEmpty(tagData)) {
      const tagValues = reduce(
        tagData,
        (acc, value, i) => {
          if (value !== undefined) {
            // Text input values
            if (typeof value === "string" && value !== "") {
              acc.push({
                name: i,
                value,
              });
            }

            // Select dropdown values
            if (typeof value === "object" && value.value !== "") {
              acc.push({
                name: i,
                value: value.value,
              });
            }
          }

          return acc;
        },
        [] as { name: string; value: string }[],
      );

      if (!isEmpty(tagValues)) {
        filterValues.matterType.tags = tagValues;
      }
    }
  }
  if (data.descriptionContains) {
    filterValues.descriptionContains = data.descriptionContains;
  }
  if (data.dataContains) {
    filterValues.dataContains = data.dataContains;
  }
  if (data.urn) {
    filterValues.urn = Number(data.urn);
  }
  if (data.matterRef) {
    filterValues.matterRef = data.matterRef;
  }
  if (data.ownerIDs && data.ownerIDs.length > 0) {
    filterValues.ownerIDs = data.ownerIDs.map((owner: { label: string; value: number }) => owner.value as number);
  }
  if (data.milestoneIDs && data.milestoneIDs.length > 0) {
    filterValues.milestoneIDs = data.milestoneIDs.map(
      (milestone: { label: string; value: number }) => milestone.value as number,
    );
  }

  return filterValues;
}
