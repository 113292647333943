import { cSizeType, cThemeColorType } from "../../../../../app/constants";
import Div from "../../../../../components/Div/Div";
import Icon, { EIcon } from "../../../../../components/Icon/Icon";
import Column from "../../../../../components/Row/Column/Column";
import Row from "../../../../../components/Row/Row";
import Typography from "../../../../../components/Typography/Typography";
import { EInvalidatedReasonType, INotificationsEntry } from "../../../../../modules/notificationsSlice";
import styles from "./InvalidatedTransaction.module.scss";

/**
 * Display invalidated reason
 * @param invalidCode The invalidated reason code
 * @returns JSX.Element
 */
function InvalidatedTransaction({ invalidCode }: Pick<INotificationsEntry, "invalidCode">): JSX.Element {
  let invalidCodeText;
  let spacing;

  switch (invalidCode) {
    case EInvalidatedReasonType.Superseded:
      invalidCodeText = `The event has been deleted`;
      spacing = 4;
      break;

    case EInvalidatedReasonType.DocDeleted:
      invalidCodeText = `No access: the Document has been deleted`;
      break;

    case EInvalidatedReasonType.DocTerminated:
      invalidCodeText = `No access: the Document has been terminated`;
      break;

    case EInvalidatedReasonType.DocTransferred:
      invalidCodeText = `No access: the Document has been transferred`;
      break;

    case EInvalidatedReasonType.MatterDeleted:
      invalidCodeText = `No access: the Matter has been deleted`;
      break;
  }

  return (
    <Div>
      <Row alignItems={{ base: "center" }} className={styles.noWrap}>
        <Column grow={false}>
          {invalidCode !== EInvalidatedReasonType.Superseded && (
            <Div mr={{ base: 2 }}>
              <Icon icon={EIcon.Inaccessible} color={cThemeColorType.Secondary} size={cSizeType.Medium} />
            </Div>
          )}
        </Column>
        <Column grow={false} spacing={{ pt: spacing }}>
          <Typography className={styles.text}>{invalidCodeText}</Typography>
        </Column>
      </Row>
    </Div>
  );
}

export default InvalidatedTransaction;
