import classNames from "classnames";
import { cSemanticColorType } from "../../app/constants";
import Div from "../Div/Div";
import Icon, { EIcon } from "../Icon/Icon";
import styles from "./InfoBox.module.scss";

/**
 * Info box variant types
 */
export enum EInfoBoxVariant {
  Info = "Info",
  Warning = "Warning",
}

interface IInfoBox {
  variant?: EInfoBoxVariant;
  children: React.ReactNode;
}

/**
 * The information box component
 * @param variant  The info box variant
 * @param children The children
 * @returns JSX.Element
 */
function InfoBox({ variant = EInfoBoxVariant.Info, children }: IInfoBox) {
  return (
    <Div className={classNames(styles.className, styles[variant.toLowerCase()])} testId="info-box">
      <Div display={{ base: "flex" }}>
        <Icon icon={EIcon[variant]} color={cSemanticColorType[variant]} className={styles.icon} />
        <Div>{children}</Div>
      </Div>
    </Div>
  );
}

export default InfoBox;
