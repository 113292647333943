import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useState } from "react";
import { IXDirection } from "../../../app/types";
import Button, { EButtonVariant } from "../../Button/Button";
import Div from "../../Div/Div";
import { ITableCardColumn } from "../TableCard";
import styles from "./TableCardHeaderCell.module.scss";

/**
 * Column order values
 */
export type TOrder =
  | "asc" // Ascending
  | "desc" // Descending
  | undefined; // Unset

/**
 * Render table header cell and optional ordering button
 * @param heading   The column heading
 * @param onOrder   Function to run on order
 * @param width     Manual column width
 * @param align     The column alignment
 * @returns JSX.Element
 */
function TableCardHeaderCell({
  heading,
  onOrder,
  width,
  align = IXDirection.Left,
}: Omit<ITableCardColumn, "filterType" | "filterOptions" | "onInputChange">): JSX.Element {
  const clx = classNames(styles.className, styles[align]); // Build classes
  const [order, setOrder] = useState<TOrder>(); // Current column order value

  /**
   * Handle column order button click
   */
  function handleOrder(): void {
    let newOrder: TOrder; // Init new order variable

    if (order === "asc") {
      // If current is "asc"
      newOrder = "desc"; // Set the new order to "desc"
    } else {
      // If current is "desc" or null
      newOrder = "asc"; // Set new order to "asc"
    }

    setOrder(newOrder); // Set internal order
    if (onOrder) {
      onOrder(newOrder, heading); // Run provided on order function
    }
  }

  return (
    <th // Open header cell
      className={clx} // Apply classes
      style={{ width }}
    >
      <Div testId="column-order">
        {heading} {/** Render the heading */}
        {onOrder && ( // If column is orderable...
          <Button // Render the button
            variant={EButtonVariant.Link} // No styling on button
            className={styles.button} // Apply button styles
            spacing={{ ml: 3 }} // Space from heading
            onClick={handleOrder} // Run handle order on click
          >
            <FontAwesomeIcon
              icon={
                order === "asc" // If order is "asc"
                  ? faArrowUp // Display up arrow
                  : faArrowDown // Otherwise, display down arrow
              }
              title={heading ?? undefined}
            />
          </Button>
        )}
      </Div>
    </th>
  );
}

export default TableCardHeaderCell;
