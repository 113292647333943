import {
  createAsyncThunk,
  createSlice,
  ListenerEffectAPI,
  PayloadAction,
  ThunkDispatch,
  UnknownAction,
} from "@reduxjs/toolkit";
import { format } from "date-fns";
import { map, omit, reduce, uniq } from "lodash";
import { toast } from "react-toastify";
import api from "../app/api";
import { documentsPageUrl, matterDocumentsUrl, renameDocumentUrl, terminateDocumentUrl } from "../app/apiUrls";
import { cStatusType } from "../app/constants";
import { RootState } from "../app/store";
import { errorToast } from "../toast/toast";
import createIdList from "../utils/createIdList/createIdList";
import { handleTerminationToast } from "../utils/handleTerminationToast/handleTerminationToast";
import { IDocument, IPostTerminateDocument } from "./documentsSlice";
import { postMatterDocumentTypes } from "./matterDocumentTypesSlice";
import { postUsersByID } from "./usersSlice";

/**
 * Describes terminate matter document request
 */
interface IPostTerminateMatterDocument extends IPostTerminateDocument {
  matterID: number;
}

/**
 * Documents list keyed by parent matter ID
 */
export interface IMatterDocumentsEntry {
  documents: Record<number, IDocument>;
}

/**
 * Describe the matter documents state object
 */
interface IMatterDocumentsState {
  entries: Record<number, IMatterDocumentsEntry>;
  status: cStatusType;
  renameStatus?: cStatusType;
  error?: string;
}

/**
 * Describe the post rename matter document thunk params
 */
interface IPostRenameMatterDocument {
  matterID: number;
  ID: number;
  description: string;
}

/**
 * Initial state for this slice
 */
const initialState: IMatterDocumentsState = {
  entries: {},
  status: cStatusType.Idle,
};

/**
 * Thunk for fetching documents for a single matter by ID
 */
export const postMatterDocuments = createAsyncThunk(
  "matters/postMatterDocuments",
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await api({ endpoint: matterDocumentsUrl, dispatch, body: { id } });

      return {
        id,
        documents: createIdList(response.data.documents),
      };
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching documents by ID and grouping them by matter ID
 */
export const postMatterDocumentsByDocumentId = createAsyncThunk(
  "matters/postMatterDocumentsByDocumentId",
  async (id: number[], { dispatch, rejectWithValue }) => {
    try {
      // Fetch the documents using the documents endpoint
      const response = await api({ endpoint: documentsPageUrl, dispatch, body: { id } });

      // Group the documents by the grouping ID
      const groupedDocuments = response.data.documents.reduce(
        (acc: Record<number, IMatterDocumentsEntry>, document: IDocument) => {
          // If the document has a grouping ID
          if (document.groupingID) {
            // If the grouping ID does not exist in the accumulator, create it and add the document
            if (!acc.hasOwnProperty(document.groupingID)) {
              acc[document.groupingID] = { documents: { [document.id]: document } };
              // If the grouping ID exists in the accumulator, add the document to the existing grouping ID
            } else {
              acc[document.groupingID].documents[document.id] = document;
            }
          }
          return acc;
        },
        {},
      );

      return {
        documents: groupedDocuments,
      };
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for terminating a matter document
 */
export const postTerminateMatterDocument = createAsyncThunk(
  "documents/postTerminateMatterDocument",
  async (
    { documentID, terminateDate, note, matterID }: IPostTerminateMatterDocument,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await api({
        endpoint: terminateDocumentUrl,
        dispatch,
        body: { documentID, terminateDate: format(new Date(terminateDate), "yyyy-MM-dd"), note },
      });
      handleTerminationToast(response.data.document);
      return {
        id: matterID,
        document: createIdList([response.data.document]),
      };
    } catch (err: any) {
      errorToast(err.message);
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for renaming a document
 */
export const postRenameMatterDocument = createAsyncThunk(
  "matterDocuments/postRenameMatterDocument",
  async ({ matterID, ID, description }: IPostRenameMatterDocument, { dispatch, rejectWithValue }) => {
    try {
      await api({
        endpoint: renameDocumentUrl,
        dispatch,
        body: {
          ID,
          description,
        },
      });
      toast("Document renamed");

      return { matterID, ID, description };
    } catch (err: any) {
      errorToast(err.message);
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Fetch matter documents effect
 * @param action       The action
 * @param listenerApi  The listener API
 * @returns void
 */
export const fetchMatterDocumentsEffect = (
  action: any,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>,
) => {
  const {
    payload: { id },
  } = action;
  const { cancelActiveListeners, dispatch, getState } = listenerApi;
  cancelActiveListeners();

  const state = getState() as RootState;
  const matterDocuments = state.matterDocuments.entries[id].documents;

  // Get the unique document type and owner IDs
  const uniqDocTypeIds = uniq(map(matterDocuments, (document) => document.documentType.id));
  const uniqOwnerIds = uniq(map(matterDocuments, (document) => document.ownerUser.id));

  // If the id array exists and is not empty, fetch the doc types
  if (uniqDocTypeIds && uniqDocTypeIds.length > 0) {
    dispatch(postMatterDocumentTypes({ ids: uniqDocTypeIds }));
  }

  // If the id array exists and is not empty, fetch the users
  if (uniqOwnerIds && uniqOwnerIds.length > 0) {
    dispatch(postUsersByID({ ids: uniqOwnerIds }));
  }
};

/**
 * Fetch matter documents by document ID effect
 * @param _            The action
 * @param listenerApi  The listener API
 * @returns void
 */
export const fetchMatterDocumentsByDocumentIdEffect = (
  _: UnknownAction,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>,
) => {
  const { cancelActiveListeners, dispatch, getState } = listenerApi;
  cancelActiveListeners();

  const state = getState() as RootState;
  const matterDocuments = state.matterDocuments.entries;

  // Get the unique document type and owner IDs
  const uniqDocTypeIds = uniq(
    reduce(
      matterDocuments,
      (acc, matterDocument) => {
        return [...acc, ...map(matterDocument.documents, (document) => document.documentType.id)];
      },
      [] as number[],
    ),
  );

  const uniqOwnerIds = uniq(
    reduce(
      matterDocuments,
      (acc, matterDocument) => {
        return [...acc, ...map(matterDocument.documents, (document) => document.ownerUser.id)];
      },
      [] as number[],
    ),
  );

  // If the id array exists and is not empty, fetch the doc types
  if (uniqDocTypeIds && uniqDocTypeIds.length > 0) {
    dispatch(postMatterDocumentTypes({ ids: uniqDocTypeIds }));
  }

  // If the id array exists and is not empty, fetch the users
  if (uniqOwnerIds && uniqOwnerIds.length > 0) {
    dispatch(postUsersByID({ ids: uniqOwnerIds }));
  }
};

/**
 * Create the state slice
 */
export const matterDocumentsSlice = createSlice({
  name: "matterDocuments",
  initialState,
  reducers: {
    deleteMatterDocument: (state, action: PayloadAction<{ documentID: number; matterID: number }>) => {
      const { documentID, matterID } = action.payload;
      state.entries[matterID].documents = omit(state.entries[matterID].documents, [documentID]);
    },
    resetMatterDocuments: (state, action: PayloadAction<number>) => {
      // Reset the documents for a matter
      state.entries = omit(state.entries, [action.payload]);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postMatterDocuments.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postMatterDocuments.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postMatterDocuments.fulfilled, (state, action) => {
        state.entries = { ...state.entries, [action.payload.id]: { documents: action.payload.documents } };
      })
      .addCase(postTerminateMatterDocument.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postTerminateMatterDocument.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postTerminateMatterDocument.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.entries = {
          ...state.entries,
          [action.payload.id]: {
            documents: { ...state.entries[action.payload.id].documents, ...action.payload.document },
          },
        };
      })
      .addCase(postRenameMatterDocument.pending, (state) => {
        state.renameStatus = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postRenameMatterDocument.rejected, (state, action) => {
        state.renameStatus = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postRenameMatterDocument.fulfilled, (state, action) => {
        state.renameStatus = cStatusType.Idle;
        state.entries[action.payload.matterID].documents[action.payload.ID].description = action.payload.description;
      })
      .addCase(postMatterDocumentsByDocumentId.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postMatterDocumentsByDocumentId.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postMatterDocumentsByDocumentId.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.entries = { ...state.entries, ...action.payload.documents };
      });
  },
});

export const { deleteMatterDocument, resetMatterDocuments } = matterDocumentsSlice.actions;

export const selectMatterDocumentsStatus = (state: RootState) => state.matterDocuments.status;
export const selectMatterDocumentsError = (state: RootState) => state.matterDocuments.error;
export const selectMatterDocuments = (state: RootState) => state.matterDocuments.entries;
export const selectMatterDocumentRenameStatus = (state: RootState) => state.matterDocuments.renameStatus;

export default matterDocumentsSlice.reducer;
