import classNames from "classnames";
import Div from "../../Div/Div";
import styles from "./Tab.module.scss";

interface ITab {
  header: React.ReactNode;
  isOpen: boolean;
  onClick: (i: number) => void;
  index: number;
}

/**
 * A single tab header
 * @param header  The header content of the tab
 * @param isOpen  Is the current tab open?
 * @param onClick On click method to change the open state
 * @param index   The tab index in the array
 * @returns JSX.Element
 */
function Tab({ header, isOpen, onClick, index }: ITab): JSX.Element {
  const clx = classNames(
    // Initialise classes
    styles.className, // Base class
    { [styles.active]: isOpen }, // Active class if open
  );

  return (
    <Div
      onClick={() => onClick(index)} // Display tab on click
      className={clx}
      id={`tab-${index}`}
    >
      <Div display={{ base: "flex" }} alignItems={{ base: "center" }}>
        {header}
      </Div>
    </Div>
  );
}

export default Tab;
