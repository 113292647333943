import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../app/api";
import { mainMenuUrl } from "../app/apiUrls";
import { cAccessType } from "../app/constants";
import { RootState } from "../app/store";
import { EIcon } from "../components/Icon/Icon";
import { getLogout } from "./userSlice";

/**
 * Describe a menu item
 */
export interface IMenuItem {
  text: string;
  access: cAccessType;
  link: string;
  icon?: EIcon;
}

/**
 * Possible keys for menu items
 */
export enum EMenuItemKey {
  Home = "home",
  Notifications = "notification",
  MatterMap = "matter",
  Cases = "case",
  Documents = "document",
  Profile = "profile",
  Administration = "administration",
  Analytics = "analytics",
  Help = "help",
  Logout = "logout",
}

export type TMenuItems = {
  [key in EMenuItemKey]: IMenuItem;
};

/**
 * Describe the main menu state object
 */
interface IMainMenuState {
  entries: TMenuItems; // Array of documents
  status: "idle" | "loading" | "failed"; // Enum of either
}

/**
 * Initial state
 */
const initialState: IMainMenuState = {
  entries: {
    [EMenuItemKey.Home]: {
      text: "Home",
      access: cAccessType.Enabled,
      link: "documents",
      icon: EIcon.Home,
    },
    [EMenuItemKey.Notifications]: {
      text: "Notifications",
      access: cAccessType.Enabled,
      link: "notification",
      icon: EIcon.Alerts,
    },
    [EMenuItemKey.MatterMap]: {
      text: "MatterMap",
      access: cAccessType.Inaccessible,
      link: "matters",
      icon: EIcon.MatterMap,
    },
    [EMenuItemKey.Cases]: {
      text: "MatterMap",
      access: cAccessType.Inaccessible,
      link: "cases",
    },
    [EMenuItemKey.Documents]: {
      text: "Documents",
      access: cAccessType.Inaccessible,
      link: "document",
      icon: EIcon.Documents,
    },
    [EMenuItemKey.Profile]: {
      text: "Profile",
      access: cAccessType.Inaccessible,
      link: "profile",
      icon: EIcon.User,
    },
    [EMenuItemKey.Administration]: {
      text: "Administration",
      access: cAccessType.Inaccessible,
      link: "admin",
      icon: EIcon.Administration,
    },
    [EMenuItemKey.Analytics]: {
      text: "Analytics",
      access: cAccessType.Inaccessible,
      link: "analytics",
      icon: EIcon.Analytics,
    },
    [EMenuItemKey.Help]: {
      text: "Help",
      access: cAccessType.Inaccessible,
      link: "help",
      icon: EIcon.Help,
    },
    [EMenuItemKey.Logout]: {
      text: "Sign out",
      access: cAccessType.Enabled,
      link: "",
      icon: EIcon.Logout,
    },
  },
  status: "idle",
};

/**
 * Get the main menu items
 */
export const getMainMenu = createAsyncThunk("mainMenu/getMainMenu", async (_, { dispatch, rejectWithValue }) => {
  try {
    const res = await api({ endpoint: mainMenuUrl, dispatch, method: "GET" });
    return res.data;
  } catch (err: any) {
    throw rejectWithValue(err.message);
  }
});

/**
 * Describe the menu options payload
 */
interface IGetMenuPayload {
  name: EMenuItemKey;
  access: cAccessType;
}

/**
 * Create the reducer slice
 */
export const mainMenuSlice = createSlice({
  name: "mainMenu", // The name of the slice
  initialState, // Set the initialState
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // Set status to loading when the promise is pending
      .addCase(getMainMenu.pending, (state) => {
        state.status = "loading";
      })
      // Set status to failed if the promise is rejected
      .addCase(getMainMenu.rejected, (state) => {
        state.status = "failed";
      })
      // Set status to idle once the promise has been fulfilled
      .addCase(getMainMenu.fulfilled, (state, action) => {
        state.status = "idle";

        // Set the updated access state for menu items
        action.payload.options.forEach(({ name, access }: IGetMenuPayload) => {
          if (state && state.entries && state.entries[name] && state.status === "idle") {
            state.entries[name].access = access;
          }
        });
      })
      .addCase(getLogout.pending, () => {
        return initialState;
      });
  },
});

// Select menu items
export const selectMainMenuItems = (state: RootState) => state.mainMenu.entries;
// Select - Main menu loading status
export const selectMainMenuStatus = (state: RootState) => state.mainMenu.status;

export default mainMenuSlice.reducer;
