import {
  createAsyncThunk,
  createSlice,
  ListenerEffectAPI,
  PayloadAction,
  ThunkDispatch,
  UnknownAction,
} from "@reduxjs/toolkit";
import { clone, map, omit, uniq } from "lodash";
import { toast } from "react-toastify";
import api from "../app/api";
import {
  matterFiltersUrl,
  matterGetIdUrl,
  matterLinkedUrl,
  matterOwnershipTransferUrl,
  mattersUrl,
  transferMatterUsersUrl,
} from "../app/apiUrls";
import { cAccessType, cStatusType } from "../app/constants";
import { RootState } from "../app/store";
import { EEmptyType, IID } from "../app/types";
import { errorToast } from "../toast/toast";
import { areSomeDefined } from "../utils/areSomeDefined/areSomeDefined";
import createIdList from "../utils/createIdList/createIdList";
import getExpiredIdCacheList, { TUnknownEntries } from "../utils/getExpiredIdCacheList/getExpiredIdCacheList";
import { TDocumentWalkInProgress } from "./documentsSlice";
import { postMatterTypes } from "./matterTypesSlice";
import { postUsersByID } from "./usersSlice";

/**
 * Matter action menu item types
 */
export enum EMatterActionMenuItemKey {
  TransferOwner = "transferOwner",
  AddEvent = "addEvent",
}

/**
 * Describes a linked matter
 */
export interface ILinkedMatter extends IMatter {
  canView: boolean;
}

/**
 * Describes a matter action
 */
export interface IMatterAction {
  state: cAccessType;
}

/**
 * Describes the matter object
 */
export interface IMatter {
  id: number;
  referenceCode: string;
  createdDtm: string;
  ownerUser: IID;
  matterType: IID;
  description: string;
  isFinalised: boolean;
  milestoneDisplayName: string | null;
  walkInProgress?: TDocumentWalkInProgress;
  action: Record<string, IMatterAction>;
  historySortDate?: string;
  compositeState: number;
}

/**
 * Describe the matters state object
 */
interface IMattersState {
  entries: Record<number, IMatter>; // Array of matters
  status: cStatusType; // API call status
  mattersByIdStatus?: cStatusType; // Matters by id API call status
  fetched: boolean; // Have entries been fetched?
  error?: string;
  page: number;
  filterData?: any;
  typeIDs?: number[];
  ownerIDs?: number[];
  emptyType?: EEmptyType;
  linkedMatters?: Record<number, ILinkedMatter>; // Array of matters
  replacementUserIDs?: number[];
}

/**
 * Render type for a matter
 */
export type TMatterRender = Omit<IMatter, "matterType" | "ownerUser"> & {
  matterType: string;
  owner: string;
};

/**
 * Render type for a matter
 */
export type TLinkedMatterRender = Omit<ILinkedMatter, "matterType" | "ownerUser"> & {
  matterType: string;
  owner: string;
};

/**
 * Describe a filter tag object
 */
interface ITag {
  name: string;
  value: string;
}

/**
 * Describe matter type object for filtering the API
 */
interface IMatterType {
  id: number;
  tags?: ITag[];
}

/**
 * Interface for post matters thunk
 */
export interface IPostMatters {
  matterType?: IMatterType;
  descriptionContains?: string;
  dataContains?: string;
  urn?: number;
  matterRef?: string;
  ownerIDs?: number[];
  milestoneIDs?: number[];
}

/**
 * Initial state
 */
const initialState: IMattersState = {
  entries: {},
  status: cStatusType.Idle,
  fetched: false,
  page: 1,
  filterData: {},
};

/**
 * Thunk for fetching matters filters
 */
export const getMattersFilters = createAsyncThunk(
  "matters/getMattersFilters",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const {
        data: { matterTypes, owners },
      } = await api({ endpoint: matterFiltersUrl, dispatch, method: "GET" });
      const typeIDs = matterTypes.map((matterType: any) => matterType.id);
      const ownerIDs = owners.map((owner: any) => owner.id);

      return { typeIDs, ownerIDs };
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching matters
 */
export const postMatters = createAsyncThunk(
  "matters/postMatters",
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      const endpoint = mattersUrl;
      const {
        matters: { page, filterData },
      } = getState() as RootState;

      const body: { page: number; filter?: IPostMatters } = {
        page: page,
      };

      const filter = clone(filterData);

      if (areSomeDefined(filter)) {
        if (filter?.documentTypeID === "") {
          delete filter.documentTypeID;
        }
        body.filter = filter;
      }

      const response = await api({ endpoint, dispatch, body });
      return createIdList(response.data.matters);
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Interface for post matters by ID thunk
 */
interface IPostMattersById {
  ids: number[];
  force?: boolean;
}

/**
 * Thunk for fetching matters by ID
 */
export const postMattersById = createAsyncThunk(
  "matters/postMattersById",
  async ({ ids, force = false }: IPostMattersById, { dispatch, rejectWithValue, getState }) => {
    try {
      let id = ids;
      // If we are not forcing the fetch, check if we have the data in the cache
      // (force will fetch the data regardless of the cache state)
      if (!force) {
        const state = getState();

        const {
          matters: { entries },
        } = state as RootState;

        // Get the expired ids
        id = getExpiredIdCacheList({ ids, entries: entries as unknown as TUnknownEntries });
      }

      if (id.length === 0) {
        return {};
      }

      const response = await api({ endpoint: mattersUrl, dispatch, body: { id } });
      return createIdList(response.data.matters);
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching linked matters
 */
export const postLinkedMatters = createAsyncThunk(
  "matters/postLinkedMatters",
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await api({ endpoint: matterLinkedUrl, dispatch, body: { id } });
      return createIdList(response.data.linkedMatters);
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching transfer users
 */
export const postMatterTransferUsers = createAsyncThunk(
  "matters/postMatterTransferUsers",
  async (matterID: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await api({ endpoint: transferMatterUsersUrl, dispatch, body: { matterID } });
      return response.data.userIDs;
    } catch (err: any) {
      errorToast(err.message);
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for transfering ownership
 */
export const postMatterOwnershipTransfer = createAsyncThunk(
  "matters/postMatterOwnershipTransfer",
  async (
    {
      matterID,
      fromUserID,
      toUserID,
      transferNote,
    }: { matterID: number; fromUserID: number; toUserID: number; transferNote: string },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await api({
        endpoint: matterOwnershipTransferUrl,
        dispatch,
        body: { matterID, fromUserID, toUserID, transferNote },
      });
      toast("Matter transferred");

      // User no longer has access to the matter
      if (response.data.matter === null) {
        return { remove: matterID };
      }

      return createIdList([response.data.matter]);
    } catch (err: any) {
      errorToast(err.message);
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for getting the matter ID from matter ref
 */
export const postMatterGetId = createAsyncThunk(
  "matters/postMatterGetId",
  async ({ ref }: { ref: string }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api({
        endpoint: matterGetIdUrl,
        dispatch,
        body: { ref },
      });

      return response.data.ID;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Effect to fetch matter types and owners after fetching matters
 * @param _           Action
 * @param listenerApi Listener API
 * @returns void
 */
export const fetchMattersEffect = (
  _: UnknownAction,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>,
) => {
  const { dispatch, getState, cancelActiveListeners } = listenerApi;
  const {
    matters: { entries },
  } = getState() as RootState;

  cancelActiveListeners();

  const uniqMatterTypeIds = uniq(map(entries, (matter) => matter.matterType.id));
  const uniqOwnerIds = uniq(map(entries, (matter) => matter.ownerUser.id));

  if (uniqMatterTypeIds.length > 0) {
    dispatch(postMatterTypes(uniqMatterTypeIds));
  }

  if (uniqOwnerIds.length > 0) {
    dispatch(postUsersByID({ ids: uniqOwnerIds }));
  }
};

/**
 * Effect to fetch matter filter items after fetching filters
 * @param _           Action
 * @param listenerApi Listener API
 * @returns void
 */
export const fetchMatterFiltersEffect = (
  _: UnknownAction,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>,
) => {
  const { dispatch, getState } = listenerApi;
  const state = getState();
  const {
    matters: { typeIDs, ownerIDs },
  } = state as RootState;

  listenerApi.cancelActiveListeners();

  if (typeIDs) {
    dispatch(postMatterTypes(typeIDs));
  }

  if (ownerIDs) {
    dispatch(postUsersByID({ ids: ownerIDs }));
  }
};

/**
 * Effect to fetch linked matters items after fetching linked matters
 * @param _           Action
 * @param listenerApi Listener API
 * @returns void
 */
export const fetchLinkedMattersEffect = (
  _: UnknownAction,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>,
) => {
  const { dispatch, getState } = listenerApi;
  const state = getState();
  const {
    matters: { linkedMatters },
  } = state as RootState;

  listenerApi.cancelActiveListeners();

  if (linkedMatters) {
    const uniqMatterTypeIds = uniq(map(linkedMatters, (matter) => matter.matterType.id));
    const uniqOwnerIds = uniq(map(linkedMatters, (matter) => matter.ownerUser.id));

    if (uniqMatterTypeIds.length > 0) {
      dispatch(postMatterTypes(uniqMatterTypeIds));
    }

    if (uniqOwnerIds.length > 0) {
      dispatch(postUsersByID({ ids: uniqOwnerIds }));
    }
  }
};

/**
 * Matters reducer
 */
export const mattersSlice = createSlice({
  name: "matters", // The name of the slice
  initialState, // Set the initialState
  reducers: {
    resetMattersState: (state) => {
      state.page = 1;
      state.entries = {};
      state.error = undefined;
    },
    updateSideFilterState: (state, action: PayloadAction<IPostMatters>) => {
      state.filterData = action.payload;
    },
    resetSideFilterState: (state) => {
      state.filterData = {};
    },
    resetLinkedMattersState: (state) => {
      state.linkedMatters = undefined;
    },
    deleteMatter: (state, action: PayloadAction<number>) => {
      state.entries = omit(state.entries, [action.payload]);
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // Set status to loading when the promise is pending
      .addCase(postMatters.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
        state.emptyType = undefined;
      })
      // Set status to failed if the promise is rejected
      .addCase(postMatters.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Set status back to idle once the promise has been fulfilled
      .addCase(postMatters.fulfilled, (state, action) => {
        // If there is filter data and there are no entries
        if (Object.keys(state.filterData as IPostMatters).length > 0 && Object.keys(action.payload).length === 0) {
          state.emptyType = EEmptyType.FilterNotFound;
          // If there are no entries and there is no filter data
        } else if (
          Object.keys(state.filterData as IPostMatters).length === 0 &&
          Object.keys(state.entries).length === 0 &&
          Object.keys(action.payload).length === 0
        ) {
          state.emptyType = EEmptyType.NotFound;
          // Otherwise, end of list has been reached
        } else if (Object.keys(action.payload).length === 0) {
          state.emptyType = EEmptyType.End;
        } else {
          state.entries = { ...state.entries, ...action.payload };
          state.page = state.page + 1;
        }
        state.status = cStatusType.Idle;
        state.fetched = true; // Set fetched to true
      })
      // Set status to loading when the promise is pending
      .addCase(postMattersById.pending, (state) => {
        state.mattersByIdStatus = cStatusType.Loading;
        state.error = undefined;
      })
      // Set error to response if the promise is rejected
      .addCase(postMattersById.rejected, (state, action) => {
        state.mattersByIdStatus = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Set status back to idle once the promise has been fulfilled
      .addCase(postMattersById.fulfilled, (state, action) => {
        state.mattersByIdStatus = cStatusType.Idle;
        state.entries = { ...state.entries, ...action.payload };
      })
      .addCase(postLinkedMatters.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postLinkedMatters.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postLinkedMatters.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.linkedMatters = action.payload;
      })
      // Reset error on fetch
      .addCase(getMattersFilters.pending, (state) => {
        state.error = undefined;
      })
      // Set error on failure
      .addCase(getMattersFilters.rejected, (state, action) => {
        state.error = action.payload as string;
      })
      // Set filter records on success
      .addCase(getMattersFilters.fulfilled, (state, action) => {
        state.typeIDs = action.payload.typeIDs;
        state.ownerIDs = action.payload.ownerIDs;
      })
      .addCase(postMatterTransferUsers.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postMatterTransferUsers.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postMatterTransferUsers.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.replacementUserIDs = action.payload;
      })
      .addCase(postMatterOwnershipTransfer.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postMatterOwnershipTransfer.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postMatterOwnershipTransfer.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;

        // If user no longer has access to the matter, remove it from state
        // otherwise update the matter
        if (action.payload.hasOwnProperty("remove")) {
          const updatedEntries = omit(state.entries, [action.payload.remove]);
          state.entries = updatedEntries;
        } else {
          state.entries = { ...state.entries, ...action.payload };
        }
      });
  },
});

export const { resetMattersState, updateSideFilterState, resetSideFilterState, resetLinkedMattersState, deleteMatter } =
  mattersSlice.actions;

// Select the status
export const selectMattersStatus = (state: RootState) => state.matters.status;
// Select matters by id status
export const selectMattersByIdStatus = (state: RootState) => state.matters.mattersByIdStatus;
// Select matters
export const selectMatters = (state: RootState) => state.matters.entries;
// Select matters error
export const selectMattersError = (state: RootState) => state.matters.error;
// Select empty type
export const selectMattersEmptyType = (state: RootState) => state.matters.emptyType;
// Select typeIds
export const selectMattersTypeIds = (state: RootState) => state.matters.typeIDs;
// Select ownerIds
export const selectMattersOwnerIds = (state: RootState) => state.matters.ownerIDs;
// Select linkedMatters
export const selectLinkedMatters = (state: RootState) => state.matters.linkedMatters;
// Select replacementUserIDs
export const selectMatterReplacementUserIDs = (state: RootState) => state.matters.replacementUserIDs;

export default mattersSlice.reducer;
