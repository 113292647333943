import classNames from "classnames";
import { AriaRole, CSSProperties } from "react";
import {
  useAlignContent,
  useAlignItems,
  useAlignSelf,
  useDisplay,
  useJustifyContent,
  useSpacing,
} from "../../../app/hooks";
import { IAlignContent, IAlignItems, IAlignSelf, IJustifyContent, TDisplay } from "../../../app/styleTypes";
import { ISpacing } from "../../../app/types";
import Div from "../../Div/Div";
import styles from "./Column.module.scss";

/**
 * Possible column values - 12 column grid
 */
export type TColumnRange = "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export interface IColumn {
  children?: React.ReactNode;
  xs?: TColumnRange;
  sm?: TColumnRange;
  md?: TColumnRange;
  lg?: TColumnRange;
  xl?: TColumnRange;
  xxl?: TColumnRange;
  all?: TColumnRange;
  grow?: boolean;
  alignContent?: IAlignContent;
  alignSelf?: IAlignSelf;
  alignItems?: IAlignItems;
  justifyContent?: IJustifyContent;
  className?: string;
  spacing?: ISpacing;
  testId?: string;
  role?: AriaRole;
  style?: CSSProperties;
  display?: TDisplay;
}

/**
 * A row column of a 12 column grid - nest inside Row component
 * @param children       The children of the column
 * @param xs             Extra small breakpoint
 * @param sm             Small breakpoint
 * @param md             Medium breakpoint
 * @param lg             Large breakpoint
 * @param xl             Extra Large breakpoint
 * @param xxl            Extra Extra Large breakpoint
 * @param all            Default column width
 * @param grow           Should the column grow on container resize?
 * @param alignContent   Align column inner content
 * @param alignSelf      Align self
 * @param alignItems     Align individual flex items
 * @param spacing        Set Spacing object
 * @param className      Custom class
 * @param role           Set the Aria role to Row for flex tables
 * @param style          Custom inline styles
 * @param display        Set the display utility styles
 * @returns JSX.Element
 */
function Column({
  children,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  all,
  grow,
  alignContent,
  alignSelf,
  alignItems,
  justifyContent,
  className,
  spacing,
  testId,
  role,
  style,
  display,
}: IColumn): JSX.Element {
  const spacingClx = useSpacing(spacing); // Get spacing classes
  const alignSelfClx = useAlignSelf(alignSelf); // Get align-self classes
  const alignItemsClx = useAlignItems(alignItems); // Get align-items classes
  const alignContentClx = useAlignContent(alignContent); // Get align-content classes
  const justifyContentClx = useJustifyContent(justifyContent); // Get justify-content classes
  const displayClx = useDisplay(display); // Get display classes
  const clx = classNames(
    (spacing = useSpacing(spacing)), // Get spacing classes
    all && styles[`col-${all}`], // Add all styles if exists
    xs && styles[`col-xs-${xs}`], // Add xs styles if exists
    sm && styles[`col-sm-${sm}`], // Add sm styles if exists
    md && styles[`col-md-${md}`], // Add md styles if exists
    lg && styles[`col-lg-${lg}`], // Add lg styles if exists
    xl && styles[`col-xl-${xl}`], // Add xl styles if exists
    xxl && styles[`col-xxl-${xxl}`], // Add xxl styles if exists
    !xs && !sm && !md && !lg && !xl && !xxl && !all && styles.col, // If no column values have been provided, add the generic col styles
    grow === false && styles.noGrow, // If grow is false, add the "no grow" styles
    alignSelfClx,
    alignItemsClx,
    alignContentClx,
    justifyContentClx,
    className, // Custom class
    spacingClx, // Spacing
    displayClx, // Utilities: display classes
  );

  return (
    <Div className={clx} testId={testId} role={role} style={style}>
      {children}
    </Div>
  );
}

export default Column;
