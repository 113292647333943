import Div from "../../../Div/Div";
import SuccessIcon from "../../../SuccessIcon/SuccessIcon";
import Typography from "../../../Typography/Typography";
import styles from "./SupportSuccess.module.scss";

/**
 * The success component shown after the support has been submitted
 * @returns JSX.Element
 */
function SupportSuccess(): JSX.Element {
  return (
    <Div className={styles.className} testId="customer-support-success">
      <Div spacing={{ mb: 6 }}>
        <SuccessIcon />
      </Div>
      <Typography variant="h4" weight="bold" spacing={{ mb: 0 }}>
        Thanks for your query.
      </Typography>
      <Typography variant="h4" weight="bold">
        We'll be in touch soon.
      </Typography>
    </Div>
  );
}

export default SupportSuccess;
