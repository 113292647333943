import { EEmptyType, IXDirection } from "../../app/types";
import Div from "../Div/Div";
import Icon, { EIcon } from "../Icon/Icon";
import Typography from "../Typography/Typography";
import styles from "./EmptyState.module.scss";
interface IEmptyState {
  emptyType?: EEmptyType;
  name: string;
}

/**
 * Render empty state for documents and notifications list
 * @param emptyType Empty type
 * @param name      Name of items
 * @returns JSX.Element
 */
function EmptyState({ emptyType, name }: IEmptyState): JSX.Element {
  switch (emptyType) {
    case EEmptyType.End:
      return (
        <Div pt={{ base: 5 }} pb={{ base: 4 }} className={styles.wrapper}>
          <Typography align={IXDirection.Center} className={styles.typography}>
            End of list
          </Typography>
        </Div>
      );

    case EEmptyType.NotFound:
      return (
        <Div pt={{ base: 8 }} className={styles.wrapper}>
          <Icon icon={EIcon.Documents} className={styles.icon} />
          <Typography align={IXDirection.Center} className={styles.typography}>
            There are no {name} available to view.
          </Typography>
        </Div>
      );

    case EEmptyType.NotificationsNotFound:
      return (
        <Div pt={{ base: 8 }} className={styles.wrapper}>
          <Icon icon={EIcon.Alerts} className={styles.icon} />
          <Typography align={IXDirection.Center} className={styles.typography}>
            You have no {name}
          </Typography>
        </Div>
      );

    case EEmptyType.FilterNotFound:
      return (
        <Div pt={{ base: 8 }} className={styles.wrapper}>
          <Icon icon={EIcon.Search} className={styles.icon} />
          <Typography align={IXDirection.Center} className={styles.typography}>
            Nothing found matching your search criteria.
          </Typography>
        </Div>
      );

    default:
      return <></>;
  }
}

export default EmptyState;
