import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Control, Controller, UseFormResetField } from "react-hook-form";
import { REQUIRED_FIELD_MESSAGE } from "../../app/constants";
import { bytesToMb, formatFileSize } from "../../utils/fileToSize/fileToSize";
import Div from "../Div/Div";
import Label from "../FormField/Label/Label";
import Icon, { EIcon } from "../Icon/Icon";
import IconFromMimeType from "../IconFromMimeType/IconFromMimeType";
import Typography from "../Typography/Typography";
import styles from "./FileUploader.module.scss";

interface IFileUploader {
  control: Control | undefined;
  defaultValue: File | null;
  name: string;
  maxFileUploadSize: number;
  required?: boolean;
  resetField?: UseFormResetField<any>;
}

/**
 * The file uploader component
 * @param control           React hook form control
 * @param defaultValue      The default value
 * @param name              The field name
 * @param maxFileUploadSize The max file upload size
 * @param required          Is the field required?
 * @param resetField        React hook form reset field
 * @returns JSX.Element
 */
function FileUploader({ control, defaultValue, name, maxFileUploadSize, required, resetField }: IFileUploader) {
  const [files, setFiles] = useState<File[]>([]);
  let onFileChange: (...event: any[]) => void;

  /**
   * Deletes the uploaded file
   */
  function handleDelete() {
    setFiles([]);
    onFileChange(null);
    if (resetField) {
      // Reset the error on the file field (Does not work as expected outside the timeout)
      setTimeout(() => {
        resetField(name);
      }, 0);
    }
  }

  const Dropzone = ({ onChange }: { onChange: (...event: any[]) => void }) => {
    onFileChange = onChange;
    const onDrop = (acceptedFiles: File[]) => {
      setFiles(acceptedFiles);
      onChange(acceptedFiles);
    };

    const { getRootProps, getInputProps, open } = useDropzone({
      onDrop,
      maxFiles: 1,
      noClick: true,
      noKeyboard: true,
      multiple: false,
    });

    return (
      <>
        {files.length > 0 ? (
          <>
            <Label>
              <Typography>Uploaded file</Typography>
            </Label>

            <Div display={{ base: "flex" }} alignItems={{ base: "center" }}>
              <Div display={{ base: "flex" }} spacing={{ mr: 5 }}>
                <Div display={{ base: "flex" }} alignItems={{ base: "center" }} spacing={{ mr: 4 }}>
                  <IconFromMimeType fileType={files[0].type} />
                </Div>
                <Div className={styles.fileNameSize}>
                  <Typography className={styles.fileName}>{files[0].name}</Typography>
                  <Typography className={bytesToMb(files[0]?.size) > maxFileUploadSize ? styles.error : ""}>
                    {formatFileSize(files[0]?.size, true)}
                  </Typography>
                </Div>
              </Div>

              <Div className={styles.deleteIconWrapper} onClick={handleDelete}>
                <Icon icon={EIcon.Delete} />
              </Div>
            </Div>
          </>
        ) : (
          <Div testId="file-upload">
            <Div>
              <div {...getRootProps({ className: styles.dropzone })}>
                <Icon icon={EIcon.Document} className={styles.dropzoneIcon} />
                <input {...getInputProps({ onChange })} data-testid="dropzone" />
                <p>
                  Drag and drop a file here, or{" "}
                  <span className={styles.dropzoneBrowse} onClick={open}>
                    browse
                  </span>
                </p>
              </div>
            </Div>
          </Div>
        )}
      </>
    );
  };

  return (
    <Controller
      render={({ field: { onChange, ...field } }) => <Dropzone {...field} onChange={onChange} />}
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: required ? REQUIRED_FIELD_MESSAGE : false,
        validate: {
          fileTooLarge: () => {
            if (files[0] && bytesToMb(files[0].size) > maxFileUploadSize) {
              return `Your file exceeds the size limit of ${maxFileUploadSize}mb`;
            }
          },
        },
      }}
    />
  );
}

export default FileUploader;
