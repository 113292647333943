import classNames from "classnames";
import { Control } from "react-hook-form";
import { IXDirection } from "../../../../app/types";
import FormField, { cFormFieldType } from "../../../FormField/FormField";
import Icon, { EIcon } from "../../../Icon/Icon";
import { ITableCardColumn } from "../../TableCard";
import styles from "./TableCardFilter.module.scss";

type ITableCardFilter = {
  register: Function;
  control: Control;
  columns: ITableCardColumn[];
  colspan?: ITableCardColumn["colspan"];
  testId?: string;
} & Omit<ITableCardColumn, "orderable" | "onOrder" | "width">;

/**
 * A data table filter cell
 * @param filterType     The type of filter (see TFilterType)
 * @param colspan        Amount of columns to span (1 to 12)
 * @param heading        Heading used for unique field name
 * @param register       Register function from React Hook Form
 * @param filterOptions  Options for filter types "select" and "autocomplete"
 * @param onInputChange  Function to run on input change of autocomplete type - should update the options
 * @param fieldFullWidth Set filter field to full width of column
 * @param control        React Hook Form control
 * @returns JSX.Element
 */
function TableCardFilter({
  filterType,
  heading,
  register,
  filterOptions,
  onInputChange = () => {},
  fieldFullWidth,
  align = IXDirection.Left,
  control,
  testId,
}: ITableCardFilter): JSX.Element {
  let contents;

  const clx = classNames(styles.className, styles[align]);

  switch (
    filterType // Switch filter type
  ) {
    case "date": // If date
      contents = <Icon icon={EIcon.Date} />; // Render calendar icon
      break;

    case "input": // If input
      contents = (
        <FormField // Render input field
          name={heading || ""}
          register={register}
          fullWidth={fieldFullWidth}
        />
      );
      break;

    case "select": // If select
      contents = (
        <FormField // Render select field
          name={heading || ""}
          type={cFormFieldType.Select}
          options={filterOptions || []}
          register={register}
          fullWidth={fieldFullWidth}
          control={control}
        />
      );
      break;

    case "autocomplete": // If autocomplete
      contents = (
        <FormField // Render autocomplete field
          name={heading || ""}
          type={cFormFieldType.AutoComplete}
          options={filterOptions || []}
          onInputChange={onInputChange}
          register={register}
          fullWidth={fieldFullWidth}
          control={control}
          clearable
        />
      );
      break;

    default: // Default to display nothing
      contents = <></>;
      break;
  }
  return (
    <td data-testid={testId} className={clx}>
      {contents}
    </td>
  );
}

export default TableCardFilter;
