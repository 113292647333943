import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import classNames from "classnames";
import Accordion from "../../../../../components/Accordion/Accordion";
import { EESignState, IESignGroup } from "../../../../../modules/esignSlice";
import { EActivityDeleteType } from "../ConfirmDeleteRecipientModal/ConfirmDeleteActivityModal";
import styles from "../ESignWorkflow.shared.module.scss";
import ESignWorkflowRecipient from "../ESignWorkflowRecipients/ESignWorkflowRecipient/ESignWorkflowRecipient";
import ESignWorkflowGroupFooter from "./ESignWorkflowGroupFooter/ESignWorkflowGroupFooter";
import ESignWorkflowGroupHeader from "./ESignWorkflowGroupHeader/ESignWorkflowGroupHeader";

interface IESignWorkflowGroup {
  group: IESignGroup;
  arrayIndex: number;
  handleDeleteActivity: (id: number, activityType: EActivityDeleteType) => void;
  handleDeleteGroupActivity: (group: IESignGroup, recipientID: number) => void;
  handleEditGroup: (group: IESignGroup) => void;
  handleAddRecipientToGroup: (group: IESignGroup) => void;
  isSortable?: boolean;
  state: EESignState;
}

/**
 * An esign group
 * @param group                      The esign group
 * @param arrayIndex                 The index in the array
 * @param handleDeleteActivity       Function to delete an activity
 * @param handleDeleteGroupActivity  Function to delete a group activity
 * @param handleEditGroup            Function to edit a group
 * @param handleAddRecipientToGroup  Function to add a recipient to a group
 * @param isSortable                 Is the group sortable?
 * @param state                      The esign state
 * @returns JSX.Element
 */
function ESignWorkflowGroup({
  group,
  arrayIndex,
  handleDeleteActivity,
  handleDeleteGroupActivity,
  handleEditGroup,
  handleAddRecipientToGroup,
  isSortable = true,
  state,
}: IESignWorkflowGroup) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: group.id });
  const sortableAttributes = isSortable ? { ...attributes, ...listeners } : null;

  // Use default dnd-kit styles
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div
      ref={isSortable ? setNodeRef : null}
      style={isSortable ? style : {}}
      {...sortableAttributes}
      className={classNames(styles.workflowGroup, isSortable && styles.sortable, isDragging && styles.active)}
      data-testid="workflow-group"
    >
      <Accordion
        areItemsOpen={true}
        items={[
          {
            header: (
              <ESignWorkflowGroupHeader
                group={group}
                arrayIndex={arrayIndex}
                handleDeleteActivity={handleDeleteActivity}
                handleEditGroup={handleEditGroup}
                state={state}
              />
            ),
            contents: (
              <>
                {group.persons.map((recipient) => (
                  <ESignWorkflowRecipient
                    recipient={recipient}
                    handleDeleteActivity={handleDeleteActivity}
                    handleDeleteGroupActivity={handleDeleteGroupActivity}
                    group={group}
                    key={recipient.id}
                    state={state}
                  />
                ))}
                <ESignWorkflowGroupFooter
                  group={group}
                  handleAddRecipientToGroup={handleAddRecipientToGroup}
                  state={state}
                />
              </>
            ),
            className: styles.accordion,
            headerClassName: styles.accordionHeader,
            contentsClassName: styles.accordionContents,
            chevronClassName: styles.accordionChevron,
          },
        ]}
      />
    </div>
  );
}

export default ESignWorkflowGroup;
