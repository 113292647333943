import classNames from "classnames";
import { Control, Controller } from "react-hook-form";
import Select, { components, OptionsOrGroups, PropsValue } from "react-select";
import { REQUIRED_FIELD_MESSAGE } from "../../../app/constants";
import { useAppSelector } from "../../../app/hooks";
import { selectUser } from "../../../modules/userSlice";
import Div from "../../Div/Div";
import AffixWrapper from "../AffixWrapper/AffixWrapper";
import { IFieldOption, IFormField } from "../FormField";
import ClearButton from "./ClearButton.tsx/ClearButton";

export type TSelectAutocomplete = {
  options: OptionsOrGroups<any, any>;
  control: Control | undefined;
  defaultValue?: PropsValue<any>;
  disabled?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  isMulti?: boolean;
  required?: boolean;
  footer?: JSX.Element;
  hidden?: boolean;
  onFocus?: () => void;
} & Pick<IFormField, "onInputChange" | "prepend" | "append" | "fullWidth" | "name">;

/**
 * Display a select field
 * @param options       The select options
 * @param control       React Hook Form control
 * @param defaultValue  The field default value
 * @param disabled      Is the select disabled?
 * @param isClearable   Is the select clearable?
 * @param isSearchable  Is the select searchable?
 * @param isMulti       Can multiple options be selected?
 * @param onInputChange Input change function
 * @param prepend       Prepend a component to the field
 * @param append        Append a component to the field
 * @param fullWidth     Display the field at full width?
 * @param name          Field name
 * @param required      Is the field required?
 * @param footer        The select footer
 * @param hidden        Is the field hidden?
 * @param onFocus       onFocus callback function
 * @returns JSX.Element
 */
function SelectAutocomplete({
  name,
  options,
  control,
  defaultValue,
  disabled,
  fullWidth,
  isClearable = false,
  isSearchable = false,
  isMulti = false,
  onInputChange,
  prepend,
  append,
  required,
  footer,
  hidden,
  onFocus,
}: TSelectAutocomplete) {
  const clx = classNames(isSearchable && "autocomplete", !fullWidth && "autoWidth", hidden && "hidden");
  const user = useAppSelector(selectUser);

  // Custom menu list so we can add a footer
  const MenuList = ({ children, ...props }: any) => {
    return (
      <>
        <components.MenuList {...props}>{children}</components.MenuList>
        {footer}
      </>
    );
  };

  /**
   * Makes the label italic if it is retired, is the logged in user or has text EMBED
   * @param param label
   * @returns JSX.Element
   */
  function formatOptionLabel({ label, value, isRetired, isInternal }: any) {
    const isItalic = isRetired || isInternal || value === user?.id;
    return (
      <div
        style={{
          fontStyle: isItalic ? "italic" : "initial",
        }}
      >
        {label} {isRetired && <span>*</span>}
      </div>
    );
  }

  /**
   * Handle menu open
   */
  function handleMenuOpen() {
    // Scroll to the selected option when the menu opens
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName("SelectAutocomplete__option--is-selected")[0];
      if (!isMulti && selectedEl) {
        selectedEl.scrollIntoView({ behavior: "auto", block: "nearest", inline: "start" });
      }
    }, 15);
  }

  return (
    <AffixWrapper prepend={prepend} append={append} fullWidth={fullWidth}>
      <Div testId="select-autocomplete" className={clx}>
        <Controller
          name={name}
          control={control}
          rules={{ required: required ? REQUIRED_FIELD_MESSAGE : false }}
          defaultValue={isMulti ? defaultValue : defaultValue || options[0]}
          render={({ field: { onChange, ref, value, ...field } }) => (
            <>
              <label htmlFor={name} hidden>
                {name}
              </label>
              <Select
                {...field}
                ref={ref}
                isMulti={isMulti}
                name={name}
                inputId={name}
                className="SelectAutocomplete"
                classNamePrefix="SelectAutocomplete"
                options={options}
                menuPosition="absolute"
                isDisabled={disabled}
                components={{
                  ClearIndicator: () => <ClearButton ref={ref} />,
                  MenuList,
                }}
                onInputChange={onInputChange}
                isClearable={isClearable}
                isSearchable={isSearchable}
                placeholder={isClearable ? "" : "Select..."}
                noOptionsMessage={() => "Nothing found"}
                onFocus={onFocus}
                onChange={(newValue: IFieldOption) => {
                  if (Array.isArray(newValue)) {
                    const foundValues = newValue.filter((val: any) => val.value);
                    return onChange(foundValues);
                  }
                  return onChange(newValue);
                }}
                formatOptionLabel={formatOptionLabel}
                closeMenuOnSelect={!isMulti}
                value={value}
                onMenuOpen={handleMenuOpen}
              />
            </>
          )}
        />
      </Div>
    </AffixWrapper>
  );
}

export default SelectAutocomplete;
