import Div from "../Div/Div";
import styles from "./SignedFile.module.scss";

interface ISignedFile {
  children: React.ReactNode;
}

/**
 * A component to render a signed version of a file, e.g. a pdf icon with the green success icon
 * @returns JSX.Element
 */
function SignedFile({ children }: ISignedFile) {
  return (
    <Div className={styles.className} testId="signed-file">
      {children}
    </Div>
  );
}

export default SignedFile;
