import { cSizeType, cThemeColorType } from "../../../../../app/constants";
import Div from "../../../../../components/Div/Div";
import Icon, { EIcon } from "../../../../../components/Icon/Icon";
import Column from "../../../../../components/Row/Column/Column";
import Row from "../../../../../components/Row/Row";
import Typography from "../../../../../components/Typography/Typography";
import { INotificationsEntry } from "../../../../../modules/notificationsSlice";
import styles from "./InsufficientPermissions.module.scss";

/**
 * Display insufficient permissions when no link is available
 * @param urn The document URN
 * @returns JSX.Element
 */
function InsufficientPermissions({ urn }: Pick<INotificationsEntry, "urn">): JSX.Element {
  return (
    <Div>
      <Row alignItems={{ base: "center" }} className={styles.noWrap}>
        <Column grow={false}>
          <Div display={{ base: "inline-flex" }}>
            <Icon icon={EIcon.Inaccessible} color={cThemeColorType.Secondary} size={cSizeType.Medium} />
          </Div>
        </Column>
        <Column grow={false}>
          <Div spacing={{ px: 4 }}>
            <Typography className={styles.text}>To request access, email URN {urn} to the administrator</Typography>
          </Div>
        </Column>
      </Row>
    </Div>
  );
}

export default InsufficientPermissions;
