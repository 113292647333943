import { updateIsLoggedIn } from "../../modules/userSlice";
import { isInvalidTokenError, isNotLoggedInError, isUnauthorisedError } from "../errors/errors";

interface IHandleErrorStatus {
  dispatch?: any;
  statusCode: number;
}

/**
 * Handle an API error status code in a global way
 * @param dispatch   Thunk dispatcher
 * @param statusCode The API response status code
 */
export default function handleErrorStatus({ dispatch, statusCode }: IHandleErrorStatus) {
  if (
    dispatch &&
    (isUnauthorisedError(statusCode) || isNotLoggedInError(statusCode) || isInvalidTokenError(statusCode))
  ) {
    dispatch(updateIsLoggedIn(false));
  }
}
