import { cSizeType, cThemeColorType } from "../../../../../../app/constants";
import Button, { EButtonVariant } from "../../../../../../components/Button/Button";
import Div from "../../../../../../components/Div/Div";
import { EIcon } from "../../../../../../components/Icon/Icon";
import { EESignState, IESignGroup } from "../../../../../../modules/esignSlice";
import styles from "../../ESignWorkflow.shared.module.scss";

interface IESignWorkflowGroupFooter {
  group: IESignGroup;
  handleAddRecipientToGroup: (group: IESignGroup) => void;
  state: EESignState;
}

/**
 * The esign group footer
 * @param group                     The esign group
 * @param handleAddRecipientToGroup Function to add a recipient to the group
 * @param state                     The esign state
 * @returns JSX.Element
 */
function ESignWorkflowGroupFooter({ group, handleAddRecipientToGroup, state }: IESignWorkflowGroupFooter) {
  /**
   * Handles add recipient click
   * @param event The mouse event
   */
  function handleAddRecipientClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.stopPropagation();
    handleAddRecipientToGroup(group);
  }

  return (
    <Div className={styles.workflowGroupFooter}>
      <Button
        size={cSizeType.Small}
        variant={EButtonVariant.Link}
        color={cThemeColorType.Primary}
        icon={EIcon.Add}
        onClick={(event) => handleAddRecipientClick(event)}
        disabled={state !== EESignState.Configuration}
        testId="add-reciptient-to-group-button"
      >
        Add recipient to group
      </Button>
    </Div>
  );
}

export default ESignWorkflowGroupFooter;
