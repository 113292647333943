import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { isRequestBodyError, isUnableToViewMatterError, isWalkError } from "../../../utils/errors/errors";
import Button, { cButtonType } from "../../Button/Button";
import { EIcon } from "../../Icon/Icon";
import { ModalActions } from "../../Modal/Modal";

interface IHandledErrorModalActions {
  errorCode: number;
  handleClose: () => void;
  setShouldShowPauseForm: Dispatch<SetStateAction<boolean>>;
  shouldShowPauseForm: boolean;
}

/**
 * Handled error modal actions
 * @param errorCode              The error code being handled
 * @param handleClose            Function to close the modal
 * @param setShouldShowPauseForm Set state for rendering the walk pause form
 * @param shouldShowPauseForm    Should the pause form be rendered?
 * @returns JSX.Element
 */
function HandledErrorModalActions({
  errorCode,
  handleClose,
  setShouldShowPauseForm,
  shouldShowPauseForm,
}: IHandledErrorModalActions) {
  const [content, setContent] = useState<JSX.Element | null>(null);

  useEffect(() => {
    // Set the content based on error code
    if (errorCode) {
      switch (true) {
        case isUnableToViewMatterError(errorCode):
          setContent(
            <Button onClick={handleClose} testId="handled-error-actions-submit-button">
              Dismiss
            </Button>,
          );
          break;
        case isRequestBodyError(errorCode):
          setContent(
            <Button onClick={handleClose} testId="handled-error-actions-submit-button">
              Dismiss
            </Button>,
          );
          break;
        case isWalkError(errorCode): {
          const buttonProps = shouldShowPauseForm
            ? {
                formId: "walk-pause-modal",
                type: cButtonType.SubmitType,
                testId: "submit-walk-pause-button",
              }
            : {
                onClick: () => setShouldShowPauseForm(true),
                testId: "show-pause-form-button",
              };

          setContent(
            <Button icon={EIcon.Pause} {...buttonProps}>
              Pause
            </Button>,
          );
          break;
        }
        default:
          setContent(<></>);
          break;
      }
    }
  }, [errorCode, shouldShowPauseForm]);

  return <ModalActions justifyContent={{ base: "center" }}>{content}</ModalActions>;
}
export default HandledErrorModalActions;
