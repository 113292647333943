import { format } from "date-fns";
import { cThemeColorType } from "../../../../app/constants";
import { EPopoverPlacement } from "../../../../app/types";
import Div from "../../../../components/Div/Div";
import Icon, { EIcon } from "../../../../components/Icon/Icon";
import Popover from "../../../../components/Popover/Popover";
import Column from "../../../../components/Row/Column/Column";
import Row from "../../../../components/Row/Row";
import Typography from "../../../../components/Typography/Typography";
import styles from "./NotificationIcon.module.scss";

interface INotificationIcon {
  isRetrospective: boolean;
  recipients: string[];
  notified: boolean;
  notifyDate: string;
}

/**
 * The notification icon component
 * @param isRetrospective Was the event created in the past?
 * @param recipients      The event recipients
 * @param notified        Has the event been notifed?
 * @param notifyDate      The notify date
 * @returns JSX.Element
 */
function NotificationIcon({ isRetrospective, recipients, notified, notifyDate }: INotificationIcon) {
  const pending = !isRetrospective && !notified;
  const recipientsText = recipients.length === 1 ? "Recipient" : "Recipients";

  /**
   * Formats the notification date for display
   * @param notifyDate The notify date
   * @returns JSX.Element
   */
  function formatNotificationDate(notifyDate: string): string {
    return format(new Date(notifyDate), "dd MMM yyyy");
  }

  /**
   * Renders the popover contents
   * @returns JSX.Element
   */
  function renderPopoverContents() {
    if (notified || pending) {
      return (
        <Div className={styles.popoverContentClip}>
          <Div className={styles.popoverContents}>
            <Div testId="notification-icon-popover-contents">
              <Row alignItems={{ base: "center" }} mb={{ base: 5 }}>
                <Column grow={false} display={{ base: "flex" }} alignSelf={{ base: "flex-start" }}>
                  <Icon icon={EIcon.Date} color={cThemeColorType.Secondary} />
                </Column>
                <Column spacing={{ pl: 5 }}>
                  <Typography weight="bold">Notification date</Typography>
                  <Typography>{formatNotificationDate(notifyDate)}</Typography>
                </Column>
              </Row>
              <Row alignItems={{ base: "center" }}>
                <Column grow={false} display={{ base: "flex" }} alignSelf={{ base: "flex-start" }}>
                  <Icon icon={EIcon.User} color={cThemeColorType.Secondary} />
                </Column>
                <Column spacing={{ pl: 5 }}>
                  <Typography weight="bold">{recipientsText}</Typography>
                  <Typography>
                    {recipients.length > 0 ? (
                      recipients.map((recipient) => <Typography key={recipient}>{recipient}</Typography>)
                    ) : (
                      <Div>Nobody</Div>
                    )}
                  </Typography>
                </Column>
              </Row>
            </Div>
          </Div>
        </Div>
      );
    } else {
      return (
        <Div className={styles.popoverContents} testId="notification-icon-popover-contents">
          <Div>Events with a past date do not send notifications</Div>
        </Div>
      );
    }
  }

  /**
   * Renders the button contents
   * @returns JSX.Element
   */
  function renderButtonContents() {
    if (notified) {
      return (
        <Div testId="notification-sent-icon">
          <Icon icon={EIcon.UserNotified} />
          {<Div className={styles.numOfRecipients}>{recipients.length}</Div>}
        </Div>
      );
    } else if (pending) {
      return (
        <Div testId="notification-pending-icon">
          <Icon icon={EIcon.NotificationPending} />
        </Div>
      );
    } else {
      return (
        <Div testId="notification-disabled-icon">
          <Icon icon={EIcon.NotificationDisabled} />
        </Div>
      );
    }
  }

  return (
    <Popover
      className={styles.popover}
      variant="popover"
      popoverPlacement={EPopoverPlacement.Top}
      width="auto"
      popoverContents={renderPopoverContents()}
      buttonContents={renderButtonContents()}
      buttonProps={{
        color: cThemeColorType.Secondary,
        className: styles.notifiedButton,
      }}
    />
  );
}

export default NotificationIcon;
