import classNames from "classnames";
import { useEffect } from "react";
import zxcvbn from "zxcvbn";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectPasswordScore, updatePasswordScore } from "../../../modules/userSlice";
import styles from "./PasswordStrength.module.scss";

function PasswordStrength(password: any): JSX.Element {
  const dispatch = useAppDispatch();
  const passwordScore = useAppSelector(selectPasswordScore);

  /**
   * Returns the text to be displayed based on the password score
   * @param passwordScore The password score
   * @returns string
   */
  function getText(passwordScore = 0) {
    switch (passwordScore) {
      case 4:
        return "Strong";
      default:
        return "Weak";
    }
  }

  /**
   * Returns the className relevant to the password score
   * @param passwordScore The password score
   * @returns string
   */
  function getClass(passwordScore = 0) {
    switch (passwordScore) {
      case 2:
      case 3:
        return "good";
      case 4:
        return "strong";
      default:
        return "weak";
    }
  }

  const barInnerClx = classNames(styles.barInner, styles[getClass(passwordScore)]);
  const textClx = classNames(styles.text, styles[getClass(passwordScore)]);

  useEffect(() => {
    const result = zxcvbn(password.password);
    dispatch(updatePasswordScore(result.score));
  }, [password]);

  return (
    <div className={styles.className} data-testid="password-strength">
      <div className={styles.bar}>
        <div className={barInnerClx} data-testid="password-strength-bar-inner"></div>
      </div>
      <div className={textClx} data-testid="password-strength-text">
        {getText(passwordScore)}
      </div>
    </div>
  );
}

export default PasswordStrength;
