import classNames from "classnames";
import { format, parseISO } from "date-fns";
import { AriaRole } from "react";
import { IXDirection, TFilterType } from "../../../../app/types";
import { ITableCardColumn, ITableCardColumnCell } from "../../TableCard";
import styles from "./TableCardColumnCell.module.scss";

type ITableCardColumnCellProps = {
  cell: ITableCardColumnCell;
  i: number;
  width?: string;
  role?: AriaRole;
} & Pick<ITableCardColumn, "align" | "filterType" | "className">;

/**
 * A single flex table cell
 * @param cell       Cell content
 * @param i          The cell index in the row
 * @param align      Column alignment
 * @param filterType The type of filter on the column
 * @param className  A class name for the table cell
 * @param width      Custom width
 * @returns JSX.Element
 */
function TableCardColumnCell({
  cell,
  i,
  align = IXDirection.Left,
  filterType,
  className,
  width,
}: ITableCardColumnCellProps): JSX.Element {
  const clx = classNames(
    // Build cell classes
    styles.className, // Generic classes
    styles[align], // Alignment class
    {
      [styles.overflow]: typeof cell === "object", // Overflow style override if cell content is JSX (fixes popover)
      [styles.actionsMenuColumn]: filterType === "actions", // Overflow style override if cell content is JSX (fixes popover)
    },
    className,
  );

  const render =
    filterType === TFilterType.Date && typeof cell === "string" // If this is a date type column
      ? format(parseISO(cell), "dd MMM") // Format the date
      : cell; // Otherwise, render the cell as is

  return (
    <td className={clx} key={i} role={"cell"} style={{ width }}>
      {render}
    </td>
  );
}

export default TableCardColumnCell;
