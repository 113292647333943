import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../app/api";
import {
  addUserUrl,
  deleteUserUrl,
  disableUserUrl,
  editUserUrl,
  enableUserUrl,
  reassignUserTaskUrl,
  replacementUsersUrl,
  securityGroupIDsUrl,
  usersUrl,
} from "../app/apiUrls";
import { cStatusType } from "../app/constants";
import { RootState } from "../app/store";
import createIdList from "../utils/createIdList/createIdList";
import getExpiredIdCacheList, { TUnknownEntries } from "../utils/getExpiredIdCacheList/getExpiredIdCacheList";

/**
 * The user status
 * An enabled user is active and a disabled user is locked
 */
export enum EUserStatus {
  Active = "A",
  Locked = "L",
}

/**
 * Define a user
 */
export interface IUser {
  firstName: string;
  lastName: string | null;
  id: number;
  isInternal?: boolean;
  emailAddress?: string;
  loginName?: string;
  preferredName?: string;
  phoneNumber?: string;
  isAdmin?: boolean;
  notifyGroupID?: string;
  status?: EUserStatus;
  isTeamLead?: boolean;
  fetchTime?: number;
}

/**
 * Users state interface
 */
export interface IUsersState {
  entries: Record<number, IUser>;
  status: cStatusType;
  fetched: boolean;
  error?: string;
  addEditUserError?: string;
  editUser?: IUser;
  securityGroupIDs?: number[];
  showEditUserModal?: boolean;
  showAddUserModal?: boolean;
  resetUserSearch: boolean;
  replacementUserIDs?: number[];
  deleteUserStatus: cStatusType;
}

/**
 * Describes the post add/edit user object
 */
export interface IPostAddEditUser {
  id?: number;
  firstName: string;
  lastName: string;
  preferredName: string;
  emailAddress: string;
  phoneNumber: string | null;
  isAdmin: boolean;
  notifyGroupID: number;
  securityGroupIDs?: number[];
  isTeamLead?: boolean;
}

/**
 * Initial state for users state
 */
const initialState: IUsersState = {
  entries: {},
  status: cStatusType.Idle,
  fetched: false,
  resetUserSearch: false,
  deleteUserStatus: cStatusType.Idle,
};

/**
 * Thunk for fetching users
 */
export const postUsers = createAsyncThunk(
  "users/postUsers",
  async ({ refresh }: { refresh?: boolean }, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = usersUrl;
      const response = await api({ endpoint, dispatch, body: { id: "all" } });

      return { users: createIdList(response.data.users), refresh };
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Interface for post users by ID thunk
 */
interface IPostUsersByID {
  ids: number[];
  force?: boolean;
}

/**
 * Thunk for fetching users by id
 */
export const postUsersByID = createAsyncThunk(
  "users/postUsersByID",
  async ({ ids, force = false }: IPostUsersByID, { dispatch, rejectWithValue, getState }) => {
    try {
      const endpoint = usersUrl;
      let id = ids;

      // If we are not forcing the fetch, check if we have the data in the cache
      // (force will fetch the data regardless of the cache state)
      if (!force) {
        const state = getState();

        const {
          users: { entries },
        } = state as RootState;

        // Get the expired ids
        id = getExpiredIdCacheList({ ids, entries: entries as unknown as TUnknownEntries });
      }

      // If we have no ids to fetch, return an empty object
      if (id.length === 0) {
        return { users: {} };
      }

      const response = await api({ endpoint, dispatch, body: { id } });

      return { users: createIdList(response.data.users) };
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk to add a user
 */
export const postAddUser = createAsyncThunk(
  "users/postAddUser",
  async (
    {
      firstName,
      lastName,
      preferredName,
      emailAddress,
      phoneNumber,
      isAdmin,
      notifyGroupID,
      securityGroupIDs,
      isTeamLead,
    }: IPostAddEditUser,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const endpoint = addUserUrl;
      const response = await api({
        endpoint,
        dispatch,
        body: {
          firstName,
          lastName,
          preferredName,
          emailAddress,
          phoneNumber,
          isAdmin: Boolean(isAdmin),
          isTeamLead: Boolean(isTeamLead),
          notifyGroupID,
          securityGroupIDs,
        },
      });
      return createIdList([response.data.user]);
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk to add edit a user
 */
export const postEditUser = createAsyncThunk(
  "users/postEditUser",
  async (
    {
      id,
      firstName,
      lastName,
      preferredName,
      emailAddress,
      phoneNumber,
      isAdmin,
      notifyGroupID,
      securityGroupIDs,
      isTeamLead,
    }: IPostAddEditUser,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const endpoint = editUserUrl;
      const response = await api({
        endpoint,
        dispatch,
        body: {
          id,
          firstName,
          lastName,
          preferredName,
          emailAddress,
          phoneNumber,
          isAdmin: Boolean(isAdmin),
          isTeamLead: Boolean(isTeamLead),
          notifyGroupID,
          securityGroupIDs,
        },
      });

      const user = createIdList([response.data.user]);
      const communicationSent = response.data.communicationSent;
      return { user, communicationSent };
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching a user to edit
 */
export const postUserToEdit = createAsyncThunk(
  "users/postUserToEdit",
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = usersUrl;
      const response = await api({ endpoint, dispatch, body: { id: [id] } });
      return response.data.users[0];
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for disabling a user
 */
export const postDisableUser = createAsyncThunk(
  "users/postDisableUser",
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = disableUserUrl;
      const response = await api({ endpoint, dispatch, body: { id } });
      const user = createIdList([response.data.user]);
      return user;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for enabling a user
 */
export const postEnableUser = createAsyncThunk(
  "users/postEnableUser",
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = enableUserUrl;
      const response = await api({ endpoint, dispatch, body: { id } });
      const user = createIdList([response.data.user]);
      return user;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for deleting a user
 */
export const postDeleteUser = createAsyncThunk(
  "users/postDeleteUser",
  async ({ id, replacementID }: { id: number; replacementID?: number }, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = deleteUserUrl;
      await api({ endpoint, dispatch, body: { id, replacementID } });
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching replacement users for a user id
 */
export const postReplacementUsers = createAsyncThunk(
  "users/postReplacementUsers",
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = replacementUsersUrl;
      const response = await api({ endpoint, dispatch, body: { id } });
      return response.data.ids;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching security groups ids for a user
 */
export const postSecurityGroupIDs = createAsyncThunk(
  "users/postSecurityGroupIDs",
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = securityGroupIDsUrl;
      const response = await api({ endpoint, dispatch, body: { id: id } });
      return response.data.securityGroupIDs;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for reassigning a user's tasks from the users page
 */
export const postUserReassignTask = createAsyncThunk(
  "users/reassignTask",
  async (userID: number, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = reassignUserTaskUrl;
      const response = await api({ endpoint, dispatch, body: { userID } });
      return response.data;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

export const usersSlice = createSlice({
  name: "users", // The name of the slice
  initialState, // Set the initialState
  reducers: {
    // Add a new user to the state
    update: (state, action: PayloadAction<IUser>) => {
      state.entries[action.payload.id] = { ...state.entries[action.payload.id], ...action.payload };
    },
    resetAddUserError: (state) => {
      state.addEditUserError = undefined;
    },
    resetEditUser: (state) => {
      state.editUser = undefined;
    },
    closeEditUserModal: (state) => {
      state.showEditUserModal = false;
    },
    closeAddUserModal: (state) => {
      state.showAddUserModal = false;
    },
    updateResetUserSearch: (state, action: PayloadAction<boolean>) => {
      state.resetUserSearch = action.payload;
    },
    resetUsersError: (state) => {
      state.error = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(postUsers.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postUsers.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postUsers.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.fetched = true; // Set fetched to true
        if (action.payload.refresh) {
          state.entries = action.payload.users;
        } else {
          state.entries = { ...state.entries, ...action.payload.users };
        }
      })
      .addCase(postUsersByID.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postUsersByID.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postUsersByID.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.entries = { ...state.entries, ...action.payload.users };
      })
      .addCase(postAddUser.pending, (state) => {
        state.status = cStatusType.Loading;
        state.addEditUserError = undefined;
      })
      .addCase(postAddUser.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.addEditUserError = action.payload as string;
      })
      .addCase(postAddUser.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.entries = { ...state.entries, ...action.payload };
        state.addEditUserError = undefined;
        state.showAddUserModal = true;
      })
      .addCase(postEditUser.pending, (state) => {
        state.status = cStatusType.Loading;
        state.addEditUserError = undefined;
      })
      .addCase(postEditUser.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.addEditUserError = action.payload as string;
      })
      .addCase(postEditUser.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.entries = { ...state.entries, ...action.payload.user };
        state.showEditUserModal = action.payload.communicationSent;
        state.addEditUserError = undefined;
        state.securityGroupIDs = undefined;
        state.editUser = undefined;
      })
      .addCase(postUserToEdit.pending, (state) => {
        state.status = cStatusType.Loading;
        state.addEditUserError = undefined;
      })
      .addCase(postUserToEdit.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.addEditUserError = action.payload as string;
      })
      .addCase(postUserToEdit.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.editUser = action.payload;
        state.addEditUserError = undefined;
      })
      .addCase(postSecurityGroupIDs.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postSecurityGroupIDs.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postSecurityGroupIDs.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.securityGroupIDs = action.payload;
      })
      .addCase(postDisableUser.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postDisableUser.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postDisableUser.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.entries = { ...state.entries, ...action.payload };
      })
      .addCase(postEnableUser.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postEnableUser.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postEnableUser.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.entries = { ...state.entries, ...action.payload };
      })
      .addCase(postDeleteUser.pending, (state) => {
        state.deleteUserStatus = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postDeleteUser.rejected, (state, action) => {
        state.deleteUserStatus = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postDeleteUser.fulfilled, (state) => {
        state.deleteUserStatus = cStatusType.Idle;
      })
      .addCase(postReplacementUsers.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postReplacementUsers.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postReplacementUsers.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.replacementUserIDs = action.payload;
      })
      .addCase(postUserReassignTask.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postUserReassignTask.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postUserReassignTask.fulfilled, (state) => {
        state.status = cStatusType.Idle;
      });
  },
});

export const {
  update,
  resetAddUserError,
  resetEditUser,
  closeEditUserModal,
  closeAddUserModal,
  updateResetUserSearch,
  resetUsersError,
} = usersSlice.actions;

export const selectUsersStatus = (state: RootState) => state.users.status; // Select the status
export const selectUsers = (state: RootState) => state.users.entries;
export const selectUsersFetched = (state: RootState) => state.users.fetched; // Select fetched
export const selectUsersError = (state: RootState) => state.users.error;
export const selectAddUserError = (state: RootState) => state.users.addEditUserError;
export const selectEditUser = (state: RootState) => state.users.editUser; // Select editUser
export const selectSecurityGroupIDs = (state: RootState) => state.users.securityGroupIDs; // Select securityGroupIDs
export const selectShowEditUserModal = (state: RootState) => state.users.showEditUserModal || false; // Select showEditUserModal
export const selectShowAddUserModal = (state: RootState) => state.users.showAddUserModal || false; // Select showAddUserModal
export const selectResetSearch = (state: RootState) => state.users.resetUserSearch; // Select resetSearch
export const selectReplacementUserIDs = (state: RootState) => state.users.replacementUserIDs; // Select replacementUserIDs
export const selectDeleteUserStatus = (state: RootState) => state.users.deleteUserStatus; // Select deleteUserStatus

export default usersSlice.reducer;
