import { arrow, flip, offset, shift } from "@floating-ui/react";
import classNames from "classnames";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { cThemeColorType } from "../../app/constants";
import { useSpacing } from "../../app/hooks";
import { EPopoverPlacement, IDirection, ISpacing } from "../../app/types";
import { IButton } from "../Button/Button";
import { EIcon } from "../Icon/Icon";
import Default from "./Default/Default";
import popoverStyles from "./Popover.module.scss";
import Tooltip from "./Tooltip/Tooltip";

export type Variant = "tooltip" | "popover";

/**
 * Interface for divProps
 */
export interface IDivProps {
  icon?: EIcon;
  disabledTooltip?: React.ReactNode;
  testId?: string;
  iconColor?: cThemeColorType;
  text?: React.ReactNode | string;
  className?: string;
}

/**
 * Generic popover prop types
 */
type TPopoverGeneric = {
  popoverPlacement?: EPopoverPlacement;
  direction?: IDirection;
  popoverContents: React.ReactNode | string;
  popoverSpacing?: ISpacing;
  width?: string;
  className?: string;
  variant?: Variant;
} & Pick<IButton, "onClick">;

/**
 * Strict typing based on the popover type
 */
type TPopoverType =
  | {
      buttonContents?: JSX.Element | string;
      buttonProps?: IButton;
      divProps?: never;
    }
  | {
      buttonContents?: never;
      buttonProps?: never;
      divProps?: IDivProps & {
        onMouseEnter?: () => void;
        onMouseLeave?: () => void;
        className?: string;
      };
    };

/**
 * External state props
 */
type TPopoverStateful =
  | {
      isOpen?: never; // If is open does not exist
      setIsOpen?: never; // Set is open should not either
    }
  | {
      isOpen: boolean; // If is open exists
      setIsOpen: Dispatch<SetStateAction<boolean>>; // So should set is open
    };

type TPopover = TPopoverGeneric & TPopoverStateful & TPopoverType; // Combine types

/**
 * A button that, when clicked, displays a popover with content
 * @param variant          The popover variant
 * @param popoverPlacement The placement of the popover
 * @param popoverContents  The contents of the popover
 * @param popoverSpacing   Spacing of the popover (margin and padding [useSpacing])
 * @param buttonContents   The contents of the button to click
 * @param buttonProps      Props to send to the button component
 * @param width            The width of the popover (defaults to 12.5rem)
 * @param onClick          Callback function run on click of popover button
 * @param isOpen           Optional is open state from parent (will mimic internal state)
 * @param setIsOpen        Callback function run on click of popover button
 * @param className        Class name to apply to popover
 * @param divProps         The div props
 * @returns JSX.Element
 */
function Popover({
  variant,
  popoverPlacement,
  popoverContents,
  popoverSpacing,
  buttonContents,
  buttonProps = {} as IButton,
  width,
  onClick,
  isOpen = false,
  setIsOpen,
  className,
  divProps,
}: TPopover): JSX.Element {
  const [showPopover, setShowPopover] = useState(false); // Internal state for the popover visibility
  const popoverSpacingClx = useSpacing(popoverSpacing); // Get popover spacing classes
  const arrowRef = useRef(null);

  // Shift the popover on overflow and setup the arrow
  const middleware = [
    offset(0),
    shift({ padding: 12 }),
    arrow({
      element: arrowRef,
    }),

    flip({ mainAxis: true, crossAxis: true, fallbackStrategy: "initialPlacement" }), // Flip the popover when overflowing container
  ];

  // If external state changes, update the internal state
  useEffect(() => {
    if (isOpen === true) {
      setShowPopover(true);
    } else {
      setShowPopover(false);
    }
  }, [isOpen]);

  // If internal state changes, update the external state
  useEffect(() => {
    if (setIsOpen) {
      setIsOpen(showPopover);
    }
  }, [showPopover]); // Update on component visibility change

  // Combine popover and custom classNames
  const popoverClx = classNames(
    popoverStyles.popover, // Initialise the popover style with defaults
    popoverSpacingClx, // Add spacing styles
    className,
    variant && popoverStyles[variant],
  );

  // Combine popover button and custom classNames
  const buttonClx = classNames(popoverStyles.button, buttonProps.className);

  // Switch variant based on prop
  switch (variant) {
    case "tooltip":
      return (
        <Tooltip
          buttonClx={buttonClx}
          buttonContents={buttonContents}
          buttonProps={buttonProps}
          divProps={divProps}
          middleware={middleware}
          popoverClx={popoverClx}
          popoverContents={popoverContents}
          popoverPlacement={popoverPlacement}
          showPopover={showPopover}
          setShowPopover={setShowPopover}
          width={width}
          arrowRef={arrowRef}
        />
      );

    default:
      return (
        <Default
          buttonClx={buttonClx}
          buttonContents={buttonContents}
          buttonProps={buttonProps}
          divProps={divProps}
          middleware={middleware}
          popoverClx={popoverClx}
          popoverContents={popoverContents}
          popoverPlacement={popoverPlacement}
          showPopover={showPopover}
          setShowPopover={setShowPopover}
          width={width}
          arrowRef={arrowRef}
          onClick={onClick}
        />
      );
  }
}

export default Popover;
