import { format } from "date-fns";
import { toast } from "react-toastify";
import { IDocument } from "../../modules/documentsSlice";

/**
 * Shows the correct toast when a document is terminated
 * @param document The document that was terminated
 */
export function handleTerminationToast(document: IDocument) {
  if (document.termination) {
    const { termination } = document;
    if (termination.terminated) {
      toast("Terminated");
    } else {
      toast(`Terminated: Effective ${format(new Date(termination.terminateDate), "dd MMM yyyy")}`);
    }
  }
}
