import { lazy, Suspense } from "react";
import Div from "../../Div/Div";
import { TTimepickerFieldProps } from "./TimepickerField";

const LazyTimepickerField = lazy(() => import("./TimepickerField"));

const TimepickerField = (props: TTimepickerFieldProps) => (
  <Suspense fallback={<Div />}>
    <LazyTimepickerField {...props} />
  </Suspense>
);

export default TimepickerField;
