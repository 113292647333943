import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { cStatusType } from "../../../../app/constants";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import ConfirmDeleteEventModal from "../../../../components/ConfirmDeleteEventModal/ConfirmDeleteEventModal";
import { ETimelineGroup, TTimeline } from "../../../../modules/documentsSlice";
import {
  postDeleteCustomEvent,
  selectEventCustomError,
  selectEventCustomStatus,
} from "../../../../modules/eventCustomSlice";
import {
  postMatterTimeline,
  selectMattersTimeline,
  selectMattersTimelineError,
  selectMattersTimelineStatus,
} from "../../../../modules/mattersTimelineSlice";
import { selectUsers } from "../../../../modules/usersSlice";
import MatterTimeline from "./MatterTimeline/MatterTimeline";

/**
 * Key for get missing entries helper
 */
export enum TimelineKey {
  UserID = "userID",
}

interface IMatterTimelineContainer {
  id: number;
  filterGroup?: ETimelineGroup;
}

/**
 * Data container for matter timeline
 * @param id          The matter ID
 * @param filterGroup The timeline filter group
 * @returns JSX.Element
 */
function MatterTimelineContainer({ id, filterGroup = ETimelineGroup.All }: IMatterTimelineContainer): JSX.Element {
  const dispatch = useAppDispatch();
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const mattersTimeline = useAppSelector(selectMattersTimeline);
  const users = useAppSelector(selectUsers);
  const eventCustomStatus = useAppSelector(selectEventCustomStatus);
  const eventCustomError = useAppSelector(selectEventCustomError);
  const mattersTimelineStatus = useAppSelector(selectMattersTimelineStatus);
  const mattersTimelineError = useAppSelector(selectMattersTimelineError);
  const [timeline, setTimeline] = useState<TTimeline[]>([]);

  // Get matter timeline entries
  useEffect(() => {
    dispatch(postMatterTimeline(id));
  }, [id]);

  // Set the correct timeline
  useEffect(() => {
    if (id && mattersTimeline[id]) {
      setTimeline(mattersTimeline[id].timeline.filter((entry) => entry.common.group.includes(filterGroup)));
    }
  }, [id, mattersTimeline, filterGroup]);

  /**
   * Open the "are you sure?" modal
   * @param eventId Event ID
   */
  function handleDeleteBtnClick(eventId: number) {
    setDeleteId(eventId);
  }

  /**
   * Close the delete modal
   */
  function handleDeleteModalClose() {
    setDeleteId(null);
  }

  /**
   * Handle event deletion
   */
  async function handleDeleteEvent() {
    try {
      await dispatch(postDeleteCustomEvent({ id: deleteId as number })).unwrap();
      dispatch(postMatterTimeline(Number(id)));
      handleDeleteModalClose();
      toast("Event deleted");
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <MatterTimeline
        timeline={timeline}
        users={users}
        handleDeleteBtnClick={handleDeleteBtnClick}
        mattersTimelineStatus={mattersTimelineStatus}
        mattersTimelineError={mattersTimelineError}
      />
      <ConfirmDeleteEventModal
        isOpen={deleteId !== null}
        handleModalClose={handleDeleteModalClose}
        isLoading={eventCustomStatus === cStatusType.Loading}
        error={eventCustomError}
        handleDeleteEvent={handleDeleteEvent}
      />
    </>
  );
}

export default MatterTimelineContainer;
