import {
  autoUpdate,
  FloatingArrow,
  Middleware,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { Ref } from "react";
import { cMonoColorType } from "../../../app/constants";
import { EPopoverPlacement } from "../../../app/types";
import Button, { EButtonVariant, IButton } from "../../Button/Button";
import Div from "../../Div/Div";
import Icon, { EIcon } from "../../Icon/Icon";
import { IDivProps } from "../Popover";
import popoverStyles from "../Popover.module.scss";

interface IDefault {
  buttonContents?: JSX.Element | string;
  buttonProps?: IButton;
  buttonClx: string;
  middleware: Array<Middleware | null | undefined | false>;
  popoverClx: string;
  divProps?: IDivProps;
  showPopover: boolean;
  setShowPopover: React.Dispatch<React.SetStateAction<boolean>>;
  width?: string;
  popoverContents: React.ReactNode | string;
  arrowRef: Ref<SVGSVGElement>;
  popoverPlacement?: EPopoverPlacement;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

/**
 * Renders a default popover component.
 *
 * @param buttonContents   The contents of the button.
 * @param buttonProps      The props for the button.
 * @param buttonClx        The class name for the button.
 * @param middleware       The middleware for the popover.
 * @param popoverClx       The class name for the popover.
 * @param divProps         The props for the div.
 * @param showPopover      Indicates whether the popover is shown or hidden.
 * @param setShowPopover   Callback function to set the visibility of the popover.
 * @param width            The width of the popover.
 * @param popoverContents  The contents of the popover.
 * @param arrowRef         The ref for the arrow element.
 * @param popoverPlacement The placement of the popover.
 * @param onClick          Callback function for the button click event.
 */
function Default({
  buttonContents,
  buttonProps = {} as IButton,
  buttonClx,
  middleware,
  popoverClx,
  divProps,
  showPopover,
  setShowPopover,
  width,
  popoverContents,
  arrowRef,
  popoverPlacement,
  onClick,
}: IDefault): JSX.Element {
  // Get the floating UI context
  const { refs, floatingStyles, context } = useFloating({
    open: showPopover,
    onOpenChange: setShowPopover,
    placement: popoverPlacement,
    // Keep floating element anchored to reference element when scrolling
    whileElementsMounted(referenceEl, floatingEl, update) {
      const cleanup = autoUpdate(referenceEl, floatingEl, update, {
        layoutShift: false,
      });
      return cleanup;
    },
    middleware,
  });

  const interactions = [useClick(context), useDismiss(context)];

  const { getReferenceProps, getFloatingProps } = useInteractions(interactions);

  return (
    <Div>
      {!isEmpty(buttonProps) || buttonContents ? (
        <Button
          {...buttonProps}
          ref={refs.setReference}
          className={buttonClx}
          testId="popover-button"
          {...getReferenceProps({
            onClick(e) {
              e.stopPropagation();

              if (onClick) {
                onClick(e as React.MouseEvent<HTMLButtonElement>);
              }
            },
          })}
        >
          <div className={popoverStyles.buttonContents}>{buttonContents}</div>
        </Button>
      ) : (
        <div
          ref={refs.setReference}
          {...getReferenceProps({
            onClick(e) {
              e.stopPropagation();
            },
          })}
        >
          {divProps?.icon && <Icon icon={divProps.icon} />}
          {divProps?.disabledTooltip && <div>{divProps?.disabledTooltip}</div>}
        </div>
      )}

      {showPopover && (
        <>
          <Div className={popoverStyles.overlay} onClick={() => setShowPopover(false)} />
          <Div
            className={classNames(popoverStyles.className, popoverClx)}
            onClick={(event) => event.stopPropagation()}
            data-popper-placement={popoverPlacement}
            ref={refs.setFloating}
            style={{ ...floatingStyles, width }}
            {...getFloatingProps()}
          >
            <FloatingArrow ref={arrowRef} context={context} className={popoverStyles.arrow} fill="#ffffff" />
            <Button
              className={popoverStyles.closeBtn}
              color={cMonoColorType.Light}
              variant={EButtonVariant.Link}
              icon={EIcon.Close}
              onClick={() => setShowPopover(false)}
            />
            {popoverContents}
          </Div>
        </>
      )}
    </Div>
  );
}

export default Default;
