import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import FormField from "../../../../../components/FormField/FormField";
import Icon, { EIcon } from "../../../../../components/Icon/Icon";
import { selectResetSearch, updateResetUserSearch } from "../../../../../modules/usersSlice";
import styles from "./SearchForm.module.scss";

type Inputs = {
  search: string;
};

interface ISearchForm {
  handleSearch: (value: string | null) => void;
}

/**
 * User list search form
 * @param handleSearch Handle the search
 * @returns JSX.Element
 */
function SearchForm({ handleSearch }: ISearchForm): JSX.Element {
  const {
    register, // Register prop for form inputs
    watch,
    resetField,
  } = useForm<Inputs>();

  const resetUserSearch = useAppSelector(selectResetSearch);
  const dispatch = useAppDispatch();

  // Search input isClearable state
  const [isClearable, setIsClearable] = useState<boolean>(false);

  const searchValue = watch("search");

  // If search value is null, reset the users list and clearable state
  useEffect(() => {
    if (searchValue === "") {
      handleSearch(null);
      setIsClearable(false);
    } else {
      handleSearch(searchValue);
      setIsClearable(true);
    }
  }, [searchValue]);

  // If resetUserSearch is true, reset the search field then set resetUserSearch back to false
  useEffect(() => {
    if (resetUserSearch === true) {
      resetField("search");
      dispatch(updateResetUserSearch(false));
    }
  }, [resetUserSearch]);

  return (
    <form onSubmit={(e) => e.preventDefault()} className={styles.form}>
      <Icon icon={EIcon.Search} className={styles.icon} />
      <FormField
        inputClassName={styles.input}
        name="search"
        register={register}
        testId="user-search-field"
        clearable={isClearable}
        placeholder="Search"
        fullWidth
      />
    </form>
  );
}

export default SearchForm;
