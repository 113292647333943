import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { IUser } from "./usersSlice";

/**
 * Describe the document owners state object
 */
interface IOwnersState {
  entries: Record<number, IUser>; // Document owners records
}

/**
 * Initial state
 */
const initialState: IOwnersState = {
  entries: {},
};

export const ownersSlice = createSlice({
  name: "owners", // The name of the slice
  initialState, // Set the initialState
  reducers: {
    updateOwners: (state, action: PayloadAction<Record<number, IUser>>) => {
      state.entries = action.payload;
    },
  },
});

export const { updateOwners } = ownersSlice.actions;

// Select all owners
export const selectOwners = (state: RootState) => state.owners.entries;

export default ownersSlice.reducer;
