import { lazy, Suspense } from "react";
import Div from "../../components/Div/Div";
import Spinner from "../../components/Spinner/Spinner";

const LazyDocumentsContainer = lazy(() => import("./DocumentsContainer"));

const DocumentsContainer = (props: JSX.IntrinsicAttributes) => (
  <Suspense
    fallback={
      <Div p={{ base: 6 }}>
        <Spinner />
      </Div>
    }
  >
    <LazyDocumentsContainer {...props} />
  </Suspense>
);

export default DocumentsContainer;
