import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

/**
 * Describe the nav bar state object
 */
interface INavBarState {
  isOpen: boolean;
}

/**
 * Initial state
 */
const initialState: INavBarState = { isOpen: false };

/**
 * Create navBar slice
 */
export const navBarSlice = createSlice({
  name: "navBar", // The name of the slice
  initialState, // Set the initialState
  reducers: {
    // Toggle is open state
    toggle: (state) => {
      state.isOpen = state.isOpen === true ? false : true;
    },
  },
});

export const { toggle } = navBarSlice.actions;

// Select isOpen
export const selectIsOpen = (state: RootState) => state.navBar.isOpen;

export default navBarSlice.reducer;
