/**
 * Server error codes mapped to custom error messages
 */
export const customErrors: { [key: number]: string } = {
  910: "Your session has expired. Please sign in to continue",
  25092: "You do not have access to view this document",
  36200: "One or more groups need a minimum number of recipients. Adjust the required number in the group's settings",
  36201: "Please add at least one signer before you continue",
  36203: "This email address has already been used for a recipient in this group",
  25938:
    "Invalid credentials. Please try again, or reset your password. If the problem persists, contact your internal administrator or Updraft support and provide code 25938",
  1334: "Invalid credentials. Please try again, or reset your password. If the problem persists, contact your internal administrator or Updraft support and provide code 1334",
};
