/**
 * Returns true if the error status code is 401
 * @param statusCode The error status code
 * @returns boolean
 */
export function isUnauthorisedError(statusCode: number) {
  return statusCode === 401;
}

/**
 * Returns true if the error status code is 909
 * @param statusCode The error status code
 * @returns boolean
 */
export function isExpiredTokenError(statusCode: number) {
  return statusCode === 909;
}

/**
 * Returns true if the error status code is 910
 * @param statusCode The error status code
 * @returns boolean
 */
export function isInvalidTokenError(statusCode: number) {
  return statusCode === 910;
}

/**
 * Returns true if the error status code is 1338
 * @param statusCode The error status code
 * @returns boolean
 */
export function isNotLoggedInError(statusCode: number) {
  return statusCode === 1338;
}

/**
 * Returns true if the error status code is 1339
 * @param statusCode The error status code
 * @returns boolean
 */
export function isInvalidSessionError(statusCode: number) {
  return statusCode === 1339;
}

/**
 * Returns true if the error status code is 25092
 * @param statusCode The error status code
 * @returns boolean
 */
export function isUnableToViewDocumentError(statusCode: number) {
  return statusCode === 25092;
}

/**
 * Returns true if the error status code is 25910
 * @param statusCode The error status code
 * @returns boolean
 */
export function isUnableToViewMatterError(statusCode: number) {
  return statusCode === 25910;
}

/**
 * Returns true if the error status code is 5000 or 24100
 * @param statusCode The error status code
 * @returns boolean
 */
export function isNotFoundError(statusCode: number) {
  return statusCode === 5000 || statusCode === 24100;
}

/**
 * Returns true if the error status code is 25938 or 1334
 * @param statusCode The error status code
 * @returns boolean
 */
export function isInvalidCredentialsError(statusCode: number) {
  return statusCode === 25938 || statusCode === 1334;
}

/**
 * Returns true if the error status code is 36203
 * @param statusCode The error status code
 * @returns boolean
 */
export function isDuplicateGroupRecipientError(statusCode: number) {
  return statusCode === 36203;
}

/**
 * Returns true if the error status code is 25705
 * @param statusCode The error status code
 * @returns boolean
 */
export function isWalkError(statusCode: number) {
  return statusCode === 25705;
}

/**
 * Returns true if the error status code is 25701
 * @param statusCode The error status code
 * @returns boolean
 */
export function isDocumentAbandonedError(statusCode: number) {
  return statusCode === 25701;
}

/**
 * Returns true if the error status code is 25702
 * @param statusCode The error status code
 * @returns boolean
 */
export function isNoActiveWalkExistsError(statusCode: number) {
  return statusCode === 25702;
}

/**
 * Returns true if the error status code is 35210
 * @param statusCode The error status code
 * @returns boolean
 */
export function isRequestBodyError(statusCode: number) {
  return statusCode === 35210;
}

/**
 * Returns true if the error status code is 1336
 * @param statusCode The error status code
 * @returns boolean
 */
export function isOutdatedContentError(statusCode: number) {
  return statusCode === 1336;
}
