import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom"; // https://reactrouter.com/web/guides/quick-start
import { cAccessType, cRouteType } from "../../../../app/constants";
import Badge from "../../../Badge/Badge";
import Div from "../../../Div/Div";
import Icon, { EIcon } from "../../../Icon/Icon";
import Typography from "../../../Typography/Typography";
import styles from "./MenuItem.module.scss";

export interface IMenuItemProps {
  text: string;
  link: string;
  access: cAccessType;
  badge?: string | number;
  icon?: EIcon;
  handleClose: () => void;
}

/**
 * A single main menu item
 * @param text        The text to display in the menu item
 * @param link        The React Router link to redirect to on click
 * @param access      One of "enabled" | cAccessType.Inaccessible | cAccessType.Denied
 * @param badge       Content for overlay badge (if any)
 * @param icon        Menu item icon
 * @param handleClose Function to close the mobile menu
 * @returns JSX.Element
 */
function MenuItem({ text, link, access, badge, icon, handleClose }: IMenuItemProps): JSX.Element | null {
  if (access === cAccessType.Inaccessible) return null; // Don't render if inaccessible

  const location = useLocation();
  const { pathname } = location;

  const clx = classNames(styles.className, styles[access]);

  return (
    <li className={clx}>
      {access === cAccessType.Enabled ? (
        <NavLink
          to={`/${link}`}
          className={({ isActive }) => {
            // Set matters link active for routes /notifications/matters/12345
            const active =
              pathname.includes(`${cRouteType.Notifications}${cRouteType.Matters}`) && `/${link}` === cRouteType.Matters
                ? true
                : isActive;
            return styles.link + " " + (active ? styles.active : "");
          }}
          onClick={handleClose}
          data-testid={link}
          end={`/${link}` === cRouteType.Notifications}
        >
          <Div className={styles.innerWrapper}>
            {icon && <Icon icon={icon} className={styles.icon} />}
            {text && <Typography className={styles.text}>{text}</Typography>}
            {/* If is accessible, display the link */}
            {badge && <Badge className={styles.badge}>{badge}</Badge>}
          </Div>
        </NavLink>
      ) : (
        <span className={styles.span}>
          <Div className={styles.innerWrapper}>
            {icon && <Icon icon={icon} className={styles.icon} />}
            <Typography className={styles.text}>{text}</Typography> {/* If is accessible, display plain text only */}
          </Div>
        </span>
      )}
    </li>
  );
}

export default MenuItem;
