import { useEffect, useState } from "react";
import { cSemanticColorType, cSizeType, cThemeColorType } from "../../../app/constants";
import { isRequestBodyError, isUnableToViewMatterError, isWalkError } from "../../../utils/errors/errors";
import Icon, { EIcon } from "../../Icon/Icon";
import { ModalIcon } from "../../Modal/Modal";

interface IHandledErrorModalIcon {
  errorCode: number;
}

/**
 * Handled error modal icon
 * @param errorCode The error code being handled
 * @returns JSX.Element
 */
function HandledErrorModalIcon({ errorCode }: IHandledErrorModalIcon) {
  const [content, setContent] = useState<JSX.Element | null>(null);

  useEffect(() => {
    // Set the content based on error code
    if (errorCode) {
      switch (true) {
        case isUnableToViewMatterError(errorCode):
          setContent(<Icon icon={EIcon.Inaccessible} color={cThemeColorType.Primary} size={cSizeType.Large} />);
          break;
        case isWalkError(errorCode):
          setContent(<Icon icon={EIcon.Warning} color={cSemanticColorType.Warning} size={cSizeType.Large} />);
          break;
        case isRequestBodyError(errorCode):
          setContent(<Icon icon={EIcon.Warning} color={cSemanticColorType.Warning} size={cSizeType.Large} />);
          break;
        default:
          setContent(<></>);
          break;
      }
    }
  }, [errorCode]);

  return <ModalIcon>{content}</ModalIcon>;
}
export default HandledErrorModalIcon;
