import Div from "../../../../../components/Div/Div";
import Modal, { ModalContent, ModalHeader, ModalTitle } from "../../../../../components/Modal/Modal";
import Column from "../../../../../components/Row/Column/Column";
import Row from "../../../../../components/Row/Row";
import Typography from "../../../../../components/Typography/Typography";
import { IMatterTag } from "../../../../../modules/matterTagsSlice";
import styles from "./MoreDetailsModal.module.scss";

interface IMoreDetailsModal {
  tags?: IMatterTag[];
  isOpen: boolean;
  handleClose: () => void;
}

/**
 * More details about a matter (tags)
 * @param tags        Matter tags array
 * @param isOpen      Is the modal open?
 * @param handleClose Close the modal
 * @returns JSX.Element
 */
function MoreDetailsModal({ tags, isOpen, handleClose }: IMoreDetailsModal): JSX.Element {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} size="lg" testId="more-details-modal">
      <ModalHeader testId="more-details-modal-header" handleClose={handleClose}>
        <ModalTitle>Matter data</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <Div>
          {tags?.map(({ id, label, value }, i) => (
            <Row key={id} py={{ base: 5 }} className={styles.row} border={i === tags.length - 1 ? {} : { bb: true }}>
              <Column all={3} spacing={{ pr: 5 }}>
                <Typography weight="medium">{label}</Typography>
              </Column>
              <Column all={9} spacing={{ pl: 5 }}>
                {value}
              </Column>
            </Row>
          ))}
        </Div>
      </ModalContent>
    </Modal>
  );
}

export default MoreDetailsModal;
