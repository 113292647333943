import { lazy, Suspense } from "react";
import Div from "../../Div/Div";
import { ICheckboxProps } from "./Checkbox";

const LazyCheckbox = lazy(() => import("./Checkbox"));

const Checkbox = (props: ICheckboxProps) => (
  <Suspense fallback={<Div />}>
    <LazyCheckbox {...props} />
  </Suspense>
);

export default Checkbox;
