import { cAccessType, cMonoColorType, cSizeType, cThemeColorType } from "../../../../../../app/constants";
import Button from "../../../../../../components/Button/Button";
import Div from "../../../../../../components/Div/Div";
import Icon, { EIcon } from "../../../../../../components/Icon/Icon";
import Typography from "../../../../../../components/Typography/Typography";
import { EESignState, IESignGroup } from "../../../../../../modules/esignSlice";
import { EActivityDeleteType } from "../../ConfirmDeleteRecipientModal/ConfirmDeleteActivityModal";
import styles from "../../ESignWorkflow.shared.module.scss";

interface IESignWorkflowGroupHeader {
  group: IESignGroup;
  arrayIndex: number;
  handleDeleteActivity: (id: number, activityType: EActivityDeleteType) => void;
  handleEditGroup: (group: IESignGroup) => void;
  state: EESignState;
}

/**
 * An esign group header
 * @param group                The esign group
 * @param arrayIndex           The index in the array
 * @param handleDeleteActivity Function to delete an activity
 * @param handleEditGroup      Function to edit the group
 * @param state                The esign state
 * @returns JSX.Element
 */
function ESignWorkflowGroupHeader({
  group,
  arrayIndex,
  handleDeleteActivity,
  handleEditGroup,
  state,
}: IESignWorkflowGroupHeader) {
  /**
   * Handles edit button click
   * @param event The mouse event
   * @param id The group id
   */
  function handleEditClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>, group: IESignGroup) {
    event.stopPropagation();
    handleEditGroup(group);
  }

  /**
   * Handles edit button click
   * @param event   The mouse event
   * @param id      The group id
   */
  function handleDeleteClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: number) {
    event.stopPropagation();
    handleDeleteActivity(id, EActivityDeleteType.Group);
  }

  return (
    <Div className={styles.recipientGroup}>
      <Div className={styles.numberWrapper}>
        <>
          <Icon icon={EIcon.Drag} />
          <Div className={styles.indexNumber}>{arrayIndex + 1}</Div>
        </>
      </Div>
      <Div className={styles.contentWrapper}>
        <Div display={{ base: "flex" }} alignItems={{ base: "center" }}>
          <Icon icon={EIcon.Group} />
        </Div>
        <Div>
          <Typography weight="medium">{group.name}</Typography>
        </Div>
        <Div display={{ base: "flex" }} alignItems={{ base: "center" }}>
          <Div display={{ base: "flex" }} mr={{ base: 4 }} testId="required">
            <Typography color={cMonoColorType.Light}>Required:&nbsp;</Typography>
            <Typography weight="medium">{group.required}</Typography>
          </Div>
          <Div display={{ base: "flex" }} testId="completed">
            <Typography color={cMonoColorType.Light}>Completed:&nbsp;</Typography>
            <Typography weight="medium">{group.completed}</Typography>
          </Div>
        </Div>
        <Div /> {/* Empty group item for alignment */}
        {group.action?.edit?.state === cAccessType.Enabled && group && state === EESignState.Configuration && (
          <Div className={styles.editButtonWrapper} onClick={(event) => handleEditClick(event, group)}>
            <Button color={cThemeColorType.Secondary} size={cSizeType.Small} testId="edit-group-button">
              Edit
            </Button>
          </Div>
        )}
      </Div>
      {group.action?.delete?.state === cAccessType.Enabled && !!group.id && state === EESignState.Configuration && (
        <Div
          role="button"
          className={styles.deleteRecipientButton}
          onClick={(event) => handleDeleteClick(event, group.id)}
          testId="delete-recipient-button"
        >
          <Icon icon={EIcon.Close} />
        </Div>
      )}
    </Div>
  );
}

export default ESignWorkflowGroupHeader;
