import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../app/api";
import { securityGroupsUrl } from "../app/apiUrls";
import { cStatusType } from "../app/constants";
import { RootState } from "../app/store";
import createIdList from "../utils/createIdList/createIdList";

/**
 * Describes a security group
 */
export interface ISecurityGroup {
  id: number;
  name: string;
  canBeDeleted: boolean;
  isGlobal: boolean;
  hasChildren: boolean;
}

/**
 * Describes the security group state object
 */
interface ISecurityGroupsState {
  entries: Record<number, ISecurityGroup>; // Notify group records
  status: cStatusType; // API call status
  fetched: boolean; // Have entries been fetched?
  error?: string;
}

/**
 * Initial state
 */
const initialState: ISecurityGroupsState = {
  entries: {},
  status: cStatusType.Idle,
  fetched: false,
};

/**
 * Thunk for fetching security groups
 */
export const postSecurityGroups = createAsyncThunk(
  "securityGroups/postSecurityGroups",
  async (data: number | "all", { dispatch, rejectWithValue }) => {
    try {
      const endpoint = securityGroupsUrl;
      const response = await api({ endpoint, dispatch, body: { id: data } });
      return createIdList(response.data.securityGroup);
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Notify groups reducer
 */
export const securityGroupsSlice = createSlice({
  name: "securityGroups", // The name of the slice
  initialState, // Set the initialState
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // Set status to loading when the promise is pending
      .addCase(postSecurityGroups.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      // Set status to failed if the promise is rejected
      .addCase(postSecurityGroups.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Set status back to idle once the promise has been fulfilled
      .addCase(postSecurityGroups.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.entries = { ...state.entries, ...action.payload };
        state.fetched = true; // Set fetched to true
      });
  },
});

// Select all securityGroups
export const selectSecurityGroups = (state: RootState) => state.securityGroups.entries;

export default securityGroupsSlice.reducer;
