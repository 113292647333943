import { cSizeType, cThemeColorType } from "../../app/constants";
import { IXDirection } from "../../app/types";
import Button from "../Button/Button";
import Icon, { EIcon } from "../Icon/Icon";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalIcon, ModalTitle } from "../Modal/Modal";
import Typography from "../Typography/Typography";

interface IInformationUpdatedModal {
  isOpen: boolean;
}

/**
 * The information updated modal shown when the data is out of sync with BE
 * @param isOpen  Whether the modal is open
 * @returns JSX.Element
 */
function InformationUpdatedModal({ isOpen }: IInformationUpdatedModal): JSX.Element {
  /**
   * Handle page refresh by reloading the page
   */
  function handleRefreshPage() {
    window.location.reload();
  }

  return (
    <Modal isOpen={isOpen} testId="information-updated-modal">
      <ModalIcon>
        <Icon icon={EIcon.Reload} color={cThemeColorType.Primary} size={cSizeType.Large} />
      </ModalIcon>
      <ModalHeader justifyContent={{ base: "center" }}>
        <ModalTitle variant="h3">Information updated</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <Typography align={IXDirection.Center}>The displayed information has changed.</Typography>
        <Typography align={IXDirection.Center}>Please refresh to see the updated version.</Typography>
      </ModalContent>
      <ModalActions justifyContent={{ base: "center" }}>
        <Button onClick={handleRefreshPage}>Refresh</Button>
      </ModalActions>
    </Modal>
  );
}
export default InformationUpdatedModal;
