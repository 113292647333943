import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { cStatusType, cThemeColorType } from "../../../../app/constants";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Button, { cButtonType } from "../../../../components/Button/Button";
import DiscardChangesModal from "../../../../components/DiscardChangesModal/DiscardChangesModal";
import Div from "../../../../components/Div/Div";
import FormField, { cFormFieldType } from "../../../../components/FormField/FormField";
import Label from "../../../../components/FormField/Label/Label";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../components/Modal/Modal";
import Column from "../../../../components/Row/Column/Column";
import Row from "../../../../components/Row/Row";
import Typography from "../../../../components/Typography/Typography";
import { ETimelineEntryType } from "../../../../modules/documentsSlice";
import { postStartNewESign, selectESignStatus } from "../../../../modules/esignSlice";
import { areSomeDefined } from "../../../../utils/areSomeDefined/areSomeDefined";
import styles from "./NewESignModal.module.scss";

type Inputs = {
  dueDate: string | null;
  autoExpire: boolean | null;
  autoRemind: boolean | null;
};

const defaultValues = {
  dueDate: null,
  autoExpire: false,
  autoRemind: false,
};

export interface INewESignModal {
  isOpen: boolean;
  handleClose: (canGoToESignWorkflow: boolean) => void;
  timelineEntryType?: ETimelineEntryType | null;
  esignUrn?: number | null;
  onSubmitSuccess: Function;
}

/**
 * A modal to start the esign process
 * @param isOpen            Is the modal open?
 * @param handleClose       Function to close the modal
 * @param timelineEntryType The timeline entry type
 * @param esignUrn          The esign urn
 * @param onSubmitSuccess   Function called on submit success
 * @returns JSX.Element
 */
function NewESignModal({ isOpen, handleClose, timelineEntryType, esignUrn, onSubmitSuccess }: INewESignModal) {
  const {
    register, // Register prop for form inputs
    handleSubmit, // Submit handler wrapper
    formState: { errors }, // Errors that may occur
    watch,
    reset,
  } = useForm<Inputs>({ defaultValues, mode: "onChange" });
  const [isDiscardChangesModalOpen, setIsDiscardChangesModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const signatureStatus = useAppSelector(selectESignStatus);
  const { dueDate } = watch();

  useEffect(() => {
    // Watch for form changes
    const subscription = watch((values, { type }) => {
      const { dueDate } = values;
      // Reset the form if the date is cleared
      if (type === "change" && !dueDate) {
        reset();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  /**
   * Handles Modal close
   * @param canGoToESignWorkflow Can we go to the esign workflow?
   */
  function handleModalClose(canGoToESignWorkflow: boolean) {
    if (areSomeDefined(watch())) {
      setIsDiscardChangesModalOpen(true);
    } else {
      handleClose(canGoToESignWorkflow);
    }
  }

  /**
   * Handles DiscardChangesModal close
   * @param canDiscard Can we discard the changes?
   */
  function handleDiscardChangesModalClose(canDiscard: boolean) {
    if (canDiscard) {
      handleClose(false);
    }
    setIsDiscardChangesModalOpen(false);
  }

  /**
   * Submit the form
   * @param data The form values on submit
   */
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const { dueDate, autoExpire, autoRemind } = data;
    try {
      if (timelineEntryType && esignUrn) {
        await dispatch(
          postStartNewESign({
            dueDate: dueDate,
            autoExpire: dueDate ? Boolean(autoExpire) : null,
            autoRemind: dueDate ? Boolean(autoRemind) : null,
            timelineEntryType,
            esignUrn,
          }),
        ).unwrap();
        handleClose(true);
        onSubmitSuccess && onSubmitSuccess();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} handleClose={() => handleModalClose(false)} size="md" testId="start-new-signature-modal">
        <ModalHeader>
          <ModalTitle>Start a new e-signature process</ModalTitle>
        </ModalHeader>
        <ModalContent>
          <Typography weight="bold">Settings (You can change these again later)</Typography>
          <Div spacing={{ mt: 6 }}>
            <form id="startNewSignatureForm" onSubmit={handleSubmit(onSubmit)}>
              <Row className={styles.control}>
                <Column all={7} spacing={{ pr: 4 }}>
                  <Label>Due date</Label>
                  <Typography className={styles.controlInfo}>
                    The date by which you want your document signed and/or approved.
                  </Typography>
                </Column>
                <Column
                  all={5}
                  display={{ base: "flex" }}
                  justifyContent={{ base: "end" }}
                  alignItems={{ base: "flex-start" }}
                >
                  <FormField
                    testId="due-date-datepicker"
                    name="dueDate"
                    register={register}
                    type={cFormFieldType.DatePicker}
                    error={errors.dueDate}
                    minDate={new Date()}
                  />
                </Column>
              </Row>
              <Row className={styles.control}>
                <Column all={9} spacing={{ pr: 4 }}>
                  <Label>Auto expire on due date</Label>
                  <Typography className={styles.controlInfo}>
                    If enabled, the signature process will stop on the due date, preventing further signatures.
                  </Typography>
                </Column>
                <Column
                  all={3}
                  display={{ base: "flex" }}
                  justifyContent={{ base: "end" }}
                  alignItems={{ base: "flex-start" }}
                  spacing={{ pt: 3 }}
                >
                  <FormField
                    testId="auto-expire-switch"
                    name="autoExpire"
                    register={register}
                    type={cFormFieldType.Switch}
                    error={errors.autoExpire}
                    options={[
                      {
                        label: "",
                        value: "true",
                      },
                    ]}
                    disabled={!dueDate}
                  />
                </Column>
              </Row>
              <Row className={styles.control}>
                <Column all={9} spacing={{ pr: 4 }}>
                  <Label>Auto remind</Label>
                  <Typography className={styles.controlInfo}>
                    An email will be sent daily to remind recipients of their pending workflow action, except the
                    Updraft initiator, unless they are part of the workflow.
                  </Typography>
                </Column>
                <Column
                  all={3}
                  display={{ base: "flex" }}
                  justifyContent={{ base: "end" }}
                  alignItems={{ base: "flex-start" }}
                  spacing={{ pt: 3 }}
                >
                  <FormField
                    testId="auto-remind-switch"
                    name="autoRemind"
                    register={register}
                    type={cFormFieldType.Switch}
                    error={errors.autoRemind}
                    options={[
                      {
                        label: "",
                        value: "true",
                      },
                    ]}
                    disabled={!dueDate}
                  />
                </Column>
              </Row>
            </form>
          </Div>
        </ModalContent>
        <ModalActions>
          <Button
            color={cThemeColorType.Secondary}
            onClick={() => handleModalClose(false)}
            testId="start-new-signature-modal-cancel-button"
          >
            Cancel
          </Button>
          <Button
            formId="startNewSignatureForm"
            type={cButtonType.SubmitType}
            testId="start-new-signature-modal-next-button"
            isLoading={signatureStatus === cStatusType.Loading}
          >
            Next
          </Button>
        </ModalActions>
      </Modal>
      <DiscardChangesModal
        isOpen={isDiscardChangesModalOpen}
        handleClose={(canDiscard) => handleDiscardChangesModalClose(canDiscard)}
      />
    </>
  );
}

export default NewESignModal;
